<template>
  <Scene>
    <Background
      skyColor="0.949019608 0.941176471 0.901960784"
    ></Background>
    <Viewpoint
      fieldOfView="0.25"
      position="5.67865035461008233e+02 3.87799079517475775e+02 1.25946228169990462e+02"
      description="Default View"
      orientation="9.37145543520705893e-01 2.11497642045378242e-01 2.77537344637664329e-01 -4.91978582149180887e+00"
      centerOfRotation="5.09962586649147852e+02 5.09687954966512621e+02 8.85472278778916433e+01"
    ></Viewpoint>
    <NavigationInfo
      type='"EXAMINE" "FLY" "ANY"'
      speed="4.00000000000000000e+00"
      headlight="true"
    ></NavigationInfo>
    <DirectionalLight
      ambientIntensity="1.00000000000000000e+00"
      intensity="0.00000000000000000e+00"
      color="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
    ></DirectionalLight>
    <Transform
      DEF="ROOT"
      translation="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
    >
      <Transform
        translation="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000006e-01"
              diffuseColor="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00"
              specularColor="0.00000000000000000e+00 0.00000000000000000e+00 5.00000000000000028e-02"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedLineSet
            colorPerVertex="false"
            coordIndex="
            0 1 -1 
            2 3 -1 
            4 5 -1 
            6 7 -1 
            8 9 -1 
            10 11 -1 
            12 13 -1 
            14 15 -1 
            16 17 -1 
            18 19 -1 
            20 21 -1 
            22 23 -1 
            24 25 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0000"
              point="
              5.10000000000000000e+02 5.10000000000000000e+02 9.95000000000000000e+01,
              5.10000000000000000e+02 5.10000000000000000e+02 7.94000015258789063e+01,
              5.00000000000000000e+02 5.01000000000000000e+02 9.83000030517578125e+01,
              5.00000000000000000e+02 5.01000000000000000e+02 7.70999984741210938e+01,
              5.20000000000000000e+02 5.18000000000000000e+02 1.00000000000000000e+02,
              5.20000000000000000e+02 5.18000000000000000e+02 7.70999984741210938e+01,
              5.01000000000000000e+02 5.20000000000000000e+02 1.00000000000000000e+02,
              5.01000000000000000e+02 5.20000000000000000e+02 7.74000015258789063e+01,
              5.19000000000000000e+02 5.02000000000000000e+02 9.96999969482421875e+01,
              5.19000000000000000e+02 5.02000000000000000e+02 7.70999984741210938e+01,
              5.04000000000000000e+02 5.08000000000000000e+02 1.00000000000000000e+02,
              5.04000000000000000e+02 5.08000000000000000e+02 7.70999984741210938e+01,
              5.11000000000000000e+02 5.04000000000000000e+02 1.00000000000000000e+02,
              5.11000000000000000e+02 5.04000000000000000e+02 7.70999984741210938e+01,
              5.15000000000000000e+02 5.12000000000000000e+02 1.00000000000000000e+02,
              5.15000000000000000e+02 5.12000000000000000e+02 7.90000000000000000e+01,
              5.07000000000000000e+02 5.17000000000000000e+02 9.95999984741210938e+01,
              5.07000000000000000e+02 5.17000000000000000e+02 7.76999969482421875e+01,
              5.17000000000000000e+02 5.09000000000000000e+02 9.94000015258789063e+01,
              5.17000000000000000e+02 5.09000000000000000e+02 8.01999969482421875e+01,
              5.01000000000000000e+02 5.11000000000000000e+02 9.95000000000000000e+01,
              5.01000000000000000e+02 5.11000000000000000e+02 8.03000030517578125e+01,
              5.13000000000000000e+02 5.21000000000000000e+02 9.95000000000000000e+01,
              5.13000000000000000e+02 5.21000000000000000e+02 8.05999984741210938e+01,
              5.10000000000000000e+02 5.00000000000000000e+02 9.83000030517578125e+01,
              5.10000000000000000e+02 5.00000000000000000e+02 8.58000030517578125e+01,
              "
            />
          </IndexedLineSet>
        </Shape>
      </Transform>
      <Transform
        translation="5.18997228640831054e+02 4.98803911718817517e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            20 19 21 -1 
            19 18 21 -1 
            18 17 21 -1 
            17 16 21 -1 
            21 16 22 -1 
            16 15 22 -1 
            10 9 11 -1 
            11 9 12 -1 
            12 9 13 -1 
            13 9 14 -1 
            9 8 14 -1 
            14 8 15 -1 
            1 0 39 -1 
            39 38 1 -1 
            38 37 1 -1 
            37 36 1 -1 
            1 36 2 -1 
            36 35 2 -1 
            30 29 31 -1 
            31 29 32 -1 
            32 29 33 -1 
            33 29 34 -1 
            29 28 34 -1 
            34 28 35 -1 
            3 2 4 -1 
            27 26 28 -1 
            7 6 8 -1 
            23 22 24 -1 
            2 35 4 -1 
            4 35 5 -1 
            28 26 35 -1 
            26 25 35 -1 
            35 25 5 -1 
            5 25 6 -1 
            8 6 15 -1 
            6 25 15 -1 
            25 24 15 -1 
            24 22 15 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0001"
              point="
              -4.31812400505874827e-01 -4.15703875336581596e-03 0.00000000000000000e+00,
              -4.17262764869094416e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -4.01154239699801851e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.86084974218850796e-01 -4.15703875336581596e-03 0.00000000000000000e+00,
              -3.74653117647094747e-01 -1.19514864159266931e-02 0.00000000000000000e+00,
              -2.24480092681754395e-01 -2.08891197356632585e-01 0.00000000000000000e+00,
              -7.43070677164140436e-02 -1.19514864159266931e-02 0.00000000000000000e+00,
              -6.33948409888287490e-02 -4.15703875336581596e-03 0.00000000000000000e+00,
              -4.83255755078776938e-02 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.27366801827557730e-02 0.00000000000000000e+00 0.00000000000000000e+00,
              -1.76674147018047178e-02 -3.63740890919506121e-03 0.00000000000000000e+00,
              -6.75518797421942319e-03 -1.45496356367803559e-02 0.00000000000000000e+00,
              -5.19629844170643729e-04 -2.80600115852193133e-02 0.00000000000000000e+00,
              -5.19629844170643729e-04 -4.26096472219996691e-02 0.00000000000000000e+00,
              -6.23555813004866843e-03 -5.61200231704386265e-02 0.00000000000000000e+00,
              -1.67840439667145069e-01 -2.81639375540534476e-01 0.00000000000000000e+00,
              -5.71592828587796919e-03 -5.07678357754801080e-01 0.00000000000000000e+00,
              0.00000000000000000e+00 -5.21708363547410792e-01 0.00000000000000000e+00,
              -5.19629844170643729e-04 -5.35738369340020393e-01 0.00000000000000000e+00,
              -6.75518797421942319e-03 -5.49248745288459350e-01 0.00000000000000000e+00,
              -1.76674147018047178e-02 -5.59641342171873890e-01 0.00000000000000000e+00,
              -3.32563100269265277e-02 -5.63798380925239706e-01 0.00000000000000000e+00,
              -4.88452053520483931e-02 -5.63798380925239706e-01 0.00000000000000000e+00,
              -6.28752111446580497e-02 -5.59641342171873890e-01 0.00000000000000000e+00,
              -7.37874378722432889e-02 -5.51846894509313013e-01 0.00000000000000000e+00,
              -2.24480092681754395e-01 -3.54387553724436422e-01 0.00000000000000000e+00,
              -3.74653117647094747e-01 -5.51846894509313013e-01 0.00000000000000000e+00,
              -3.86084974218850796e-01 -5.60160972016044645e-01 0.00000000000000000e+00,
              -4.00634609855631152e-01 -5.63798380925239706e-01 0.00000000000000000e+00,
              -4.16743135024923717e-01 -5.63798380925239706e-01 0.00000000000000000e+00,
              -4.31292770661704072e-01 -5.59641342171873890e-01 0.00000000000000000e+00,
              -4.42724627233460122e-01 -5.49248745288459350e-01 0.00000000000000000e+00,
              -4.48960185363508846e-01 -5.35738369340020393e-01 0.00000000000000000e+00,
              -4.48960185363508846e-01 -5.21188733703240037e-01 0.00000000000000000e+00,
              -4.42724627233460122e-01 -5.07158727910630436e-01 0.00000000000000000e+00,
              -2.80600115852193022e-01 -2.81639375540534476e-01 0.00000000000000000e+00,
              -4.42724627233460122e-01 -5.61200231704386265e-02 0.00000000000000000e+00,
              -4.48960185363508846e-01 -4.26096472219996691e-02 0.00000000000000000e+00,
              -4.48960185363508846e-01 -2.80600115852193133e-02 0.00000000000000000e+00,
              -4.43244257077630821e-01 -1.45496356367803559e-02 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.98004888433184760e+02 5.19947228640831099e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            29 28 30 -1 
            28 27 30 -1 
            27 26 30 -1 
            26 25 30 -1 
            25 24 30 -1 
            24 23 30 -1 
            23 22 30 -1 
            22 21 30 -1 
            33 32 21 -1 
            32 31 21 -1 
            31 30 21 -1 
            21 20 33 -1 
            33 20 34 -1 
            1 0 2 -1 
            2 0 3 -1 
            3 0 4 -1 
            4 0 5 -1 
            5 0 6 -1 
            6 0 7 -1 
            0 74 7 -1 
            48 47 49 -1 
            49 47 50 -1 
            50 47 51 -1 
            47 46 51 -1 
            51 46 52 -1 
            46 45 52 -1 
            45 44 52 -1 
            52 44 53 -1 
            44 43 53 -1 
            43 42 53 -1 
            42 41 53 -1 
            41 40 53 -1 
            53 40 54 -1 
            40 39 54 -1 
            54 39 55 -1 
            39 38 55 -1 
            38 37 55 -1 
            37 36 55 -1 
            36 35 55 -1 
            73 72 74 -1 
            72 71 74 -1 
            74 71 7 -1 
            71 70 7 -1 
            69 68 70 -1 
            68 67 70 -1 
            67 66 70 -1 
            66 65 70 -1 
            65 64 70 -1 
            55 35 56 -1 
            56 35 57 -1 
            35 34 57 -1 
            57 34 58 -1 
            58 34 59 -1 
            59 34 60 -1 
            60 34 61 -1 
            61 34 62 -1 
            62 34 63 -1 
            63 34 64 -1 
            34 20 64 -1 
            20 19 64 -1 
            19 18 64 -1 
            64 18 70 -1 
            18 17 70 -1 
            17 16 70 -1 
            16 15 70 -1 
            15 14 70 -1 
            14 13 70 -1 
            13 12 70 -1 
            12 11 70 -1 
            11 10 70 -1 
            10 9 70 -1 
            70 9 7 -1 
            9 8 7 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0002"
              point="
              -4.47920925675167392e-01 3.60103482010314446e-01 0.00000000000000000e+00,
              -4.45322776454313729e-01 3.74653117647094802e-01 0.00000000000000000e+00,
              -4.35969439259240643e-01 3.87643863751363005e-01 0.00000000000000000e+00,
              -4.22978693154972440e-01 3.95957941258094637e-01 0.00000000000000000e+00,
              -4.07389797829850631e-01 3.99075720323119054e-01 0.00000000000000000e+00,
              -3.91281272660558066e-01 3.95957941258094637e-01 0.00000000000000000e+00,
              -3.77770896712119164e-01 3.87643863751363005e-01 0.00000000000000000e+00,
              -3.68937189361216777e-01 3.75172747491265446e-01 0.00000000000000000e+00,
              -3.65819410296192360e-01 3.60623111854485201e-01 0.00000000000000000e+00,
              -3.65819410296192360e-01 3.11777906502436197e-03 0.00000000000000000e+00,
              -3.63221261075338753e-01 -9.87296703924384067e-03 0.00000000000000000e+00,
              -3.54907183568607065e-01 -2.75403817410485585e-02 0.00000000000000000e+00,
              -3.43475326996851127e-01 -4.46881665986825910e-02 0.00000000000000000e+00,
              -3.31523840580924323e-01 -5.66396530146093397e-02 0.00000000000000000e+00,
              -3.12297536346607396e-01 -6.65126200538531526e-02 0.00000000000000000e+00,
              -2.82678635228875930e-01 -7.63855870930969932e-02 0.00000000000000000e+00,
              -2.50461584890290800e-01 -8.36604049114871851e-02 0.00000000000000000e+00,
              -2.23960462837583696e-01 -8.57389242881700930e-02 0.00000000000000000e+00,
              -1.89664893122315659e-01 -8.36604049114871851e-02 0.00000000000000000e+00,
              -1.49653395121169652e-01 -7.74248467814384472e-02 0.00000000000000000e+00,
              -1.11720416496706443e-01 -6.85911394305360744e-02 0.00000000000000000e+00,
              -8.21015153789749763e-02 -5.81985425471215206e-02 0.00000000000000000e+00,
              -8.21015153789749763e-02 3.60623111854485201e-01 0.00000000000000000e+00,
              -7.95033661581213136e-02 3.75172747491265446e-01 0.00000000000000000e+00,
              -7.01500289630482277e-02 3.87643863751363005e-01 0.00000000000000000e+00,
              -5.71592828587800250e-02 3.95957941258094637e-01 0.00000000000000000e+00,
              -4.15703875336582152e-02 3.99075720323119054e-01 0.00000000000000000e+00,
              -2.54618623643656505e-02 3.95957941258094637e-01 0.00000000000000000e+00,
              -1.19514864159266931e-02 3.87643863751363005e-01 0.00000000000000000e+00,
              -3.11777906502430646e-03 3.75172747491265446e-01 0.00000000000000000e+00,
              0.00000000000000000e+00 3.60623111854485201e-01 0.00000000000000000e+00,
              0.00000000000000000e+00 -7.89837363139506421e-02 0.00000000000000000e+00,
              0.00000000000000000e+00 -8.21015153789750041e-02 0.00000000000000000e+00,
              0.00000000000000000e+00 -2.27597871746778813e-01 0.00000000000000000e+00,
              -7.79444766256087718e-03 -2.74884187566315052e-01 0.00000000000000000e+00,
              -3.11777906502436197e-02 -3.16974204944143967e-01 0.00000000000000000e+00,
              -6.75518797421945649e-02 -3.52309034347753514e-01 0.00000000000000000e+00,
              -1.13798935873389351e-01 -3.77770896712119164e-01 0.00000000000000000e+00,
              -1.67320809822974370e-01 -3.93359792037240974e-01 0.00000000000000000e+00,
              -2.25519352370095905e-01 -3.99075720323118999e-01 0.00000000000000000e+00,
              -2.83198265073046684e-01 -3.95438311413923882e-01 0.00000000000000000e+00,
              -3.40357547931826709e-01 -3.84526084686338643e-01 0.00000000000000000e+00,
              -3.91800902504728765e-01 -3.67897929672875323e-01 0.00000000000000000e+00,
              -4.32851660194216281e-01 -3.47112735906046188e-01 0.00000000000000000e+00,
              -4.44283516765972275e-01 -3.35680879334290194e-01 0.00000000000000000e+00,
              -4.49999445051850300e-01 -3.21131243697509783e-01 0.00000000000000000e+00,
              -4.49999445051850300e-01 -3.05542348372388028e-01 0.00000000000000000e+00,
              -4.43763886921801576e-01 -2.90992712735607617e-01 0.00000000000000000e+00,
              -4.32851660194216281e-01 -2.79560856163851623e-01 0.00000000000000000e+00,
              -4.17782394713265171e-01 -2.73844927877973598e-01 0.00000000000000000e+00,
              -4.02193499388143305e-01 -2.73844927877973598e-01 0.00000000000000000e+00,
              -3.87124233907192195e-01 -2.80080486008022322e-01 0.00000000000000000e+00,
              -3.58544592477802238e-01 -2.93590861956461224e-01 0.00000000000000000e+00,
              -3.16454575099973212e-01 -3.06061978216558728e-01 0.00000000000000000e+00,
              -2.69168259280437028e-01 -3.15415315411631814e-01 0.00000000000000000e+00,
              -2.25519352370095905e-01 -3.18533094476656176e-01 0.00000000000000000e+00,
              -1.93821931875681475e-01 -3.16454575099973268e-01 0.00000000000000000e+00,
              -1.64722660602120707e-01 -3.10738646814095243e-01 0.00000000000000000e+00,
              -1.39260798237755057e-01 -3.01385309619022157e-01 0.00000000000000000e+00,
              -1.18995234315096676e-01 -2.89433823203095408e-01 0.00000000000000000e+00,
              -1.03406338989974811e-01 -2.75923447254656506e-01 0.00000000000000000e+00,
              -9.19744824182188170e-02 -2.60854181773705396e-01 0.00000000000000000e+00,
              -8.46996645998286390e-02 -2.45265286448583558e-01 0.00000000000000000e+00,
              -8.21015153789749763e-02 -2.30196020967632448e-01 0.00000000000000000e+00,
              -8.21015153789749763e-02 -1.43937466835291628e-01 0.00000000000000000e+00,
              -1.50692654809511051e-01 -1.61085251692925646e-01 0.00000000000000000e+00,
              -2.24480092681754451e-01 -1.66281550134632916e-01 0.00000000000000000e+00,
              -2.67609369747924819e-01 -1.63163771069608554e-01 0.00000000000000000e+00,
              -3.11258276658265998e-01 -1.53810433874535440e-01 0.00000000000000000e+00,
              -3.51269774659412004e-01 -1.39260798237755057e-01 0.00000000000000000e+00,
              -3.82447565309655679e-01 -1.20554123847608857e-01 0.00000000000000000e+00,
              -4.08948687362362784e-01 -9.66511510157553738e-02 0.00000000000000000e+00,
              -4.29733881129191919e-01 -6.59929902096824533e-02 0.00000000000000000e+00,
              -4.43763886921801576e-01 -3.27366801827558562e-02 0.00000000000000000e+00,
              -4.47920925675167392e-01 5.19629844170726996e-04 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.98940945948910723e+02 4.98917945948910756e+02 9.88522285645371426e+01"
        rotation="8.55167184663299773e-01 3.66530412295518748e-01 3.66530412295518804e-01 -1.72662016832750753e+00"
        scale="1.00000000000000022e+00 1.00000000000000022e+00 1.00000000000000022e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            20 19 21 -1 
            19 18 21 -1 
            18 17 21 -1 
            21 17 22 -1 
            22 17 23 -1 
            17 16 23 -1 
            3 2 4 -1 
            2 1 4 -1 
            1 0 4 -1 
            4 0 5 -1 
            5 0 6 -1 
            0 33 6 -1 
            30 29 31 -1 
            29 28 31 -1 
            28 27 31 -1 
            27 26 31 -1 
            31 26 32 -1 
            26 25 32 -1 
            25 24 32 -1 
            13 12 14 -1 
            12 11 14 -1 
            11 10 14 -1 
            14 10 15 -1 
            10 9 15 -1 
            15 9 16 -1 
            9 8 16 -1 
            8 7 16 -1 
            16 7 23 -1 
            23 7 24 -1 
            7 6 24 -1 
            6 33 24 -1 
            33 32 24 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0003"
              point="
              -4.23498322999143140e-01 -2.82678635228875985e-01 0.00000000000000000e+00,
              -4.40126478012606459e-01 -2.80080486008022322e-01 0.00000000000000000e+00,
              -4.53636853961045361e-01 -2.71766408501290690e-01 0.00000000000000000e+00,
              -4.62990191156118447e-01 -2.59295292241193243e-01 0.00000000000000000e+00,
              -4.65588340376972110e-01 -2.44226026760242132e-01 0.00000000000000000e+00,
              -4.64029450844459901e-01 -2.33313800032656837e-01 0.00000000000000000e+00,
              -4.58833152402752631e-01 -2.22921203149242270e-01 0.00000000000000000e+00,
              -1.21593383535950283e-01 2.02136009382413107e-01 0.00000000000000000e+00,
              -4.23498322999143140e-01 2.02136009382413107e-01 0.00000000000000000e+00,
              -4.39606848168435704e-01 2.04734158603266769e-01 0.00000000000000000e+00,
              -4.53117224116874662e-01 2.14087495798339800e-01 0.00000000000000000e+00,
              -4.62470561311947748e-01 2.27078241902608002e-01 0.00000000000000000e+00,
              -4.65588340376972110e-01 2.42147507383559224e-01 0.00000000000000000e+00,
              -4.62470561311947748e-01 2.57216772864510224e-01 0.00000000000000000e+00,
              -4.53117224116874662e-01 2.70727148812949181e-01 0.00000000000000000e+00,
              -4.39606848168435704e-01 2.79560856163851568e-01 0.00000000000000000e+00,
              -4.23498322999143140e-01 2.82678635228875985e-01 0.00000000000000000e+00,
              -4.26096472219996691e-02 2.82678635228875985e-01 0.00000000000000000e+00,
              -2.65011220527071045e-02 2.79560856163851568e-01 0.00000000000000000e+00,
              -1.24711162600973924e-02 2.71246778657119936e-01 0.00000000000000000e+00,
              -3.11777906502430646e-03 2.58775662397022377e-01 0.00000000000000000e+00,
              5.55111512312578270e-17 2.44226026760242132e-01 0.00000000000000000e+00,
              -2.07851937668290798e-03 2.32794170188486083e-01 0.00000000000000000e+00,
              -6.75518797421936767e-03 2.22921203149242186e-01 0.00000000000000000e+00,
              -3.43994956841021771e-01 -2.02136009382413162e-01 0.00000000000000000e+00,
              -4.26096472219996691e-02 -2.02136009382413162e-01 0.00000000000000000e+00,
              -2.65011220527071045e-02 -2.05253788447437524e-01 0.00000000000000000e+00,
              -1.24711162600973924e-02 -2.14087495798339911e-01 0.00000000000000000e+00,
              -3.11777906502430646e-03 -2.27597871746778840e-01 0.00000000000000000e+00,
              5.55111512312578270e-17 -2.42667137227729923e-01 0.00000000000000000e+00,
              -3.63740890919506121e-03 -2.57736402708681034e-01 0.00000000000000000e+00,
              -1.29907461042681471e-02 -2.70727148812949237e-01 0.00000000000000000e+00,
              -2.70207518968777483e-02 -2.80080486008022322e-01 0.00000000000000000e+00,
              -4.26096472219996691e-02 -2.82678635228875985e-01 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99997228640831111e+02 4.99997228640831111e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="1.00000000000000019e-02 1.00000000000000019e-02 1.00000000000000019e-02"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedLineSet
            colorPerVertex="false"
            coordIndex="
            0 1 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0004"
              point="
              0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00,
              2.00000000000000000e+01 0.00000000000000000e+00 0.00000000000000000e+00,
              "
            />
          </IndexedLineSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99997228640831111e+02 4.99997228640831111e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="1.00000000000000019e-02 1.00000000000000019e-02 1.00000000000000019e-02"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedLineSet
            colorPerVertex="false"
            coordIndex="
            0 1 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0005"
              point="
              0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00,
              0.00000000000000000e+00 2.10000000000000000e+01 0.00000000000000000e+00,
              "
            />
          </IndexedLineSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99997228640831111e+02 4.99997228640831111e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="1.00000000000000019e-02 1.00000000000000019e-02 1.00000000000000019e-02"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedLineSet
            colorPerVertex="false"
            coordIndex="
            0 1 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0006"
              point="
              0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00,
              0.00000000000000000e+00 0.00000000000000000e+00 2.29000015258789063e+01,
              "
            />
          </IndexedLineSet>
        </Shape>
      </Transform>
      <Transform
        translation="5.04997228640831111e+02 4.99588575103220251e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            15 14 16 -1 
            16 14 17 -1 
            17 14 18 -1 
            12 11 13 -1 
            11 10 13 -1 
            10 9 13 -1 
            9 8 13 -1 
            24 23 25 -1 
            23 22 25 -1 
            22 21 25 -1 
            25 21 26 -1 
            21 20 26 -1 
            26 20 27 -1 
            20 19 27 -1 
            27 19 28 -1 
            19 18 28 -1 
            18 14 28 -1 
            14 13 28 -1 
            28 13 29 -1 
            13 8 29 -1 
            29 8 30 -1 
            30 8 31 -1 
            8 7 31 -1 
            31 7 32 -1 
            7 6 32 -1 
            32 6 33 -1 
            6 5 33 -1 
            33 5 34 -1 
            34 5 35 -1 
            35 5 36 -1 
            36 5 37 -1 
            55 54 56 -1 
            56 54 57 -1 
            57 54 58 -1 
            58 54 59 -1 
            54 53 59 -1 
            59 53 60 -1 
            60 53 61 -1 
            61 53 62 -1 
            62 53 63 -1 
            63 53 64 -1 
            64 53 65 -1 
            53 52 65 -1 
            52 51 65 -1 
            51 50 65 -1 
            50 49 65 -1 
            49 48 65 -1 
            48 47 65 -1 
            39 38 40 -1 
            38 37 40 -1 
            40 37 41 -1 
            41 37 42 -1 
            42 37 43 -1 
            43 37 44 -1 
            44 37 45 -1 
            46 45 47 -1 
            5 4 37 -1 
            4 3 37 -1 
            3 2 37 -1 
            2 1 37 -1 
            1 0 37 -1 
            0 79 37 -1 
            66 65 67 -1 
            37 79 45 -1 
            79 78 45 -1 
            78 77 45 -1 
            45 77 47 -1 
            77 76 47 -1 
            76 75 47 -1 
            75 74 47 -1 
            74 73 47 -1 
            73 72 47 -1 
            72 71 47 -1 
            71 70 47 -1 
            67 65 68 -1 
            65 47 68 -1 
            68 47 69 -1 
            70 69 47 -1 
            127 80 128 -1 
            80 109 128 -1 
            95 111 96 -1 
            111 110 96 -1 
            128 109 129 -1 
            129 109 130 -1 
            109 108 130 -1 
            130 108 131 -1 
            131 108 132 -1 
            108 107 132 -1 
            132 107 133 -1 
            107 106 133 -1 
            133 106 134 -1 
            106 105 134 -1 
            134 105 135 -1 
            105 104 135 -1 
            135 104 136 -1 
            136 104 137 -1 
            104 103 137 -1 
            137 103 138 -1 
            103 102 138 -1 
            138 102 139 -1 
            102 101 139 -1 
            139 101 140 -1 
            101 100 140 -1 
            140 100 141 -1 
            96 110 97 -1 
            110 141 97 -1 
            100 99 141 -1 
            99 98 141 -1 
            141 98 97 -1 
            111 95 112 -1 
            127 126 80 -1 
            81 80 126 -1 
            126 125 81 -1 
            81 125 82 -1 
            125 124 82 -1 
            124 123 82 -1 
            95 94 112 -1 
            112 94 113 -1 
            82 123 83 -1 
            123 122 83 -1 
            83 122 84 -1 
            122 121 84 -1 
            84 121 85 -1 
            121 120 85 -1 
            85 120 86 -1 
            120 119 86 -1 
            86 119 87 -1 
            119 118 87 -1 
            87 118 88 -1 
            118 117 88 -1 
            88 117 89 -1 
            117 116 89 -1 
            89 116 90 -1 
            116 115 90 -1 
            90 115 91 -1 
            115 114 91 -1 
            91 114 92 -1 
            114 113 92 -1 
            92 113 93 -1 
            113 94 93 -1 
            157 156 158 -1 
            158 156 159 -1 
            159 156 160 -1 
            154 153 155 -1 
            153 152 155 -1 
            152 151 155 -1 
            151 150 155 -1 
            166 165 167 -1 
            165 164 167 -1 
            164 163 167 -1 
            167 163 168 -1 
            163 162 168 -1 
            168 162 169 -1 
            162 161 169 -1 
            169 161 170 -1 
            161 160 170 -1 
            160 156 170 -1 
            156 155 170 -1 
            170 155 171 -1 
            155 150 171 -1 
            171 150 172 -1 
            172 150 173 -1 
            150 149 173 -1 
            173 149 174 -1 
            149 148 174 -1 
            174 148 175 -1 
            148 147 175 -1 
            175 147 176 -1 
            176 147 177 -1 
            177 147 178 -1 
            178 147 179 -1 
            197 196 198 -1 
            198 196 199 -1 
            199 196 200 -1 
            200 196 201 -1 
            196 195 201 -1 
            201 195 202 -1 
            202 195 203 -1 
            203 195 204 -1 
            204 195 205 -1 
            205 195 206 -1 
            206 195 207 -1 
            195 194 207 -1 
            194 193 207 -1 
            193 192 207 -1 
            192 191 207 -1 
            191 190 207 -1 
            190 189 207 -1 
            181 180 182 -1 
            180 179 182 -1 
            182 179 183 -1 
            183 179 184 -1 
            184 179 185 -1 
            185 179 186 -1 
            186 179 187 -1 
            188 187 189 -1 
            147 146 179 -1 
            146 145 179 -1 
            145 144 179 -1 
            144 143 179 -1 
            143 142 179 -1 
            142 221 179 -1 
            208 207 209 -1 
            179 221 187 -1 
            221 220 187 -1 
            220 219 187 -1 
            187 219 189 -1 
            219 218 189 -1 
            218 217 189 -1 
            217 216 189 -1 
            216 215 189 -1 
            215 214 189 -1 
            214 213 189 -1 
            213 212 189 -1 
            209 207 210 -1 
            207 189 210 -1 
            210 189 211 -1 
            212 211 189 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0007"
              point="
              -3.46863313580844268e-01 -3.80784749808309342e-01 0.00000000000000000e+00,
              -3.49523818382998397e-01 -3.48193565981921305e-01 0.00000000000000000e+00,
              -3.56507643488652937e-01 -3.25246712063341992e-01 0.00000000000000000e+00,
              -3.68147351998077244e-01 -3.08618557049878672e-01 0.00000000000000000e+00,
              -3.82780128409924980e-01 -2.93985780638030991e-01 0.00000000000000000e+00,
              -4.01403662025003882e-01 -2.82013509028337439e-01 0.00000000000000000e+00,
              -4.23685389743044705e-01 -2.72701742220797960e-01 0.00000000000000000e+00,
              -4.48627622263239600e-01 -2.66715606415951212e-01 0.00000000000000000e+00,
              -4.75232670284780889e-01 -2.64387664714066384e-01 0.00000000000000000e+00,
              -6.29874511909989443e-01 -2.64387664714066384e-01 0.00000000000000000e+00,
              -6.40183968018336658e-01 -2.62724849212719991e-01 0.00000000000000000e+00,
              -6.48498045525068401e-01 -2.57071276508142488e-01 0.00000000000000000e+00,
              -6.54484181329915149e-01 -2.49089762101680101e-01 0.00000000000000000e+00,
              -6.56146996831261431e-01 -2.39445432193871433e-01 0.00000000000000000e+00,
              -6.56146996831261431e-01 -2.49422325201949513e-02 0.00000000000000000e+00,
              -6.54484181329915149e-01 -1.56304657126554725e-02 0.00000000000000000e+00,
              -6.48498045525068401e-01 -7.31638820592384054e-03 0.00000000000000000e+00,
              -6.40183968018336658e-01 -1.99537860161558278e-03 0.00000000000000000e+00,
              -6.29874511909989443e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.20590828659572280e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.10946498751763556e-01 -1.99537860161558278e-03 0.00000000000000000e+00,
              -3.02299858144762623e-01 -7.64895130619314134e-03 0.00000000000000000e+00,
              -2.96313722339915875e-01 -1.62955919131940186e-02 0.00000000000000000e+00,
              -2.94318343738300237e-01 -2.59399218210027982e-02 0.00000000000000000e+00,
              -2.96313722339915875e-01 -3.59168148290807676e-02 0.00000000000000000e+00,
              -3.02299858144762623e-01 -4.42308923358123440e-02 0.00000000000000000e+00,
              -3.10946498751763556e-01 -4.98844650403898471e-02 0.00000000000000000e+00,
              -3.20590828659572280e-01 -5.15472805417362401e-02 0.00000000000000000e+00,
              -6.03602026988717455e-01 -5.15472805417362401e-02 0.00000000000000000e+00,
              -6.03602026988717455e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              -4.75232670284780889e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              -4.38983292355430876e-01 -2.15833452074753518e-01 0.00000000000000000e+00,
              -4.04729293027696502e-01 -2.24480092681754395e-01 0.00000000000000000e+00,
              -3.73468361602385501e-01 -2.38115179792794340e-01 0.00000000000000000e+00,
              -3.46863313580844268e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -3.25246712063341992e-01 -2.77357625624567727e-01 0.00000000000000000e+00,
              -3.08618557049878672e-01 -3.02964984345301169e-01 0.00000000000000000e+00,
              -2.97976537841262157e-01 -3.31565410968458041e-01 0.00000000000000000e+00,
              -2.94318343738300237e-01 -3.61828653092961194e-01 0.00000000000000000e+00,
              -2.97976537841262157e-01 -3.92091895217464403e-01 0.00000000000000000e+00,
              -3.08618557049878672e-01 -4.20692321840621275e-01 0.00000000000000000e+00,
              -3.25246712063341992e-01 -4.46632243661624018e-01 0.00000000000000000e+00,
              -3.46863313580844268e-01 -4.68248845179126294e-01 0.00000000000000000e+00,
              -3.73468361602385501e-01 -4.85874689493397349e-01 0.00000000000000000e+00,
              -4.04729293027696502e-01 -4.99509776604437239e-01 0.00000000000000000e+00,
              -4.38983292355430876e-01 -5.08156417211438227e-01 0.00000000000000000e+00,
              -4.75232670284780889e-01 -5.10816922013592301e-01 0.00000000000000000e+00,
              -5.22124067422747418e-01 -5.07158727910630325e-01 0.00000000000000000e+00,
              -5.69680590761252326e-01 -4.95519019401206073e-01 0.00000000000000000e+00,
              -6.12248667595718388e-01 -4.78225738187204263e-01 0.00000000000000000e+00,
              -6.43842162121298633e-01 -4.55944010469163441e-01 0.00000000000000000e+00,
              -6.47832919324529799e-01 -4.52285816366201521e-01 0.00000000000000000e+00,
              -6.50825987226953173e-01 -4.48295059162970300e-01 0.00000000000000000e+00,
              -6.53486492029107358e-01 -4.43639175759200644e-01 0.00000000000000000e+00,
              -6.55481870630722940e-01 -4.38650729255161631e-01 0.00000000000000000e+00,
              -6.56812123031800033e-01 -4.32997156550584128e-01 0.00000000000000000e+00,
              -6.55814433730992241e-01 -4.27011020745737324e-01 0.00000000000000000e+00,
              -6.53486492029107358e-01 -4.21357448041159821e-01 0.00000000000000000e+00,
              -6.48830608625337590e-01 -4.15371312236313017e-01 0.00000000000000000e+00,
              -6.43509599021029333e-01 -4.11047991932812551e-01 0.00000000000000000e+00,
              -6.37523463216182584e-01 -4.08387487130658422e-01 0.00000000000000000e+00,
              -6.31869890511605026e-01 -4.07389797829850631e-01 0.00000000000000000e+00,
              -6.26881444007566069e-01 -4.07389797829850631e-01 0.00000000000000000e+00,
              -6.21892997503527112e-01 -4.09052613331196968e-01 0.00000000000000000e+00,
              -6.16571987899218854e-01 -4.11380555033081796e-01 0.00000000000000000e+00,
              -6.11916104495449087e-01 -4.14041059835235981e-01 0.00000000000000000e+00,
              -6.07925347292217921e-01 -4.17034127737659355e-01 0.00000000000000000e+00,
              -6.04599716289525246e-01 -4.20027195640082729e-01 0.00000000000000000e+00,
              -6.01274085286832571e-01 -4.22687700442236858e-01 0.00000000000000000e+00,
              -5.98613580484678498e-01 -4.25015642144121741e-01 0.00000000000000000e+00,
              -5.97283328083601406e-01 -4.26013331444929533e-01 0.00000000000000000e+00,
              -5.74669037265291394e-01 -4.37985603054623085e-01 0.00000000000000000e+00,
              -5.41745290338634056e-01 -4.48960185363508846e-01 0.00000000000000000e+00,
              -5.06161038609822533e-01 -4.56941699769971232e-01 0.00000000000000000e+00,
              -4.75232670284780889e-01 -4.59269641471856116e-01 0.00000000000000000e+00,
              -4.48627622263239600e-01 -4.57274262870240533e-01 0.00000000000000000e+00,
              -4.23685389743044705e-01 -4.51288127065393729e-01 0.00000000000000000e+00,
              -4.01403662025003882e-01 -4.41976360257854251e-01 0.00000000000000000e+00,
              -3.82780128409924980e-01 -4.29671525547891453e-01 0.00000000000000000e+00,
              -3.65486847195923115e-01 -4.11047991932812551e-01 0.00000000000000000e+00,
              2.16166015175022208e-02 -5.15472805417362401e-02 0.00000000000000000e+00,
              -4.95519019401206018e-02 -5.45403484441596698e-02 0.00000000000000000e+00,
              -9.34502311756637560e-02 -6.28544259508912462e-02 0.00000000000000000e+00,
              -1.21718094698551327e-01 -7.74872023627389273e-02 0.00000000000000000e+00,
              -1.44997511717399941e-01 -9.87712407799719583e-02 0.00000000000000000e+00,
              -1.63288482232209486e-01 -1.26706541202590339e-01 0.00000000000000000e+00,
              -1.75925880042441585e-01 -1.61958229831132450e-01 0.00000000000000000e+00,
              -1.83574831348634726e-01 -2.04858869765867757e-01 0.00000000000000000e+00,
              -1.85902773050519610e-01 -2.55741024107065396e-01 0.00000000000000000e+00,
              -1.83574831348634726e-01 -3.06290615347993844e-01 0.00000000000000000e+00,
              -1.75925880042441585e-01 -3.49191255282729096e-01 0.00000000000000000e+00,
              -1.63288482232209486e-01 -3.84442943911271318e-01 0.00000000000000000e+00,
              -1.44997511717399941e-01 -4.12378244333889588e-01 0.00000000000000000e+00,
              -1.21718094698551327e-01 -4.33662282751122619e-01 0.00000000000000000e+00,
              -9.34502311756637560e-02 -4.47962496062701054e-01 0.00000000000000000e+00,
              -6.01939211487371173e-02 -4.56609136669701987e-01 0.00000000000000000e+00,
              -2.06189122166945404e-02 -4.59269641471856116e-01 0.00000000000000000e+00,
              1.86235336150788466e-02 -4.56609136669701987e-01 0.00000000000000000e+00,
              5.22124067422746752e-02 -4.47962496062701054e-01 0.00000000000000000e+00,
              8.01477071648929451e-02 -4.33662282751122619e-01 0.00000000000000000e+00,
              1.03427124183741670e-01 -4.12378244333889588e-01 0.00000000000000000e+00,
              1.21718094698551216e-01 -3.84442943911271318e-01 0.00000000000000000e+00,
              1.34355492508783425e-01 -3.49191255282729096e-01 0.00000000000000000e+00,
              1.42004443814976455e-01 -3.06290615347993844e-01 0.00000000000000000e+00,
              1.44664948617130640e-01 -2.55741024107065396e-01 0.00000000000000000e+00,
              1.42004443814976455e-01 -2.04858869765867757e-01 0.00000000000000000e+00,
              1.34355492508783425e-01 -1.61958229831132450e-01 0.00000000000000000e+00,
              1.21718094698551216e-01 -1.26706541202590339e-01 0.00000000000000000e+00,
              1.03427124183741670e-01 -9.87712407799719583e-02 0.00000000000000000e+00,
              7.24987558586999148e-02 -7.48266975605847984e-02 0.00000000000000000e+00,
              3.42539993277343191e-02 -5.10816922013592301e-01 0.00000000000000000e+00,
              -5.88636687476600251e-02 -5.06493601710091834e-01 0.00000000000000000e+00,
              -1.17394774395050860e-01 -4.93523640799590491e-01 0.00000000000000000e+00,
              -1.55306967825747155e-01 -4.72239602382357460e-01 0.00000000000000000e+00,
              -1.85570209950250420e-01 -4.42974049558662097e-01 0.00000000000000000e+00,
              -2.08849626969099034e-01 -4.06724671629312085e-01 0.00000000000000000e+00,
              -2.25145218882292941e-01 -3.63158905494038287e-01 0.00000000000000000e+00,
              -2.35122111890370911e-01 -3.12609314253109893e-01 0.00000000000000000e+00,
              -2.38447742893063586e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -2.35122111890370911e-01 -1.98540170860751708e-01 0.00000000000000000e+00,
              -2.25145218882292941e-01 -1.47990579619823315e-01 0.00000000000000000e+00,
              -2.08849626969099034e-01 -1.04424813484549517e-01 0.00000000000000000e+00,
              -1.85570209950250420e-01 -6.78428724549302586e-02 0.00000000000000000e+00,
              -1.55306967825747155e-01 -3.85773196312348965e-02 0.00000000000000000e+00,
              -1.17394774395050860e-01 -1.72932812140018655e-02 0.00000000000000000e+00,
              -7.24987558587000258e-02 -4.32332030350046637e-03 0.00000000000000000e+00,
              -2.06189122166945404e-02 0.00000000000000000e+00 0.00000000000000000e+00,
              2.09514753169637302e-02 -2.99306790242337417e-03 0.00000000000000000e+00,
              5.88636687476599141e-02 -1.13071454091550616e-02 0.00000000000000000e+00,
              9.21199787745866638e-02 -2.49422325201949513e-02 0.00000000000000000e+00,
              1.20387842297474235e-01 -4.38983292355431542e-02 0.00000000000000000e+00,
              1.43667259316322848e-01 -6.78428724549302586e-02 0.00000000000000000e+00,
              1.63288482232209486e-01 -9.64432990780871302e-02 0.00000000000000000e+00,
              1.78253821744326357e-01 -1.30032172205282959e-01 0.00000000000000000e+00,
              1.88895840952942873e-01 -1.67611802535710008e-01 0.00000000000000000e+00,
              1.94881976757789732e-01 -2.09514753169637469e-01 0.00000000000000000e+00,
              1.97209918459674616e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              1.93884287456981830e-01 -3.12609314253109893e-01 0.00000000000000000e+00,
              1.83907394448903916e-01 -3.63158905494038287e-01 0.00000000000000000e+00,
              1.67279239435440541e-01 -4.06724671629312085e-01 0.00000000000000000e+00,
              1.43999822416592038e-01 -4.42974049558662097e-01 0.00000000000000000e+00,
              1.03094561083472369e-01 -4.76562922685857926e-01 0.00000000000000000e+00,
              6.04267153189256057e-01 -3.80784749808309342e-01 0.00000000000000000e+00,
              6.01606648387102094e-01 -3.48193565981921305e-01 0.00000000000000000e+00,
              5.94622823281447443e-01 -3.25246712063341992e-01 0.00000000000000000e+00,
              5.82983114772023026e-01 -3.08618557049878672e-01 0.00000000000000000e+00,
              5.68350338360175344e-01 -2.93985780638030991e-01 0.00000000000000000e+00,
              5.49726804745096498e-01 -2.82013509028337439e-01 0.00000000000000000e+00,
              5.27445077027055786e-01 -2.72701742220797960e-01 0.00000000000000000e+00,
              5.02502844506860891e-01 -2.66715606415951212e-01 0.00000000000000000e+00,
              4.75897796485319491e-01 -2.64387664714066384e-01 0.00000000000000000e+00,
              3.21255954860111048e-01 -2.64387664714066384e-01 0.00000000000000000e+00,
              3.10946498751763833e-01 -2.62724849212719991e-01 0.00000000000000000e+00,
              3.02632421245031979e-01 -2.57071276508142488e-01 0.00000000000000000e+00,
              2.96646285440185342e-01 -2.49089762101680101e-01 0.00000000000000000e+00,
              2.94983469938838949e-01 -2.39445432193871433e-01 0.00000000000000000e+00,
              2.94983469938838949e-01 -2.49422325201949513e-02 0.00000000000000000e+00,
              2.96646285440185342e-01 -1.56304657126554725e-02 0.00000000000000000e+00,
              3.02632421245031979e-01 -7.31638820592384054e-03 0.00000000000000000e+00,
              3.10946498751763833e-01 -1.99537860161558278e-03 0.00000000000000000e+00,
              3.21255954860111048e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              6.30539638110528156e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              6.40183968018336991e-01 -1.99537860161558278e-03 0.00000000000000000e+00,
              6.48830608625337701e-01 -7.64895130619314134e-03 0.00000000000000000e+00,
              6.54816744430184450e-01 -1.62955919131940186e-02 0.00000000000000000e+00,
              6.56812123031800033e-01 -2.59399218210027982e-02 0.00000000000000000e+00,
              6.54816744430184450e-01 -3.59168148290807676e-02 0.00000000000000000e+00,
              6.48830608625337701e-01 -4.42308923358123440e-02 0.00000000000000000e+00,
              6.40183968018336991e-01 -4.98844650403898471e-02 0.00000000000000000e+00,
              6.30539638110528156e-01 -5.15472805417362401e-02 0.00000000000000000e+00,
              3.47528439781382925e-01 -5.15472805417362401e-02 0.00000000000000000e+00,
              3.47528439781382925e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              4.75897796485319491e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              5.12147174414669504e-01 -2.15833452074753518e-01 0.00000000000000000e+00,
              5.46401173742403934e-01 -2.24480092681754395e-01 0.00000000000000000e+00,
              5.77662105167714879e-01 -2.38115179792794340e-01 0.00000000000000000e+00,
              6.04267153189256057e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              6.25883754706758388e-01 -2.77357625624567727e-01 0.00000000000000000e+00,
              6.42511909720221652e-01 -3.02964984345301169e-01 0.00000000000000000e+00,
              6.53153928928838168e-01 -3.31565410968458041e-01 0.00000000000000000e+00,
              6.56812123031800033e-01 -3.61828653092961194e-01 0.00000000000000000e+00,
              6.53153928928838168e-01 -3.92091895217464403e-01 0.00000000000000000e+00,
              6.42511909720221652e-01 -4.20692321840621275e-01 0.00000000000000000e+00,
              6.25883754706758388e-01 -4.46632243661624018e-01 0.00000000000000000e+00,
              6.04267153189256057e-01 -4.68248845179126294e-01 0.00000000000000000e+00,
              5.77662105167714879e-01 -4.85874689493397349e-01 0.00000000000000000e+00,
              5.46401173742403934e-01 -4.99509776604437239e-01 0.00000000000000000e+00,
              5.12147174414669504e-01 -5.08156417211438227e-01 0.00000000000000000e+00,
              4.75897796485319491e-01 -5.10816922013592301e-01 0.00000000000000000e+00,
              4.29006399347352962e-01 -5.07158727910630325e-01 0.00000000000000000e+00,
              3.81449876008848054e-01 -4.95519019401206073e-01 0.00000000000000000e+00,
              3.38881799174381992e-01 -4.78225738187204263e-01 0.00000000000000000e+00,
              3.07288304648801747e-01 -4.55944010469163441e-01 0.00000000000000000e+00,
              3.03297547445570581e-01 -4.52285816366201521e-01 0.00000000000000000e+00,
              3.00304479543147207e-01 -4.48295059162970300e-01 0.00000000000000000e+00,
              2.97643974740993023e-01 -4.43639175759200644e-01 0.00000000000000000e+00,
              2.95648596139377551e-01 -4.38650729255161631e-01 0.00000000000000000e+00,
              2.94318343738300459e-01 -4.32997156550584128e-01 0.00000000000000000e+00,
              2.95316033039108250e-01 -4.27011020745737324e-01 0.00000000000000000e+00,
              2.97643974740993023e-01 -4.21357448041159821e-01 0.00000000000000000e+00,
              3.02299858144762679e-01 -4.15371312236313017e-01 0.00000000000000000e+00,
              3.07620867749071047e-01 -4.11047991932812551e-01 0.00000000000000000e+00,
              3.13607003553917796e-01 -4.08387487130658422e-01 0.00000000000000000e+00,
              3.19260576258495243e-01 -4.07389797829850631e-01 0.00000000000000000e+00,
              3.24249022762534311e-01 -4.07389797829850631e-01 0.00000000000000000e+00,
              3.29237469266573379e-01 -4.09052613331196968e-01 0.00000000000000000e+00,
              3.34558478870881526e-01 -4.11380555033081796e-01 0.00000000000000000e+00,
              3.39214362274651293e-01 -4.14041059835235981e-01 0.00000000000000000e+00,
              3.43205119477882681e-01 -4.17034127737659355e-01 0.00000000000000000e+00,
              3.46530750480575245e-01 -4.20027195640082729e-01 0.00000000000000000e+00,
              3.49856381483267809e-01 -4.22687700442236858e-01 0.00000000000000000e+00,
              3.52516886285421993e-01 -4.25015642144121741e-01 0.00000000000000000e+00,
              3.53847138686498974e-01 -4.26013331444929533e-01 0.00000000000000000e+00,
              3.76461429504808986e-01 -4.37985603054623085e-01 0.00000000000000000e+00,
              4.09385176431466435e-01 -4.48960185363508846e-01 0.00000000000000000e+00,
              4.44969428160277847e-01 -4.56941699769971232e-01 0.00000000000000000e+00,
              4.75897796485319491e-01 -4.59269641471856116e-01 0.00000000000000000e+00,
              5.02502844506860891e-01 -4.57274262870240533e-01 0.00000000000000000e+00,
              5.27445077027055786e-01 -4.51288127065393729e-01 0.00000000000000000e+00,
              5.49726804745096498e-01 -4.41976360257854251e-01 0.00000000000000000e+00,
              5.68350338360175344e-01 -4.29671525547891453e-01 0.00000000000000000e+00,
              5.85643619574177210e-01 -4.11047991932812551e-01 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="5.09997228640831111e+02 4.99588575103220251e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            15 14 16 -1 
            16 14 17 -1 
            17 14 18 -1 
            12 11 13 -1 
            11 10 13 -1 
            10 9 13 -1 
            9 8 13 -1 
            24 23 25 -1 
            23 22 25 -1 
            22 21 25 -1 
            25 21 26 -1 
            21 20 26 -1 
            26 20 27 -1 
            20 19 27 -1 
            27 19 28 -1 
            19 18 28 -1 
            18 14 28 -1 
            14 13 28 -1 
            28 13 29 -1 
            13 8 29 -1 
            29 8 30 -1 
            30 8 31 -1 
            8 7 31 -1 
            31 7 32 -1 
            7 6 32 -1 
            32 6 33 -1 
            6 5 33 -1 
            33 5 34 -1 
            34 5 35 -1 
            35 5 36 -1 
            36 5 37 -1 
            55 54 56 -1 
            56 54 57 -1 
            57 54 58 -1 
            58 54 59 -1 
            54 53 59 -1 
            59 53 60 -1 
            60 53 61 -1 
            61 53 62 -1 
            62 53 63 -1 
            63 53 64 -1 
            64 53 65 -1 
            53 52 65 -1 
            52 51 65 -1 
            51 50 65 -1 
            50 49 65 -1 
            49 48 65 -1 
            48 47 65 -1 
            39 38 40 -1 
            38 37 40 -1 
            40 37 41 -1 
            41 37 42 -1 
            42 37 43 -1 
            43 37 44 -1 
            44 37 45 -1 
            46 45 47 -1 
            5 4 37 -1 
            4 3 37 -1 
            3 2 37 -1 
            2 1 37 -1 
            1 0 37 -1 
            0 79 37 -1 
            66 65 67 -1 
            37 79 45 -1 
            79 78 45 -1 
            78 77 45 -1 
            45 77 47 -1 
            77 76 47 -1 
            76 75 47 -1 
            75 74 47 -1 
            74 73 47 -1 
            73 72 47 -1 
            72 71 47 -1 
            71 70 47 -1 
            67 65 68 -1 
            65 47 68 -1 
            68 47 69 -1 
            70 69 47 -1 
            94 93 95 -1 
            93 92 95 -1 
            92 91 95 -1 
            91 90 95 -1 
            90 89 95 -1 
            89 88 95 -1 
            88 87 95 -1 
            95 87 96 -1 
            87 86 96 -1 
            85 84 86 -1 
            86 84 96 -1 
            104 103 105 -1 
            103 102 105 -1 
            102 101 105 -1 
            101 100 105 -1 
            100 99 105 -1 
            99 98 105 -1 
            105 98 80 -1 
            81 80 82 -1 
            82 80 83 -1 
            83 80 84 -1 
            84 80 96 -1 
            96 80 97 -1 
            80 98 97 -1 
            153 106 154 -1 
            106 135 154 -1 
            121 137 122 -1 
            137 136 122 -1 
            154 135 155 -1 
            155 135 156 -1 
            135 134 156 -1 
            156 134 157 -1 
            157 134 158 -1 
            134 133 158 -1 
            158 133 159 -1 
            133 132 159 -1 
            159 132 160 -1 
            132 131 160 -1 
            160 131 161 -1 
            131 130 161 -1 
            161 130 162 -1 
            162 130 163 -1 
            130 129 163 -1 
            163 129 164 -1 
            129 128 164 -1 
            164 128 165 -1 
            128 127 165 -1 
            165 127 166 -1 
            127 126 166 -1 
            166 126 167 -1 
            122 136 123 -1 
            136 167 123 -1 
            126 125 167 -1 
            125 124 167 -1 
            167 124 123 -1 
            137 121 138 -1 
            153 152 106 -1 
            107 106 152 -1 
            152 151 107 -1 
            107 151 108 -1 
            151 150 108 -1 
            150 149 108 -1 
            121 120 138 -1 
            138 120 139 -1 
            108 149 109 -1 
            149 148 109 -1 
            109 148 110 -1 
            148 147 110 -1 
            110 147 111 -1 
            147 146 111 -1 
            111 146 112 -1 
            146 145 112 -1 
            112 145 113 -1 
            145 144 113 -1 
            113 144 114 -1 
            144 143 114 -1 
            114 143 115 -1 
            143 142 115 -1 
            115 142 116 -1 
            142 141 116 -1 
            116 141 117 -1 
            141 140 117 -1 
            117 140 118 -1 
            140 139 118 -1 
            118 139 119 -1 
            139 120 119 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0008"
              point="
              -2.54909616356392255e-01 -3.80784749808309342e-01 0.00000000000000000e+00,
              -2.57570121158546383e-01 -3.48193565981921305e-01 0.00000000000000000e+00,
              -2.64553946264200923e-01 -3.25246712063341992e-01 0.00000000000000000e+00,
              -2.76193654773625230e-01 -3.08618557049878672e-01 0.00000000000000000e+00,
              -2.90826431185472967e-01 -2.93985780638030991e-01 0.00000000000000000e+00,
              -3.09449964800551869e-01 -2.82013509028337439e-01 0.00000000000000000e+00,
              -3.31731692518592691e-01 -2.72701742220797960e-01 0.00000000000000000e+00,
              -3.56673925038787587e-01 -2.66715606415951212e-01 0.00000000000000000e+00,
              -3.83278973060328876e-01 -2.64387664714066384e-01 0.00000000000000000e+00,
              -5.37920814685537430e-01 -2.64387664714066384e-01 0.00000000000000000e+00,
              -5.48230270793884644e-01 -2.62724849212719991e-01 0.00000000000000000e+00,
              -5.56544348300616387e-01 -2.57071276508142488e-01 0.00000000000000000e+00,
              -5.62530484105463136e-01 -2.49089762101680101e-01 0.00000000000000000e+00,
              -5.64193299606809417e-01 -2.39445432193871433e-01 0.00000000000000000e+00,
              -5.64193299606809417e-01 -2.49422325201949513e-02 0.00000000000000000e+00,
              -5.62530484105463136e-01 -1.56304657126554725e-02 0.00000000000000000e+00,
              -5.56544348300616387e-01 -7.31638820592384054e-03 0.00000000000000000e+00,
              -5.48230270793884644e-01 -1.99537860161558278e-03 0.00000000000000000e+00,
              -5.37920814685537430e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -2.28637131435120267e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -2.18992801527311542e-01 -1.99537860161558278e-03 0.00000000000000000e+00,
              -2.10346160920310610e-01 -7.64895130619314134e-03 0.00000000000000000e+00,
              -2.04360025115463861e-01 -1.62955919131940186e-02 0.00000000000000000e+00,
              -2.02364646513848223e-01 -2.59399218210027982e-02 0.00000000000000000e+00,
              -2.04360025115463861e-01 -3.59168148290807676e-02 0.00000000000000000e+00,
              -2.10346160920310610e-01 -4.42308923358123440e-02 0.00000000000000000e+00,
              -2.18992801527311542e-01 -4.98844650403898471e-02 0.00000000000000000e+00,
              -2.28637131435120267e-01 -5.15472805417362401e-02 0.00000000000000000e+00,
              -5.11648329764265442e-01 -5.15472805417362401e-02 0.00000000000000000e+00,
              -5.11648329764265442e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              -3.83278973060328876e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              -3.47029595130978863e-01 -2.15833452074753518e-01 0.00000000000000000e+00,
              -3.12775595803244488e-01 -2.24480092681754395e-01 0.00000000000000000e+00,
              -2.81514664377933488e-01 -2.38115179792794340e-01 0.00000000000000000e+00,
              -2.54909616356392255e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -2.33293014838889978e-01 -2.77357625624567727e-01 0.00000000000000000e+00,
              -2.16664859825426659e-01 -3.02964984345301169e-01 0.00000000000000000e+00,
              -2.06022840616810143e-01 -3.31565410968458041e-01 0.00000000000000000e+00,
              -2.02364646513848223e-01 -3.61828653092961194e-01 0.00000000000000000e+00,
              -2.06022840616810143e-01 -3.92091895217464403e-01 0.00000000000000000e+00,
              -2.16664859825426659e-01 -4.20692321840621275e-01 0.00000000000000000e+00,
              -2.33293014838889978e-01 -4.46632243661624018e-01 0.00000000000000000e+00,
              -2.54909616356392255e-01 -4.68248845179126294e-01 0.00000000000000000e+00,
              -2.81514664377933488e-01 -4.85874689493397349e-01 0.00000000000000000e+00,
              -3.12775595803244488e-01 -4.99509776604437239e-01 0.00000000000000000e+00,
              -3.47029595130978863e-01 -5.08156417211438227e-01 0.00000000000000000e+00,
              -3.83278973060328876e-01 -5.10816922013592301e-01 0.00000000000000000e+00,
              -4.30170370198295349e-01 -5.07158727910630325e-01 0.00000000000000000e+00,
              -4.77726893536800368e-01 -4.95519019401206073e-01 0.00000000000000000e+00,
              -5.20294970371266374e-01 -4.78225738187204263e-01 0.00000000000000000e+00,
              -5.51888464896846620e-01 -4.55944010469163441e-01 0.00000000000000000e+00,
              -5.55879222100077786e-01 -4.52285816366201521e-01 0.00000000000000000e+00,
              -5.58872290002501160e-01 -4.48295059162970300e-01 0.00000000000000000e+00,
              -5.61532794804655344e-01 -4.43639175759200644e-01 0.00000000000000000e+00,
              -5.63528173406270927e-01 -4.38650729255161631e-01 0.00000000000000000e+00,
              -5.64858425807348019e-01 -4.32997156550584128e-01 0.00000000000000000e+00,
              -5.63860736506540228e-01 -4.27011020745737324e-01 0.00000000000000000e+00,
              -5.61532794804655344e-01 -4.21357448041159821e-01 0.00000000000000000e+00,
              -5.56876911400885577e-01 -4.15371312236313017e-01 0.00000000000000000e+00,
              -5.51555901796577319e-01 -4.11047991932812551e-01 0.00000000000000000e+00,
              -5.45569765991730571e-01 -4.08387487130658422e-01 0.00000000000000000e+00,
              -5.39916193287153012e-01 -4.07389797829850631e-01 0.00000000000000000e+00,
              -5.34927746783114055e-01 -4.07389797829850631e-01 0.00000000000000000e+00,
              -5.29939300279075098e-01 -4.09052613331196968e-01 0.00000000000000000e+00,
              -5.24618290674766841e-01 -4.11380555033081796e-01 0.00000000000000000e+00,
              -5.19962407270997073e-01 -4.14041059835235981e-01 0.00000000000000000e+00,
              -5.15971650067765908e-01 -4.17034127737659355e-01 0.00000000000000000e+00,
              -5.12646019065073233e-01 -4.20027195640082729e-01 0.00000000000000000e+00,
              -5.09320388062380558e-01 -4.22687700442236858e-01 0.00000000000000000e+00,
              -5.06659883260226485e-01 -4.25015642144121741e-01 0.00000000000000000e+00,
              -5.05329630859149392e-01 -4.26013331444929533e-01 0.00000000000000000e+00,
              -4.82715340040839325e-01 -4.37985603054623085e-01 0.00000000000000000e+00,
              -4.49791593114182042e-01 -4.48960185363508846e-01 0.00000000000000000e+00,
              -4.14207341385370575e-01 -4.56941699769971232e-01 0.00000000000000000e+00,
              -3.83278973060328876e-01 -4.59269641471856116e-01 0.00000000000000000e+00,
              -3.56673925038787587e-01 -4.57274262870240533e-01 0.00000000000000000e+00,
              -3.31731692518592691e-01 -4.51288127065393729e-01 0.00000000000000000e+00,
              -3.09449964800551869e-01 -4.41976360257854251e-01 0.00000000000000000e+00,
              -2.90826431185472967e-01 -4.29671525547891453e-01 0.00000000000000000e+00,
              -2.73533149971471101e-01 -4.11047991932812551e-01 0.00000000000000000e+00,
              9.47804835767407372e-03 -7.98151440646233112e-03 0.00000000000000000e+00,
              1.77921258644057056e-02 -1.99537860161558278e-03 0.00000000000000000e+00,
              2.84341450730222212e-02 0.00000000000000000e+00 0.00000000000000000e+00,
              3.87436011813694359e-02 -1.99537860161558278e-03 0.00000000000000000e+00,
              4.73902417883703686e-02 -7.31638820592384054e-03 0.00000000000000000e+00,
              5.30438144929479272e-02 -1.52979026123862827e-02 0.00000000000000000e+00,
              5.50391930945635099e-02 -2.46096694199257060e-02 0.00000000000000000e+00,
              5.50391930945635099e-02 -4.86207252593666650e-01 0.00000000000000000e+00,
              5.30438144929479272e-02 -4.95851582501475319e-01 0.00000000000000000e+00,
              4.73902417883703686e-02 -5.03833096907937761e-01 0.00000000000000000e+00,
              3.87436011813694359e-02 -5.09154106512246019e-01 0.00000000000000000e+00,
              2.87667081732915220e-02 -5.10816922013592301e-01 0.00000000000000000e+00,
              1.84572520649441962e-02 -5.09154106512246019e-01 0.00000000000000000e+00,
              1.01431745582125643e-02 -5.03833096907937761e-01 0.00000000000000000e+00,
              4.15703875336581596e-03 -4.95851582501475319e-01 0.00000000000000000e+00,
              2.49422325201953399e-03 -4.86207252593666650e-01 0.00000000000000000e+00,
              2.49422325201953399e-03 -8.77966584710861420e-02 0.00000000000000000e+00,
              -9.89375223301066642e-02 -1.90891219554558567e-01 0.00000000000000000e+00,
              -1.07251599836838241e-01 -1.96877355359405370e-01 0.00000000000000000e+00,
              -1.17228492844916155e-01 -1.98540170860751708e-01 0.00000000000000000e+00,
              -1.27537948953263425e-01 -1.96877355359405370e-01 0.00000000000000000e+00,
              -1.36184589560264357e-01 -1.91556345755097113e-01 0.00000000000000000e+00,
              -1.42170725365111161e-01 -1.83907394448904027e-01 0.00000000000000000e+00,
              -1.44166103966726744e-01 -1.74263064541095303e-01 0.00000000000000000e+00,
              -1.42835851565649707e-01 -1.64951297733555879e-01 0.00000000000000000e+00,
              -1.37182278861072204e-01 -1.56637220226824192e-01 0.00000000000000000e+00,
              3.89265108865175735e-01 -5.15472805417362401e-02 0.00000000000000000e+00,
              3.18096605407552913e-01 -5.45403484441596698e-02 0.00000000000000000e+00,
              2.74198276172009758e-01 -6.28544259508912462e-02 0.00000000000000000e+00,
              2.45930412649122188e-01 -7.74872023627389273e-02 0.00000000000000000e+00,
              2.22650995630273574e-01 -9.87712407799719583e-02 0.00000000000000000e+00,
              2.04360025115463917e-01 -1.26706541202590339e-01 0.00000000000000000e+00,
              1.91722627305231819e-01 -1.61958229831132450e-01 0.00000000000000000e+00,
              1.84073675999038788e-01 -2.04858869765867757e-01 0.00000000000000000e+00,
              1.81745734297153905e-01 -2.55741024107065396e-01 0.00000000000000000e+00,
              1.84073675999038788e-01 -3.06290615347993844e-01 0.00000000000000000e+00,
              1.91722627305231819e-01 -3.49191255282729096e-01 0.00000000000000000e+00,
              2.04360025115463917e-01 -3.84442943911271318e-01 0.00000000000000000e+00,
              2.22650995630273574e-01 -4.12378244333889588e-01 0.00000000000000000e+00,
              2.45930412649122188e-01 -4.33662282751122619e-01 0.00000000000000000e+00,
              2.74198276172009758e-01 -4.47962496062701054e-01 0.00000000000000000e+00,
              3.07454586198936397e-01 -4.56609136669701987e-01 0.00000000000000000e+00,
              3.47029595130978974e-01 -4.59269641471856116e-01 0.00000000000000000e+00,
              3.86272040962752472e-01 -4.56609136669701987e-01 0.00000000000000000e+00,
              4.19860914089948190e-01 -4.47962496062701054e-01 0.00000000000000000e+00,
              4.47796214512566570e-01 -4.33662282751122619e-01 0.00000000000000000e+00,
              4.71075631531415184e-01 -4.12378244333889588e-01 0.00000000000000000e+00,
              4.89366602046224730e-01 -3.84442943911271318e-01 0.00000000000000000e+00,
              5.02003999856456828e-01 -3.49191255282729096e-01 0.00000000000000000e+00,
              5.09652951162650081e-01 -3.06290615347993844e-01 0.00000000000000000e+00,
              5.12313455964804043e-01 -2.55741024107065396e-01 0.00000000000000000e+00,
              5.09652951162650081e-01 -2.04858869765867757e-01 0.00000000000000000e+00,
              5.02003999856456828e-01 -1.61958229831132450e-01 0.00000000000000000e+00,
              4.89366602046224730e-01 -1.26706541202590339e-01 0.00000000000000000e+00,
              4.71075631531415184e-01 -9.87712407799719583e-02 0.00000000000000000e+00,
              4.40147263206373318e-01 -7.48266975605847984e-02 0.00000000000000000e+00,
              4.01902506675407722e-01 -5.10816922013592301e-01 0.00000000000000000e+00,
              3.08784838600013267e-01 -5.06493601710091834e-01 0.00000000000000000e+00,
              2.50253732952622543e-01 -4.93523640799590491e-01 0.00000000000000000e+00,
              2.12341539521926359e-01 -4.72239602382357460e-01 0.00000000000000000e+00,
              1.82078297397423206e-01 -4.42974049558662097e-01 0.00000000000000000e+00,
              1.58798880378574481e-01 -4.06724671629312085e-01 0.00000000000000000e+00,
              1.42503288465380518e-01 -3.63158905494038287e-01 0.00000000000000000e+00,
              1.32526395457302604e-01 -3.12609314253109893e-01 0.00000000000000000e+00,
              1.29200764454609818e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              1.32526395457302604e-01 -1.98540170860751708e-01 0.00000000000000000e+00,
              1.42503288465380518e-01 -1.47990579619823315e-01 0.00000000000000000e+00,
              1.58798880378574481e-01 -1.04424813484549517e-01 0.00000000000000000e+00,
              1.82078297397423206e-01 -6.78428724549302586e-02 0.00000000000000000e+00,
              2.12341539521926359e-01 -3.85773196312348965e-02 0.00000000000000000e+00,
              2.50253732952622543e-01 -1.72932812140018655e-02 0.00000000000000000e+00,
              2.95149751488973489e-01 -4.32332030350046637e-03 0.00000000000000000e+00,
              3.47029595130978974e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              3.88599982664637134e-01 -2.99306790242337417e-03 0.00000000000000000e+00,
              4.26512176095333539e-01 -1.13071454091550616e-02 0.00000000000000000e+00,
              4.59768486122260067e-01 -2.49422325201949513e-02 0.00000000000000000e+00,
              4.88036349645147749e-01 -4.38983292355431542e-02 0.00000000000000000e+00,
              5.11315766663996363e-01 -6.78428724549302586e-02 0.00000000000000000e+00,
              5.30936989579883112e-01 -9.64432990780871302e-02 0.00000000000000000e+00,
              5.45902329091999872e-01 -1.30032172205282959e-01 0.00000000000000000e+00,
              5.56544348300616387e-01 -1.67611802535710008e-01 0.00000000000000000e+00,
              5.62530484105463358e-01 -2.09514753169637469e-01 0.00000000000000000e+00,
              5.64858425807348019e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              5.61532794804655455e-01 -3.12609314253109893e-01 0.00000000000000000e+00,
              5.51555901796577541e-01 -3.63158905494038287e-01 0.00000000000000000e+00,
              5.34927746783114277e-01 -4.06724671629312085e-01 0.00000000000000000e+00,
              5.11648329764265442e-01 -4.42974049558662097e-01 0.00000000000000000e+00,
              4.70743068431145884e-01 -4.76562922685857926e-01 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="5.14997228640831054e+02 4.99588575103220251e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            15 14 16 -1 
            16 14 17 -1 
            17 14 18 -1 
            12 11 13 -1 
            11 10 13 -1 
            10 9 13 -1 
            9 8 13 -1 
            24 23 25 -1 
            23 22 25 -1 
            22 21 25 -1 
            25 21 26 -1 
            21 20 26 -1 
            26 20 27 -1 
            20 19 27 -1 
            27 19 28 -1 
            19 18 28 -1 
            18 14 28 -1 
            14 13 28 -1 
            28 13 29 -1 
            13 8 29 -1 
            29 8 30 -1 
            30 8 31 -1 
            8 7 31 -1 
            31 7 32 -1 
            7 6 32 -1 
            32 6 33 -1 
            6 5 33 -1 
            33 5 34 -1 
            34 5 35 -1 
            35 5 36 -1 
            36 5 37 -1 
            55 54 56 -1 
            56 54 57 -1 
            57 54 58 -1 
            58 54 59 -1 
            54 53 59 -1 
            59 53 60 -1 
            60 53 61 -1 
            61 53 62 -1 
            62 53 63 -1 
            63 53 64 -1 
            64 53 65 -1 
            53 52 65 -1 
            52 51 65 -1 
            51 50 65 -1 
            50 49 65 -1 
            49 48 65 -1 
            48 47 65 -1 
            39 38 40 -1 
            38 37 40 -1 
            40 37 41 -1 
            41 37 42 -1 
            42 37 43 -1 
            43 37 44 -1 
            44 37 45 -1 
            46 45 47 -1 
            5 4 37 -1 
            4 3 37 -1 
            3 2 37 -1 
            2 1 37 -1 
            1 0 37 -1 
            0 79 37 -1 
            66 65 67 -1 
            37 79 45 -1 
            79 78 45 -1 
            78 77 45 -1 
            45 77 47 -1 
            77 76 47 -1 
            76 75 47 -1 
            75 74 47 -1 
            74 73 47 -1 
            73 72 47 -1 
            72 71 47 -1 
            71 70 47 -1 
            67 65 68 -1 
            65 47 68 -1 
            68 47 69 -1 
            70 69 47 -1 
            94 93 95 -1 
            93 92 95 -1 
            92 91 95 -1 
            91 90 95 -1 
            90 89 95 -1 
            89 88 95 -1 
            88 87 95 -1 
            95 87 96 -1 
            87 86 96 -1 
            85 84 86 -1 
            86 84 96 -1 
            104 103 105 -1 
            103 102 105 -1 
            102 101 105 -1 
            101 100 105 -1 
            100 99 105 -1 
            99 98 105 -1 
            105 98 80 -1 
            81 80 82 -1 
            82 80 83 -1 
            83 80 84 -1 
            84 80 96 -1 
            96 80 97 -1 
            80 98 97 -1 
            121 120 122 -1 
            122 120 123 -1 
            123 120 124 -1 
            118 117 119 -1 
            117 116 119 -1 
            116 115 119 -1 
            115 114 119 -1 
            130 129 131 -1 
            129 128 131 -1 
            128 127 131 -1 
            131 127 132 -1 
            127 126 132 -1 
            132 126 133 -1 
            126 125 133 -1 
            133 125 134 -1 
            125 124 134 -1 
            124 120 134 -1 
            120 119 134 -1 
            134 119 135 -1 
            119 114 135 -1 
            135 114 136 -1 
            136 114 137 -1 
            114 113 137 -1 
            137 113 138 -1 
            113 112 138 -1 
            138 112 139 -1 
            112 111 139 -1 
            139 111 140 -1 
            140 111 141 -1 
            141 111 142 -1 
            142 111 143 -1 
            161 160 162 -1 
            162 160 163 -1 
            163 160 164 -1 
            164 160 165 -1 
            160 159 165 -1 
            165 159 166 -1 
            166 159 167 -1 
            167 159 168 -1 
            168 159 169 -1 
            169 159 170 -1 
            170 159 171 -1 
            159 158 171 -1 
            158 157 171 -1 
            157 156 171 -1 
            156 155 171 -1 
            155 154 171 -1 
            154 153 171 -1 
            145 144 146 -1 
            144 143 146 -1 
            146 143 147 -1 
            147 143 148 -1 
            148 143 149 -1 
            149 143 150 -1 
            150 143 151 -1 
            152 151 153 -1 
            111 110 143 -1 
            110 109 143 -1 
            109 108 143 -1 
            108 107 143 -1 
            107 106 143 -1 
            106 185 143 -1 
            172 171 173 -1 
            143 185 151 -1 
            185 184 151 -1 
            184 183 151 -1 
            151 183 153 -1 
            183 182 153 -1 
            182 181 153 -1 
            181 180 153 -1 
            180 179 153 -1 
            179 178 153 -1 
            178 177 153 -1 
            177 176 153 -1 
            173 171 174 -1 
            171 153 174 -1 
            174 153 175 -1 
            176 175 153 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0009"
              point="
              -2.28637131435120267e-01 -3.80784749808309342e-01 0.00000000000000000e+00,
              -2.31297636237274395e-01 -3.48193565981921305e-01 0.00000000000000000e+00,
              -2.38281461342928935e-01 -3.25246712063341992e-01 0.00000000000000000e+00,
              -2.49921169852353242e-01 -3.08618557049878672e-01 0.00000000000000000e+00,
              -2.64553946264200979e-01 -2.93985780638030991e-01 0.00000000000000000e+00,
              -2.83177479879279881e-01 -2.82013509028337439e-01 0.00000000000000000e+00,
              -3.05459207597320703e-01 -2.72701742220797960e-01 0.00000000000000000e+00,
              -3.30401440117515599e-01 -2.66715606415951212e-01 0.00000000000000000e+00,
              -3.57006488139056888e-01 -2.64387664714066384e-01 0.00000000000000000e+00,
              -5.11648329764265442e-01 -2.64387664714066384e-01 0.00000000000000000e+00,
              -5.21957785872612656e-01 -2.62724849212719991e-01 0.00000000000000000e+00,
              -5.30271863379344399e-01 -2.57071276508142488e-01 0.00000000000000000e+00,
              -5.36257999184191148e-01 -2.49089762101680101e-01 0.00000000000000000e+00,
              -5.37920814685537430e-01 -2.39445432193871433e-01 0.00000000000000000e+00,
              -5.37920814685537430e-01 -2.49422325201949513e-02 0.00000000000000000e+00,
              -5.36257999184191148e-01 -1.56304657126554725e-02 0.00000000000000000e+00,
              -5.30271863379344399e-01 -7.31638820592384054e-03 0.00000000000000000e+00,
              -5.21957785872612656e-01 -1.99537860161558278e-03 0.00000000000000000e+00,
              -5.11648329764265442e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -2.02364646513848279e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -1.92720316606039554e-01 -1.99537860161558278e-03 0.00000000000000000e+00,
              -1.84073675999038622e-01 -7.64895130619314134e-03 0.00000000000000000e+00,
              -1.78087540194191873e-01 -1.62955919131940186e-02 0.00000000000000000e+00,
              -1.76092161592576235e-01 -2.59399218210027982e-02 0.00000000000000000e+00,
              -1.78087540194191873e-01 -3.59168148290807676e-02 0.00000000000000000e+00,
              -1.84073675999038622e-01 -4.42308923358123440e-02 0.00000000000000000e+00,
              -1.92720316606039554e-01 -4.98844650403898471e-02 0.00000000000000000e+00,
              -2.02364646513848279e-01 -5.15472805417362401e-02 0.00000000000000000e+00,
              -4.85375844842993454e-01 -5.15472805417362401e-02 0.00000000000000000e+00,
              -4.85375844842993454e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              -3.57006488139056888e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              -3.20757110209706875e-01 -2.15833452074753518e-01 0.00000000000000000e+00,
              -2.86503110881972500e-01 -2.24480092681754395e-01 0.00000000000000000e+00,
              -2.55242179456661500e-01 -2.38115179792794340e-01 0.00000000000000000e+00,
              -2.28637131435120267e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -2.07020529917617990e-01 -2.77357625624567727e-01 0.00000000000000000e+00,
              -1.90392374904154671e-01 -3.02964984345301169e-01 0.00000000000000000e+00,
              -1.79750355695538155e-01 -3.31565410968458041e-01 0.00000000000000000e+00,
              -1.76092161592576235e-01 -3.61828653092961194e-01 0.00000000000000000e+00,
              -1.79750355695538155e-01 -3.92091895217464403e-01 0.00000000000000000e+00,
              -1.90392374904154671e-01 -4.20692321840621275e-01 0.00000000000000000e+00,
              -2.07020529917617990e-01 -4.46632243661624018e-01 0.00000000000000000e+00,
              -2.28637131435120267e-01 -4.68248845179126294e-01 0.00000000000000000e+00,
              -2.55242179456661500e-01 -4.85874689493397349e-01 0.00000000000000000e+00,
              -2.86503110881972500e-01 -4.99509776604437239e-01 0.00000000000000000e+00,
              -3.20757110209706875e-01 -5.08156417211438227e-01 0.00000000000000000e+00,
              -3.57006488139056888e-01 -5.10816922013592301e-01 0.00000000000000000e+00,
              -4.03897885277023361e-01 -5.07158727910630325e-01 0.00000000000000000e+00,
              -4.51454408615528380e-01 -4.95519019401206073e-01 0.00000000000000000e+00,
              -4.94022485449994386e-01 -4.78225738187204263e-01 0.00000000000000000e+00,
              -5.25615979975574632e-01 -4.55944010469163441e-01 0.00000000000000000e+00,
              -5.29606737178805798e-01 -4.52285816366201521e-01 0.00000000000000000e+00,
              -5.32599805081229172e-01 -4.48295059162970300e-01 0.00000000000000000e+00,
              -5.35260309883383356e-01 -4.43639175759200644e-01 0.00000000000000000e+00,
              -5.37255688484998939e-01 -4.38650729255161631e-01 0.00000000000000000e+00,
              -5.38585940886076031e-01 -4.32997156550584128e-01 0.00000000000000000e+00,
              -5.37588251585268240e-01 -4.27011020745737324e-01 0.00000000000000000e+00,
              -5.35260309883383356e-01 -4.21357448041159821e-01 0.00000000000000000e+00,
              -5.30604426479613589e-01 -4.15371312236313017e-01 0.00000000000000000e+00,
              -5.25283416875305331e-01 -4.11047991932812551e-01 0.00000000000000000e+00,
              -5.19297281070458583e-01 -4.08387487130658422e-01 0.00000000000000000e+00,
              -5.13643708365881024e-01 -4.07389797829850631e-01 0.00000000000000000e+00,
              -5.08655261861842067e-01 -4.07389797829850631e-01 0.00000000000000000e+00,
              -5.03666815357803110e-01 -4.09052613331196968e-01 0.00000000000000000e+00,
              -4.98345805753494853e-01 -4.11380555033081796e-01 0.00000000000000000e+00,
              -4.93689922349725085e-01 -4.14041059835235981e-01 0.00000000000000000e+00,
              -4.89699165146493920e-01 -4.17034127737659355e-01 0.00000000000000000e+00,
              -4.86373534143801245e-01 -4.20027195640082729e-01 0.00000000000000000e+00,
              -4.83047903141108625e-01 -4.22687700442236858e-01 0.00000000000000000e+00,
              -4.80387398338954497e-01 -4.25015642144121741e-01 0.00000000000000000e+00,
              -4.79057145937877404e-01 -4.26013331444929533e-01 0.00000000000000000e+00,
              -4.56442855119567337e-01 -4.37985603054623085e-01 0.00000000000000000e+00,
              -4.23519108192910054e-01 -4.48960185363508846e-01 0.00000000000000000e+00,
              -3.87934856464098587e-01 -4.56941699769971232e-01 0.00000000000000000e+00,
              -3.57006488139056888e-01 -4.59269641471856116e-01 0.00000000000000000e+00,
              -3.30401440117515599e-01 -4.57274262870240533e-01 0.00000000000000000e+00,
              -3.05459207597320703e-01 -4.51288127065393729e-01 0.00000000000000000e+00,
              -2.83177479879279881e-01 -4.41976360257854251e-01 0.00000000000000000e+00,
              -2.64553946264200979e-01 -4.29671525547891453e-01 0.00000000000000000e+00,
              -2.47260665050199113e-01 -4.11047991932812551e-01 0.00000000000000000e+00,
              3.57505332789460617e-02 -7.98151440646233112e-03 0.00000000000000000e+00,
              4.40646107856776936e-02 -1.99537860161558278e-03 0.00000000000000000e+00,
              5.47066299942942091e-02 0.00000000000000000e+00 0.00000000000000000e+00,
              6.50160861026414238e-02 -1.99537860161558278e-03 0.00000000000000000e+00,
              7.36627267096423566e-02 -7.31638820592384054e-03 0.00000000000000000e+00,
              7.93162994142199151e-02 -1.52979026123862827e-02 0.00000000000000000e+00,
              8.13116780158354979e-02 -2.46096694199257060e-02 0.00000000000000000e+00,
              8.13116780158354979e-02 -4.86207252593666650e-01 0.00000000000000000e+00,
              7.93162994142199151e-02 -4.95851582501475319e-01 0.00000000000000000e+00,
              7.36627267096423566e-02 -5.03833096907937761e-01 0.00000000000000000e+00,
              6.50160861026414238e-02 -5.09154106512246019e-01 0.00000000000000000e+00,
              5.50391930945635099e-02 -5.10816922013592301e-01 0.00000000000000000e+00,
              4.47297369862161842e-02 -5.09154106512246019e-01 0.00000000000000000e+00,
              3.64156594794845523e-02 -5.03833096907937761e-01 0.00000000000000000e+00,
              3.04295236746378039e-02 -4.95851582501475319e-01 0.00000000000000000e+00,
              2.87667081732915220e-02 -4.86207252593666650e-01 0.00000000000000000e+00,
              2.87667081732915220e-02 -8.77966584710861420e-02 0.00000000000000000e+00,
              -7.26650374088346762e-02 -1.90891219554558567e-01 0.00000000000000000e+00,
              -8.09791149155662526e-02 -1.96877355359405370e-01 0.00000000000000000e+00,
              -9.09560079236441665e-02 -1.98540170860751708e-01 0.00000000000000000e+00,
              -1.01265464031991437e-01 -1.96877355359405370e-01 0.00000000000000000e+00,
              -1.09912104638992369e-01 -1.91556345755097113e-01 0.00000000000000000e+00,
              -1.15898240443839173e-01 -1.83907394448904027e-01 0.00000000000000000e+00,
              -1.17893619045454756e-01 -1.74263064541095303e-01 0.00000000000000000e+00,
              -1.16563366644377719e-01 -1.64951297733555879e-01 0.00000000000000000e+00,
              -1.10909793939800216e-01 -1.56637220226824192e-01 0.00000000000000000e+00,
              4.86040971043532055e-01 -3.80784749808309342e-01 0.00000000000000000e+00,
              4.83380466241377871e-01 -3.48193565981921305e-01 0.00000000000000000e+00,
              4.76396641135723220e-01 -3.25246712063341992e-01 0.00000000000000000e+00,
              4.64756932626299246e-01 -3.08618557049878672e-01 0.00000000000000000e+00,
              4.50124156214451343e-01 -2.93985780638030991e-01 0.00000000000000000e+00,
              4.31500622599372496e-01 -2.82013509028337439e-01 0.00000000000000000e+00,
              4.09218894881331674e-01 -2.72701742220797960e-01 0.00000000000000000e+00,
              3.84276662361136778e-01 -2.66715606415951212e-01 0.00000000000000000e+00,
              3.57671614339595489e-01 -2.64387664714066384e-01 0.00000000000000000e+00,
              2.03029772714386936e-01 -2.64387664714066384e-01 0.00000000000000000e+00,
              1.92720316606039610e-01 -2.62724849212719991e-01 0.00000000000000000e+00,
              1.84406239099307978e-01 -2.57071276508142488e-01 0.00000000000000000e+00,
              1.78420103294461230e-01 -2.49089762101680101e-01 0.00000000000000000e+00,
              1.76757287793114837e-01 -2.39445432193871433e-01 0.00000000000000000e+00,
              1.76757287793114837e-01 -2.49422325201949513e-02 0.00000000000000000e+00,
              1.78420103294461230e-01 -1.56304657126554725e-02 0.00000000000000000e+00,
              1.84406239099307978e-01 -7.31638820592384054e-03 0.00000000000000000e+00,
              1.92720316606039610e-01 -1.99537860161558278e-03 0.00000000000000000e+00,
              2.03029772714386936e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              5.12313455964803932e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              5.21957785872612767e-01 -1.99537860161558278e-03 0.00000000000000000e+00,
              5.30604426479613700e-01 -7.64895130619314134e-03 0.00000000000000000e+00,
              5.36590562284460448e-01 -1.62955919131940186e-02 0.00000000000000000e+00,
              5.38585940886076031e-01 -2.59399218210027982e-02 0.00000000000000000e+00,
              5.36590562284460448e-01 -3.59168148290807676e-02 0.00000000000000000e+00,
              5.30604426479613700e-01 -4.42308923358123440e-02 0.00000000000000000e+00,
              5.21957785872612767e-01 -4.98844650403898471e-02 0.00000000000000000e+00,
              5.12313455964803932e-01 -5.15472805417362401e-02 0.00000000000000000e+00,
              2.29302257635658813e-01 -5.15472805417362401e-02 0.00000000000000000e+00,
              2.29302257635658813e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              3.57671614339595489e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              3.93920992268945391e-01 -2.15833452074753518e-01 0.00000000000000000e+00,
              4.28174991596679710e-01 -2.24480092681754395e-01 0.00000000000000000e+00,
              4.59435923021990655e-01 -2.38115179792794340e-01 0.00000000000000000e+00,
              4.86040971043532055e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              5.07657572561034387e-01 -2.77357625624567727e-01 0.00000000000000000e+00,
              5.24285727574497651e-01 -3.02964984345301169e-01 0.00000000000000000e+00,
              5.34927746783114166e-01 -3.31565410968458041e-01 0.00000000000000000e+00,
              5.38585940886076031e-01 -3.61828653092961194e-01 0.00000000000000000e+00,
              5.34927746783114166e-01 -3.92091895217464403e-01 0.00000000000000000e+00,
              5.24285727574497651e-01 -4.20692321840621275e-01 0.00000000000000000e+00,
              5.07657572561034387e-01 -4.46632243661624018e-01 0.00000000000000000e+00,
              4.86040971043532055e-01 -4.68248845179126294e-01 0.00000000000000000e+00,
              4.59435923021990655e-01 -4.85874689493397349e-01 0.00000000000000000e+00,
              4.28174991596679710e-01 -4.99509776604437239e-01 0.00000000000000000e+00,
              3.93920992268945391e-01 -5.08156417211438227e-01 0.00000000000000000e+00,
              3.57671614339595489e-01 -5.10816922013592301e-01 0.00000000000000000e+00,
              3.10780217201628961e-01 -5.07158727910630325e-01 0.00000000000000000e+00,
              2.63223693863123942e-01 -4.95519019401206073e-01 0.00000000000000000e+00,
              2.20655617028657991e-01 -4.78225738187204263e-01 0.00000000000000000e+00,
              1.89062122503077745e-01 -4.55944010469163441e-01 0.00000000000000000e+00,
              1.85071365299846469e-01 -4.52285816366201521e-01 0.00000000000000000e+00,
              1.82078297397423094e-01 -4.48295059162970300e-01 0.00000000000000000e+00,
              1.79417792595269021e-01 -4.43639175759200644e-01 0.00000000000000000e+00,
              1.77422413993653327e-01 -4.38650729255161631e-01 0.00000000000000000e+00,
              1.76092161592576346e-01 -4.32997156550584128e-01 0.00000000000000000e+00,
              1.77089850893384138e-01 -4.27011020745737324e-01 0.00000000000000000e+00,
              1.79417792595269021e-01 -4.21357448041159821e-01 0.00000000000000000e+00,
              1.84073675999038677e-01 -4.15371312236313017e-01 0.00000000000000000e+00,
              1.89394685603346935e-01 -4.11047991932812551e-01 0.00000000000000000e+00,
              1.95380821408193683e-01 -4.08387487130658422e-01 0.00000000000000000e+00,
              2.01034394112771242e-01 -4.07389797829850631e-01 0.00000000000000000e+00,
              2.06022840616810199e-01 -4.07389797829850631e-01 0.00000000000000000e+00,
              2.11011287120849156e-01 -4.09052613331196968e-01 0.00000000000000000e+00,
              2.16332296725157414e-01 -4.11380555033081796e-01 0.00000000000000000e+00,
              2.20988180128927292e-01 -4.14041059835235981e-01 0.00000000000000000e+00,
              2.24978937332158346e-01 -4.17034127737659355e-01 0.00000000000000000e+00,
              2.28304568334851021e-01 -4.20027195640082729e-01 0.00000000000000000e+00,
              2.31630199337543696e-01 -4.22687700442236858e-01 0.00000000000000000e+00,
              2.34290704139697881e-01 -4.25015642144121741e-01 0.00000000000000000e+00,
              2.35620956540774862e-01 -4.26013331444929533e-01 0.00000000000000000e+00,
              2.58235247359084985e-01 -4.37985603054623085e-01 0.00000000000000000e+00,
              2.91158994285742323e-01 -4.48960185363508846e-01 0.00000000000000000e+00,
              3.26743246014553734e-01 -4.56941699769971232e-01 0.00000000000000000e+00,
              3.57671614339595489e-01 -4.59269641471856116e-01 0.00000000000000000e+00,
              3.84276662361136778e-01 -4.57274262870240533e-01 0.00000000000000000e+00,
              4.09218894881331674e-01 -4.51288127065393729e-01 0.00000000000000000e+00,
              4.31500622599372496e-01 -4.41976360257854251e-01 0.00000000000000000e+00,
              4.50124156214451343e-01 -4.29671525547891453e-01 0.00000000000000000e+00,
              4.67417437428453209e-01 -4.11047991932812551e-01 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99588575103220251e+02 5.04997228640831111e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            15 14 16 -1 
            16 14 17 -1 
            17 14 18 -1 
            12 11 13 -1 
            11 10 13 -1 
            10 9 13 -1 
            9 8 13 -1 
            24 23 25 -1 
            23 22 25 -1 
            22 21 25 -1 
            25 21 26 -1 
            21 20 26 -1 
            26 20 27 -1 
            20 19 27 -1 
            27 19 28 -1 
            19 18 28 -1 
            18 14 28 -1 
            14 13 28 -1 
            28 13 29 -1 
            13 8 29 -1 
            29 8 30 -1 
            30 8 31 -1 
            8 7 31 -1 
            31 7 32 -1 
            7 6 32 -1 
            32 6 33 -1 
            6 5 33 -1 
            33 5 34 -1 
            34 5 35 -1 
            35 5 36 -1 
            36 5 37 -1 
            55 54 56 -1 
            56 54 57 -1 
            57 54 58 -1 
            58 54 59 -1 
            54 53 59 -1 
            59 53 60 -1 
            60 53 61 -1 
            61 53 62 -1 
            62 53 63 -1 
            63 53 64 -1 
            64 53 65 -1 
            53 52 65 -1 
            52 51 65 -1 
            51 50 65 -1 
            50 49 65 -1 
            49 48 65 -1 
            48 47 65 -1 
            39 38 40 -1 
            38 37 40 -1 
            40 37 41 -1 
            41 37 42 -1 
            42 37 43 -1 
            43 37 44 -1 
            44 37 45 -1 
            46 45 47 -1 
            5 4 37 -1 
            4 3 37 -1 
            3 2 37 -1 
            2 1 37 -1 
            1 0 37 -1 
            0 79 37 -1 
            66 65 67 -1 
            37 79 45 -1 
            79 78 45 -1 
            78 77 45 -1 
            45 77 47 -1 
            77 76 47 -1 
            76 75 47 -1 
            75 74 47 -1 
            74 73 47 -1 
            73 72 47 -1 
            72 71 47 -1 
            71 70 47 -1 
            67 65 68 -1 
            65 47 68 -1 
            68 47 69 -1 
            70 69 47 -1 
            127 80 128 -1 
            80 109 128 -1 
            95 111 96 -1 
            111 110 96 -1 
            128 109 129 -1 
            129 109 130 -1 
            109 108 130 -1 
            130 108 131 -1 
            131 108 132 -1 
            108 107 132 -1 
            132 107 133 -1 
            107 106 133 -1 
            133 106 134 -1 
            106 105 134 -1 
            134 105 135 -1 
            105 104 135 -1 
            135 104 136 -1 
            136 104 137 -1 
            104 103 137 -1 
            137 103 138 -1 
            103 102 138 -1 
            138 102 139 -1 
            102 101 139 -1 
            139 101 140 -1 
            101 100 140 -1 
            140 100 141 -1 
            96 110 97 -1 
            110 141 97 -1 
            100 99 141 -1 
            99 98 141 -1 
            141 98 97 -1 
            111 95 112 -1 
            127 126 80 -1 
            81 80 126 -1 
            126 125 81 -1 
            81 125 82 -1 
            125 124 82 -1 
            124 123 82 -1 
            95 94 112 -1 
            112 94 113 -1 
            82 123 83 -1 
            123 122 83 -1 
            83 122 84 -1 
            122 121 84 -1 
            84 121 85 -1 
            121 120 85 -1 
            85 120 86 -1 
            120 119 86 -1 
            86 119 87 -1 
            119 118 87 -1 
            87 118 88 -1 
            118 117 88 -1 
            88 117 89 -1 
            117 116 89 -1 
            89 116 90 -1 
            116 115 90 -1 
            90 115 91 -1 
            115 114 91 -1 
            91 114 92 -1 
            114 113 92 -1 
            92 113 93 -1 
            113 94 93 -1 
            157 156 158 -1 
            158 156 159 -1 
            159 156 160 -1 
            154 153 155 -1 
            153 152 155 -1 
            152 151 155 -1 
            151 150 155 -1 
            166 165 167 -1 
            165 164 167 -1 
            164 163 167 -1 
            167 163 168 -1 
            163 162 168 -1 
            168 162 169 -1 
            162 161 169 -1 
            169 161 170 -1 
            161 160 170 -1 
            160 156 170 -1 
            156 155 170 -1 
            170 155 171 -1 
            155 150 171 -1 
            171 150 172 -1 
            172 150 173 -1 
            150 149 173 -1 
            173 149 174 -1 
            149 148 174 -1 
            174 148 175 -1 
            148 147 175 -1 
            175 147 176 -1 
            176 147 177 -1 
            177 147 178 -1 
            178 147 179 -1 
            197 196 198 -1 
            198 196 199 -1 
            199 196 200 -1 
            200 196 201 -1 
            196 195 201 -1 
            201 195 202 -1 
            202 195 203 -1 
            203 195 204 -1 
            204 195 205 -1 
            205 195 206 -1 
            206 195 207 -1 
            195 194 207 -1 
            194 193 207 -1 
            193 192 207 -1 
            192 191 207 -1 
            191 190 207 -1 
            190 189 207 -1 
            181 180 182 -1 
            180 179 182 -1 
            182 179 183 -1 
            183 179 184 -1 
            184 179 185 -1 
            185 179 186 -1 
            186 179 187 -1 
            188 187 189 -1 
            147 146 179 -1 
            146 145 179 -1 
            145 144 179 -1 
            144 143 179 -1 
            143 142 179 -1 
            142 221 179 -1 
            208 207 209 -1 
            179 221 187 -1 
            221 220 187 -1 
            220 219 187 -1 
            187 219 189 -1 
            219 218 189 -1 
            218 217 189 -1 
            217 216 189 -1 
            216 215 189 -1 
            215 214 189 -1 
            214 213 189 -1 
            213 212 189 -1 
            209 207 210 -1 
            207 189 210 -1 
            210 189 211 -1 
            212 211 189 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0010"
              point="
              -1.00367543661264436e+00 -1.25376288801513219e-01 0.00000000000000000e+00,
              -1.00633594141479854e+00 -9.27851049751251544e-02 0.00000000000000000e+00,
              -1.01331976652045297e+00 -6.98382510565458414e-02 0.00000000000000000e+00,
              -1.02495947502987717e+00 -5.32100960430825221e-02 0.00000000000000000e+00,
              -1.03959225144172507e+00 -3.85773196312348410e-02 0.00000000000000000e+00,
              -1.05821578505680391e+00 -2.66050480215412610e-02 0.00000000000000000e+00,
              -1.08049751277484463e+00 -1.72932812140018100e-02 0.00000000000000000e+00,
              -1.10543974529503952e+00 -1.13071454091550616e-02 0.00000000000000000e+00,
              -1.13204479331658092e+00 -8.97920370727020578e-03 0.00000000000000000e+00,
              -1.28668663494178959e+00 -8.97920370727020578e-03 0.00000000000000000e+00,
              -1.29699609105013680e+00 -7.31638820592386829e-03 0.00000000000000000e+00,
              -1.30531016855686843e+00 -1.66281550134633749e-03 0.00000000000000000e+00,
              -1.31129630436171518e+00 6.31869890511604915e-03 0.00000000000000000e+00,
              -1.31295911986306146e+00 1.59630288129247178e-02 0.00000000000000000e+00,
              -1.31295911986306146e+00 2.30466228486601199e-01 0.00000000000000000e+00,
              -1.31129630436171518e+00 2.39777995294140678e-01 0.00000000000000000e+00,
              -1.30531016855686843e+00 2.48092072800872310e-01 0.00000000000000000e+00,
              -1.29699609105013680e+00 2.53413082405180567e-01 0.00000000000000000e+00,
              -1.28668663494178959e+00 2.55408461006796150e-01 0.00000000000000000e+00,
              -9.77402951691372257e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -9.67758621783563644e-01 2.53413082405180567e-01 0.00000000000000000e+00,
              -9.59111981176562711e-01 2.47759509700603009e-01 0.00000000000000000e+00,
              -9.53125845371715963e-01 2.39112869093602132e-01 0.00000000000000000e+00,
              -9.51130466770100269e-01 2.29468539185793352e-01 0.00000000000000000e+00,
              -9.53125845371715963e-01 2.19491646177715383e-01 0.00000000000000000e+00,
              -9.59111981176562711e-01 2.11177568670983806e-01 0.00000000000000000e+00,
              -9.67758621783563644e-01 2.05523995966406303e-01 0.00000000000000000e+00,
              -9.77402951691372257e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -1.26041415002051749e+00 2.03861180465059910e-01 0.00000000000000000e+00,
              -1.26041415002051749e+00 4.25680768344660065e-02 0.00000000000000000e+00,
              -1.13204479331658092e+00 4.25680768344660065e-02 0.00000000000000000e+00,
              -1.09579541538723091e+00 3.95750089320426324e-02 0.00000000000000000e+00,
              -1.06154141605949648e+00 3.09283683250417551e-02 0.00000000000000000e+00,
              -1.03028048463418553e+00 1.72932812140018100e-02 0.00000000000000000e+00,
              -1.00367543661264436e+00 0.00000000000000000e+00 0.00000000000000000e+00,
              -9.82058835095142024e-01 -2.19491646177715771e-02 0.00000000000000000e+00,
              -9.65430680081678760e-01 -4.75565233385050190e-02 0.00000000000000000e+00,
              -9.54788660873062245e-01 -7.61569499616618906e-02 0.00000000000000000e+00,
              -9.51130466770100269e-01 -1.06420192086165072e-01 0.00000000000000000e+00,
              -9.54788660873062245e-01 -1.36683434210668253e-01 0.00000000000000000e+00,
              -9.65430680081678760e-01 -1.65283860833825125e-01 0.00000000000000000e+00,
              -9.82058835095142024e-01 -1.91223782654827867e-01 0.00000000000000000e+00,
              -1.00367543661264436e+00 -2.12840384172330144e-01 0.00000000000000000e+00,
              -1.03028048463418553e+00 -2.30466228486601199e-01 0.00000000000000000e+00,
              -1.06154141605949648e+00 -2.44101315597641116e-01 0.00000000000000000e+00,
              -1.09579541538723091e+00 -2.52747956204642021e-01 0.00000000000000000e+00,
              -1.13204479331658092e+00 -2.55408461006796150e-01 0.00000000000000000e+00,
              -1.17893619045454745e+00 -2.51750266903834230e-01 0.00000000000000000e+00,
              -1.22649271379305236e+00 -2.40110558394409923e-01 0.00000000000000000e+00,
              -1.26906079062751842e+00 -2.22817277180408113e-01 0.00000000000000000e+00,
              -1.30065428515309867e+00 -2.00535549462367291e-01 0.00000000000000000e+00,
              -1.30464504235632983e+00 -1.96877355359405370e-01 0.00000000000000000e+00,
              -1.30763811025875332e+00 -1.92886598156174177e-01 0.00000000000000000e+00,
              -1.31029861506090728e+00 -1.88230714752404465e-01 0.00000000000000000e+00,
              -1.31229399366252286e+00 -1.83242268248365481e-01 0.00000000000000000e+00,
              -1.31362424606360007e+00 -1.77588695543787950e-01 0.00000000000000000e+00,
              -1.31262655676279216e+00 -1.71602559738941174e-01 0.00000000000000000e+00,
              -1.31029861506090728e+00 -1.65948987034363643e-01 0.00000000000000000e+00,
              -1.30564273165713773e+00 -1.59962851229516867e-01 0.00000000000000000e+00,
              -1.30032172205282937e+00 -1.55639530926016401e-01 0.00000000000000000e+00,
              -1.29433558624798262e+00 -1.52979026123862272e-01 0.00000000000000000e+00,
              -1.28868201354340517e+00 -1.51981336823054480e-01 0.00000000000000000e+00,
              -1.28369356703936610e+00 -1.51981336823054480e-01 0.00000000000000000e+00,
              -1.27870512053532703e+00 -1.53644152324400818e-01 0.00000000000000000e+00,
              -1.27338411093101889e+00 -1.55972094026285674e-01 0.00000000000000000e+00,
              -1.26872822752724912e+00 -1.58632598828439803e-01 0.00000000000000000e+00,
              -1.26473747032401795e+00 -1.61625666730863204e-01 0.00000000000000000e+00,
              -1.26141183932132539e+00 -1.64618734633286579e-01 0.00000000000000000e+00,
              -1.25808620831863260e+00 -1.67279239435440708e-01 0.00000000000000000e+00,
              -1.25542570351647842e+00 -1.69607181137325591e-01 0.00000000000000000e+00,
              -1.25409545111540144e+00 -1.70604870438133382e-01 0.00000000000000000e+00,
              -1.23148116029709143e+00 -1.82577142047826935e-01 0.00000000000000000e+00,
              -1.19855741337043398e+00 -1.93551724356712695e-01 0.00000000000000000e+00,
              -1.16297316164162257e+00 -2.01533238763175082e-01 0.00000000000000000e+00,
              -1.13204479331658092e+00 -2.03861180465059938e-01 0.00000000000000000e+00,
              -1.10543974529503952e+00 -2.01865801863444355e-01 0.00000000000000000e+00,
              -1.08049751277484463e+00 -1.95879666058597579e-01 0.00000000000000000e+00,
              -1.05821578505680391e+00 -1.86567899251058128e-01 0.00000000000000000e+00,
              -1.03959225144172507e+00 -1.74263064541095303e-01 0.00000000000000000e+00,
              -1.02229897022772320e+00 -1.55639530926016401e-01 0.00000000000000000e+00,
              -6.35195521514297812e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -7.06364024971920634e-01 2.00868112562636481e-01 0.00000000000000000e+00,
              -7.50262354207463789e-01 1.92554035055904904e-01 0.00000000000000000e+00,
              -7.78530217730351359e-01 1.77921258644057223e-01 0.00000000000000000e+00,
              -8.01809634749199973e-01 1.56637220226824192e-01 0.00000000000000000e+00,
              -8.20100605264009519e-01 1.28701919804205811e-01 0.00000000000000000e+00,
              -8.32738003074241617e-01 9.34502311756637005e-02 0.00000000000000000e+00,
              -8.40386954380434759e-01 5.05495912409283932e-02 0.00000000000000000e+00,
              -8.42714896082319642e-01 -3.32563100269245293e-04 0.00000000000000000e+00,
              -8.40386954380434759e-01 -5.08821543411976940e-02 0.00000000000000000e+00,
              -8.32738003074241617e-01 -9.37827942759329736e-02 0.00000000000000000e+00,
              -8.20100605264009519e-01 -1.29034482904475140e-01 0.00000000000000000e+00,
              -8.01809634749199973e-01 -1.56969783327093465e-01 0.00000000000000000e+00,
              -7.78530217730351359e-01 -1.78253821744326468e-01 0.00000000000000000e+00,
              -7.50262354207463789e-01 -1.92554035055904904e-01 0.00000000000000000e+00,
              -7.17006044180537150e-01 -2.01200675662905837e-01 0.00000000000000000e+00,
              -6.77431035248494573e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -6.38188589416721186e-01 -2.01200675662905837e-01 0.00000000000000000e+00,
              -6.04599716289525357e-01 -1.92554035055904904e-01 0.00000000000000000e+00,
              -5.76664415866907087e-01 -1.78253821744326468e-01 0.00000000000000000e+00,
              -5.53384998848058363e-01 -1.56969783327093465e-01 0.00000000000000000e+00,
              -5.35094028333248817e-01 -1.29034482904475140e-01 0.00000000000000000e+00,
              -5.22456630523016607e-01 -9.37827942759329736e-02 0.00000000000000000e+00,
              -5.14807679216823577e-01 -5.08821543411976940e-02 0.00000000000000000e+00,
              -5.12147174414669393e-01 -3.32563100269245293e-04 0.00000000000000000e+00,
              -5.14807679216823577e-01 5.05495912409283932e-02 0.00000000000000000e+00,
              -5.22456630523016607e-01 9.34502311756637005e-02 0.00000000000000000e+00,
              -5.35094028333248817e-01 1.28701919804205811e-01 0.00000000000000000e+00,
              -5.53384998848058363e-01 1.56637220226824192e-01 0.00000000000000000e+00,
              -5.84313367173100118e-01 1.80581763446211352e-01 0.00000000000000000e+00,
              -6.22558123704065713e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -7.15675791779460058e-01 -2.51085140703295684e-01 0.00000000000000000e+00,
              -7.74206897426850893e-01 -2.38115179792794340e-01 0.00000000000000000e+00,
              -8.12119090857547188e-01 -2.16831141375561309e-01 0.00000000000000000e+00,
              -8.42382332982050452e-01 -1.87565588551865947e-01 0.00000000000000000e+00,
              -8.65661750000899066e-01 -1.51316210622515934e-01 0.00000000000000000e+00,
              -8.81957341914092918e-01 -1.07750444487242136e-01 0.00000000000000000e+00,
              -8.91934234922170943e-01 -5.72008532463137154e-02 0.00000000000000000e+00,
              -8.95259865924863618e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -8.91934234922170943e-01 5.68682901460444423e-02 0.00000000000000000e+00,
              -8.81957341914092918e-01 1.07417881386972836e-01 0.00000000000000000e+00,
              -8.65661750000899066e-01 1.50983647522246633e-01 0.00000000000000000e+00,
              -8.42382332982050452e-01 1.87565588551865892e-01 0.00000000000000000e+00,
              -8.12119090857547188e-01 2.16831141375561254e-01 0.00000000000000000e+00,
              -7.74206897426850893e-01 2.38115179792794285e-01 0.00000000000000000e+00,
              -7.29310878890500058e-01 2.51085140703295684e-01 0.00000000000000000e+00,
              -6.77431035248494573e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -6.35860647714836302e-01 2.52415393104372776e-01 0.00000000000000000e+00,
              -5.97948454284140118e-01 2.44101315597641089e-01 0.00000000000000000e+00,
              -5.64692144257213369e-01 2.30466228486601199e-01 0.00000000000000000e+00,
              -5.36424280734325798e-01 2.11510131771252996e-01 0.00000000000000000e+00,
              -5.13144863715477184e-01 1.87565588551865892e-01 0.00000000000000000e+00,
              -4.93523640799590546e-01 1.58965161928709020e-01 0.00000000000000000e+00,
              -4.78558301287473675e-01 1.25376288801513192e-01 0.00000000000000000e+00,
              -4.67916282078857160e-01 8.77966584710861420e-02 0.00000000000000000e+00,
              -4.61930146274010300e-01 4.58937078371586815e-02 0.00000000000000000e+00,
              -4.59602204572125417e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -4.62927835574818203e-01 -5.72008532463137154e-02 0.00000000000000000e+00,
              -4.72904728582896117e-01 -1.07750444487242136e-01 0.00000000000000000e+00,
              -4.89532883596359492e-01 -1.51316210622515934e-01 0.00000000000000000e+00,
              -5.12812300615207994e-01 -1.87565588551865947e-01 0.00000000000000000e+00,
              -5.53717561948327663e-01 -2.21154461679061776e-01 0.00000000000000000e+00,
              -5.25449698425439760e-02 -1.25376288801513219e-01 0.00000000000000000e+00,
              -5.52054746446979383e-02 -9.27851049751251544e-02 0.00000000000000000e+00,
              -6.21892997503525891e-02 -6.98382510565458414e-02 0.00000000000000000e+00,
              -7.38290082597770070e-02 -5.32100960430825221e-02 0.00000000000000000e+00,
              -8.84617846716246881e-02 -3.85773196312348410e-02 0.00000000000000000e+00,
              -1.07085318286703535e-01 -2.66050480215412610e-02 0.00000000000000000e+00,
              -1.29367046004744246e-01 -1.72932812140018100e-02 0.00000000000000000e+00,
              -1.54309278524939142e-01 -1.13071454091550616e-02 0.00000000000000000e+00,
              -1.80914326546480542e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -3.35556168171688984e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -3.45865624280036199e-01 -7.31638820592386829e-03 0.00000000000000000e+00,
              -3.54179701786768053e-01 -1.66281550134633749e-03 0.00000000000000000e+00,
              -3.60165837591614690e-01 6.31869890511604915e-03 0.00000000000000000e+00,
              -3.61828653092961083e-01 1.59630288129247178e-02 0.00000000000000000e+00,
              -3.61828653092961083e-01 2.30466228486601199e-01 0.00000000000000000e+00,
              -3.60165837591614690e-01 2.39777995294140678e-01 0.00000000000000000e+00,
              -3.54179701786768053e-01 2.48092072800872310e-01 0.00000000000000000e+00,
              -3.45865624280036199e-01 2.53413082405180567e-01 0.00000000000000000e+00,
              -3.35556168171688984e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -2.62724849212718770e-02 2.55408461006796150e-01 0.00000000000000000e+00,
              -1.66281550134630418e-02 2.53413082405180567e-01 0.00000000000000000e+00,
              -7.98151440646233112e-03 2.47759509700603009e-01 0.00000000000000000e+00,
              -1.99537860161558278e-03 2.39112869093602132e-01 0.00000000000000000e+00,
              0.00000000000000000e+00 2.29468539185793352e-01 0.00000000000000000e+00,
              -1.99537860161558278e-03 2.19491646177715383e-01 0.00000000000000000e+00,
              -7.98151440646233112e-03 2.11177568670983806e-01 0.00000000000000000e+00,
              -1.66281550134630418e-02 2.05523995966406303e-01 0.00000000000000000e+00,
              -2.62724849212718770e-02 2.03861180465059910e-01 0.00000000000000000e+00,
              -3.09283683250417107e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -3.09283683250417107e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -1.80914326546480542e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -1.44664948617130529e-01 3.95750089320426324e-02 0.00000000000000000e+00,
              -1.10410949289396099e-01 3.09283683250417551e-02 0.00000000000000000e+00,
              -7.91500178640851537e-02 1.72932812140018100e-02 0.00000000000000000e+00,
              -5.25449698425439760e-02 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.09283683250416441e-02 -2.19491646177715771e-02 0.00000000000000000e+00,
              -1.43002133115783803e-02 -4.75565233385050190e-02 0.00000000000000000e+00,
              -3.65819410296186476e-03 -7.61569499616618906e-02 0.00000000000000000e+00,
              0.00000000000000000e+00 -1.06420192086165072e-01 0.00000000000000000e+00,
              -3.65819410296186476e-03 -1.36683434210668253e-01 0.00000000000000000e+00,
              -1.43002133115783803e-02 -1.65283860833825125e-01 0.00000000000000000e+00,
              -3.09283683250416441e-02 -1.91223782654827867e-01 0.00000000000000000e+00,
              -5.25449698425439760e-02 -2.12840384172330144e-01 0.00000000000000000e+00,
              -7.91500178640851537e-02 -2.30466228486601199e-01 0.00000000000000000e+00,
              -1.10410949289396099e-01 -2.44101315597641116e-01 0.00000000000000000e+00,
              -1.44664948617130529e-01 -2.52747956204642021e-01 0.00000000000000000e+00,
              -1.80914326546480542e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -2.27805723684447070e-01 -2.51750266903834230e-01 0.00000000000000000e+00,
              -2.75362247022951978e-01 -2.40110558394409923e-01 0.00000000000000000e+00,
              -3.17930323857418040e-01 -2.22817277180408113e-01 0.00000000000000000e+00,
              -3.49523818382998286e-01 -2.00535549462367291e-01 0.00000000000000000e+00,
              -3.53514575586229451e-01 -1.96877355359405370e-01 0.00000000000000000e+00,
              -3.56507643488652826e-01 -1.92886598156174177e-01 0.00000000000000000e+00,
              -3.59168148290807010e-01 -1.88230714752404465e-01 0.00000000000000000e+00,
              -3.61163526892422482e-01 -1.83242268248365481e-01 0.00000000000000000e+00,
              -3.62493779293499574e-01 -1.77588695543787950e-01 0.00000000000000000e+00,
              -3.61496089992691783e-01 -1.71602559738941174e-01 0.00000000000000000e+00,
              -3.59168148290807010e-01 -1.65948987034363643e-01 0.00000000000000000e+00,
              -3.54512264887037354e-01 -1.59962851229516867e-01 0.00000000000000000e+00,
              -3.49191255282728985e-01 -1.55639530926016401e-01 0.00000000000000000e+00,
              -3.43205119477882237e-01 -1.52979026123862272e-01 0.00000000000000000e+00,
              -3.37551546773304789e-01 -1.51981336823054480e-01 0.00000000000000000e+00,
              -3.32563100269265721e-01 -1.51981336823054480e-01 0.00000000000000000e+00,
              -3.27574653765226653e-01 -1.53644152324400818e-01 0.00000000000000000e+00,
              -3.22253644160918507e-01 -1.55972094026285674e-01 0.00000000000000000e+00,
              -3.17597760757148739e-01 -1.58632598828439803e-01 0.00000000000000000e+00,
              -3.13607003553917352e-01 -1.61625666730863204e-01 0.00000000000000000e+00,
              -3.10281372551224788e-01 -1.64618734633286579e-01 0.00000000000000000e+00,
              -3.06955741548532224e-01 -1.67279239435440708e-01 0.00000000000000000e+00,
              -3.04295236746378039e-01 -1.69607181137325591e-01 0.00000000000000000e+00,
              -3.02964984345301058e-01 -1.70604870438133382e-01 0.00000000000000000e+00,
              -2.80350693526991046e-01 -1.82577142047826935e-01 0.00000000000000000e+00,
              -2.47426946600333597e-01 -1.93551724356712695e-01 0.00000000000000000e+00,
              -2.11842694871522186e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -1.80914326546480542e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -1.54309278524939142e-01 -2.01865801863444355e-01 0.00000000000000000e+00,
              -1.29367046004744246e-01 -1.95879666058597579e-01 0.00000000000000000e+00,
              -1.07085318286703535e-01 -1.86567899251058128e-01 0.00000000000000000e+00,
              -8.84617846716246881e-02 -1.74263064541095303e-01 0.00000000000000000e+00,
              -7.11685034576228226e-02 -1.55639530926016401e-01 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99588575103220251e+02 5.09997228640831111e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            15 14 16 -1 
            16 14 17 -1 
            17 14 18 -1 
            12 11 13 -1 
            11 10 13 -1 
            10 9 13 -1 
            9 8 13 -1 
            24 23 25 -1 
            23 22 25 -1 
            22 21 25 -1 
            25 21 26 -1 
            21 20 26 -1 
            26 20 27 -1 
            20 19 27 -1 
            27 19 28 -1 
            19 18 28 -1 
            18 14 28 -1 
            14 13 28 -1 
            28 13 29 -1 
            13 8 29 -1 
            29 8 30 -1 
            30 8 31 -1 
            8 7 31 -1 
            31 7 32 -1 
            7 6 32 -1 
            32 6 33 -1 
            6 5 33 -1 
            33 5 34 -1 
            34 5 35 -1 
            35 5 36 -1 
            36 5 37 -1 
            55 54 56 -1 
            56 54 57 -1 
            57 54 58 -1 
            58 54 59 -1 
            54 53 59 -1 
            59 53 60 -1 
            60 53 61 -1 
            61 53 62 -1 
            62 53 63 -1 
            63 53 64 -1 
            64 53 65 -1 
            53 52 65 -1 
            52 51 65 -1 
            51 50 65 -1 
            50 49 65 -1 
            49 48 65 -1 
            48 47 65 -1 
            39 38 40 -1 
            38 37 40 -1 
            40 37 41 -1 
            41 37 42 -1 
            42 37 43 -1 
            43 37 44 -1 
            44 37 45 -1 
            46 45 47 -1 
            5 4 37 -1 
            4 3 37 -1 
            3 2 37 -1 
            2 1 37 -1 
            1 0 37 -1 
            0 79 37 -1 
            66 65 67 -1 
            37 79 45 -1 
            79 78 45 -1 
            78 77 45 -1 
            45 77 47 -1 
            77 76 47 -1 
            76 75 47 -1 
            75 74 47 -1 
            74 73 47 -1 
            73 72 47 -1 
            72 71 47 -1 
            71 70 47 -1 
            67 65 68 -1 
            65 47 68 -1 
            68 47 69 -1 
            70 69 47 -1 
            94 93 95 -1 
            93 92 95 -1 
            92 91 95 -1 
            91 90 95 -1 
            90 89 95 -1 
            89 88 95 -1 
            88 87 95 -1 
            95 87 96 -1 
            87 86 96 -1 
            85 84 86 -1 
            86 84 96 -1 
            104 103 105 -1 
            103 102 105 -1 
            102 101 105 -1 
            101 100 105 -1 
            100 99 105 -1 
            99 98 105 -1 
            105 98 80 -1 
            81 80 82 -1 
            82 80 83 -1 
            83 80 84 -1 
            84 80 96 -1 
            96 80 97 -1 
            80 98 97 -1 
            153 106 154 -1 
            106 135 154 -1 
            121 137 122 -1 
            137 136 122 -1 
            154 135 155 -1 
            155 135 156 -1 
            135 134 156 -1 
            156 134 157 -1 
            157 134 158 -1 
            134 133 158 -1 
            158 133 159 -1 
            133 132 159 -1 
            159 132 160 -1 
            132 131 160 -1 
            160 131 161 -1 
            131 130 161 -1 
            161 130 162 -1 
            162 130 163 -1 
            130 129 163 -1 
            163 129 164 -1 
            129 128 164 -1 
            164 128 165 -1 
            128 127 165 -1 
            165 127 166 -1 
            127 126 166 -1 
            166 126 167 -1 
            122 136 123 -1 
            136 167 123 -1 
            126 125 167 -1 
            125 124 167 -1 
            167 124 123 -1 
            137 121 138 -1 
            153 152 106 -1 
            107 106 152 -1 
            152 151 107 -1 
            107 151 108 -1 
            151 150 108 -1 
            150 149 108 -1 
            121 120 138 -1 
            138 120 139 -1 
            108 149 109 -1 
            149 148 109 -1 
            109 148 110 -1 
            148 147 110 -1 
            110 147 111 -1 
            147 146 111 -1 
            111 146 112 -1 
            146 145 112 -1 
            112 145 113 -1 
            145 144 113 -1 
            113 144 114 -1 
            144 143 114 -1 
            114 143 115 -1 
            143 142 115 -1 
            115 142 116 -1 
            142 141 116 -1 
            116 141 117 -1 
            141 140 117 -1 
            117 140 118 -1 
            140 139 118 -1 
            118 139 119 -1 
            139 120 119 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0011"
              point="
              -8.19768042163740329e-01 -1.25376288801513219e-01 0.00000000000000000e+00,
              -8.22428546965894403e-01 -9.27851049751251544e-02 0.00000000000000000e+00,
              -8.29412372071548942e-01 -6.98382510565458414e-02 0.00000000000000000e+00,
              -8.41052080580973249e-01 -5.32100960430825221e-02 0.00000000000000000e+00,
              -8.55684856992821041e-01 -3.85773196312348410e-02 0.00000000000000000e+00,
              -8.74308390607899888e-01 -2.66050480215412610e-02 0.00000000000000000e+00,
              -8.96590118325940710e-01 -1.72932812140018100e-02 0.00000000000000000e+00,
              -9.21532350846135606e-01 -1.13071454091550616e-02 0.00000000000000000e+00,
              -9.48137398867676895e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -1.10277924049288556e+00 -8.97920370727020578e-03 0.00000000000000000e+00,
              -1.11308869660123277e+00 -7.31638820592386829e-03 0.00000000000000000e+00,
              -1.12140277410796441e+00 -1.66281550134633749e-03 0.00000000000000000e+00,
              -1.12738890991281115e+00 6.31869890511604915e-03 0.00000000000000000e+00,
              -1.12905172541415744e+00 1.59630288129247178e-02 0.00000000000000000e+00,
              -1.12905172541415744e+00 2.30466228486601199e-01 0.00000000000000000e+00,
              -1.12738890991281115e+00 2.39777995294140678e-01 0.00000000000000000e+00,
              -1.12140277410796441e+00 2.48092072800872310e-01 0.00000000000000000e+00,
              -1.11308869660123277e+00 2.53413082405180567e-01 0.00000000000000000e+00,
              -1.10277924049288556e+00 2.55408461006796150e-01 0.00000000000000000e+00,
              -7.93495557242468230e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -7.83851227334659617e-01 2.53413082405180567e-01 0.00000000000000000e+00,
              -7.75204586727658684e-01 2.47759509700603009e-01 0.00000000000000000e+00,
              -7.69218450922811936e-01 2.39112869093602132e-01 0.00000000000000000e+00,
              -7.67223072321196242e-01 2.29468539185793352e-01 0.00000000000000000e+00,
              -7.69218450922811936e-01 2.19491646177715383e-01 0.00000000000000000e+00,
              -7.75204586727658684e-01 2.11177568670983806e-01 0.00000000000000000e+00,
              -7.83851227334659617e-01 2.05523995966406303e-01 0.00000000000000000e+00,
              -7.93495557242468230e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -1.07650675557161346e+00 2.03861180465059910e-01 0.00000000000000000e+00,
              -1.07650675557161346e+00 4.25680768344660065e-02 0.00000000000000000e+00,
              -9.48137398867676895e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -9.11888020938326882e-01 3.95750089320426324e-02 0.00000000000000000e+00,
              -8.77634021610592452e-01 3.09283683250417551e-02 0.00000000000000000e+00,
              -8.46373090185281507e-01 1.72932812140018100e-02 0.00000000000000000e+00,
              -8.19768042163740329e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -7.98151440646237997e-01 -2.19491646177715771e-02 0.00000000000000000e+00,
              -7.81523285632774734e-01 -4.75565233385050190e-02 0.00000000000000000e+00,
              -7.70881266424158218e-01 -7.61569499616618906e-02 0.00000000000000000e+00,
              -7.67223072321196242e-01 -1.06420192086165072e-01 0.00000000000000000e+00,
              -7.70881266424158218e-01 -1.36683434210668253e-01 0.00000000000000000e+00,
              -7.81523285632774734e-01 -1.65283860833825125e-01 0.00000000000000000e+00,
              -7.98151440646237997e-01 -1.91223782654827867e-01 0.00000000000000000e+00,
              -8.19768042163740329e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              -8.46373090185281507e-01 -2.30466228486601199e-01 0.00000000000000000e+00,
              -8.77634021610592452e-01 -2.44101315597641116e-01 0.00000000000000000e+00,
              -9.11888020938326882e-01 -2.52747956204642021e-01 0.00000000000000000e+00,
              -9.48137398867676895e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -9.95028796005643423e-01 -2.51750266903834230e-01 0.00000000000000000e+00,
              -1.04258531934414833e+00 -2.40110558394409923e-01 0.00000000000000000e+00,
              -1.08515339617861439e+00 -2.22817277180408113e-01 0.00000000000000000e+00,
              -1.11674689070419464e+00 -2.00535549462367291e-01 0.00000000000000000e+00,
              -1.12073764790742580e+00 -1.96877355359405370e-01 0.00000000000000000e+00,
              -1.12373071580984929e+00 -1.92886598156174177e-01 0.00000000000000000e+00,
              -1.12639122061200325e+00 -1.88230714752404465e-01 0.00000000000000000e+00,
              -1.12838659921361906e+00 -1.83242268248365481e-01 0.00000000000000000e+00,
              -1.12971685161469604e+00 -1.77588695543787950e-01 0.00000000000000000e+00,
              -1.12871916231388814e+00 -1.71602559738941174e-01 0.00000000000000000e+00,
              -1.12639122061200325e+00 -1.65948987034363643e-01 0.00000000000000000e+00,
              -1.12173533720823371e+00 -1.59962851229516867e-01 0.00000000000000000e+00,
              -1.11641432760392534e+00 -1.55639530926016401e-01 0.00000000000000000e+00,
              -1.11042819179907859e+00 -1.52979026123862272e-01 0.00000000000000000e+00,
              -1.10477461909450114e+00 -1.51981336823054480e-01 0.00000000000000000e+00,
              -1.09978617259046207e+00 -1.51981336823054480e-01 0.00000000000000000e+00,
              -1.09479772608642301e+00 -1.53644152324400818e-01 0.00000000000000000e+00,
              -1.08947671648211486e+00 -1.55972094026285674e-01 0.00000000000000000e+00,
              -1.08482083307834509e+00 -1.58632598828439803e-01 0.00000000000000000e+00,
              -1.08083007587511393e+00 -1.61625666730863204e-01 0.00000000000000000e+00,
              -1.07750444487242136e+00 -1.64618734633286579e-01 0.00000000000000000e+00,
              -1.07417881386972858e+00 -1.67279239435440708e-01 0.00000000000000000e+00,
              -1.07151830906757439e+00 -1.69607181137325591e-01 0.00000000000000000e+00,
              -1.07018805666649741e+00 -1.70604870438133382e-01 0.00000000000000000e+00,
              -1.04757376584818740e+00 -1.82577142047826935e-01 0.00000000000000000e+00,
              -1.01465001892152995e+00 -1.93551724356712695e-01 0.00000000000000000e+00,
              -9.79065767192718539e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -9.48137398867676895e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -9.21532350846135606e-01 -2.01865801863444355e-01 0.00000000000000000e+00,
              -8.96590118325940710e-01 -1.95879666058597579e-01 0.00000000000000000e+00,
              -8.74308390607899888e-01 -1.86567899251058128e-01 0.00000000000000000e+00,
              -8.55684856992821041e-01 -1.74263064541095303e-01 0.00000000000000000e+00,
              -8.38391575778819176e-01 -1.55639530926016401e-01 0.00000000000000000e+00,
              -5.55380377449673945e-01 2.47426946600333819e-01 0.00000000000000000e+00,
              -5.47066299942942313e-01 2.53413082405180567e-01 0.00000000000000000e+00,
              -5.36424280734325798e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -5.26114824625978583e-01 2.53413082405180567e-01 0.00000000000000000e+00,
              -5.17468184018977650e-01 2.48092072800872310e-01 0.00000000000000000e+00,
              -5.11814611314400092e-01 2.40110558394409868e-01 0.00000000000000000e+00,
              -5.09819232712784509e-01 2.30798791586870444e-01 0.00000000000000000e+00,
              -5.09819232712784509e-01 -2.30798791586870472e-01 0.00000000000000000e+00,
              -5.11814611314400092e-01 -2.40443121494679196e-01 0.00000000000000000e+00,
              -5.17468184018977650e-01 -2.48424635901141555e-01 0.00000000000000000e+00,
              -5.26114824625978583e-01 -2.53745645505449813e-01 0.00000000000000000e+00,
              -5.36091717634056497e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -5.46401173742403823e-01 -2.53745645505449813e-01 0.00000000000000000e+00,
              -5.54715251249135455e-01 -2.48424635901141555e-01 0.00000000000000000e+00,
              -5.60701387053982203e-01 -2.40443121494679196e-01 0.00000000000000000e+00,
              -5.62364202555328485e-01 -2.30798791586870472e-01 0.00000000000000000e+00,
              -5.62364202555328485e-01 1.67611802535710008e-01 0.00000000000000000e+00,
              -6.63795948137454683e-01 6.45172414522375837e-02 0.00000000000000000e+00,
              -6.72110025644186315e-01 5.85311056473907798e-02 0.00000000000000000e+00,
              -6.82086918652264229e-01 5.68682901460444423e-02 0.00000000000000000e+00,
              -6.92396374760611444e-01 5.85311056473907798e-02 0.00000000000000000e+00,
              -7.01043015367612377e-01 6.38521152516990376e-02 0.00000000000000000e+00,
              -7.07029151172459125e-01 7.15010665578921234e-02 0.00000000000000000e+00,
              -7.09024529774074708e-01 8.11453964657008475e-02 0.00000000000000000e+00,
              -7.07694277372997727e-01 9.04571632732402708e-02 0.00000000000000000e+00,
              -7.02040704668420279e-01 9.87712407799719583e-02 0.00000000000000000e+00,
              -1.75593316942172284e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -2.46761820399795107e-01 2.00868112562636481e-01 0.00000000000000000e+00,
              -2.90660149635338261e-01 1.92554035055904904e-01 0.00000000000000000e+00,
              -3.18928013158225832e-01 1.77921258644057223e-01 0.00000000000000000e+00,
              -3.42207430177074445e-01 1.56637220226824192e-01 0.00000000000000000e+00,
              -3.60498400691884102e-01 1.28701919804205811e-01 0.00000000000000000e+00,
              -3.73135798502116200e-01 9.34502311756637005e-02 0.00000000000000000e+00,
              -3.80784749808309231e-01 5.05495912409283932e-02 0.00000000000000000e+00,
              -3.83112691510194114e-01 -3.32563100269245293e-04 0.00000000000000000e+00,
              -3.80784749808309231e-01 -5.08821543411976940e-02 0.00000000000000000e+00,
              -3.73135798502116200e-01 -9.37827942759329736e-02 0.00000000000000000e+00,
              -3.60498400691884102e-01 -1.29034482904475140e-01 0.00000000000000000e+00,
              -3.42207430177074445e-01 -1.56969783327093465e-01 0.00000000000000000e+00,
              -3.18928013158225832e-01 -1.78253821744326468e-01 0.00000000000000000e+00,
              -2.90660149635338261e-01 -1.92554035055904904e-01 0.00000000000000000e+00,
              -2.57403839608411622e-01 -2.01200675662905837e-01 0.00000000000000000e+00,
              -2.17828830676369045e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -1.78586384844595547e-01 -2.01200675662905837e-01 0.00000000000000000e+00,
              -1.44997511717399830e-01 -1.92554035055904904e-01 0.00000000000000000e+00,
              -1.17062211294781449e-01 -1.78253821744326468e-01 0.00000000000000000e+00,
              -9.37827942759328348e-02 -1.56969783327093465e-01 0.00000000000000000e+00,
              -7.54918237611232890e-02 -1.29034482904475140e-01 0.00000000000000000e+00,
              -6.28544259508911907e-02 -9.37827942759329736e-02 0.00000000000000000e+00,
              -5.52054746446979383e-02 -5.08821543411976940e-02 0.00000000000000000e+00,
              -5.25449698425439760e-02 -3.32563100269245293e-04 0.00000000000000000e+00,
              -5.52054746446979383e-02 5.05495912409283932e-02 0.00000000000000000e+00,
              -6.28544259508911907e-02 9.34502311756637005e-02 0.00000000000000000e+00,
              -7.54918237611232890e-02 1.28701919804205811e-01 0.00000000000000000e+00,
              -9.37827942759328348e-02 1.56637220226824192e-01 0.00000000000000000e+00,
              -1.24711162600974701e-01 1.80581763446211352e-01 0.00000000000000000e+00,
              -1.62955919131940297e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -2.56073587207334752e-01 -2.51085140703295684e-01 0.00000000000000000e+00,
              -3.14604692854725476e-01 -2.38115179792794340e-01 0.00000000000000000e+00,
              -3.52516886285421660e-01 -2.16831141375561309e-01 0.00000000000000000e+00,
              -3.82780128409924814e-01 -1.87565588551865947e-01 0.00000000000000000e+00,
              -4.06059545428773538e-01 -1.51316210622515934e-01 0.00000000000000000e+00,
              -4.22355137341967501e-01 -1.07750444487242136e-01 0.00000000000000000e+00,
              -4.32332030350045415e-01 -5.72008532463137154e-02 0.00000000000000000e+00,
              -4.35657661352738201e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -4.32332030350045415e-01 5.68682901460444423e-02 0.00000000000000000e+00,
              -4.22355137341967501e-01 1.07417881386972836e-01 0.00000000000000000e+00,
              -4.06059545428773538e-01 1.50983647522246633e-01 0.00000000000000000e+00,
              -3.82780128409924814e-01 1.87565588551865892e-01 0.00000000000000000e+00,
              -3.52516886285421660e-01 2.16831141375561254e-01 0.00000000000000000e+00,
              -3.14604692854725476e-01 2.38115179792794285e-01 0.00000000000000000e+00,
              -2.69708674318374531e-01 2.51085140703295684e-01 0.00000000000000000e+00,
              -2.17828830676369045e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -1.76258443142710886e-01 2.52415393104372776e-01 0.00000000000000000e+00,
              -1.38346249712014480e-01 2.44101315597641089e-01 0.00000000000000000e+00,
              -1.05089939685087952e-01 2.30466228486601199e-01 0.00000000000000000e+00,
              -7.68220761622002701e-02 2.11510131771252996e-01 0.00000000000000000e+00,
              -5.35426591433516563e-02 1.87565588551865892e-01 0.00000000000000000e+00,
              -3.39214362274649073e-02 1.58965161928709020e-01 0.00000000000000000e+00,
              -1.89560967153481474e-02 1.25376288801513192e-01 0.00000000000000000e+00,
              -8.31407750673163193e-03 8.77966584710861420e-02 0.00000000000000000e+00,
              -2.32794170188466154e-03 4.58937078371586815e-02 0.00000000000000000e+00,
              0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.32563100269256395e-03 -5.72008532463137154e-02 0.00000000000000000e+00,
              -1.33025240107704779e-02 -1.07750444487242136e-01 0.00000000000000000e+00,
              -2.99306790242337417e-02 -1.51316210622515934e-01 0.00000000000000000e+00,
              -5.32100960430825776e-02 -1.87565588551865947e-01 0.00000000000000000e+00,
              -9.41153573762021356e-02 -2.21154461679061776e-01 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99588575103220251e+02 5.14997228640831054e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            15 14 16 -1 
            16 14 17 -1 
            17 14 18 -1 
            12 11 13 -1 
            11 10 13 -1 
            10 9 13 -1 
            9 8 13 -1 
            24 23 25 -1 
            23 22 25 -1 
            22 21 25 -1 
            25 21 26 -1 
            21 20 26 -1 
            26 20 27 -1 
            20 19 27 -1 
            27 19 28 -1 
            19 18 28 -1 
            18 14 28 -1 
            14 13 28 -1 
            28 13 29 -1 
            13 8 29 -1 
            29 8 30 -1 
            30 8 31 -1 
            8 7 31 -1 
            31 7 32 -1 
            7 6 32 -1 
            32 6 33 -1 
            6 5 33 -1 
            33 5 34 -1 
            34 5 35 -1 
            35 5 36 -1 
            36 5 37 -1 
            55 54 56 -1 
            56 54 57 -1 
            57 54 58 -1 
            58 54 59 -1 
            54 53 59 -1 
            59 53 60 -1 
            60 53 61 -1 
            61 53 62 -1 
            62 53 63 -1 
            63 53 64 -1 
            64 53 65 -1 
            53 52 65 -1 
            52 51 65 -1 
            51 50 65 -1 
            50 49 65 -1 
            49 48 65 -1 
            48 47 65 -1 
            39 38 40 -1 
            38 37 40 -1 
            40 37 41 -1 
            41 37 42 -1 
            42 37 43 -1 
            43 37 44 -1 
            44 37 45 -1 
            46 45 47 -1 
            5 4 37 -1 
            4 3 37 -1 
            3 2 37 -1 
            2 1 37 -1 
            1 0 37 -1 
            0 79 37 -1 
            66 65 67 -1 
            37 79 45 -1 
            79 78 45 -1 
            78 77 45 -1 
            45 77 47 -1 
            77 76 47 -1 
            76 75 47 -1 
            75 74 47 -1 
            74 73 47 -1 
            73 72 47 -1 
            72 71 47 -1 
            71 70 47 -1 
            67 65 68 -1 
            65 47 68 -1 
            68 47 69 -1 
            70 69 47 -1 
            94 93 95 -1 
            93 92 95 -1 
            92 91 95 -1 
            91 90 95 -1 
            90 89 95 -1 
            89 88 95 -1 
            88 87 95 -1 
            95 87 96 -1 
            87 86 96 -1 
            85 84 86 -1 
            86 84 96 -1 
            104 103 105 -1 
            103 102 105 -1 
            102 101 105 -1 
            101 100 105 -1 
            100 99 105 -1 
            99 98 105 -1 
            105 98 80 -1 
            81 80 82 -1 
            82 80 83 -1 
            83 80 84 -1 
            84 80 96 -1 
            96 80 97 -1 
            80 98 97 -1 
            121 120 122 -1 
            122 120 123 -1 
            123 120 124 -1 
            118 117 119 -1 
            117 116 119 -1 
            116 115 119 -1 
            115 114 119 -1 
            130 129 131 -1 
            129 128 131 -1 
            128 127 131 -1 
            131 127 132 -1 
            127 126 132 -1 
            132 126 133 -1 
            126 125 133 -1 
            133 125 134 -1 
            125 124 134 -1 
            124 120 134 -1 
            120 119 134 -1 
            134 119 135 -1 
            119 114 135 -1 
            135 114 136 -1 
            136 114 137 -1 
            114 113 137 -1 
            137 113 138 -1 
            113 112 138 -1 
            138 112 139 -1 
            112 111 139 -1 
            139 111 140 -1 
            140 111 141 -1 
            141 111 142 -1 
            142 111 143 -1 
            161 160 162 -1 
            162 160 163 -1 
            163 160 164 -1 
            164 160 165 -1 
            160 159 165 -1 
            165 159 166 -1 
            166 159 167 -1 
            167 159 168 -1 
            168 159 169 -1 
            169 159 170 -1 
            170 159 171 -1 
            159 158 171 -1 
            158 157 171 -1 
            157 156 171 -1 
            156 155 171 -1 
            155 154 171 -1 
            154 153 171 -1 
            145 144 146 -1 
            144 143 146 -1 
            146 143 147 -1 
            147 143 148 -1 
            148 143 149 -1 
            149 143 150 -1 
            150 143 151 -1 
            152 151 153 -1 
            111 110 143 -1 
            110 109 143 -1 
            109 108 143 -1 
            108 107 143 -1 
            107 106 143 -1 
            106 185 143 -1 
            172 171 173 -1 
            143 185 151 -1 
            185 184 151 -1 
            184 183 151 -1 
            151 183 153 -1 
            183 182 153 -1 
            182 181 153 -1 
            181 180 153 -1 
            180 179 153 -1 
            179 178 153 -1 
            178 177 153 -1 
            177 176 153 -1 
            173 171 174 -1 
            171 153 174 -1 
            174 153 175 -1 
            176 175 153 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0012"
              point="
              -7.67223072321196353e-01 -1.25376288801513219e-01 0.00000000000000000e+00,
              -7.69883577123350427e-01 -9.27851049751251544e-02 0.00000000000000000e+00,
              -7.76867402229004966e-01 -6.98382510565458414e-02 0.00000000000000000e+00,
              -7.88507110738429273e-01 -5.32100960430825221e-02 0.00000000000000000e+00,
              -8.03139887150277065e-01 -3.85773196312348410e-02 0.00000000000000000e+00,
              -8.21763420765355912e-01 -2.66050480215412610e-02 0.00000000000000000e+00,
              -8.44045148483396734e-01 -1.72932812140018100e-02 0.00000000000000000e+00,
              -8.68987381003591630e-01 -1.13071454091550616e-02 0.00000000000000000e+00,
              -8.95592429025132919e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -1.05023427065034158e+00 -8.97920370727020578e-03 0.00000000000000000e+00,
              -1.06054372675868880e+00 -7.31638820592386829e-03 0.00000000000000000e+00,
              -1.06885780426542043e+00 -1.66281550134633749e-03 0.00000000000000000e+00,
              -1.07484394007026718e+00 6.31869890511604915e-03 0.00000000000000000e+00,
              -1.07650675557161346e+00 1.59630288129247178e-02 0.00000000000000000e+00,
              -1.07650675557161346e+00 2.30466228486601199e-01 0.00000000000000000e+00,
              -1.07484394007026718e+00 2.39777995294140678e-01 0.00000000000000000e+00,
              -1.06885780426542043e+00 2.48092072800872310e-01 0.00000000000000000e+00,
              -1.06054372675868880e+00 2.53413082405180567e-01 0.00000000000000000e+00,
              -1.05023427065034158e+00 2.55408461006796150e-01 0.00000000000000000e+00,
              -7.40950587399924254e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -7.31306257492115641e-01 2.53413082405180567e-01 0.00000000000000000e+00,
              -7.22659616885114708e-01 2.47759509700603009e-01 0.00000000000000000e+00,
              -7.16673481080267960e-01 2.39112869093602132e-01 0.00000000000000000e+00,
              -7.14678102478652266e-01 2.29468539185793352e-01 0.00000000000000000e+00,
              -7.16673481080267960e-01 2.19491646177715383e-01 0.00000000000000000e+00,
              -7.22659616885114708e-01 2.11177568670983806e-01 0.00000000000000000e+00,
              -7.31306257492115641e-01 2.05523995966406303e-01 0.00000000000000000e+00,
              -7.40950587399924254e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -1.02396178572906948e+00 2.03861180465059910e-01 0.00000000000000000e+00,
              -1.02396178572906948e+00 4.25680768344660065e-02 0.00000000000000000e+00,
              -8.95592429025132919e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -8.59343051095782906e-01 3.95750089320426324e-02 0.00000000000000000e+00,
              -8.25089051768048476e-01 3.09283683250417551e-02 0.00000000000000000e+00,
              -7.93828120342737531e-01 1.72932812140018100e-02 0.00000000000000000e+00,
              -7.67223072321196353e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -7.45606470803694021e-01 -2.19491646177715771e-02 0.00000000000000000e+00,
              -7.28978315790230758e-01 -4.75565233385050190e-02 0.00000000000000000e+00,
              -7.18336296581614242e-01 -7.61569499616618906e-02 0.00000000000000000e+00,
              -7.14678102478652266e-01 -1.06420192086165072e-01 0.00000000000000000e+00,
              -7.18336296581614242e-01 -1.36683434210668253e-01 0.00000000000000000e+00,
              -7.28978315790230758e-01 -1.65283860833825125e-01 0.00000000000000000e+00,
              -7.45606470803694021e-01 -1.91223782654827867e-01 0.00000000000000000e+00,
              -7.67223072321196353e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              -7.93828120342737531e-01 -2.30466228486601199e-01 0.00000000000000000e+00,
              -8.25089051768048476e-01 -2.44101315597641116e-01 0.00000000000000000e+00,
              -8.59343051095782906e-01 -2.52747956204642021e-01 0.00000000000000000e+00,
              -8.95592429025132919e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -9.42483826163099447e-01 -2.51750266903834230e-01 0.00000000000000000e+00,
              -9.90040349501604355e-01 -2.40110558394409923e-01 0.00000000000000000e+00,
              -1.03260842633607042e+00 -2.22817277180408113e-01 0.00000000000000000e+00,
              -1.06420192086165066e+00 -2.00535549462367291e-01 0.00000000000000000e+00,
              -1.06819267806488183e+00 -1.96877355359405370e-01 0.00000000000000000e+00,
              -1.07118574596730531e+00 -1.92886598156174177e-01 0.00000000000000000e+00,
              -1.07384625076945928e+00 -1.88230714752404465e-01 0.00000000000000000e+00,
              -1.07584162937107486e+00 -1.83242268248365481e-01 0.00000000000000000e+00,
              -1.07717188177215206e+00 -1.77588695543787950e-01 0.00000000000000000e+00,
              -1.07617419247134416e+00 -1.71602559738941174e-01 0.00000000000000000e+00,
              -1.07384625076945928e+00 -1.65948987034363643e-01 0.00000000000000000e+00,
              -1.06919036736568973e+00 -1.59962851229516867e-01 0.00000000000000000e+00,
              -1.06386935776138136e+00 -1.55639530926016401e-01 0.00000000000000000e+00,
              -1.05788322195653461e+00 -1.52979026123862272e-01 0.00000000000000000e+00,
              -1.05222964925195717e+00 -1.51981336823054480e-01 0.00000000000000000e+00,
              -1.04724120274791810e+00 -1.51981336823054480e-01 0.00000000000000000e+00,
              -1.04225275624387903e+00 -1.53644152324400818e-01 0.00000000000000000e+00,
              -1.03693174663957088e+00 -1.55972094026285674e-01 0.00000000000000000e+00,
              -1.03227586323580112e+00 -1.58632598828439803e-01 0.00000000000000000e+00,
              -1.02828510603256995e+00 -1.61625666730863204e-01 0.00000000000000000e+00,
              -1.02495947502987739e+00 -1.64618734633286579e-01 0.00000000000000000e+00,
              -1.02163384402718460e+00 -1.67279239435440708e-01 0.00000000000000000e+00,
              -1.01897333922503042e+00 -1.69607181137325591e-01 0.00000000000000000e+00,
              -1.01764308682395344e+00 -1.70604870438133382e-01 0.00000000000000000e+00,
              -9.95028796005643423e-01 -1.82577142047826935e-01 0.00000000000000000e+00,
              -9.62105049078986085e-01 -1.93551724356712695e-01 0.00000000000000000e+00,
              -9.26520797350174563e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -8.95592429025132919e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -8.68987381003591630e-01 -2.01865801863444355e-01 0.00000000000000000e+00,
              -8.44045148483396734e-01 -1.95879666058597579e-01 0.00000000000000000e+00,
              -8.21763420765355912e-01 -1.86567899251058128e-01 0.00000000000000000e+00,
              -8.03139887150277065e-01 -1.74263064541095303e-01 0.00000000000000000e+00,
              -7.85846605936275200e-01 -1.55639530926016401e-01 0.00000000000000000e+00,
              -5.02835407607129969e-01 2.47426946600333819e-01 0.00000000000000000e+00,
              -4.94521330100398337e-01 2.53413082405180567e-01 0.00000000000000000e+00,
              -4.83879310891781822e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -4.73569854783434607e-01 2.53413082405180567e-01 0.00000000000000000e+00,
              -4.64923214176433675e-01 2.48092072800872310e-01 0.00000000000000000e+00,
              -4.59269641471856116e-01 2.40110558394409868e-01 0.00000000000000000e+00,
              -4.57274262870240533e-01 2.30798791586870444e-01 0.00000000000000000e+00,
              -4.57274262870240533e-01 -2.30798791586870472e-01 0.00000000000000000e+00,
              -4.59269641471856116e-01 -2.40443121494679196e-01 0.00000000000000000e+00,
              -4.64923214176433675e-01 -2.48424635901141555e-01 0.00000000000000000e+00,
              -4.73569854783434607e-01 -2.53745645505449813e-01 0.00000000000000000e+00,
              -4.83546747791512521e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -4.93856203899859847e-01 -2.53745645505449813e-01 0.00000000000000000e+00,
              -5.02170281406591479e-01 -2.48424635901141555e-01 0.00000000000000000e+00,
              -5.08156417211438227e-01 -2.40443121494679196e-01 0.00000000000000000e+00,
              -5.09819232712784509e-01 -2.30798791586870472e-01 0.00000000000000000e+00,
              -5.09819232712784509e-01 1.67611802535710008e-01 0.00000000000000000e+00,
              -6.11250978294910707e-01 6.45172414522375837e-02 0.00000000000000000e+00,
              -6.19565055801642339e-01 5.85311056473907798e-02 0.00000000000000000e+00,
              -6.29541948809720253e-01 5.68682901460444423e-02 0.00000000000000000e+00,
              -6.39851404918067468e-01 5.85311056473907798e-02 0.00000000000000000e+00,
              -6.48498045525068401e-01 6.38521152516990376e-02 0.00000000000000000e+00,
              -6.54484181329915149e-01 7.15010665578921234e-02 0.00000000000000000e+00,
              -6.56479559931530732e-01 8.11453964657008475e-02 0.00000000000000000e+00,
              -6.55149307530453751e-01 9.04571632732402708e-02 0.00000000000000000e+00,
              -6.49495734825876303e-01 9.87712407799719583e-02 0.00000000000000000e+00,
              -5.25449698425439760e-02 -1.25376288801513219e-01 0.00000000000000000e+00,
              -5.52054746446981603e-02 -9.27851049751251544e-02 0.00000000000000000e+00,
              -6.21892997503528111e-02 -6.98382510565458414e-02 0.00000000000000000e+00,
              -7.38290082597767849e-02 -5.32100960430825221e-02 0.00000000000000000e+00,
              -8.84617846716246881e-02 -3.85773196312348410e-02 0.00000000000000000e+00,
              -1.07085318286703535e-01 -2.66050480215412610e-02 0.00000000000000000e+00,
              -1.29367046004744357e-01 -1.72932812140018100e-02 0.00000000000000000e+00,
              -1.54309278524939253e-01 -1.13071454091550616e-02 0.00000000000000000e+00,
              -1.80914326546480542e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -3.35556168171689095e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -3.45865624280036421e-01 -7.31638820592386829e-03 0.00000000000000000e+00,
              -3.54179701786768053e-01 -1.66281550134633749e-03 0.00000000000000000e+00,
              -3.60165837591614801e-01 6.31869890511604915e-03 0.00000000000000000e+00,
              -3.61828653092961194e-01 1.59630288129247178e-02 0.00000000000000000e+00,
              -3.61828653092961194e-01 2.30466228486601199e-01 0.00000000000000000e+00,
              -3.60165837591614801e-01 2.39777995294140678e-01 0.00000000000000000e+00,
              -3.54179701786768053e-01 2.48092072800872310e-01 0.00000000000000000e+00,
              -3.45865624280036421e-01 2.53413082405180567e-01 0.00000000000000000e+00,
              -3.35556168171689095e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -2.62724849212720990e-02 2.55408461006796150e-01 0.00000000000000000e+00,
              -1.66281550134632639e-02 2.53413082405180567e-01 0.00000000000000000e+00,
              -7.98151440646233112e-03 2.47759509700603009e-01 0.00000000000000000e+00,
              -1.99537860161558278e-03 2.39112869093602132e-01 0.00000000000000000e+00,
              0.00000000000000000e+00 2.29468539185793352e-01 0.00000000000000000e+00,
              -1.99537860161558278e-03 2.19491646177715383e-01 0.00000000000000000e+00,
              -7.98151440646233112e-03 2.11177568670983806e-01 0.00000000000000000e+00,
              -1.66281550134632639e-02 2.05523995966406303e-01 0.00000000000000000e+00,
              -2.62724849212720990e-02 2.03861180465059910e-01 0.00000000000000000e+00,
              -3.09283683250417218e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -3.09283683250417218e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -1.80914326546480542e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -1.44664948617130640e-01 3.95750089320426324e-02 0.00000000000000000e+00,
              -1.10410949289396321e-01 3.09283683250417551e-02 0.00000000000000000e+00,
              -7.91500178640853758e-02 1.72932812140018100e-02 0.00000000000000000e+00,
              -5.25449698425439760e-02 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.09283683250416441e-02 -2.19491646177715771e-02 0.00000000000000000e+00,
              -1.43002133115783803e-02 -4.75565233385050190e-02 0.00000000000000000e+00,
              -3.65819410296186476e-03 -7.61569499616618906e-02 0.00000000000000000e+00,
              0.00000000000000000e+00 -1.06420192086165072e-01 0.00000000000000000e+00,
              -3.65819410296186476e-03 -1.36683434210668253e-01 0.00000000000000000e+00,
              -1.43002133115783803e-02 -1.65283860833825125e-01 0.00000000000000000e+00,
              -3.09283683250416441e-02 -1.91223782654827867e-01 0.00000000000000000e+00,
              -5.25449698425439760e-02 -2.12840384172330144e-01 0.00000000000000000e+00,
              -7.91500178640853758e-02 -2.30466228486601199e-01 0.00000000000000000e+00,
              -1.10410949289396321e-01 -2.44101315597641116e-01 0.00000000000000000e+00,
              -1.44664948617130640e-01 -2.52747956204642021e-01 0.00000000000000000e+00,
              -1.80914326546480542e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -2.27805723684447070e-01 -2.51750266903834230e-01 0.00000000000000000e+00,
              -2.75362247022952089e-01 -2.40110558394409923e-01 0.00000000000000000e+00,
              -3.17930323857418040e-01 -2.22817277180408113e-01 0.00000000000000000e+00,
              -3.49523818382998286e-01 -2.00535549462367291e-01 0.00000000000000000e+00,
              -3.53514575586229562e-01 -1.96877355359405370e-01 0.00000000000000000e+00,
              -3.56507643488652937e-01 -1.92886598156174177e-01 0.00000000000000000e+00,
              -3.59168148290807010e-01 -1.88230714752404465e-01 0.00000000000000000e+00,
              -3.61163526892422704e-01 -1.83242268248365481e-01 0.00000000000000000e+00,
              -3.62493779293499685e-01 -1.77588695543787950e-01 0.00000000000000000e+00,
              -3.61496089992691894e-01 -1.71602559738941174e-01 0.00000000000000000e+00,
              -3.59168148290807010e-01 -1.65948987034363643e-01 0.00000000000000000e+00,
              -3.54512264887037354e-01 -1.59962851229516867e-01 0.00000000000000000e+00,
              -3.49191255282729096e-01 -1.55639530926016401e-01 0.00000000000000000e+00,
              -3.43205119477882348e-01 -1.52979026123862272e-01 0.00000000000000000e+00,
              -3.37551546773304789e-01 -1.51981336823054480e-01 0.00000000000000000e+00,
              -3.32563100269265832e-01 -1.51981336823054480e-01 0.00000000000000000e+00,
              -3.27574653765226875e-01 -1.53644152324400818e-01 0.00000000000000000e+00,
              -3.22253644160918618e-01 -1.55972094026285674e-01 0.00000000000000000e+00,
              -3.17597760757148739e-01 -1.58632598828439803e-01 0.00000000000000000e+00,
              -3.13607003553917685e-01 -1.61625666730863204e-01 0.00000000000000000e+00,
              -3.10281372551225010e-01 -1.64618734633286579e-01 0.00000000000000000e+00,
              -3.06955741548532335e-01 -1.67279239435440708e-01 0.00000000000000000e+00,
              -3.04295236746378150e-01 -1.69607181137325591e-01 0.00000000000000000e+00,
              -3.02964984345301169e-01 -1.70604870438133382e-01 0.00000000000000000e+00,
              -2.80350693526991046e-01 -1.82577142047826935e-01 0.00000000000000000e+00,
              -2.47426946600333708e-01 -1.93551724356712695e-01 0.00000000000000000e+00,
              -2.11842694871522297e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -1.80914326546480542e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -1.54309278524939253e-01 -2.01865801863444355e-01 0.00000000000000000e+00,
              -1.29367046004744357e-01 -1.95879666058597579e-01 0.00000000000000000e+00,
              -1.07085318286703535e-01 -1.86567899251058128e-01 0.00000000000000000e+00,
              -8.84617846716246881e-02 -1.74263064541095303e-01 0.00000000000000000e+00,
              -7.11685034576228226e-02 -1.55639530926016401e-01 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99588575103220251e+02 5.19997228640831054e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            15 14 16 -1 
            16 14 17 -1 
            17 14 18 -1 
            12 11 13 -1 
            11 10 13 -1 
            10 9 13 -1 
            9 8 13 -1 
            24 23 25 -1 
            23 22 25 -1 
            22 21 25 -1 
            25 21 26 -1 
            21 20 26 -1 
            26 20 27 -1 
            20 19 27 -1 
            27 19 28 -1 
            19 18 28 -1 
            18 14 28 -1 
            14 13 28 -1 
            28 13 29 -1 
            13 8 29 -1 
            29 8 30 -1 
            30 8 31 -1 
            8 7 31 -1 
            31 7 32 -1 
            7 6 32 -1 
            32 6 33 -1 
            6 5 33 -1 
            33 5 34 -1 
            34 5 35 -1 
            35 5 36 -1 
            36 5 37 -1 
            55 54 56 -1 
            56 54 57 -1 
            57 54 58 -1 
            58 54 59 -1 
            54 53 59 -1 
            59 53 60 -1 
            60 53 61 -1 
            61 53 62 -1 
            62 53 63 -1 
            63 53 64 -1 
            64 53 65 -1 
            53 52 65 -1 
            52 51 65 -1 
            51 50 65 -1 
            50 49 65 -1 
            49 48 65 -1 
            48 47 65 -1 
            39 38 40 -1 
            38 37 40 -1 
            40 37 41 -1 
            41 37 42 -1 
            42 37 43 -1 
            43 37 44 -1 
            44 37 45 -1 
            46 45 47 -1 
            5 4 37 -1 
            4 3 37 -1 
            3 2 37 -1 
            2 1 37 -1 
            1 0 37 -1 
            0 79 37 -1 
            66 65 67 -1 
            37 79 45 -1 
            79 78 45 -1 
            78 77 45 -1 
            45 77 47 -1 
            77 76 47 -1 
            76 75 47 -1 
            75 74 47 -1 
            74 73 47 -1 
            73 72 47 -1 
            72 71 47 -1 
            71 70 47 -1 
            67 65 68 -1 
            65 47 68 -1 
            68 47 69 -1 
            70 69 47 -1 
            83 82 84 -1 
            82 81 84 -1 
            81 80 84 -1 
            84 80 85 -1 
            85 80 86 -1 
            80 137 86 -1 
            110 109 111 -1 
            109 108 111 -1 
            111 108 112 -1 
            112 108 113 -1 
            134 133 135 -1 
            133 132 135 -1 
            132 131 135 -1 
            131 130 135 -1 
            130 129 135 -1 
            135 129 136 -1 
            136 129 137 -1 
            129 128 137 -1 
            106 105 107 -1 
            107 105 108 -1 
            108 105 113 -1 
            113 105 114 -1 
            114 105 115 -1 
            104 103 105 -1 
            115 105 116 -1 
            122 121 123 -1 
            121 120 123 -1 
            120 119 123 -1 
            119 118 123 -1 
            118 117 123 -1 
            117 116 123 -1 
            123 116 124 -1 
            137 128 86 -1 
            86 128 87 -1 
            128 127 87 -1 
            127 126 87 -1 
            126 125 87 -1 
            125 124 87 -1 
            87 124 88 -1 
            88 124 89 -1 
            89 124 90 -1 
            90 124 91 -1 
            91 124 92 -1 
            92 124 93 -1 
            103 102 105 -1 
            93 124 94 -1 
            124 116 94 -1 
            94 116 95 -1 
            95 116 96 -1 
            96 116 97 -1 
            97 116 98 -1 
            98 116 99 -1 
            99 116 100 -1 
            100 116 101 -1 
            101 116 102 -1 
            116 105 102 -1 
            185 138 186 -1 
            138 167 186 -1 
            153 169 154 -1 
            169 168 154 -1 
            186 167 187 -1 
            187 167 188 -1 
            167 166 188 -1 
            188 166 189 -1 
            189 166 190 -1 
            166 165 190 -1 
            190 165 191 -1 
            165 164 191 -1 
            191 164 192 -1 
            164 163 192 -1 
            192 163 193 -1 
            163 162 193 -1 
            193 162 194 -1 
            194 162 195 -1 
            162 161 195 -1 
            195 161 196 -1 
            161 160 196 -1 
            196 160 197 -1 
            160 159 197 -1 
            197 159 198 -1 
            159 158 198 -1 
            198 158 199 -1 
            154 168 155 -1 
            168 199 155 -1 
            158 157 199 -1 
            157 156 199 -1 
            199 156 155 -1 
            169 153 170 -1 
            185 184 138 -1 
            139 138 184 -1 
            184 183 139 -1 
            139 183 140 -1 
            183 182 140 -1 
            182 181 140 -1 
            153 152 170 -1 
            170 152 171 -1 
            140 181 141 -1 
            181 180 141 -1 
            141 180 142 -1 
            180 179 142 -1 
            142 179 143 -1 
            179 178 143 -1 
            143 178 144 -1 
            178 177 144 -1 
            144 177 145 -1 
            177 176 145 -1 
            145 176 146 -1 
            176 175 146 -1 
            146 175 147 -1 
            175 174 147 -1 
            147 174 148 -1 
            174 173 148 -1 
            148 173 149 -1 
            173 172 149 -1 
            149 172 150 -1 
            172 171 150 -1 
            150 171 151 -1 
            171 152 151 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0013"
              point="
              -9.82391398195411325e-01 -1.25376288801513219e-01 0.00000000000000000e+00,
              -9.85051902997565398e-01 -9.27851049751251544e-02 0.00000000000000000e+00,
              -9.92035728103219938e-01 -6.98382510565458414e-02 0.00000000000000000e+00,
              -1.00367543661264413e+00 -5.32100960430825221e-02 0.00000000000000000e+00,
              -1.01830821302449204e+00 -3.85773196312348410e-02 0.00000000000000000e+00,
              -1.03693174663957088e+00 -2.66050480215412610e-02 0.00000000000000000e+00,
              -1.05921347435761160e+00 -1.72932812140018100e-02 0.00000000000000000e+00,
              -1.08415570687780649e+00 -1.13071454091550616e-02 0.00000000000000000e+00,
              -1.11076075489934789e+00 -8.97920370727020578e-03 0.00000000000000000e+00,
              -1.26540259652455656e+00 -8.97920370727020578e-03 0.00000000000000000e+00,
              -1.27571205263290377e+00 -7.31638820592386829e-03 0.00000000000000000e+00,
              -1.28402613013963540e+00 -1.66281550134633749e-03 0.00000000000000000e+00,
              -1.29001226594448215e+00 6.31869890511604915e-03 0.00000000000000000e+00,
              -1.29167508144582843e+00 1.59630288129247178e-02 0.00000000000000000e+00,
              -1.29167508144582843e+00 2.30466228486601199e-01 0.00000000000000000e+00,
              -1.29001226594448215e+00 2.39777995294140678e-01 0.00000000000000000e+00,
              -1.28402613013963540e+00 2.48092072800872310e-01 0.00000000000000000e+00,
              -1.27571205263290377e+00 2.53413082405180567e-01 0.00000000000000000e+00,
              -1.26540259652455656e+00 2.55408461006796150e-01 0.00000000000000000e+00,
              -9.56118913274139226e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -9.46474583366330613e-01 2.53413082405180567e-01 0.00000000000000000e+00,
              -9.37827942759329680e-01 2.47759509700603009e-01 0.00000000000000000e+00,
              -9.31841806954482932e-01 2.39112869093602132e-01 0.00000000000000000e+00,
              -9.29846428352867238e-01 2.29468539185793352e-01 0.00000000000000000e+00,
              -9.31841806954482932e-01 2.19491646177715383e-01 0.00000000000000000e+00,
              -9.37827942759329680e-01 2.11177568670983806e-01 0.00000000000000000e+00,
              -9.46474583366330613e-01 2.05523995966406303e-01 0.00000000000000000e+00,
              -9.56118913274139226e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -1.23913011160328446e+00 2.03861180465059910e-01 0.00000000000000000e+00,
              -1.23913011160328446e+00 4.25680768344660065e-02 0.00000000000000000e+00,
              -1.11076075489934789e+00 4.25680768344660065e-02 0.00000000000000000e+00,
              -1.07451137696999788e+00 3.95750089320426324e-02 0.00000000000000000e+00,
              -1.04025737764226345e+00 3.09283683250417551e-02 0.00000000000000000e+00,
              -1.00899644621695250e+00 1.72932812140018100e-02 0.00000000000000000e+00,
              -9.82391398195411325e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -9.60774796677908993e-01 -2.19491646177715771e-02 0.00000000000000000e+00,
              -9.44146641664445729e-01 -4.75565233385050190e-02 0.00000000000000000e+00,
              -9.33504622455829214e-01 -7.61569499616618906e-02 0.00000000000000000e+00,
              -9.29846428352867238e-01 -1.06420192086165072e-01 0.00000000000000000e+00,
              -9.33504622455829214e-01 -1.36683434210668253e-01 0.00000000000000000e+00,
              -9.44146641664445729e-01 -1.65283860833825125e-01 0.00000000000000000e+00,
              -9.60774796677908993e-01 -1.91223782654827867e-01 0.00000000000000000e+00,
              -9.82391398195411325e-01 -2.12840384172330144e-01 0.00000000000000000e+00,
              -1.00899644621695250e+00 -2.30466228486601199e-01 0.00000000000000000e+00,
              -1.04025737764226345e+00 -2.44101315597641116e-01 0.00000000000000000e+00,
              -1.07451137696999788e+00 -2.52747956204642021e-01 0.00000000000000000e+00,
              -1.11076075489934789e+00 -2.55408461006796150e-01 0.00000000000000000e+00,
              -1.15765215203731442e+00 -2.51750266903834230e-01 0.00000000000000000e+00,
              -1.20520867537581933e+00 -2.40110558394409923e-01 0.00000000000000000e+00,
              -1.24777675221028539e+00 -2.22817277180408113e-01 0.00000000000000000e+00,
              -1.27937024673586563e+00 -2.00535549462367291e-01 0.00000000000000000e+00,
              -1.28336100393909680e+00 -1.96877355359405370e-01 0.00000000000000000e+00,
              -1.28635407184152029e+00 -1.92886598156174177e-01 0.00000000000000000e+00,
              -1.28901457664367425e+00 -1.88230714752404465e-01 0.00000000000000000e+00,
              -1.29100995524528983e+00 -1.83242268248365481e-01 0.00000000000000000e+00,
              -1.29234020764636703e+00 -1.77588695543787950e-01 0.00000000000000000e+00,
              -1.29134251834555913e+00 -1.71602559738941174e-01 0.00000000000000000e+00,
              -1.28901457664367425e+00 -1.65948987034363643e-01 0.00000000000000000e+00,
              -1.28435869323990470e+00 -1.59962851229516867e-01 0.00000000000000000e+00,
              -1.27903768363559633e+00 -1.55639530926016401e-01 0.00000000000000000e+00,
              -1.27305154783074959e+00 -1.52979026123862272e-01 0.00000000000000000e+00,
              -1.26739797512617214e+00 -1.51981336823054480e-01 0.00000000000000000e+00,
              -1.26240952862213307e+00 -1.51981336823054480e-01 0.00000000000000000e+00,
              -1.25742108211809400e+00 -1.53644152324400818e-01 0.00000000000000000e+00,
              -1.25210007251378586e+00 -1.55972094026285674e-01 0.00000000000000000e+00,
              -1.24744418911001609e+00 -1.58632598828439803e-01 0.00000000000000000e+00,
              -1.24345343190678492e+00 -1.61625666730863204e-01 0.00000000000000000e+00,
              -1.24012780090409236e+00 -1.64618734633286579e-01 0.00000000000000000e+00,
              -1.23680216990139957e+00 -1.67279239435440708e-01 0.00000000000000000e+00,
              -1.23414166509924539e+00 -1.69607181137325591e-01 0.00000000000000000e+00,
              -1.23281141269816841e+00 -1.70604870438133382e-01 0.00000000000000000e+00,
              -1.21019712187985840e+00 -1.82577142047826935e-01 0.00000000000000000e+00,
              -1.17727337495320095e+00 -1.93551724356712695e-01 0.00000000000000000e+00,
              -1.14168912322438953e+00 -2.01533238763175082e-01 0.00000000000000000e+00,
              -1.11076075489934789e+00 -2.03861180465059938e-01 0.00000000000000000e+00,
              -1.08415570687780649e+00 -2.01865801863444355e-01 0.00000000000000000e+00,
              -1.05921347435761160e+00 -1.95879666058597579e-01 0.00000000000000000e+00,
              -1.03693174663957088e+00 -1.86567899251058128e-01 0.00000000000000000e+00,
              -1.01830821302449204e+00 -1.74263064541095303e-01 0.00000000000000000e+00,
              -1.00101493181049017e+00 -1.55639530926016401e-01 0.00000000000000000e+00,
              -8.47703342586358599e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -8.58345361794975115e-01 -2.53745645505449813e-01 0.00000000000000000e+00,
              -8.66659439301706858e-01 -2.48424635901141555e-01 0.00000000000000000e+00,
              -8.72313012006284305e-01 -2.40775684594948441e-01 0.00000000000000000e+00,
              -8.73975827507630587e-01 -2.30798791586870472e-01 0.00000000000000000e+00,
              -8.72645575106553606e-01 -2.22152150979869567e-01 0.00000000000000000e+00,
              -8.67657128602514538e-01 -2.14835762773945727e-01 0.00000000000000000e+00,
              -5.82650551671753836e-01 7.98151440646237553e-02 0.00000000000000000e+00,
              -5.76664415866907087e-01 8.84617846716246881e-02 0.00000000000000000e+00,
              -5.70678280062060228e-01 1.00766619381587541e-01 0.00000000000000000e+00,
              -5.66354959758559762e-01 1.13071454091550394e-01 0.00000000000000000e+00,
              -5.64692144257213369e-01 1.22383220899089817e-01 0.00000000000000000e+00,
              -5.67020085959098363e-01 1.37015997310937498e-01 0.00000000000000000e+00,
              -5.74003911064752792e-01 1.51648773722785235e-01 0.00000000000000000e+00,
              -5.84978493373638608e-01 1.65283860833825069e-01 0.00000000000000000e+00,
              -5.99278706685217100e-01 1.77588695543787922e-01 0.00000000000000000e+00,
              -6.17569677200026756e-01 1.88230714752404382e-01 0.00000000000000000e+00,
              -6.39851404918067468e-01 1.96544792259136070e-01 0.00000000000000000e+00,
              -6.65458763638800965e-01 2.01865801863444327e-01 0.00000000000000000e+00,
              -6.92728937860880745e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -7.30308568191307739e-01 2.00535549462367291e-01 0.00000000000000000e+00,
              -7.70881266424158218e-01 1.91556345755097113e-01 0.00000000000000000e+00,
              -8.07463207453777532e-01 1.78586384844595714e-01 0.00000000000000000e+00,
              -8.31075187572895335e-01 1.64951297733555879e-01 0.00000000000000000e+00,
              -8.40054391280165458e-01 1.59962851229516811e-01 0.00000000000000000e+00,
              -8.50363847388512673e-01 1.59297725028978321e-01 0.00000000000000000e+00,
              -8.60008177296321508e-01 1.61958229831132450e-01 0.00000000000000000e+00,
              -8.67989691702783839e-01 1.68276928736248499e-01 0.00000000000000000e+00,
              -8.72978138206822907e-01 1.77256132443518677e-01 0.00000000000000000e+00,
              -8.73643264407361286e-01 1.87233025451596646e-01 0.00000000000000000e+00,
              -8.70982759605207213e-01 1.96877355359405370e-01 0.00000000000000000e+00,
              -8.64331497599821974e-01 2.04858869765867757e-01 0.00000000000000000e+00,
              -8.31407750673164636e-01 2.25145218882292941e-01 0.00000000000000000e+00,
              -7.88174547638159972e-01 2.41440810795486960e-01 0.00000000000000000e+00,
              -7.40285461199385653e-01 2.51750266903834174e-01 0.00000000000000000e+00,
              -6.92728937860880745e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -6.57477249232338523e-01 2.52747956204642077e-01 0.00000000000000000e+00,
              -6.23555813004873505e-01 2.45764131098987426e-01 0.00000000000000000e+00,
              -5.92960007780101050e-01 2.34124422589563175e-01 0.00000000000000000e+00,
              -5.66022396658290572e-01 2.19159083077446137e-01 0.00000000000000000e+00,
              -5.44073232040518939e-01 2.00202986362098045e-01 0.00000000000000000e+00,
              -5.26779950826517074e-01 1.77588695543787922e-01 0.00000000000000000e+00,
              -5.15805368517631369e-01 1.51981336823054480e-01 0.00000000000000000e+00,
              -5.12147174414669393e-01 1.24711162600974701e-01 0.00000000000000000e+00,
              -5.14475116116554387e-01 1.05089939685088007e-01 0.00000000000000000e+00,
              -5.20793815021670325e-01 8.51361536689320131e-02 0.00000000000000000e+00,
              -5.30438144929479161e-01 6.58474938533146203e-02 0.00000000000000000e+00,
              -5.42742979639441847e-01 4.98844650403898471e-02 0.00000000000000000e+00,
              -7.90502489340044856e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -5.38419659335941381e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -5.28442766327863467e-01 -2.05856559066675548e-01 0.00000000000000000e+00,
              -5.19796125720862645e-01 -2.11510131771253052e-01 0.00000000000000000e+00,
              -5.14142553016285087e-01 -2.20156772378253984e-01 0.00000000000000000e+00,
              -5.12147174414669393e-01 -2.29801102286062681e-01 0.00000000000000000e+00,
              -5.14142553016285087e-01 -2.39445432193871377e-01 0.00000000000000000e+00,
              -5.20128688821131835e-01 -2.47759509700603037e-01 0.00000000000000000e+00,
              -5.28775329428132768e-01 -2.53745645505449813e-01 0.00000000000000000e+00,
              -5.38419659335941381e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -1.75593316942172395e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -2.46761820399795218e-01 2.00868112562636481e-01 0.00000000000000000e+00,
              -2.90660149635338483e-01 1.92554035055904904e-01 0.00000000000000000e+00,
              -3.18928013158226165e-01 1.77921258644057223e-01 0.00000000000000000e+00,
              -3.42207430177074667e-01 1.56637220226824192e-01 0.00000000000000000e+00,
              -3.60498400691884324e-01 1.28701919804205811e-01 0.00000000000000000e+00,
              -3.73135798502116312e-01 9.34502311756637005e-02 0.00000000000000000e+00,
              -3.80784749808309564e-01 5.05495912409283932e-02 0.00000000000000000e+00,
              -3.83112691510194336e-01 -3.32563100269245293e-04 0.00000000000000000e+00,
              -3.80784749808309564e-01 -5.08821543411976940e-02 0.00000000000000000e+00,
              -3.73135798502116312e-01 -9.37827942759329736e-02 0.00000000000000000e+00,
              -3.60498400691884324e-01 -1.29034482904475140e-01 0.00000000000000000e+00,
              -3.42207430177074667e-01 -1.56969783327093465e-01 0.00000000000000000e+00,
              -3.18928013158226165e-01 -1.78253821744326468e-01 0.00000000000000000e+00,
              -2.90660149635338483e-01 -1.92554035055904904e-01 0.00000000000000000e+00,
              -2.57403839608411733e-01 -2.01200675662905837e-01 0.00000000000000000e+00,
              -2.17828830676369156e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -1.78586384844595658e-01 -2.01200675662905837e-01 0.00000000000000000e+00,
              -1.44997511717400052e-01 -1.92554035055904904e-01 0.00000000000000000e+00,
              -1.17062211294781671e-01 -1.78253821744326468e-01 0.00000000000000000e+00,
              -9.37827942759330568e-02 -1.56969783327093465e-01 0.00000000000000000e+00,
              -7.54918237611232890e-02 -1.29034482904475140e-01 0.00000000000000000e+00,
              -6.28544259508914127e-02 -9.37827942759329736e-02 0.00000000000000000e+00,
              -5.52054746446981603e-02 -5.08821543411976940e-02 0.00000000000000000e+00,
              -5.25449698425441980e-02 -3.32563100269245293e-04 0.00000000000000000e+00,
              -5.52054746446981603e-02 5.05495912409283932e-02 0.00000000000000000e+00,
              -6.28544259508914127e-02 9.34502311756637005e-02 0.00000000000000000e+00,
              -7.54918237611232890e-02 1.28701919804205811e-01 0.00000000000000000e+00,
              -9.37827942759330568e-02 1.56637220226824192e-01 0.00000000000000000e+00,
              -1.24711162600974701e-01 1.80581763446211352e-01 0.00000000000000000e+00,
              -1.62955919131940519e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -2.56073587207334752e-01 -2.51085140703295684e-01 0.00000000000000000e+00,
              -3.14604692854725476e-01 -2.38115179792794340e-01 0.00000000000000000e+00,
              -3.52516886285421882e-01 -2.16831141375561309e-01 0.00000000000000000e+00,
              -3.82780128409925036e-01 -1.87565588551865947e-01 0.00000000000000000e+00,
              -4.06059545428773760e-01 -1.51316210622515934e-01 0.00000000000000000e+00,
              -4.22355137341967724e-01 -1.07750444487242136e-01 0.00000000000000000e+00,
              -4.32332030350045748e-01 -5.72008532463137154e-02 0.00000000000000000e+00,
              -4.35657661352738312e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -4.32332030350045748e-01 5.68682901460444423e-02 0.00000000000000000e+00,
              -4.22355137341967724e-01 1.07417881386972836e-01 0.00000000000000000e+00,
              -4.06059545428773760e-01 1.50983647522246633e-01 0.00000000000000000e+00,
              -3.82780128409925036e-01 1.87565588551865892e-01 0.00000000000000000e+00,
              -3.52516886285421882e-01 2.16831141375561254e-01 0.00000000000000000e+00,
              -3.14604692854725476e-01 2.38115179792794285e-01 0.00000000000000000e+00,
              -2.69708674318374753e-01 2.51085140703295684e-01 0.00000000000000000e+00,
              -2.17828830676369156e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -1.76258443142710997e-01 2.52415393104372776e-01 0.00000000000000000e+00,
              -1.38346249712014702e-01 2.44101315597641089e-01 0.00000000000000000e+00,
              -1.05089939685088174e-01 2.30466228486601199e-01 0.00000000000000000e+00,
              -7.68220761622004922e-02 2.11510131771252996e-01 0.00000000000000000e+00,
              -5.35426591433518784e-02 1.87565588551865892e-01 0.00000000000000000e+00,
              -3.39214362274651293e-02 1.58965161928709020e-01 0.00000000000000000e+00,
              -1.89560967153481474e-02 1.25376288801513192e-01 0.00000000000000000e+00,
              -8.31407750673185397e-03 8.77966584710861420e-02 0.00000000000000000e+00,
              -2.32794170188488359e-03 4.58937078371586815e-02 0.00000000000000000e+00,
              -2.22044604925031308e-16 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.32563100269278600e-03 -5.72008532463137154e-02 0.00000000000000000e+00,
              -1.33025240107706999e-02 -1.07750444487242136e-01 0.00000000000000000e+00,
              -2.99306790242339638e-02 -1.51316210622515934e-01 0.00000000000000000e+00,
              -5.32100960430827996e-02 -1.87565588551865947e-01 0.00000000000000000e+00,
              -9.41153573762023576e-02 -2.21154461679061776e-01 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99792901872025652e+02 4.99792901872025652e+02 7.99972286408310964e+01"
        rotation="8.55167184663299773e-01 3.66530412295518748e-01 3.66530412295518804e-01 -1.72662016832750753e+00"
        scale="1.00000000000000022e+00 1.00000000000000022e+00 1.00000000000000022e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            40 0 41 -1 
            52 51 18 -1 
            41 0 42 -1 
            51 50 18 -1 
            42 0 43 -1 
            50 49 18 -1 
            43 0 44 -1 
            48 47 49 -1 
            47 46 49 -1 
            46 45 49 -1 
            45 44 49 -1 
            18 49 19 -1 
            19 49 20 -1 
            20 49 21 -1 
            21 49 22 -1 
            22 49 23 -1 
            23 49 24 -1 
            24 49 25 -1 
            49 44 25 -1 
            0 35 44 -1 
            35 34 44 -1 
            34 33 44 -1 
            33 32 44 -1 
            32 31 44 -1 
            31 30 44 -1 
            30 29 44 -1 
            29 28 44 -1 
            28 27 44 -1 
            27 26 44 -1 
            44 26 25 -1 
            85 84 65 -1 
            65 84 66 -1 
            1 0 40 -1 
            40 39 1 -1 
            39 38 1 -1 
            66 84 67 -1 
            12 11 72 -1 
            73 72 11 -1 
            38 37 1 -1 
            11 10 73 -1 
            10 9 73 -1 
            73 9 74 -1 
            9 8 74 -1 
            8 7 74 -1 
            74 7 75 -1 
            37 36 1 -1 
            7 6 75 -1 
            75 6 76 -1 
            6 5 76 -1 
            76 5 77 -1 
            5 4 77 -1 
            84 83 67 -1 
            67 83 68 -1 
            71 70 36 -1 
            70 69 36 -1 
            69 68 36 -1 
            77 4 78 -1 
            83 82 68 -1 
            82 81 68 -1 
            81 80 68 -1 
            80 79 68 -1 
            68 79 36 -1 
            79 78 36 -1 
            78 4 36 -1 
            4 3 36 -1 
            3 2 36 -1 
            2 1 36 -1 
            65 64 85 -1 
            72 97 12 -1 
            52 18 53 -1 
            85 64 86 -1 
            64 63 86 -1 
            53 18 54 -1 
            12 97 13 -1 
            86 63 87 -1 
            87 63 88 -1 
            63 62 88 -1 
            62 61 88 -1 
            54 18 55 -1 
            97 96 13 -1 
            13 96 14 -1 
            96 95 14 -1 
            14 95 15 -1 
            88 61 89 -1 
            61 60 89 -1 
            57 56 58 -1 
            58 56 59 -1 
            59 56 60 -1 
            18 17 55 -1 
            55 17 56 -1 
            95 94 15 -1 
            17 16 56 -1 
            16 15 56 -1 
            89 60 90 -1 
            90 60 91 -1 
            91 60 92 -1 
            92 60 93 -1 
            60 56 93 -1 
            93 56 94 -1 
            56 15 94 -1 
            145 98 146 -1 
            98 127 146 -1 
            113 129 114 -1 
            129 128 114 -1 
            146 127 147 -1 
            147 127 148 -1 
            127 126 148 -1 
            148 126 149 -1 
            149 126 150 -1 
            126 125 150 -1 
            150 125 151 -1 
            125 124 151 -1 
            151 124 152 -1 
            124 123 152 -1 
            152 123 153 -1 
            123 122 153 -1 
            153 122 154 -1 
            154 122 155 -1 
            122 121 155 -1 
            155 121 156 -1 
            121 120 156 -1 
            156 120 157 -1 
            120 119 157 -1 
            157 119 158 -1 
            119 118 158 -1 
            158 118 159 -1 
            114 128 115 -1 
            128 159 115 -1 
            118 117 159 -1 
            117 116 159 -1 
            159 116 115 -1 
            129 113 130 -1 
            145 144 98 -1 
            99 98 144 -1 
            144 143 99 -1 
            99 143 100 -1 
            143 142 100 -1 
            142 141 100 -1 
            113 112 130 -1 
            130 112 131 -1 
            100 141 101 -1 
            141 140 101 -1 
            101 140 102 -1 
            140 139 102 -1 
            102 139 103 -1 
            139 138 103 -1 
            103 138 104 -1 
            138 137 104 -1 
            104 137 105 -1 
            137 136 105 -1 
            105 136 106 -1 
            136 135 106 -1 
            106 135 107 -1 
            135 134 107 -1 
            107 134 108 -1 
            134 133 108 -1 
            108 133 109 -1 
            133 132 109 -1 
            109 132 110 -1 
            132 131 110 -1 
            110 131 111 -1 
            131 112 111 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0014"
              point="
              -8.21430857665086611e-01 1.39676502113091627e-01 0.00000000000000000e+00,
              -8.20765731464548121e-01 1.11408638590204057e-01 0.00000000000000000e+00,
              -8.17772663562124635e-01 9.37827942759329458e-02 0.00000000000000000e+00,
              -8.12784217058085678e-01 8.18105226662393936e-02 0.00000000000000000e+00,
              -8.04802702651623347e-01 7.15010665578921234e-02 0.00000000000000000e+00,
              -7.94493246543276133e-01 6.21892997503527001e-02 0.00000000000000000e+00,
              -7.81190722532505433e-01 5.52054746446981048e-02 0.00000000000000000e+00,
              -7.64562567519042169e-01 4.98844650403898471e-02 0.00000000000000000e+00,
              -7.44608781502886230e-01 4.58937078371586815e-02 0.00000000000000000e+00,
              -7.20664238283499126e-01 4.32332030350045526e-02 0.00000000000000000e+00,
              -6.92728937860880745e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -6.59140064733684916e-01 4.35657661352737979e-02 0.00000000000000000e+00,
              -6.31204764311066535e-01 4.75565233385050190e-02 0.00000000000000000e+00,
              -6.08923036593025713e-01 5.38752222436210682e-02 0.00000000000000000e+00,
              -5.91629755379023958e-01 6.28544259508912462e-02 0.00000000000000000e+00,
              -5.79324920669061161e-01 7.41615713600462523e-02 0.00000000000000000e+00,
              -5.71010843162329418e-01 8.74640953708168967e-02 0.00000000000000000e+00,
              -5.66354959758559762e-01 1.03427124183741670e-01 0.00000000000000000e+00,
              -5.64692144257213369e-01 1.23048347099628363e-01 0.00000000000000000e+00,
              -5.65689833558021160e-01 1.38346249712014591e-01 0.00000000000000000e+00,
              -5.68682901460444645e-01 1.51981336823054480e-01 0.00000000000000000e+00,
              -5.73671347964483491e-01 1.63953608432748033e-01 0.00000000000000000e+00,
              -5.81320299270676744e-01 1.74595627641364548e-01 0.00000000000000000e+00,
              -5.91962318479293259e-01 1.83574831348634726e-01 0.00000000000000000e+00,
              -6.05264842490063848e-01 1.90891219554558511e-01 0.00000000000000000e+00,
              -6.21892997503527112e-01 1.96212229158866769e-01 0.00000000000000000e+00,
              -6.41846783519683051e-01 2.00202986362098045e-01 0.00000000000000000e+00,
              -6.65458763638800965e-01 2.02863491164252174e-01 0.00000000000000000e+00,
              -6.93061500961150045e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -7.20996801383768315e-01 2.02863491164252174e-01 0.00000000000000000e+00,
              -7.44608781502886230e-01 2.00202986362098045e-01 0.00000000000000000e+00,
              -7.64562567519042169e-01 1.96212229158866769e-01 0.00000000000000000e+00,
              -7.81190722532505433e-01 1.90891219554558511e-01 0.00000000000000000e+00,
              -7.94493246543276133e-01 1.83574831348634726e-01 0.00000000000000000e+00,
              -8.04802702651623347e-01 1.74595627641364548e-01 0.00000000000000000e+00,
              -8.13449343258624280e-01 1.60627977430055358e-01 0.00000000000000000e+00,
              -8.12119090857547188e-01 1.56304657126554725e-02 0.00000000000000000e+00,
              -8.39056701979357666e-01 3.49191255282729207e-02 0.00000000000000000e+00,
              -8.58345361794975115e-01 5.95287949481985712e-02 0.00000000000000000e+00,
              -8.70317633404668722e-01 8.91269108721632342e-02 0.00000000000000000e+00,
              -8.73975827507630587e-01 1.24046036400436155e-01 0.00000000000000000e+00,
              -8.68654817903322329e-01 1.63621045332478787e-01 0.00000000000000000e+00,
              -8.52359225990128366e-01 1.96877355359405370e-01 0.00000000000000000e+00,
              -8.26086741068856378e-01 2.22484714080138812e-01 0.00000000000000000e+00,
              -7.90169926239775666e-01 2.40775684594948414e-01 0.00000000000000000e+00,
              -7.45939033903963322e-01 2.51750266903834174e-01 0.00000000000000000e+00,
              -6.93061500961150045e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -6.40183968018336769e-01 2.51750266903834174e-01 0.00000000000000000e+00,
              -5.95953075682524425e-01 2.40775684594948414e-01 0.00000000000000000e+00,
              -5.60368823953713013e-01 2.22484714080138812e-01 0.00000000000000000e+00,
              -5.34096339032440914e-01 1.96544792259136070e-01 0.00000000000000000e+00,
              -5.17800747119246951e-01 1.63288482232209486e-01 0.00000000000000000e+00,
              -5.12147174414669393e-01 1.23380910199897609e-01 0.00000000000000000e+00,
              -5.16137931617900669e-01 8.84617846716246881e-02 0.00000000000000000e+00,
              -5.28110203227594166e-01 5.85311056473907798e-02 0.00000000000000000e+00,
              -5.47731426143480804e-01 3.35888731271958840e-02 0.00000000000000000e+00,
              -5.74003911064752792e-01 1.46327764118476811e-02 0.00000000000000000e+00,
              -5.47398863043211614e-01 -7.31638820592386829e-03 0.00000000000000000e+00,
              -5.27777640127324865e-01 -3.49191255282729207e-02 0.00000000000000000e+00,
              -5.16137931617900669e-01 -6.85079986554687770e-02 0.00000000000000000e+00,
              -5.12147174414669393e-01 -1.07085318286703590e-01 0.00000000000000000e+00,
              -5.24119446024363000e-01 -1.70937433538402628e-01 0.00000000000000000e+00,
              -5.59038571552635921e-01 -2.17496267576099855e-01 0.00000000000000000e+00,
              -6.15906861698680363e-01 -2.46096694199256699e-01 0.00000000000000000e+00,
              -6.93061500961150045e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -7.70216140223619616e-01 -2.46096694199256699e-01 0.00000000000000000e+00,
              -8.27084430369664170e-01 -2.17163704475830582e-01 0.00000000000000000e+00,
              -8.62336118998206280e-01 -1.70604870438133382e-01 0.00000000000000000e+00,
              -8.73975827507630587e-01 -1.06420192086165072e-01 0.00000000000000000e+00,
              -8.70317633404668722e-01 -6.75103093546609578e-02 0.00000000000000000e+00,
              -8.58345361794975115e-01 -3.39214362274651293e-02 0.00000000000000000e+00,
              -8.39056701979357666e-01 -5.98613580484680385e-03 0.00000000000000000e+00,
              -6.56812123031800033e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -7.17338607280806451e-01 -1.06420192086164878e-02 0.00000000000000000e+00,
              -7.53587985210156353e-01 -1.46327764118476811e-02 0.00000000000000000e+00,
              -7.75537149827927874e-01 -2.19491646177715771e-02 0.00000000000000000e+00,
              -7.93162994142199040e-01 -3.25911838263880371e-02 0.00000000000000000e+00,
              -8.06132955052700328e-01 -4.62262709374279546e-02 0.00000000000000000e+00,
              -8.14779595659701261e-01 -6.25218628506219731e-02 0.00000000000000000e+00,
              -8.19768042163740329e-01 -8.24756488667779397e-02 0.00000000000000000e+00,
              -8.21430857665086611e-01 -1.06420192086165072e-01 0.00000000000000000e+00,
              -8.18437789762663237e-01 -1.37681123511476045e-01 0.00000000000000000e+00,
              -8.09458586055393003e-01 -1.61958229831132450e-01 0.00000000000000000e+00,
              -7.93162994142199040e-01 -1.80581763446211352e-01 0.00000000000000000e+00,
              -7.68885887822542635e-01 -1.93551724356712695e-01 0.00000000000000000e+00,
              -7.35962140895885297e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -6.93061500961150045e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -6.50160861026414683e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -6.17237114099757456e-01 -1.93551724356712695e-01 0.00000000000000000e+00,
              -5.92960007780100939e-01 -1.80581763446211352e-01 0.00000000000000000e+00,
              -5.76996978967176277e-01 -1.61958229831132450e-01 0.00000000000000000e+00,
              -5.68017775259906044e-01 -1.37681123511476045e-01 0.00000000000000000e+00,
              -5.64692144257213369e-01 -1.06420192086165072e-01 0.00000000000000000e+00,
              -5.66354959758559762e-01 -8.24756488667779397e-02 0.00000000000000000e+00,
              -5.71343406262598719e-01 -6.25218628506219731e-02 0.00000000000000000e+00,
              -5.79990046869599651e-01 -4.62262709374279546e-02 0.00000000000000000e+00,
              -5.92960007780100939e-01 -3.25911838263880371e-02 0.00000000000000000e+00,
              -6.16571987899218854e-01 -2.06189122166945127e-02 0.00000000000000000e+00,
              -1.75593316942172395e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -2.46761820399795329e-01 2.00868112562636481e-01 0.00000000000000000e+00,
              -2.90660149635338372e-01 1.92554035055904904e-01 0.00000000000000000e+00,
              -3.18928013158225943e-01 1.77921258644057223e-01 0.00000000000000000e+00,
              -3.42207430177074556e-01 1.56637220226824192e-01 0.00000000000000000e+00,
              -3.60498400691884213e-01 1.28701919804205811e-01 0.00000000000000000e+00,
              -3.73135798502116312e-01 9.34502311756637005e-02 0.00000000000000000e+00,
              -3.80784749808309453e-01 5.05495912409283932e-02 0.00000000000000000e+00,
              -3.83112691510194225e-01 -3.32563100269245293e-04 0.00000000000000000e+00,
              -3.80784749808309453e-01 -5.08821543411976940e-02 0.00000000000000000e+00,
              -3.73135798502116312e-01 -9.37827942759329736e-02 0.00000000000000000e+00,
              -3.60498400691884213e-01 -1.29034482904475140e-01 0.00000000000000000e+00,
              -3.42207430177074556e-01 -1.56969783327093465e-01 0.00000000000000000e+00,
              -3.18928013158225943e-01 -1.78253821744326468e-01 0.00000000000000000e+00,
              -2.90660149635338372e-01 -1.92554035055904904e-01 0.00000000000000000e+00,
              -2.57403839608411844e-01 -2.01200675662905837e-01 0.00000000000000000e+00,
              -2.17828830676369156e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -1.78586384844595880e-01 -2.01200675662905837e-01 0.00000000000000000e+00,
              -1.44997511717399941e-01 -1.92554035055904904e-01 0.00000000000000000e+00,
              -1.17062211294781671e-01 -1.78253821744326468e-01 0.00000000000000000e+00,
              -9.37827942759329458e-02 -1.56969783327093465e-01 0.00000000000000000e+00,
              -7.54918237611235110e-02 -1.29034482904475140e-01 0.00000000000000000e+00,
              -6.28544259508913017e-02 -9.37827942759329736e-02 0.00000000000000000e+00,
              -5.52054746446982714e-02 -5.08821543411976940e-02 0.00000000000000000e+00,
              -5.25449698425440870e-02 -3.32563100269245293e-04 0.00000000000000000e+00,
              -5.52054746446982714e-02 5.05495912409283932e-02 0.00000000000000000e+00,
              -6.28544259508913017e-02 9.34502311756637005e-02 0.00000000000000000e+00,
              -7.54918237611235110e-02 1.28701919804205811e-01 0.00000000000000000e+00,
              -9.37827942759329458e-02 1.56637220226824192e-01 0.00000000000000000e+00,
              -1.24711162600974812e-01 1.80581763446211352e-01 0.00000000000000000e+00,
              -1.62955919131940297e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -2.56073587207334752e-01 -2.51085140703295684e-01 0.00000000000000000e+00,
              -3.14604692854725587e-01 -2.38115179792794340e-01 0.00000000000000000e+00,
              -3.52516886285421882e-01 -2.16831141375561309e-01 0.00000000000000000e+00,
              -3.82780128409925036e-01 -1.87565588551865947e-01 0.00000000000000000e+00,
              -4.06059545428773705e-01 -1.51316210622515934e-01 0.00000000000000000e+00,
              -4.22355137341967668e-01 -1.07750444487242136e-01 0.00000000000000000e+00,
              -4.32332030350045582e-01 -5.72008532463137154e-02 0.00000000000000000e+00,
              -4.35657661352738312e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -4.32332030350045582e-01 5.68682901460444423e-02 0.00000000000000000e+00,
              -4.22355137341967668e-01 1.07417881386972836e-01 0.00000000000000000e+00,
              -4.06059545428773705e-01 1.50983647522246633e-01 0.00000000000000000e+00,
              -3.82780128409925036e-01 1.87565588551865892e-01 0.00000000000000000e+00,
              -3.52516886285421882e-01 2.16831141375561254e-01 0.00000000000000000e+00,
              -3.14604692854725587e-01 2.38115179792794285e-01 0.00000000000000000e+00,
              -2.69708674318374642e-01 2.51085140703295684e-01 0.00000000000000000e+00,
              -2.17828830676369156e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -1.76258443142710997e-01 2.52415393104372776e-01 0.00000000000000000e+00,
              -1.38346249712014702e-01 2.44101315597641089e-01 0.00000000000000000e+00,
              -1.05089939685088063e-01 2.30466228486601199e-01 0.00000000000000000e+00,
              -7.68220761622004922e-02 2.11510131771252996e-01 0.00000000000000000e+00,
              -5.35426591433518784e-02 1.87565588551865892e-01 0.00000000000000000e+00,
              -3.39214362274652403e-02 1.58965161928709020e-01 0.00000000000000000e+00,
              -1.89560967153482585e-02 1.25376288801513192e-01 0.00000000000000000e+00,
              -8.31407750673174295e-03 8.77966584710861420e-02 0.00000000000000000e+00,
              -2.32794170188499461e-03 4.58937078371586815e-02 0.00000000000000000e+00,
              0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.32563100269278600e-03 -5.72008532463137154e-02 0.00000000000000000e+00,
              -1.33025240107708109e-02 -1.07750444487242136e-01 0.00000000000000000e+00,
              -2.99306790242340748e-02 -1.51316210622515934e-01 0.00000000000000000e+00,
              -5.32100960430825776e-02 -1.87565588551865947e-01 0.00000000000000000e+00,
              -9.41153573762022466e-02 -2.21154461679061776e-01 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99792901872025652e+02 4.99792901872025652e+02 8.49972286408310964e+01"
        rotation="8.55167184663299773e-01 3.66530412295518748e-01 3.66530412295518804e-01 -1.72662016832750753e+00"
        scale="1.00000000000000022e+00 1.00000000000000022e+00 1.00000000000000022e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            40 0 41 -1 
            52 51 18 -1 
            41 0 42 -1 
            51 50 18 -1 
            42 0 43 -1 
            50 49 18 -1 
            43 0 44 -1 
            48 47 49 -1 
            47 46 49 -1 
            46 45 49 -1 
            45 44 49 -1 
            18 49 19 -1 
            19 49 20 -1 
            20 49 21 -1 
            21 49 22 -1 
            22 49 23 -1 
            23 49 24 -1 
            24 49 25 -1 
            49 44 25 -1 
            0 35 44 -1 
            35 34 44 -1 
            34 33 44 -1 
            33 32 44 -1 
            32 31 44 -1 
            31 30 44 -1 
            30 29 44 -1 
            29 28 44 -1 
            28 27 44 -1 
            27 26 44 -1 
            44 26 25 -1 
            85 84 65 -1 
            65 84 66 -1 
            1 0 40 -1 
            40 39 1 -1 
            39 38 1 -1 
            66 84 67 -1 
            12 11 72 -1 
            73 72 11 -1 
            38 37 1 -1 
            11 10 73 -1 
            10 9 73 -1 
            73 9 74 -1 
            9 8 74 -1 
            8 7 74 -1 
            74 7 75 -1 
            37 36 1 -1 
            7 6 75 -1 
            75 6 76 -1 
            6 5 76 -1 
            76 5 77 -1 
            5 4 77 -1 
            84 83 67 -1 
            67 83 68 -1 
            71 70 36 -1 
            70 69 36 -1 
            69 68 36 -1 
            77 4 78 -1 
            83 82 68 -1 
            82 81 68 -1 
            81 80 68 -1 
            80 79 68 -1 
            68 79 36 -1 
            79 78 36 -1 
            78 4 36 -1 
            4 3 36 -1 
            3 2 36 -1 
            2 1 36 -1 
            65 64 85 -1 
            72 97 12 -1 
            52 18 53 -1 
            85 64 86 -1 
            64 63 86 -1 
            53 18 54 -1 
            12 97 13 -1 
            86 63 87 -1 
            87 63 88 -1 
            63 62 88 -1 
            62 61 88 -1 
            54 18 55 -1 
            97 96 13 -1 
            13 96 14 -1 
            96 95 14 -1 
            14 95 15 -1 
            88 61 89 -1 
            61 60 89 -1 
            57 56 58 -1 
            58 56 59 -1 
            59 56 60 -1 
            18 17 55 -1 
            55 17 56 -1 
            95 94 15 -1 
            17 16 56 -1 
            16 15 56 -1 
            89 60 90 -1 
            90 60 91 -1 
            91 60 92 -1 
            92 60 93 -1 
            60 56 93 -1 
            93 56 94 -1 
            56 15 94 -1 
            113 112 114 -1 
            114 112 115 -1 
            115 112 116 -1 
            110 109 111 -1 
            109 108 111 -1 
            108 107 111 -1 
            107 106 111 -1 
            122 121 123 -1 
            121 120 123 -1 
            120 119 123 -1 
            123 119 124 -1 
            119 118 124 -1 
            124 118 125 -1 
            118 117 125 -1 
            125 117 126 -1 
            117 116 126 -1 
            116 112 126 -1 
            112 111 126 -1 
            126 111 127 -1 
            111 106 127 -1 
            127 106 128 -1 
            128 106 129 -1 
            106 105 129 -1 
            129 105 130 -1 
            105 104 130 -1 
            130 104 131 -1 
            104 103 131 -1 
            131 103 132 -1 
            132 103 133 -1 
            133 103 134 -1 
            134 103 135 -1 
            153 152 154 -1 
            154 152 155 -1 
            155 152 156 -1 
            156 152 157 -1 
            152 151 157 -1 
            157 151 158 -1 
            158 151 159 -1 
            159 151 160 -1 
            160 151 161 -1 
            161 151 162 -1 
            162 151 163 -1 
            151 150 163 -1 
            150 149 163 -1 
            149 148 163 -1 
            148 147 163 -1 
            147 146 163 -1 
            146 145 163 -1 
            137 136 138 -1 
            136 135 138 -1 
            138 135 139 -1 
            139 135 140 -1 
            140 135 141 -1 
            141 135 142 -1 
            142 135 143 -1 
            144 143 145 -1 
            103 102 135 -1 
            102 101 135 -1 
            101 100 135 -1 
            100 99 135 -1 
            99 98 135 -1 
            98 177 135 -1 
            164 163 165 -1 
            135 177 143 -1 
            177 176 143 -1 
            176 175 143 -1 
            143 175 145 -1 
            175 174 145 -1 
            174 173 145 -1 
            173 172 145 -1 
            172 171 145 -1 
            171 170 145 -1 
            170 169 145 -1 
            169 168 145 -1 
            165 163 166 -1 
            163 145 166 -1 
            166 145 167 -1 
            168 167 145 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0015"
              point="
              -7.68885887822542524e-01 1.39676502113091627e-01 0.00000000000000000e+00,
              -7.68220761622004034e-01 1.11408638590204057e-01 0.00000000000000000e+00,
              -7.65227693719580548e-01 9.37827942759329458e-02 0.00000000000000000e+00,
              -7.60239247215541591e-01 8.18105226662393936e-02 0.00000000000000000e+00,
              -7.52257732809079260e-01 7.15010665578921234e-02 0.00000000000000000e+00,
              -7.41948276700731935e-01 6.21892997503527001e-02 0.00000000000000000e+00,
              -7.28645752689961346e-01 5.52054746446981048e-02 0.00000000000000000e+00,
              -7.12017597676498082e-01 4.98844650403898471e-02 0.00000000000000000e+00,
              -6.92063811660342143e-01 4.58937078371586815e-02 0.00000000000000000e+00,
              -6.68119268440954928e-01 4.32332030350045526e-02 0.00000000000000000e+00,
              -6.40183968018336658e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -6.06595094891140829e-01 4.35657661352737979e-02 0.00000000000000000e+00,
              -5.78659794468522448e-01 4.75565233385050190e-02 0.00000000000000000e+00,
              -5.56378066750481626e-01 5.38752222436210682e-02 0.00000000000000000e+00,
              -5.39084785536479871e-01 6.28544259508912462e-02 0.00000000000000000e+00,
              -5.26779950826516963e-01 7.41615713600462523e-02 0.00000000000000000e+00,
              -5.18465873319785331e-01 8.74640953708168967e-02 0.00000000000000000e+00,
              -5.13809989916015564e-01 1.03427124183741670e-01 0.00000000000000000e+00,
              -5.12147174414669282e-01 1.23048347099628363e-01 0.00000000000000000e+00,
              -5.13144863715477184e-01 1.38346249712014591e-01 0.00000000000000000e+00,
              -5.16137931617900447e-01 1.51981336823054480e-01 0.00000000000000000e+00,
              -5.21126378121939515e-01 1.63953608432748033e-01 0.00000000000000000e+00,
              -5.28775329428132546e-01 1.74595627641364548e-01 0.00000000000000000e+00,
              -5.39417348636749061e-01 1.83574831348634726e-01 0.00000000000000000e+00,
              -5.52719872647519761e-01 1.90891219554558511e-01 0.00000000000000000e+00,
              -5.69348027660983025e-01 1.96212229158866769e-01 0.00000000000000000e+00,
              -5.89301813677138964e-01 2.00202986362098045e-01 0.00000000000000000e+00,
              -6.12913793796256878e-01 2.02863491164252174e-01 0.00000000000000000e+00,
              -6.40516531118605847e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -6.68451831541224228e-01 2.02863491164252174e-01 0.00000000000000000e+00,
              -6.92063811660342143e-01 2.00202986362098045e-01 0.00000000000000000e+00,
              -7.12017597676498082e-01 1.96212229158866769e-01 0.00000000000000000e+00,
              -7.28645752689961346e-01 1.90891219554558511e-01 0.00000000000000000e+00,
              -7.41948276700731935e-01 1.83574831348634726e-01 0.00000000000000000e+00,
              -7.52257732809079260e-01 1.74595627641364548e-01 0.00000000000000000e+00,
              -7.60904373416080082e-01 1.60627977430055358e-01 0.00000000000000000e+00,
              -7.59574121015003101e-01 1.56304657126554725e-02 0.00000000000000000e+00,
              -7.86511732136813579e-01 3.49191255282729207e-02 0.00000000000000000e+00,
              -8.05800391952431028e-01 5.95287949481985712e-02 0.00000000000000000e+00,
              -8.17772663562124635e-01 8.91269108721632342e-02 0.00000000000000000e+00,
              -8.21430857665086500e-01 1.24046036400436155e-01 0.00000000000000000e+00,
              -8.16109848060778242e-01 1.63621045332478787e-01 0.00000000000000000e+00,
              -7.99814256147584279e-01 1.96877355359405370e-01 0.00000000000000000e+00,
              -7.73541771226312291e-01 2.22484714080138812e-01 0.00000000000000000e+00,
              -7.37624956397231468e-01 2.40775684594948414e-01 0.00000000000000000e+00,
              -6.93394064061419124e-01 2.51750266903834174e-01 0.00000000000000000e+00,
              -6.40516531118605847e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -5.87638998175792571e-01 2.51750266903834174e-01 0.00000000000000000e+00,
              -5.43408105839980227e-01 2.40775684594948414e-01 0.00000000000000000e+00,
              -5.07823854111168815e-01 2.22484714080138812e-01 0.00000000000000000e+00,
              -4.81551369189896883e-01 1.96544792259136070e-01 0.00000000000000000e+00,
              -4.65255777276702809e-01 1.63288482232209486e-01 0.00000000000000000e+00,
              -4.59602204572125306e-01 1.23380910199897609e-01 0.00000000000000000e+00,
              -4.63592961775356527e-01 8.84617846716246881e-02 0.00000000000000000e+00,
              -4.75565233385050079e-01 5.85311056473907798e-02 0.00000000000000000e+00,
              -4.95186456300936717e-01 3.35888731271958840e-02 0.00000000000000000e+00,
              -5.21458941222208816e-01 1.46327764118476811e-02 0.00000000000000000e+00,
              -4.94853893200667527e-01 -7.31638820592386829e-03 0.00000000000000000e+00,
              -4.75232670284780834e-01 -3.49191255282729207e-02 0.00000000000000000e+00,
              -4.63592961775356527e-01 -6.85079986554687770e-02 0.00000000000000000e+00,
              -4.59602204572125306e-01 -1.07085318286703590e-01 0.00000000000000000e+00,
              -4.71574476181818913e-01 -1.70937433538402628e-01 0.00000000000000000e+00,
              -5.06493601710091834e-01 -2.17496267576099855e-01 0.00000000000000000e+00,
              -5.63361891856136277e-01 -2.46096694199256699e-01 0.00000000000000000e+00,
              -6.40516531118605847e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -7.17671170381075640e-01 -2.46096694199256699e-01 0.00000000000000000e+00,
              -7.74539460527120083e-01 -2.17163704475830582e-01 0.00000000000000000e+00,
              -8.09791149155662193e-01 -1.70604870438133382e-01 0.00000000000000000e+00,
              -8.21430857665086500e-01 -1.06420192086165072e-01 0.00000000000000000e+00,
              -8.17772663562124635e-01 -6.75103093546609578e-02 0.00000000000000000e+00,
              -8.05800391952431028e-01 -3.39214362274651293e-02 0.00000000000000000e+00,
              -7.86511732136813579e-01 -5.98613580484680385e-03 0.00000000000000000e+00,
              -6.04267153189255946e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -6.64793637438262364e-01 -1.06420192086164878e-02 0.00000000000000000e+00,
              -7.01043015367612266e-01 -1.46327764118476811e-02 0.00000000000000000e+00,
              -7.22992179985383787e-01 -2.19491646177715771e-02 0.00000000000000000e+00,
              -7.40618024299654953e-01 -3.25911838263880371e-02 0.00000000000000000e+00,
              -7.53587985210156353e-01 -4.62262709374279546e-02 0.00000000000000000e+00,
              -7.62234625817157174e-01 -6.25218628506219731e-02 0.00000000000000000e+00,
              -7.67223072321196131e-01 -8.24756488667779397e-02 0.00000000000000000e+00,
              -7.68885887822542524e-01 -1.06420192086165072e-01 0.00000000000000000e+00,
              -7.65892819920119150e-01 -1.37681123511476045e-01 0.00000000000000000e+00,
              -7.56913616212848916e-01 -1.61958229831132450e-01 0.00000000000000000e+00,
              -7.40618024299654953e-01 -1.80581763446211352e-01 0.00000000000000000e+00,
              -7.16340917979998548e-01 -1.93551724356712695e-01 0.00000000000000000e+00,
              -6.83417171053341210e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -6.40516531118605847e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -5.97615891183870707e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -5.64692144257213258e-01 -1.93551724356712695e-01 0.00000000000000000e+00,
              -5.40415037937556963e-01 -1.80581763446211352e-01 0.00000000000000000e+00,
              -5.24452009124632079e-01 -1.61958229831132450e-01 0.00000000000000000e+00,
              -5.15472805417361957e-01 -1.37681123511476045e-01 0.00000000000000000e+00,
              -5.12147174414669282e-01 -1.06420192086165072e-01 0.00000000000000000e+00,
              -5.13809989916015564e-01 -8.24756488667779397e-02 0.00000000000000000e+00,
              -5.18798436420054632e-01 -6.25218628506219731e-02 0.00000000000000000e+00,
              -5.27445077027055564e-01 -4.62262709374279546e-02 0.00000000000000000e+00,
              -5.40415037937556963e-01 -3.25911838263880371e-02 0.00000000000000000e+00,
              -5.64027018056674767e-01 -2.06189122166945127e-02 0.00000000000000000e+00,
              -5.25449698425439760e-02 -1.25376288801513219e-01 0.00000000000000000e+00,
              -5.52054746446981603e-02 -9.27851049751251544e-02 0.00000000000000000e+00,
              -6.21892997503527001e-02 -6.98382510565458414e-02 0.00000000000000000e+00,
              -7.38290082597770070e-02 -5.32100960430825221e-02 0.00000000000000000e+00,
              -8.84617846716246881e-02 -3.85773196312348410e-02 0.00000000000000000e+00,
              -1.07085318286703646e-01 -2.66050480215412610e-02 0.00000000000000000e+00,
              -1.29367046004744468e-01 -1.72932812140018100e-02 0.00000000000000000e+00,
              -1.54309278524939364e-01 -1.13071454091550616e-02 0.00000000000000000e+00,
              -1.80914326546480653e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -3.35556168171689206e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -3.45865624280036477e-01 -7.31638820592386829e-03 0.00000000000000000e+00,
              -3.54179701786768053e-01 -1.66281550134633749e-03 0.00000000000000000e+00,
              -3.60165837591614857e-01 6.31869890511604915e-03 0.00000000000000000e+00,
              -3.61828653092961194e-01 1.59630288129247178e-02 0.00000000000000000e+00,
              -3.61828653092961194e-01 2.30466228486601199e-01 0.00000000000000000e+00,
              -3.60165837591614857e-01 2.39777995294140678e-01 0.00000000000000000e+00,
              -3.54179701786768053e-01 2.48092072800872310e-01 0.00000000000000000e+00,
              -3.45865624280036477e-01 2.53413082405180567e-01 0.00000000000000000e+00,
              -3.35556168171689206e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -2.62724849212719880e-02 2.55408461006796150e-01 0.00000000000000000e+00,
              -1.66281550134632639e-02 2.53413082405180567e-01 0.00000000000000000e+00,
              -7.98151440646244215e-03 2.47759509700603009e-01 0.00000000000000000e+00,
              -1.99537860161558278e-03 2.39112869093602132e-01 0.00000000000000000e+00,
              0.00000000000000000e+00 2.29468539185793352e-01 0.00000000000000000e+00,
              -1.99537860161558278e-03 2.19491646177715383e-01 0.00000000000000000e+00,
              -7.98151440646244215e-03 2.11177568670983806e-01 0.00000000000000000e+00,
              -1.66281550134632639e-02 2.05523995966406303e-01 0.00000000000000000e+00,
              -2.62724849212719880e-02 2.03861180465059910e-01 0.00000000000000000e+00,
              -3.09283683250417218e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -3.09283683250417218e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -1.80914326546480653e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -1.44664948617130640e-01 3.95750089320426324e-02 0.00000000000000000e+00,
              -1.10410949289396210e-01 3.09283683250417551e-02 0.00000000000000000e+00,
              -7.91500178640852647e-02 1.72932812140018100e-02 0.00000000000000000e+00,
              -5.25449698425439760e-02 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.09283683250416441e-02 -2.19491646177715771e-02 0.00000000000000000e+00,
              -1.43002133115783803e-02 -4.75565233385050190e-02 0.00000000000000000e+00,
              -3.65819410296186476e-03 -7.61569499616618906e-02 0.00000000000000000e+00,
              0.00000000000000000e+00 -1.06420192086165072e-01 0.00000000000000000e+00,
              -3.65819410296186476e-03 -1.36683434210668253e-01 0.00000000000000000e+00,
              -1.43002133115783803e-02 -1.65283860833825125e-01 0.00000000000000000e+00,
              -3.09283683250416441e-02 -1.91223782654827867e-01 0.00000000000000000e+00,
              -5.25449698425439760e-02 -2.12840384172330144e-01 0.00000000000000000e+00,
              -7.91500178640852647e-02 -2.30466228486601199e-01 0.00000000000000000e+00,
              -1.10410949289396210e-01 -2.44101315597641116e-01 0.00000000000000000e+00,
              -1.44664948617130640e-01 -2.52747956204642021e-01 0.00000000000000000e+00,
              -1.80914326546480653e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -2.27805723684447070e-01 -2.51750266903834230e-01 0.00000000000000000e+00,
              -2.75362247022952089e-01 -2.40110558394409923e-01 0.00000000000000000e+00,
              -3.17930323857418040e-01 -2.22817277180408113e-01 0.00000000000000000e+00,
              -3.49523818382998341e-01 -2.00535549462367291e-01 0.00000000000000000e+00,
              -3.53514575586229618e-01 -1.96877355359405370e-01 0.00000000000000000e+00,
              -3.56507643488652937e-01 -1.92886598156174177e-01 0.00000000000000000e+00,
              -3.59168148290807066e-01 -1.88230714752404465e-01 0.00000000000000000e+00,
              -3.61163526892422704e-01 -1.83242268248365481e-01 0.00000000000000000e+00,
              -3.62493779293499740e-01 -1.77588695543787950e-01 0.00000000000000000e+00,
              -3.61496089992692005e-01 -1.71602559738941174e-01 0.00000000000000000e+00,
              -3.59168148290807066e-01 -1.65948987034363643e-01 0.00000000000000000e+00,
              -3.54512264887037354e-01 -1.59962851229516867e-01 0.00000000000000000e+00,
              -3.49191255282729096e-01 -1.55639530926016401e-01 0.00000000000000000e+00,
              -3.43205119477882292e-01 -1.52979026123862272e-01 0.00000000000000000e+00,
              -3.37551546773304789e-01 -1.51981336823054480e-01 0.00000000000000000e+00,
              -3.32563100269265832e-01 -1.51981336823054480e-01 0.00000000000000000e+00,
              -3.27574653765226764e-01 -1.53644152324400818e-01 0.00000000000000000e+00,
              -3.22253644160918618e-01 -1.55972094026285674e-01 0.00000000000000000e+00,
              -3.17597760757148850e-01 -1.58632598828439803e-01 0.00000000000000000e+00,
              -3.13607003553917685e-01 -1.61625666730863204e-01 0.00000000000000000e+00,
              -3.10281372551225010e-01 -1.64618734633286579e-01 0.00000000000000000e+00,
              -3.06955741548532335e-01 -1.67279239435440708e-01 0.00000000000000000e+00,
              -3.04295236746378261e-01 -1.69607181137325591e-01 0.00000000000000000e+00,
              -3.02964984345301169e-01 -1.70604870438133382e-01 0.00000000000000000e+00,
              -2.80350693526991157e-01 -1.82577142047826935e-01 0.00000000000000000e+00,
              -2.47426946600333819e-01 -1.93551724356712695e-01 0.00000000000000000e+00,
              -2.11842694871522297e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -1.80914326546480653e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -1.54309278524939364e-01 -2.01865801863444355e-01 0.00000000000000000e+00,
              -1.29367046004744468e-01 -1.95879666058597579e-01 0.00000000000000000e+00,
              -1.07085318286703646e-01 -1.86567899251058128e-01 0.00000000000000000e+00,
              -8.84617846716246881e-02 -1.74263064541095303e-01 0.00000000000000000e+00,
              -7.11685034576228226e-02 -1.55639530926016401e-01 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99792901872025652e+02 4.99792901872025652e+02 8.99972286408310964e+01"
        rotation="8.55167184663299773e-01 3.66530412295518748e-01 3.66530412295518804e-01 -1.72662016832750753e+00"
        scale="1.00000000000000022e+00 1.00000000000000022e+00 1.00000000000000022e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            77 76 84 -1 
            76 75 84 -1 
            75 74 84 -1 
            74 73 84 -1 
            97 96 8 -1 
            96 95 8 -1 
            8 95 9 -1 
            95 94 9 -1 
            9 94 10 -1 
            94 93 10 -1 
            10 93 11 -1 
            11 93 12 -1 
            93 92 12 -1 
            12 92 13 -1 
            13 92 14 -1 
            14 92 15 -1 
            92 91 15 -1 
            15 91 16 -1 
            73 72 84 -1 
            37 36 38 -1 
            38 36 39 -1 
            39 36 40 -1 
            36 35 40 -1 
            40 35 41 -1 
            41 35 42 -1 
            42 35 43 -1 
            43 35 44 -1 
            44 35 45 -1 
            45 35 46 -1 
            46 35 47 -1 
            35 34 47 -1 
            34 33 47 -1 
            33 32 47 -1 
            32 31 47 -1 
            31 30 47 -1 
            30 29 47 -1 
            69 68 70 -1 
            70 68 71 -1 
            71 68 72 -1 
            25 24 26 -1 
            26 24 27 -1 
            27 24 28 -1 
            28 24 29 -1 
            24 23 29 -1 
            23 22 29 -1 
            22 21 29 -1 
            21 20 29 -1 
            19 18 20 -1 
            18 17 20 -1 
            17 16 20 -1 
            48 47 49 -1 
            49 47 50 -1 
            47 29 50 -1 
            50 29 51 -1 
            51 29 52 -1 
            52 29 53 -1 
            53 29 54 -1 
            54 29 55 -1 
            55 29 56 -1 
            56 29 57 -1 
            57 29 58 -1 
            29 20 58 -1 
            58 20 59 -1 
            59 20 60 -1 
            60 20 61 -1 
            61 20 62 -1 
            62 20 63 -1 
            63 20 64 -1 
            20 16 64 -1 
            64 16 65 -1 
            65 16 66 -1 
            66 16 67 -1 
            67 16 68 -1 
            16 91 68 -1 
            91 90 68 -1 
            68 90 72 -1 
            90 89 72 -1 
            89 88 72 -1 
            88 87 72 -1 
            87 86 72 -1 
            86 85 72 -1 
            85 84 72 -1 
            77 84 78 -1 
            8 7 97 -1 
            7 6 97 -1 
            6 5 97 -1 
            97 5 98 -1 
            5 4 98 -1 
            4 3 98 -1 
            98 3 99 -1 
            3 2 99 -1 
            99 2 100 -1 
            2 1 100 -1 
            100 1 101 -1 
            1 0 101 -1 
            101 0 102 -1 
            0 83 102 -1 
            83 82 102 -1 
            82 81 102 -1 
            78 84 79 -1 
            81 80 102 -1 
            79 84 80 -1 
            84 109 80 -1 
            109 108 80 -1 
            108 107 80 -1 
            107 106 80 -1 
            106 105 80 -1 
            105 104 80 -1 
            104 103 80 -1 
            102 80 103 -1 
            157 110 158 -1 
            110 139 158 -1 
            125 141 126 -1 
            141 140 126 -1 
            158 139 159 -1 
            159 139 160 -1 
            139 138 160 -1 
            160 138 161 -1 
            161 138 162 -1 
            138 137 162 -1 
            162 137 163 -1 
            137 136 163 -1 
            163 136 164 -1 
            136 135 164 -1 
            164 135 165 -1 
            135 134 165 -1 
            165 134 166 -1 
            166 134 167 -1 
            134 133 167 -1 
            167 133 168 -1 
            133 132 168 -1 
            168 132 169 -1 
            132 131 169 -1 
            169 131 170 -1 
            131 130 170 -1 
            170 130 171 -1 
            126 140 127 -1 
            140 171 127 -1 
            130 129 171 -1 
            129 128 171 -1 
            171 128 127 -1 
            141 125 142 -1 
            157 156 110 -1 
            111 110 156 -1 
            156 155 111 -1 
            111 155 112 -1 
            155 154 112 -1 
            154 153 112 -1 
            125 124 142 -1 
            142 124 143 -1 
            112 153 113 -1 
            153 152 113 -1 
            113 152 114 -1 
            152 151 114 -1 
            114 151 115 -1 
            151 150 115 -1 
            115 150 116 -1 
            150 149 116 -1 
            116 149 117 -1 
            149 148 117 -1 
            117 148 118 -1 
            148 147 118 -1 
            118 147 119 -1 
            147 146 119 -1 
            119 146 120 -1 
            146 145 120 -1 
            120 145 121 -1 
            145 144 121 -1 
            121 144 122 -1 
            144 143 122 -1 
            122 143 123 -1 
            143 124 123 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0016"
              point="
              -8.86945788418131764e-01 1.91888908855366358e-01 0.00000000000000000e+00,
              -8.59675614196051985e-01 2.17496267576099800e-01 0.00000000000000000e+00,
              -8.21763420765355690e-01 2.37782616692525040e-01 0.00000000000000000e+00,
              -7.78197654630081836e-01 2.50752577603026383e-01 0.00000000000000000e+00,
              -7.34299325394538793e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -7.03370957069497038e-01 2.52747956204642077e-01 0.00000000000000000e+00,
              -6.73772841145532375e-01 2.45764131098987426e-01 0.00000000000000000e+00,
              -6.46170103823183295e-01 2.34456985689832420e-01 0.00000000000000000e+00,
              -6.21227871302988399e-01 2.19491646177715383e-01 0.00000000000000000e+00,
              -5.98613580484678387e-01 2.00868112562636481e-01 0.00000000000000000e+00,
              -5.78327231368253036e-01 1.78918947944865014e-01 0.00000000000000000e+00,
              -5.60368823953712791e-01 1.53976715424670063e-01 0.00000000000000000e+00,
              -5.45736047541865110e-01 1.27039104302859529e-01 0.00000000000000000e+00,
              -5.34096339032440803e-01 9.74409883788949216e-02 0.00000000000000000e+00,
              -5.25449698425439760e-01 6.61800569535839212e-02 0.00000000000000000e+00,
              -5.20128688821131613e-01 3.32563100269265832e-02 0.00000000000000000e+00,
              -5.18133310219516030e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -5.20128688821131613e-01 -3.32563100269265832e-02 0.00000000000000000e+00,
              -5.25449698425439760e-01 -6.58474938533146481e-02 0.00000000000000000e+00,
              -5.34096339032440803e-01 -9.71084252786256208e-02 0.00000000000000000e+00,
              -5.45736047541865110e-01 -1.26373978102321038e-01 0.00000000000000000e+00,
              -5.60701387053982092e-01 -1.53644152324400818e-01 0.00000000000000000e+00,
              -5.78659794468522337e-01 -1.78586384844595741e-01 0.00000000000000000e+00,
              -5.99611269785486067e-01 -2.00535549462367291e-01 0.00000000000000000e+00,
              -6.22225560603796191e-01 -2.19491646177715438e-01 0.00000000000000000e+00,
              -6.47500356224260387e-01 -2.34789548790101665e-01 0.00000000000000000e+00,
              -6.75435656646878657e-01 -2.46096694199256699e-01 0.00000000000000000e+00,
              -7.05698898771381922e-01 -2.53080519304911267e-01 0.00000000000000000e+00,
              -7.36959830196692867e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -7.83518664234390094e-01 -2.51750266903834230e-01 0.00000000000000000e+00,
              -8.30742624472625812e-01 -2.40110558394409923e-01 0.00000000000000000e+00,
              -8.72978138206822574e-01 -2.22817277180408113e-01 0.00000000000000000e+00,
              -9.04239069632133630e-01 -2.00535549462367291e-01 0.00000000000000000e+00,
              -9.08229826835364795e-01 -1.96877355359405370e-01 0.00000000000000000e+00,
              -9.11222894737788169e-01 -1.92886598156174177e-01 0.00000000000000000e+00,
              -9.13883399539942354e-01 -1.88230714752404465e-01 0.00000000000000000e+00,
              -9.15878778141557937e-01 -1.83242268248365481e-01 0.00000000000000000e+00,
              -9.17209030542634918e-01 -1.77588695543787950e-01 0.00000000000000000e+00,
              -9.16543904342096427e-01 -1.71602559738941174e-01 0.00000000000000000e+00,
              -9.13883399539942354e-01 -1.65948987034363643e-01 0.00000000000000000e+00,
              -9.09227516136172587e-01 -1.59962851229516867e-01 0.00000000000000000e+00,
              -9.04239069632133630e-01 -1.55639530926016401e-01 0.00000000000000000e+00,
              -8.98252933827286881e-01 -1.52979026123862272e-01 0.00000000000000000e+00,
              -8.92599361122709323e-01 -1.51981336823054480e-01 0.00000000000000000e+00,
              -8.87278351518401065e-01 -1.51981336823054480e-01 0.00000000000000000e+00,
              -8.82289905014362108e-01 -1.53644152324400818e-01 0.00000000000000000e+00,
              -8.76968895410053850e-01 -1.55972094026285674e-01 0.00000000000000000e+00,
              -8.72313012006284083e-01 -1.58632598828439803e-01 0.00000000000000000e+00,
              -8.68322254803052918e-01 -1.61625666730863204e-01 0.00000000000000000e+00,
              -8.64996623800360243e-01 -1.64618734633286579e-01 0.00000000000000000e+00,
              -8.61670992797667568e-01 -1.67279239435440708e-01 0.00000000000000000e+00,
              -8.59343051095782684e-01 -1.69607181137325591e-01 0.00000000000000000e+00,
              -8.58012798694705703e-01 -1.70604870438133382e-01 0.00000000000000000e+00,
              -8.35398507876395580e-01 -1.82577142047826935e-01 0.00000000000000000e+00,
              -8.02807324050007542e-01 -1.93551724356712695e-01 0.00000000000000000e+00,
              -7.67555635421465321e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -7.36959830196692867e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -7.11685034576228670e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -6.86742802056033774e-01 -1.94216850557251242e-01 0.00000000000000000e+00,
              -6.62798258836646670e-01 -1.82244578947557689e-01 0.00000000000000000e+00,
              -6.41181657319144338e-01 -1.66281550134632916e-01 0.00000000000000000e+00,
              -6.21560434403257700e-01 -1.46660327218746223e-01 0.00000000000000000e+00,
              -6.04599716289525135e-01 -1.23380910199897609e-01 0.00000000000000000e+00,
              -5.90632066078215945e-01 -9.71084252786256208e-02 0.00000000000000000e+00,
              -5.79657483769330129e-01 -6.71777462543917125e-02 0.00000000000000000e+00,
              -5.73006221763944890e-01 -3.45865624280036477e-02 0.00000000000000000e+00,
              -5.70678280062060006e-01 -6.65126200538546097e-04 0.00000000000000000e+00,
              -5.71343406262598608e-01 1.79584074145403561e-02 0.00000000000000000e+00,
              -5.73006221763944890e-01 3.59168148290807121e-02 0.00000000000000000e+00,
              -6.07260221091679209e-01 1.69607181137325091e-02 0.00000000000000000e+00,
              -6.47500356224260387e-01 2.66050480215407337e-03 0.00000000000000000e+00,
              -6.91066122359534241e-01 -6.31869890511607690e-03 0.00000000000000000e+00,
              -7.34631888494807983e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -7.78530217730351137e-01 -4.65588340376971166e-03 0.00000000000000000e+00,
              -8.21763420765355690e-01 8.64664060700093273e-03 0.00000000000000000e+00,
              -8.59675614196051985e-01 2.89329897234261169e-02 0.00000000000000000e+00,
              -8.86945788418131764e-01 5.45403484441596142e-02 0.00000000000000000e+00,
              -8.96922681426209789e-01 6.98382510565458414e-02 0.00000000000000000e+00,
              -9.04571632732402819e-01 8.74640953708168967e-02 0.00000000000000000e+00,
              -9.09560079236441887e-01 1.05422502785357253e-01 0.00000000000000000e+00,
              -9.11222894737788169e-01 1.23048347099628363e-01 0.00000000000000000e+00,
              -9.09560079236441887e-01 1.40341628313630173e-01 0.00000000000000000e+00,
              -9.04571632732402819e-01 1.58632598828439775e-01 0.00000000000000000e+00,
              -8.96922681426209789e-01 1.76258443142710886e-01 0.00000000000000000e+00,
              -8.44710274683935003e-01 8.54687167692013139e-02 0.00000000000000000e+00,
              -8.27084430369663948e-01 7.01708141568150867e-02 0.00000000000000000e+00,
              -7.98151440646237775e-01 5.62031639455058962e-02 0.00000000000000000e+00,
              -7.65227693719580548e-01 4.58937078371586815e-02 0.00000000000000000e+00,
              -7.33966762294269492e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -6.91731248560072731e-01 4.58937078371586815e-02 0.00000000000000000e+00,
              -6.48830608625337479e-01 5.68682901460444423e-02 0.00000000000000000e+00,
              -6.11916104495448976e-01 7.21661927584306695e-02 0.00000000000000000e+00,
              -5.86308745774715478e-01 8.97920370727017247e-02 0.00000000000000000e+00,
              -6.02271774587640252e-01 1.22050657798820572e-01 0.00000000000000000e+00,
              -6.22890686804334681e-01 1.50318521321708143e-01 0.00000000000000000e+00,
              -6.47500356224260387e-01 1.72932812140018211e-01 0.00000000000000000e+00,
              -6.74770530446340167e-01 1.89893530253750775e-01 0.00000000000000000e+00,
              -7.04036083270035640e-01 2.00202986362098045e-01 0.00000000000000000e+00,
              -7.33966762294269492e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -7.65227693719580548e-01 2.00202986362098045e-01 0.00000000000000000e+00,
              -7.98151440646237775e-01 1.90226093354020076e-01 0.00000000000000000e+00,
              -8.27084430369663948e-01 1.75925880042441585e-01 0.00000000000000000e+00,
              -8.44710274683935003e-01 1.60627977430055358e-01 0.00000000000000000e+00,
              -8.49366158087704770e-01 1.52979026123862272e-01 0.00000000000000000e+00,
              -8.54022041491474426e-01 1.42004443814976455e-01 0.00000000000000000e+00,
              -8.57680235594436402e-01 1.30697298405821449e-01 0.00000000000000000e+00,
              -8.58677924895244193e-01 1.23048347099628363e-01 0.00000000000000000e+00,
              -8.57680235594436402e-01 1.15066832693165977e-01 0.00000000000000000e+00,
              -8.54022041491474426e-01 1.04092250384280161e-01 0.00000000000000000e+00,
              -8.49366158087704770e-01 9.27851049751251544e-02 0.00000000000000000e+00,
              -1.75593316942172173e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -2.46761820399795107e-01 2.00868112562636481e-01 0.00000000000000000e+00,
              -2.90660149635338150e-01 1.92554035055904904e-01 0.00000000000000000e+00,
              -3.18928013158225721e-01 1.77921258644057223e-01 0.00000000000000000e+00,
              -3.42207430177074334e-01 1.56637220226824192e-01 0.00000000000000000e+00,
              -3.60498400691883991e-01 1.28701919804205811e-01 0.00000000000000000e+00,
              -3.73135798502116089e-01 9.34502311756637005e-02 0.00000000000000000e+00,
              -3.80784749808309231e-01 5.05495912409283932e-02 0.00000000000000000e+00,
              -3.83112691510194003e-01 -3.32563100269245293e-04 0.00000000000000000e+00,
              -3.80784749808309231e-01 -5.08821543411976940e-02 0.00000000000000000e+00,
              -3.73135798502116089e-01 -9.37827942759329736e-02 0.00000000000000000e+00,
              -3.60498400691883991e-01 -1.29034482904475140e-01 0.00000000000000000e+00,
              -3.42207430177074334e-01 -1.56969783327093465e-01 0.00000000000000000e+00,
              -3.18928013158225721e-01 -1.78253821744326468e-01 0.00000000000000000e+00,
              -2.90660149635338150e-01 -1.92554035055904904e-01 0.00000000000000000e+00,
              -2.57403839608411622e-01 -2.01200675662905837e-01 0.00000000000000000e+00,
              -2.17828830676368934e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -1.78586384844595658e-01 -2.01200675662905837e-01 0.00000000000000000e+00,
              -1.44997511717399719e-01 -1.92554035055904904e-01 0.00000000000000000e+00,
              -1.17062211294781449e-01 -1.78253821744326468e-01 0.00000000000000000e+00,
              -9.37827942759327238e-02 -1.56969783327093465e-01 0.00000000000000000e+00,
              -7.54918237611232890e-02 -1.29034482904475140e-01 0.00000000000000000e+00,
              -6.28544259508910796e-02 -9.37827942759329736e-02 0.00000000000000000e+00,
              -5.52054746446980493e-02 -5.08821543411976940e-02 0.00000000000000000e+00,
              -5.25449698425438649e-02 -3.32563100269245293e-04 0.00000000000000000e+00,
              -5.52054746446980493e-02 5.05495912409283932e-02 0.00000000000000000e+00,
              -6.28544259508910796e-02 9.34502311756637005e-02 0.00000000000000000e+00,
              -7.54918237611232890e-02 1.28701919804205811e-01 0.00000000000000000e+00,
              -9.37827942759327238e-02 1.56637220226824192e-01 0.00000000000000000e+00,
              -1.24711162600974590e-01 1.80581763446211352e-01 0.00000000000000000e+00,
              -1.62955919131940075e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -2.56073587207334530e-01 -2.51085140703295684e-01 0.00000000000000000e+00,
              -3.14604692854725365e-01 -2.38115179792794340e-01 0.00000000000000000e+00,
              -3.52516886285421660e-01 -2.16831141375561309e-01 0.00000000000000000e+00,
              -3.82780128409924814e-01 -1.87565588551865947e-01 0.00000000000000000e+00,
              -4.06059545428773427e-01 -1.51316210622515934e-01 0.00000000000000000e+00,
              -4.22355137341967390e-01 -1.07750444487242136e-01 0.00000000000000000e+00,
              -4.32332030350045415e-01 -5.72008532463137154e-02 0.00000000000000000e+00,
              -4.35657661352738090e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -4.32332030350045415e-01 5.68682901460444423e-02 0.00000000000000000e+00,
              -4.22355137341967390e-01 1.07417881386972836e-01 0.00000000000000000e+00,
              -4.06059545428773427e-01 1.50983647522246633e-01 0.00000000000000000e+00,
              -3.82780128409924814e-01 1.87565588551865892e-01 0.00000000000000000e+00,
              -3.52516886285421660e-01 2.16831141375561254e-01 0.00000000000000000e+00,
              -3.14604692854725365e-01 2.38115179792794285e-01 0.00000000000000000e+00,
              -2.69708674318374420e-01 2.51085140703295684e-01 0.00000000000000000e+00,
              -2.17828830676368934e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -1.76258443142710775e-01 2.52415393104372776e-01 0.00000000000000000e+00,
              -1.38346249712014480e-01 2.44101315597641089e-01 0.00000000000000000e+00,
              -1.05089939685087841e-01 2.30466228486601199e-01 0.00000000000000000e+00,
              -7.68220761622002701e-02 2.11510131771252996e-01 0.00000000000000000e+00,
              -5.35426591433516563e-02 1.87565588551865892e-01 0.00000000000000000e+00,
              -3.39214362274650183e-02 1.58965161928709020e-01 0.00000000000000000e+00,
              -1.89560967153480364e-02 1.25376288801513192e-01 0.00000000000000000e+00,
              -8.31407750673152091e-03 8.77966584710861420e-02 0.00000000000000000e+00,
              -2.32794170188477256e-03 4.58937078371586815e-02 0.00000000000000000e+00,
              0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.32563100269234191e-03 -5.72008532463137154e-02 0.00000000000000000e+00,
              -1.33025240107705889e-02 -1.07750444487242136e-01 0.00000000000000000e+00,
              -2.99306790242338527e-02 -1.51316210622515934e-01 0.00000000000000000e+00,
              -5.32100960430823555e-02 -1.87565588551865947e-01 0.00000000000000000e+00,
              -9.41153573762020246e-02 -2.21154461679061776e-01 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99792901872025652e+02 4.99792901872025652e+02 9.49972286408310964e+01"
        rotation="8.55167184663299773e-01 3.66530412295518748e-01 3.66530412295518804e-01 -1.72662016832750753e+00"
        scale="1.00000000000000022e+00 1.00000000000000022e+00 1.00000000000000022e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            77 76 84 -1 
            76 75 84 -1 
            75 74 84 -1 
            74 73 84 -1 
            97 96 8 -1 
            96 95 8 -1 
            8 95 9 -1 
            95 94 9 -1 
            9 94 10 -1 
            94 93 10 -1 
            10 93 11 -1 
            11 93 12 -1 
            93 92 12 -1 
            12 92 13 -1 
            13 92 14 -1 
            14 92 15 -1 
            92 91 15 -1 
            15 91 16 -1 
            73 72 84 -1 
            37 36 38 -1 
            38 36 39 -1 
            39 36 40 -1 
            36 35 40 -1 
            40 35 41 -1 
            41 35 42 -1 
            42 35 43 -1 
            43 35 44 -1 
            44 35 45 -1 
            45 35 46 -1 
            46 35 47 -1 
            35 34 47 -1 
            34 33 47 -1 
            33 32 47 -1 
            32 31 47 -1 
            31 30 47 -1 
            30 29 47 -1 
            69 68 70 -1 
            70 68 71 -1 
            71 68 72 -1 
            25 24 26 -1 
            26 24 27 -1 
            27 24 28 -1 
            28 24 29 -1 
            24 23 29 -1 
            23 22 29 -1 
            22 21 29 -1 
            21 20 29 -1 
            19 18 20 -1 
            18 17 20 -1 
            17 16 20 -1 
            48 47 49 -1 
            49 47 50 -1 
            47 29 50 -1 
            50 29 51 -1 
            51 29 52 -1 
            52 29 53 -1 
            53 29 54 -1 
            54 29 55 -1 
            55 29 56 -1 
            56 29 57 -1 
            57 29 58 -1 
            29 20 58 -1 
            58 20 59 -1 
            59 20 60 -1 
            60 20 61 -1 
            61 20 62 -1 
            62 20 63 -1 
            63 20 64 -1 
            20 16 64 -1 
            64 16 65 -1 
            65 16 66 -1 
            66 16 67 -1 
            67 16 68 -1 
            16 91 68 -1 
            91 90 68 -1 
            68 90 72 -1 
            90 89 72 -1 
            89 88 72 -1 
            88 87 72 -1 
            87 86 72 -1 
            86 85 72 -1 
            85 84 72 -1 
            77 84 78 -1 
            8 7 97 -1 
            7 6 97 -1 
            6 5 97 -1 
            97 5 98 -1 
            5 4 98 -1 
            4 3 98 -1 
            98 3 99 -1 
            3 2 99 -1 
            99 2 100 -1 
            2 1 100 -1 
            100 1 101 -1 
            1 0 101 -1 
            101 0 102 -1 
            0 83 102 -1 
            83 82 102 -1 
            82 81 102 -1 
            78 84 79 -1 
            81 80 102 -1 
            79 84 80 -1 
            84 109 80 -1 
            109 108 80 -1 
            108 107 80 -1 
            107 106 80 -1 
            106 105 80 -1 
            105 104 80 -1 
            104 103 80 -1 
            102 80 103 -1 
            125 124 126 -1 
            126 124 127 -1 
            127 124 128 -1 
            122 121 123 -1 
            121 120 123 -1 
            120 119 123 -1 
            119 118 123 -1 
            134 133 135 -1 
            133 132 135 -1 
            132 131 135 -1 
            135 131 136 -1 
            131 130 136 -1 
            136 130 137 -1 
            130 129 137 -1 
            137 129 138 -1 
            129 128 138 -1 
            128 124 138 -1 
            124 123 138 -1 
            138 123 139 -1 
            123 118 139 -1 
            139 118 140 -1 
            140 118 141 -1 
            118 117 141 -1 
            141 117 142 -1 
            117 116 142 -1 
            142 116 143 -1 
            116 115 143 -1 
            143 115 144 -1 
            144 115 145 -1 
            145 115 146 -1 
            146 115 147 -1 
            165 164 166 -1 
            166 164 167 -1 
            167 164 168 -1 
            168 164 169 -1 
            164 163 169 -1 
            169 163 170 -1 
            170 163 171 -1 
            171 163 172 -1 
            172 163 173 -1 
            173 163 174 -1 
            174 163 175 -1 
            163 162 175 -1 
            162 161 175 -1 
            161 160 175 -1 
            160 159 175 -1 
            159 158 175 -1 
            158 157 175 -1 
            149 148 150 -1 
            148 147 150 -1 
            150 147 151 -1 
            151 147 152 -1 
            152 147 153 -1 
            153 147 154 -1 
            154 147 155 -1 
            156 155 157 -1 
            115 114 147 -1 
            114 113 147 -1 
            113 112 147 -1 
            112 111 147 -1 
            111 110 147 -1 
            110 189 147 -1 
            176 175 177 -1 
            147 189 155 -1 
            189 188 155 -1 
            188 187 155 -1 
            155 187 157 -1 
            187 186 157 -1 
            186 185 157 -1 
            185 184 157 -1 
            184 183 157 -1 
            183 182 157 -1 
            182 181 157 -1 
            181 180 157 -1 
            177 175 178 -1 
            175 157 178 -1 
            178 157 179 -1 
            180 179 157 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0017"
              point="
              -8.34400818575587899e-01 1.91888908855366358e-01 0.00000000000000000e+00,
              -8.07130644353508120e-01 2.17496267576099800e-01 0.00000000000000000e+00,
              -7.69218450922811825e-01 2.37782616692525040e-01 0.00000000000000000e+00,
              -7.25652684787537972e-01 2.50752577603026383e-01 0.00000000000000000e+00,
              -6.81754355551994928e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -6.50825987226953173e-01 2.52747956204642077e-01 0.00000000000000000e+00,
              -6.21227871302988510e-01 2.45764131098987426e-01 0.00000000000000000e+00,
              -5.93625133980639430e-01 2.34456985689832420e-01 0.00000000000000000e+00,
              -5.68682901460444534e-01 2.19491646177715383e-01 0.00000000000000000e+00,
              -5.46068610642134411e-01 2.00868112562636481e-01 0.00000000000000000e+00,
              -5.25782261525709282e-01 1.78918947944865014e-01 0.00000000000000000e+00,
              -5.07823854111168815e-01 1.53976715424670063e-01 0.00000000000000000e+00,
              -4.93191077699321245e-01 1.27039104302859529e-01 0.00000000000000000e+00,
              -4.81551369189896938e-01 9.74409883788949216e-02 0.00000000000000000e+00,
              -4.72904728582895950e-01 6.61800569535839212e-02 0.00000000000000000e+00,
              -4.67583718978587692e-01 3.32563100269265832e-02 0.00000000000000000e+00,
              -4.65588340376972165e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -4.67583718978587692e-01 -3.32563100269265832e-02 0.00000000000000000e+00,
              -4.72904728582895950e-01 -6.58474938533146481e-02 0.00000000000000000e+00,
              -4.81551369189896938e-01 -9.71084252786256208e-02 0.00000000000000000e+00,
              -4.93191077699321245e-01 -1.26373978102321038e-01 0.00000000000000000e+00,
              -5.08156417211438116e-01 -1.53644152324400818e-01 0.00000000000000000e+00,
              -5.26114824625978583e-01 -1.78586384844595741e-01 0.00000000000000000e+00,
              -5.47066299942942313e-01 -2.00535549462367291e-01 0.00000000000000000e+00,
              -5.69680590761252326e-01 -2.19491646177715438e-01 0.00000000000000000e+00,
              -5.94955386381716522e-01 -2.34789548790101665e-01 0.00000000000000000e+00,
              -6.22890686804334903e-01 -2.46096694199256699e-01 0.00000000000000000e+00,
              -6.53153928928838057e-01 -2.53080519304911267e-01 0.00000000000000000e+00,
              -6.84414860354149002e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -7.30973694391846229e-01 -2.51750266903834230e-01 0.00000000000000000e+00,
              -7.78197654630081948e-01 -2.40110558394409923e-01 0.00000000000000000e+00,
              -8.20433168364278709e-01 -2.22817277180408113e-01 0.00000000000000000e+00,
              -8.51694099789589765e-01 -2.00535549462367291e-01 0.00000000000000000e+00,
              -8.55684856992820930e-01 -1.96877355359405370e-01 0.00000000000000000e+00,
              -8.58677924895244304e-01 -1.92886598156174177e-01 0.00000000000000000e+00,
              -8.61338429697398489e-01 -1.88230714752404465e-01 0.00000000000000000e+00,
              -8.63333808299014072e-01 -1.83242268248365481e-01 0.00000000000000000e+00,
              -8.64664060700091053e-01 -1.77588695543787950e-01 0.00000000000000000e+00,
              -8.63998934499552562e-01 -1.71602559738941174e-01 0.00000000000000000e+00,
              -8.61338429697398489e-01 -1.65948987034363643e-01 0.00000000000000000e+00,
              -8.56682546293628722e-01 -1.59962851229516867e-01 0.00000000000000000e+00,
              -8.51694099789589765e-01 -1.55639530926016401e-01 0.00000000000000000e+00,
              -8.45707963984742905e-01 -1.52979026123862272e-01 0.00000000000000000e+00,
              -8.40054391280165458e-01 -1.51981336823054480e-01 0.00000000000000000e+00,
              -8.34733381675857200e-01 -1.51981336823054480e-01 0.00000000000000000e+00,
              -8.29744935171818243e-01 -1.53644152324400818e-01 0.00000000000000000e+00,
              -8.24423925567509985e-01 -1.55972094026285674e-01 0.00000000000000000e+00,
              -8.19768042163740218e-01 -1.58632598828439803e-01 0.00000000000000000e+00,
              -8.15777284960509053e-01 -1.61625666730863204e-01 0.00000000000000000e+00,
              -8.12451653957816378e-01 -1.64618734633286579e-01 0.00000000000000000e+00,
              -8.09126022955123703e-01 -1.67279239435440708e-01 0.00000000000000000e+00,
              -8.06798081253238819e-01 -1.69607181137325591e-01 0.00000000000000000e+00,
              -8.05467828852161838e-01 -1.70604870438133382e-01 0.00000000000000000e+00,
              -7.82853538033851715e-01 -1.82577142047826935e-01 0.00000000000000000e+00,
              -7.50262354207463678e-01 -1.93551724356712695e-01 0.00000000000000000e+00,
              -7.15010665578921456e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -6.84414860354149002e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -6.59140064733684805e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -6.34197832213489909e-01 -1.94216850557251242e-01 0.00000000000000000e+00,
              -6.10253288994102805e-01 -1.82244578947557689e-01 0.00000000000000000e+00,
              -5.88636687476600473e-01 -1.66281550134632916e-01 0.00000000000000000e+00,
              -5.69015464560713724e-01 -1.46660327218746223e-01 0.00000000000000000e+00,
              -5.52054746446981159e-01 -1.23380910199897609e-01 0.00000000000000000e+00,
              -5.38087096235672080e-01 -9.71084252786256208e-02 0.00000000000000000e+00,
              -5.27112513926786264e-01 -6.71777462543917125e-02 0.00000000000000000e+00,
              -5.20461251921401025e-01 -3.45865624280036477e-02 0.00000000000000000e+00,
              -5.18133310219516141e-01 -6.65126200538546097e-04 0.00000000000000000e+00,
              -5.18798436420054632e-01 1.79584074145403561e-02 0.00000000000000000e+00,
              -5.20461251921401025e-01 3.59168148290807121e-02 0.00000000000000000e+00,
              -5.54715251249135344e-01 1.69607181137325091e-02 0.00000000000000000e+00,
              -5.94955386381716522e-01 2.66050480215407337e-03 0.00000000000000000e+00,
              -6.38521152516990376e-01 -6.31869890511607690e-03 0.00000000000000000e+00,
              -6.82086918652264229e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -7.25985247887807272e-01 -4.65588340376971166e-03 0.00000000000000000e+00,
              -7.69218450922811825e-01 8.64664060700093273e-03 0.00000000000000000e+00,
              -8.07130644353508120e-01 2.89329897234261169e-02 0.00000000000000000e+00,
              -8.34400818575587899e-01 5.45403484441596142e-02 0.00000000000000000e+00,
              -8.44377711583665924e-01 6.98382510565458414e-02 0.00000000000000000e+00,
              -8.52026662889858954e-01 8.74640953708168967e-02 0.00000000000000000e+00,
              -8.57015109393898022e-01 1.05422502785357253e-01 0.00000000000000000e+00,
              -8.58677924895244304e-01 1.23048347099628363e-01 0.00000000000000000e+00,
              -8.57015109393898022e-01 1.40341628313630173e-01 0.00000000000000000e+00,
              -8.52026662889858954e-01 1.58632598828439775e-01 0.00000000000000000e+00,
              -8.44377711583665924e-01 1.76258443142710886e-01 0.00000000000000000e+00,
              -7.92165304841391138e-01 8.54687167692013139e-02 0.00000000000000000e+00,
              -7.74539460527120083e-01 7.01708141568150867e-02 0.00000000000000000e+00,
              -7.45606470803694021e-01 5.62031639455058962e-02 0.00000000000000000e+00,
              -7.12682723877036572e-01 4.58937078371586815e-02 0.00000000000000000e+00,
              -6.81421792451725628e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -6.39186278717528866e-01 4.58937078371586815e-02 0.00000000000000000e+00,
              -5.96285638782793503e-01 5.68682901460444423e-02 0.00000000000000000e+00,
              -5.59371134652905111e-01 7.21661927584306695e-02 0.00000000000000000e+00,
              -5.33763775932171614e-01 8.97920370727017247e-02 0.00000000000000000e+00,
              -5.49726804745096387e-01 1.22050657798820572e-01 0.00000000000000000e+00,
              -5.70345716961790927e-01 1.50318521321708143e-01 0.00000000000000000e+00,
              -5.94955386381716522e-01 1.72932812140018211e-01 0.00000000000000000e+00,
              -6.22225560603796302e-01 1.89893530253750775e-01 0.00000000000000000e+00,
              -6.51491113427491775e-01 2.00202986362098045e-01 0.00000000000000000e+00,
              -6.81421792451725628e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -7.12682723877036572e-01 2.00202986362098045e-01 0.00000000000000000e+00,
              -7.45606470803694021e-01 1.90226093354020076e-01 0.00000000000000000e+00,
              -7.74539460527120083e-01 1.75925880042441585e-01 0.00000000000000000e+00,
              -7.92165304841391138e-01 1.60627977430055358e-01 0.00000000000000000e+00,
              -7.96821188245160905e-01 1.52979026123862272e-01 0.00000000000000000e+00,
              -8.01477071648930561e-01 1.42004443814976455e-01 0.00000000000000000e+00,
              -8.05135265751892537e-01 1.30697298405821449e-01 0.00000000000000000e+00,
              -8.06132955052700328e-01 1.23048347099628363e-01 0.00000000000000000e+00,
              -8.05135265751892537e-01 1.15066832693165977e-01 0.00000000000000000e+00,
              -8.01477071648930561e-01 1.04092250384280161e-01 0.00000000000000000e+00,
              -7.96821188245160905e-01 9.27851049751251544e-02 0.00000000000000000e+00,
              -5.25449698425439760e-02 -1.25376288801513219e-01 0.00000000000000000e+00,
              -5.52054746446981603e-02 -9.27851049751251544e-02 0.00000000000000000e+00,
              -6.21892997503527001e-02 -6.98382510565458414e-02 0.00000000000000000e+00,
              -7.38290082597770070e-02 -5.32100960430825221e-02 0.00000000000000000e+00,
              -8.84617846716246881e-02 -3.85773196312348410e-02 0.00000000000000000e+00,
              -1.07085318286703646e-01 -2.66050480215412610e-02 0.00000000000000000e+00,
              -1.29367046004744468e-01 -1.72932812140018100e-02 0.00000000000000000e+00,
              -1.54309278524939364e-01 -1.13071454091550616e-02 0.00000000000000000e+00,
              -1.80914326546480653e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -3.35556168171689206e-01 -8.97920370727020578e-03 0.00000000000000000e+00,
              -3.45865624280036532e-01 -7.31638820592386829e-03 0.00000000000000000e+00,
              -3.54179701786768053e-01 -1.66281550134633749e-03 0.00000000000000000e+00,
              -3.60165837591614912e-01 6.31869890511604915e-03 0.00000000000000000e+00,
              -3.61828653092961194e-01 1.59630288129247178e-02 0.00000000000000000e+00,
              -3.61828653092961194e-01 2.30466228486601199e-01 0.00000000000000000e+00,
              -3.60165837591614912e-01 2.39777995294140678e-01 0.00000000000000000e+00,
              -3.54179701786768053e-01 2.48092072800872310e-01 0.00000000000000000e+00,
              -3.45865624280036532e-01 2.53413082405180567e-01 0.00000000000000000e+00,
              -3.35556168171689206e-01 2.55408461006796150e-01 0.00000000000000000e+00,
              -2.62724849212719880e-02 2.55408461006796150e-01 0.00000000000000000e+00,
              -1.66281550134632639e-02 2.53413082405180567e-01 0.00000000000000000e+00,
              -7.98151440646244215e-03 2.47759509700603009e-01 0.00000000000000000e+00,
              -1.99537860161558278e-03 2.39112869093602132e-01 0.00000000000000000e+00,
              0.00000000000000000e+00 2.29468539185793352e-01 0.00000000000000000e+00,
              -1.99537860161558278e-03 2.19491646177715383e-01 0.00000000000000000e+00,
              -7.98151440646244215e-03 2.11177568670983806e-01 0.00000000000000000e+00,
              -1.66281550134632639e-02 2.05523995966406303e-01 0.00000000000000000e+00,
              -2.62724849212719880e-02 2.03861180465059910e-01 0.00000000000000000e+00,
              -3.09283683250417218e-01 2.03861180465059910e-01 0.00000000000000000e+00,
              -3.09283683250417218e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -1.80914326546480653e-01 4.25680768344660065e-02 0.00000000000000000e+00,
              -1.44664948617130640e-01 3.95750089320426324e-02 0.00000000000000000e+00,
              -1.10410949289396210e-01 3.09283683250417551e-02 0.00000000000000000e+00,
              -7.91500178640852647e-02 1.72932812140018100e-02 0.00000000000000000e+00,
              -5.25449698425439760e-02 0.00000000000000000e+00 0.00000000000000000e+00,
              -3.09283683250416441e-02 -2.19491646177715771e-02 0.00000000000000000e+00,
              -1.43002133115783803e-02 -4.75565233385050190e-02 0.00000000000000000e+00,
              -3.65819410296186476e-03 -7.61569499616618906e-02 0.00000000000000000e+00,
              0.00000000000000000e+00 -1.06420192086165072e-01 0.00000000000000000e+00,
              -3.65819410296186476e-03 -1.36683434210668253e-01 0.00000000000000000e+00,
              -1.43002133115783803e-02 -1.65283860833825125e-01 0.00000000000000000e+00,
              -3.09283683250416441e-02 -1.91223782654827867e-01 0.00000000000000000e+00,
              -5.25449698425439760e-02 -2.12840384172330144e-01 0.00000000000000000e+00,
              -7.91500178640852647e-02 -2.30466228486601199e-01 0.00000000000000000e+00,
              -1.10410949289396210e-01 -2.44101315597641116e-01 0.00000000000000000e+00,
              -1.44664948617130640e-01 -2.52747956204642021e-01 0.00000000000000000e+00,
              -1.80914326546480653e-01 -2.55408461006796150e-01 0.00000000000000000e+00,
              -2.27805723684447070e-01 -2.51750266903834230e-01 0.00000000000000000e+00,
              -2.75362247022952089e-01 -2.40110558394409923e-01 0.00000000000000000e+00,
              -3.17930323857418040e-01 -2.22817277180408113e-01 0.00000000000000000e+00,
              -3.49523818382998397e-01 -2.00535549462367291e-01 0.00000000000000000e+00,
              -3.53514575586229562e-01 -1.96877355359405370e-01 0.00000000000000000e+00,
              -3.56507643488652937e-01 -1.92886598156174177e-01 0.00000000000000000e+00,
              -3.59168148290807010e-01 -1.88230714752404465e-01 0.00000000000000000e+00,
              -3.61163526892422704e-01 -1.83242268248365481e-01 0.00000000000000000e+00,
              -3.62493779293499685e-01 -1.77588695543787950e-01 0.00000000000000000e+00,
              -3.61496089992692005e-01 -1.71602559738941174e-01 0.00000000000000000e+00,
              -3.59168148290807010e-01 -1.65948987034363643e-01 0.00000000000000000e+00,
              -3.54512264887037354e-01 -1.59962851229516867e-01 0.00000000000000000e+00,
              -3.49191255282729096e-01 -1.55639530926016401e-01 0.00000000000000000e+00,
              -3.43205119477882237e-01 -1.52979026123862272e-01 0.00000000000000000e+00,
              -3.37551546773304789e-01 -1.51981336823054480e-01 0.00000000000000000e+00,
              -3.32563100269265832e-01 -1.51981336823054480e-01 0.00000000000000000e+00,
              -3.27574653765226764e-01 -1.53644152324400818e-01 0.00000000000000000e+00,
              -3.22253644160918618e-01 -1.55972094026285674e-01 0.00000000000000000e+00,
              -3.17597760757148850e-01 -1.58632598828439803e-01 0.00000000000000000e+00,
              -3.13607003553917685e-01 -1.61625666730863204e-01 0.00000000000000000e+00,
              -3.10281372551225010e-01 -1.64618734633286579e-01 0.00000000000000000e+00,
              -3.06955741548532335e-01 -1.67279239435440708e-01 0.00000000000000000e+00,
              -3.04295236746378261e-01 -1.69607181137325591e-01 0.00000000000000000e+00,
              -3.02964984345301169e-01 -1.70604870438133382e-01 0.00000000000000000e+00,
              -2.80350693526991157e-01 -1.82577142047826935e-01 0.00000000000000000e+00,
              -2.47426946600333819e-01 -1.93551724356712695e-01 0.00000000000000000e+00,
              -2.11842694871522297e-01 -2.01533238763175082e-01 0.00000000000000000e+00,
              -1.80914326546480653e-01 -2.03861180465059938e-01 0.00000000000000000e+00,
              -1.54309278524939364e-01 -2.01865801863444355e-01 0.00000000000000000e+00,
              -1.29367046004744468e-01 -1.95879666058597579e-01 0.00000000000000000e+00,
              -1.07085318286703646e-01 -1.86567899251058128e-01 0.00000000000000000e+00,
              -8.84617846716246881e-02 -1.74263064541095303e-01 0.00000000000000000e+00,
              -7.11685034576228226e-02 -1.55639530926016401e-01 0.00000000000000000e+00,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="5.19997228640831054e+02 4.99997228640831111e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            12 11 10 9 8 7 6 5 4 3 2 1 -1 
            0 1 2 -1 
            0 2 3 -1 
            0 3 4 -1 
            0 4 5 -1 
            0 5 6 -1 
            0 6 7 -1 
            0 7 8 -1 
            0 8 9 -1 
            0 9 10 -1 
            0 10 11 -1 
            0 11 12 -1 
            0 12 1 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0018"
              point="
              1.38567954301834106e-01 0.00000000000000000e+00 0.00000000000000000e+00,
              -1.38567954301834106e-01 1.38567954301834106e-01 0.00000000000000000e+00,
              -1.38567954301834106e-01 1.20003372430801392e-01 6.92839771509170532e-02,
              -1.38567954301834106e-01 6.92839771509170532e-02 1.20003372430801392e-01,
              -1.38567954301834106e-01 8.48484070202902116e-18 1.38567954301834106e-01,
              -1.38567954301834106e-01 -6.92839771509170532e-02 1.20003372430801392e-01,
              -1.38567954301834106e-01 -1.20003372430801392e-01 6.92839771509170532e-02,
              -1.38567954301834106e-01 -1.38567954301834106e-01 1.69696814040580423e-17,
              -1.38567954301834106e-01 -1.20003372430801392e-01 -6.92839771509170532e-02,
              -1.38567954301834106e-01 -6.92839771509170532e-02 -1.20003372430801392e-01,
              -1.38567954301834106e-01 -2.54545212789064509e-17 -1.38567954301834106e-01,
              -1.38567954301834106e-01 6.92839771509170532e-02 -1.20003372430801392e-01,
              -1.38567954301834106e-01 1.20003372430801392e-01 -6.92839771509170532e-02,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99997228640831111e+02 5.20997228640831054e+02 7.70972271149521902e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            12 11 10 9 8 7 6 5 4 3 2 1 -1 
            0 1 2 -1 
            0 2 3 -1 
            0 3 4 -1 
            0 4 5 -1 
            0 5 6 -1 
            0 6 7 -1 
            0 7 8 -1 
            0 8 9 -1 
            0 9 10 -1 
            0 10 11 -1 
            0 11 12 -1 
            0 12 1 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0019"
              point="
              0.00000000000000000e+00 1.38567954301834106e-01 -1.19993756148116758e-17,
              1.38567954301834106e-01 -1.38567954301834106e-01 2.39987512296233516e-17,
              1.20003372430801392e-01 -1.38567954301834106e-01 -6.92839771509170532e-02,
              6.92839771509170532e-02 -1.38567954301834106e-01 -1.20003372430801392e-01,
              2.04842163168406969e-17 -1.38567954301834106e-01 -1.38567954301834106e-01,
              -6.92839771509170532e-02 -1.38567954301834106e-01 -1.20003372430801392e-01,
              -1.20003372430801392e-01 -1.38567954301834106e-01 -6.92839771509170532e-02,
              -1.38567954301834106e-01 -1.38567954301834106e-01 -1.69696814040580423e-17,
              -1.20003372430801392e-01 -1.38567954301834106e-01 6.92839771509170532e-02,
              -6.92839771509170532e-02 -1.38567954301834106e-01 1.20003372430801392e-01,
              -3.74538977208987392e-17 -1.38567954301834106e-01 1.38567954301834106e-01,
              6.92839771509170532e-02 -1.38567954301834106e-01 1.20003372430801392e-01,
              1.20003372430801392e-01 -1.38567954301834106e-01 6.92839771509170532e-02,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="4.99997228640831111e+02 4.99997228640831111e+02 9.99972286408310964e+01"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="1.00000001490116119e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="1.00000000000000006e-01 1.00000000000000006e-01 1.00000000000000006e-01"
              specularColor="5.00000000000000097e-03 5.00000000000000097e-03 5.00000000000000097e-03"
              shininess="3.90625000000000000e-02"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            12 11 10 9 8 7 6 5 4 3 2 1 -1 
            0 1 2 -1 
            0 2 3 -1 
            0 3 4 -1 
            0 4 5 -1 
            0 5 6 -1 
            0 6 7 -1 
            0 7 8 -1 
            0 8 9 -1 
            0 9 10 -1 
            0 10 11 -1 
            0 11 12 -1 
            0 12 1 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0020"
              point="
              0.00000000000000000e+00 1.19993756148116758e-17 1.38567954301834106e-01,
              -1.19993756148116758e-17 -1.38567954301834106e-01 -1.38567954301834106e-01,
              6.92839771509170532e-02 -1.20003372430801392e-01 -1.38567954301834106e-01,
              1.20003372430801392e-01 -6.92839771509170532e-02 -1.38567954301834106e-01,
              1.38567954301834106e-01 -3.24835911044717602e-17 -1.38567954301834106e-01,
              1.20003372430801392e-01 6.92839771509170532e-02 -1.38567954301834106e-01,
              6.92839771509170532e-02 1.20003372430801392e-01 -1.38567954301834106e-01,
              2.89690561916891055e-17 1.38567954301834106e-01 -1.38567954301834106e-01,
              -6.92839771509170532e-02 1.20003372430801392e-01 -1.38567954301834106e-01,
              -1.20003372430801392e-01 6.92839771509170532e-02 -1.38567954301834106e-01,
              -1.38567954301834106e-01 2.54545212789064509e-17 -1.38567954301834106e-01,
              -1.20003372430801392e-01 -6.92839771509170532e-02 -1.38567954301834106e-01,
              -6.92839771509170532e-02 -1.20003372430801392e-01 -1.38567954301834106e-01,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="3.00000011920928955e-01"
              emissiveColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="4.00000000000000022e-01 0.00000000000000000e+00 0.00000000000000000e+00"
              specularColor="2.99999999999999989e-01 0.00000000000000000e+00 0.00000000000000000e+00"
              shininess="3.90625000000000000e-02"
              transparency="1.00000001490116119e-01"
            />
          </Appearance>
          <IndexedFaceSet
            solid="false"
            colorPerVertex="false"
            normalPerVertex="false"
            coordIndex="
            5 1 16 19 -1 
            2 6 20 17 -1 
            1 2 17 16 -1 
            2 1 5 6 -1 
            16 17 20 19 -1 
            8 4 18 22 -1 
            4 5 19 18 -1 
            5 4 8 9 -1 
            18 19 23 22 -1 
            6 5 9 10 -1 
            7 11 25 21 -1 
            6 7 21 20 -1 
            11 10 24 25 -1 
            7 6 10 11 -1 
            20 21 25 24 -1 
            12 8 22 26 -1 
            13 12 26 27 -1 
            9 8 12 13 -1 
            22 23 27 26 -1 
            10 14 28 24 -1 
            14 13 27 28 -1 
            10 9 13 14 -1 
            23 24 28 27 -1 
            23 19 29 31 -1 
            20 24 32 30 -1 
            19 20 30 29 -1 
            24 23 31 32 -1 
            29 30 32 31 -1 
            43 36 117 127 -1 
            36 37 118 117 -1 
            37 36 43 44 -1 
            37 38 119 118 -1 
            38 37 44 45 -1 
            38 39 120 119 -1 
            39 38 45 46 -1 
            40 47 131 121 -1 
            39 40 121 120 -1 
            40 39 46 47 -1 
            49 41 125 135 -1 
            41 42 126 125 -1 
            42 41 49 50 -1 
            42 43 127 126 -1 
            43 42 50 51 -1 
            44 43 51 52 -1 
            45 44 52 53 -1 
            46 45 53 54 -1 
            47 46 54 55 -1 
            48 56 142 132 -1 
            47 48 132 131 -1 
            48 47 55 56 -1 
            58 49 135 145 -1 
            50 49 58 59 -1 
            51 50 59 60 -1 
            52 51 60 61 -1 
            53 52 61 62 -1 
            54 53 62 63 -1 
            55 54 63 64 -1 
            56 65 152 142 -1 
            56 55 64 65 -1 
            67 58 145 155 -1 
            59 58 67 68 -1 
            60 59 68 69 -1 
            61 60 69 70 -1 
            62 61 70 71 -1 
            63 62 71 72 -1 
            64 63 72 73 -1 
            65 74 162 152 -1 
            65 64 73 74 -1 
            76 67 155 165 -1 
            68 67 76 77 -1 
            69 68 77 78 -1 
            70 69 78 79 -1 
            71 70 79 80 -1 
            72 71 80 81 -1 
            73 72 81 82 -1 
            74 83 172 162 -1 
            74 73 82 83 -1 
            85 76 165 175 -1 
            86 85 175 176 -1 
            77 76 85 86 -1 
            78 77 86 87 -1 
            79 78 87 88 -1 
            80 79 88 89 -1 
            81 80 89 90 -1 
            82 81 90 91 -1 
            83 92 182 172 -1 
            83 82 91 92 -1 
            93 86 176 184 -1 
            94 93 184 185 -1 
            87 86 93 94 -1 
            88 87 94 95 -1 
            89 88 95 96 -1 
            97 96 187 188 -1 
            90 89 96 97 -1 
            98 97 188 189 -1 
            91 90 97 98 -1 
            92 99 190 182 -1 
            99 98 189 190 -1 
            92 91 98 99 -1 
            100 94 185 192 -1 
            101 100 192 193 -1 
            95 94 100 101 -1 
            96 102 194 187 -1 
            102 101 193 194 -1 
            96 95 101 102 -1 
            109 103 202 207 -1 
            103 104 203 202 -1 
            104 103 109 110 -1 
            202 203 208 207 -1 
            104 105 204 203 -1 
            105 104 110 111 -1 
            203 204 209 208 -1 
            106 112 210 205 -1 
            105 106 205 204 -1 
            106 105 111 112 -1 
            204 205 210 209 -1 
            116 108 206 214 -1 
            108 109 207 206 -1 
            109 108 116 117 -1 
            206 207 215 214 -1 
            110 109 117 118 -1 
            207 208 216 215 -1 
            111 110 118 119 -1 
            208 209 217 216 -1 
            112 111 119 120 -1 
            209 210 218 217 -1 
            113 121 219 211 -1 
            112 113 211 210 -1 
            113 112 120 121 -1 
            210 211 219 218 -1 
            125 115 213 222 -1 
            115 116 214 213 -1 
            116 115 125 126 -1 
            213 214 223 222 -1 
            117 116 126 127 -1 
            218 219 228 227 -1 
            122 132 229 220 -1 
            121 122 220 219 -1 
            122 121 131 132 -1 
            219 220 229 228 -1 
            134 124 221 231 -1 
            124 125 222 221 -1 
            125 124 134 135 -1 
            221 222 232 231 -1 
            222 223 233 232 -1 
            228 229 239 238 -1 
            133 143 240 230 -1 
            132 133 230 229 -1 
            133 132 142 143 -1 
            229 230 240 239 -1 
            144 134 231 241 -1 
            135 134 144 145 -1 
            231 232 242 241 -1 
            232 233 243 242 -1 
            237 238 248 247 -1 
            238 239 249 248 -1 
            143 153 250 240 -1 
            143 142 152 153 -1 
            239 240 250 249 -1 
            154 144 241 251 -1 
            145 144 154 155 -1 
            241 242 252 251 -1 
            247 248 258 257 -1 
            248 249 259 258 -1 
            153 163 260 250 -1 
            153 152 162 163 -1 
            249 250 260 259 -1 
            164 154 251 261 -1 
            165 164 261 262 -1 
            155 154 164 165 -1 
            251 252 262 261 -1 
            252 253 263 262 -1 
            258 259 269 268 -1 
            163 173 270 260 -1 
            173 172 269 270 -1 
            163 162 172 173 -1 
            259 260 270 269 -1 
            175 165 262 271 -1 
            176 175 271 272 -1 
            262 263 272 271 -1 
            263 264 273 272 -1 
            264 265 274 273 -1 
            265 266 275 274 -1 
            267 268 277 276 -1 
            172 182 278 269 -1 
            268 269 278 277 -1 
            184 176 272 279 -1 
            185 184 279 280 -1 
            272 273 280 279 -1 
            273 274 281 280 -1 
            274 275 282 281 -1 
            275 276 283 282 -1 
            276 277 284 283 -1 
            182 190 285 278 -1 
            190 189 284 285 -1 
            277 278 285 284 -1 
            192 185 280 286 -1 
            193 192 286 287 -1 
            280 281 287 286 -1 
            281 282 288 287 -1 
            188 187 194 195 -1 
            282 283 289 288 -1 
            189 196 290 284 -1 
            196 195 289 290 -1 
            189 188 195 196 -1 
            283 284 290 289 -1 
            198 193 287 291 -1 
            199 198 291 292 -1 
            194 193 198 199 -1 
            287 288 292 291 -1 
            195 200 293 289 -1 
            200 199 292 293 -1 
            195 194 199 200 -1 
            288 289 293 292 -1 
            223 214 295 300 -1 
            214 215 296 295 -1 
            295 296 301 300 -1 
            215 216 297 296 -1 
            296 297 302 301 -1 
            216 217 298 297 -1 
            297 298 303 302 -1 
            218 227 304 299 -1 
            217 218 299 298 -1 
            298 299 304 303 -1 
            233 223 300 306 -1 
            300 301 307 306 -1 
            301 302 308 307 -1 
            302 303 309 308 -1 
            303 304 310 309 -1 
            228 238 311 305 -1 
            227 228 305 304 -1 
            238 237 310 311 -1 
            304 305 311 310 -1 
            243 233 306 313 -1 
            306 307 314 313 -1 
            307 308 315 314 -1 
            308 309 316 315 -1 
            237 247 317 310 -1 
            309 310 317 316 -1 
            252 242 312 318 -1 
            242 243 313 312 -1 
            253 252 318 319 -1 
            312 313 319 318 -1 
            313 314 320 319 -1 
            314 315 321 320 -1 
            315 316 322 321 -1 
            247 257 323 317 -1 
            316 317 323 322 -1 
            263 253 319 325 -1 
            264 263 325 326 -1 
            319 320 326 325 -1 
            265 264 326 327 -1 
            320 321 327 326 -1 
            266 265 327 328 -1 
            321 322 328 327 -1 
            322 323 329 328 -1 
            258 268 330 324 -1 
            257 258 324 323 -1 
            268 267 329 330 -1 
            323 324 330 329 -1 
            275 266 328 331 -1 
            267 276 332 329 -1 
            276 275 331 332 -1 
            328 329 332 331 -1 
            337 333 358 367 -1 
            334 338 368 359 -1 
            333 334 359 358 -1 
            334 333 337 338 -1 
            341 335 365 374 -1 
            335 336 366 365 -1 
            336 335 341 342 -1 
            336 337 367 366 -1 
            337 336 342 343 -1 
            338 337 343 344 -1 
            339 345 378 369 -1 
            338 339 369 368 -1 
            339 338 344 345 -1 
            346 341 374 383 -1 
            347 346 383 384 -1 
            342 341 346 347 -1 
            343 342 347 348 -1 
            344 343 348 349 -1 
            345 350 387 378 -1 
            350 349 386 387 -1 
            345 344 349 350 -1 
            351 347 384 393 -1 
            352 351 393 394 -1 
            348 347 351 352 -1 
            349 353 395 386 -1 
            353 352 394 395 -1 
            349 348 352 353 -1 
            363 354 418 427 -1 
            354 355 419 418 -1 
            355 354 363 364 -1 
            418 419 428 427 -1 
            355 356 420 419 -1 
            356 355 364 365 -1 
            419 420 429 428 -1 
            356 357 421 420 -1 
            357 356 365 366 -1 
            420 421 430 429 -1 
            357 358 422 421 -1 
            358 357 366 367 -1 
            421 422 431 430 -1 
            358 359 423 422 -1 
            422 423 432 431 -1 
            359 360 424 423 -1 
            360 359 368 369 -1 
            423 424 433 432 -1 
            360 361 425 424 -1 
            361 360 369 370 -1 
            424 425 434 433 -1 
            362 371 435 426 -1 
            361 362 426 425 -1 
            362 361 370 371 -1 
            425 426 435 434 -1 
            372 363 427 436 -1 
            364 363 372 373 -1 
            427 428 437 436 -1 
            365 364 373 374 -1 
            428 429 438 437 -1 
            429 430 439 438 -1 
            431 432 441 440 -1 
            432 433 442 441 -1 
            370 369 378 379 -1 
            433 434 443 442 -1 
            371 380 444 435 -1 
            371 370 379 380 -1 
            434 435 444 443 -1 
            381 372 436 445 -1 
            382 381 445 446 -1 
            373 372 381 382 -1 
            436 437 446 445 -1 
            374 373 382 383 -1 
            437 438 447 446 -1 
            438 439 448 447 -1 
            439 440 449 448 -1 
            440 441 450 449 -1 
            441 442 451 450 -1 
            379 378 387 388 -1 
            442 443 452 451 -1 
            380 389 453 444 -1 
            380 379 388 389 -1 
            443 444 453 452 -1 
            391 382 446 454 -1 
            383 382 391 392 -1 
            446 447 455 454 -1 
            384 383 392 393 -1 
            447 448 456 455 -1 
            448 449 457 456 -1 
            449 450 458 457 -1 
            387 386 395 396 -1 
            450 451 459 458 -1 
            388 387 396 397 -1 
            451 452 460 459 -1 
            389 398 461 453 -1 
            389 388 397 398 -1 
            452 453 461 460 -1 
            399 391 454 462 -1 
            400 399 462 463 -1 
            392 391 399 400 -1 
            454 455 463 462 -1 
            393 392 400 401 -1 
            455 456 464 463 -1 
            394 393 401 402 -1 
            456 457 465 464 -1 
            395 394 402 403 -1 
            457 458 466 465 -1 
            396 395 403 404 -1 
            458 459 467 466 -1 
            397 396 404 405 -1 
            459 460 468 467 -1 
            398 406 469 461 -1 
            406 405 468 469 -1 
            398 397 405 406 -1 
            460 461 469 468 -1 
            407 400 463 470 -1 
            408 407 470 471 -1 
            401 400 407 408 -1 
            463 464 471 470 -1 
            402 401 408 409 -1 
            464 465 472 471 -1 
            403 402 409 410 -1 
            465 466 473 472 -1 
            411 410 473 474 -1 
            404 403 410 411 -1 
            466 467 474 473 -1 
            405 412 475 468 -1 
            412 411 474 475 -1 
            405 404 411 412 -1 
            467 468 475 474 -1 
            414 408 471 476 -1 
            415 414 476 477 -1 
            409 408 414 415 -1 
            471 472 477 476 -1 
            410 416 478 473 -1 
            416 415 477 478 -1 
            410 409 415 416 -1 
            472 473 478 477 -1 
            439 430 479 481 -1 
            431 440 482 480 -1 
            430 431 480 479 -1 
            440 439 481 482 -1 
            479 480 482 481 -1 
            489 486 494 497 -1 
            487 490 498 495 -1 
            486 487 495 494 -1 
            487 486 489 490 -1 
            494 495 498 497 -1 
            492 489 497 499 -1 
            490 493 500 498 -1 
            493 492 499 500 -1 
            490 489 492 493 -1 
            497 498 500 499 -1 
            512 507 570 574 -1 
            508 513 575 571 -1 
            507 508 571 570 -1 
            508 507 512 513 -1 
            570 571 575 574 -1 
            517 510 572 577 -1 
            510 511 573 572 -1 
            511 510 517 518 -1 
            572 573 578 577 -1 
            511 512 574 573 -1 
            512 511 518 519 -1 
            573 574 579 578 -1 
            513 512 519 520 -1 
            574 575 580 579 -1 
            514 521 581 576 -1 
            513 514 576 575 -1 
            514 513 520 521 -1 
            575 576 581 580 -1 
            523 517 577 582 -1 
            524 523 582 583 -1 
            518 517 523 524 -1 
            577 578 583 582 -1 
            519 518 524 525 -1 
            578 579 584 583 -1 
            520 519 525 526 -1 
            579 580 585 584 -1 
            521 527 586 581 -1 
            521 520 526 527 -1 
            580 581 586 585 -1 
            529 524 583 587 -1 
            530 529 587 588 -1 
            525 524 529 530 -1 
            583 584 588 587 -1 
            531 530 588 589 -1 
            526 525 530 531 -1 
            584 585 589 588 -1 
            527 532 590 586 -1 
            532 531 589 590 -1 
            527 526 531 532 -1 
            585 586 590 589 -1 
            546 542 592 595 -1 
            542 543 593 592 -1 
            543 542 546 547 -1 
            592 593 596 595 -1 
            544 548 597 594 -1 
            543 544 594 593 -1 
            544 543 547 548 -1 
            593 594 597 596 -1 
            551 546 595 599 -1 
            547 546 551 552 -1 
            595 596 600 599 -1 
            548 553 601 597 -1 
            548 547 552 553 -1 
            596 597 601 600 -1 
            556 551 599 604 -1 
            552 551 556 557 -1 
            599 600 605 604 -1 
            553 552 557 558 -1 
            600 601 606 605 -1 
            554 559 607 602 -1 
            553 554 602 601 -1 
            554 553 558 559 -1 
            601 602 607 606 -1 
            560 556 604 609 -1 
            561 560 609 610 -1 
            557 556 560 561 -1 
            604 605 610 609 -1 
            562 561 610 611 -1 
            558 557 561 562 -1 
            605 606 611 610 -1 
            559 563 612 607 -1 
            563 562 611 612 -1 
            559 558 562 563 -1 
            606 607 612 611 -1 
            619 617 650 659 -1 
            618 620 660 651 -1 
            617 618 651 650 -1 
            620 619 659 660 -1 
            618 617 619 620 -1 
            631 624 697 704 -1 
            624 625 698 697 -1 
            625 624 631 632 -1 
            697 698 705 704 -1 
            625 626 699 698 -1 
            626 625 632 633 -1 
            698 699 706 705 -1 
            626 627 700 699 -1 
            627 626 633 634 -1 
            699 700 707 706 -1 
            627 628 701 700 -1 
            628 627 634 635 -1 
            700 701 708 707 -1 
            629 636 709 702 -1 
            628 629 702 701 -1 
            629 628 635 636 -1 
            701 702 709 708 -1 
            638 630 703 712 -1 
            630 631 704 703 -1 
            631 630 638 639 -1 
            703 704 713 712 -1 
            632 631 639 640 -1 
            633 632 640 641 -1 
            634 633 641 642 -1 
            635 634 642 643 -1 
            636 635 643 644 -1 
            637 645 719 710 -1 
            636 637 710 709 -1 
            637 636 644 645 -1 
            709 710 719 718 -1 
            647 638 712 721 -1 
            639 638 647 648 -1 
            712 713 722 721 -1 
            640 639 648 649 -1 
            641 640 649 650 -1 
            642 641 650 651 -1 
            643 642 651 652 -1 
            644 643 652 653 -1 
            645 654 728 719 -1 
            645 644 653 654 -1 
            718 719 728 727 -1 
            655 646 720 729 -1 
            646 647 721 720 -1 
            647 646 655 656 -1 
            720 721 730 729 -1 
            648 647 656 657 -1 
            649 648 657 658 -1 
            650 649 658 659 -1 
            652 651 660 661 -1 
            653 652 661 662 -1 
            654 663 737 728 -1 
            654 653 662 663 -1 
            727 728 737 736 -1 
            664 655 729 738 -1 
            656 655 664 665 -1 
            729 730 739 738 -1 
            657 656 665 666 -1 
            658 657 666 667 -1 
            659 658 667 668 -1 
            660 659 668 669 -1 
            661 660 669 670 -1 
            662 661 670 671 -1 
            663 672 746 737 -1 
            663 662 671 672 -1 
            736 737 746 745 -1 
            673 664 738 747 -1 
            674 673 747 748 -1 
            665 664 673 674 -1 
            738 739 748 747 -1 
            666 665 674 675 -1 
            667 666 675 676 -1 
            668 667 676 677 -1 
            669 668 677 678 -1 
            670 669 678 679 -1 
            671 670 679 680 -1 
            672 681 755 746 -1 
            681 680 754 755 -1 
            672 671 680 681 -1 
            745 746 755 754 -1 
            682 674 748 756 -1 
            683 682 756 757 -1 
            675 674 682 683 -1 
            748 749 757 756 -1 
            676 675 683 684 -1 
            677 676 684 685 -1 
            678 677 685 686 -1 
            679 678 686 687 -1 
            680 688 762 754 -1 
            688 687 761 762 -1 
            680 679 687 688 -1 
            689 683 757 763 -1 
            690 689 763 764 -1 
            684 683 689 690 -1 
            757 758 764 763 -1 
            691 690 764 765 -1 
            685 684 690 691 -1 
            758 759 765 764 -1 
            692 691 765 766 -1 
            686 685 691 692 -1 
            687 693 767 761 -1 
            693 692 766 767 -1 
            687 686 692 693 -1 
            760 761 767 766 -1 
            713 704 772 778 -1 
            704 705 773 772 -1 
            772 773 779 778 -1 
            705 706 774 773 -1 
            773 774 780 779 -1 
            706 707 775 774 -1 
            774 775 781 780 -1 
            707 708 776 775 -1 
            775 776 782 781 -1 
            709 718 783 777 -1 
            708 709 777 776 -1 
            776 777 783 782 -1 
            722 713 778 785 -1 
            778 779 786 785 -1 
            779 780 787 786 -1 
            780 781 788 787 -1 
            781 782 789 788 -1 
            718 727 790 783 -1 
            782 783 790 789 -1 
            730 721 784 791 -1 
            721 722 785 784 -1 
            784 785 792 791 -1 
            785 786 793 792 -1 
            786 787 794 793 -1 
            787 788 795 794 -1 
            788 789 796 795 -1 
            727 736 797 790 -1 
            789 790 797 796 -1 
            739 730 791 798 -1 
            791 792 799 798 -1 
            792 793 800 799 -1 
            793 794 801 800 -1 
            794 795 802 801 -1 
            795 796 803 802 -1 
            736 745 804 797 -1 
            796 797 804 803 -1 
            748 739 798 805 -1 
            749 748 805 806 -1 
            798 799 806 805 -1 
            799 800 807 806 -1 
            800 801 808 807 -1 
            801 802 809 808 -1 
            802 803 810 809 -1 
            745 754 811 804 -1 
            803 804 811 810 -1 
            757 749 806 812 -1 
            758 757 812 813 -1 
            806 807 813 812 -1 
            759 758 813 814 -1 
            807 808 814 813 -1 
            808 809 815 814 -1 
            761 760 815 816 -1 
            809 810 816 815 -1 
            754 762 817 811 -1 
            762 761 816 817 -1 
            810 811 817 816 -1 
            765 759 814 818 -1 
            760 766 819 815 -1 
            766 765 818 819 -1 
            814 815 819 818 -1 
            824 820 844 850 -1 
            821 825 851 845 -1 
            820 821 845 844 -1 
            821 820 824 825 -1 
            828 824 850 856 -1 
            829 828 856 857 -1 
            825 824 828 829 -1 
            825 826 852 851 -1 
            826 825 829 830 -1 
            827 831 859 853 -1 
            826 827 853 852 -1 
            827 826 830 831 -1 
            833 829 857 863 -1 
            834 833 863 864 -1 
            830 829 833 834 -1 
            831 835 865 859 -1 
            831 830 834 835 -1 
            836 834 864 869 -1 
            835 837 870 865 -1 
            837 836 869 870 -1 
            835 834 836 837 -1 
            864 865 870 869 -1 
            845 840 873 878 -1 
            840 841 874 873 -1 
            841 840 845 846 -1 
            873 874 879 878 -1 
            841 842 875 874 -1 
            842 841 846 847 -1 
            874 875 880 879 -1 
            843 848 881 876 -1 
            842 843 876 875 -1 
            843 842 847 848 -1 
            875 876 881 880 -1 
            850 844 877 882 -1 
            844 845 878 877 -1 
            877 878 883 882 -1 
            846 845 851 852 -1 
            878 879 884 883 -1 
            847 846 852 853 -1 
            879 880 885 884 -1 
            848 854 886 881 -1 
            848 847 853 854 -1 
            880 881 886 885 -1 
            856 850 882 888 -1 
            882 883 889 888 -1 
            883 884 890 889 -1 
            884 885 891 890 -1 
            854 853 859 860 -1 
            885 886 892 891 -1 
            855 861 893 887 -1 
            854 855 887 886 -1 
            861 860 892 893 -1 
            855 854 860 861 -1 
            886 887 893 892 -1 
            862 856 888 894 -1 
            863 862 894 895 -1 
            857 856 862 863 -1 
            888 889 895 894 -1 
            864 863 895 896 -1 
            889 890 896 895 -1 
            865 864 896 897 -1 
            890 891 897 896 -1 
            860 866 898 892 -1 
            866 865 897 898 -1 
            860 859 865 866 -1 
            891 892 898 897 -1 
            902 899 967 975 -1 
            900 903 976 968 -1 
            899 900 968 967 -1 
            900 899 902 903 -1 
            967 968 976 975 -1 
            907 901 974 984 -1 
            901 902 975 974 -1 
            902 901 907 908 -1 
            903 902 908 909 -1 
            903 904 977 976 -1 
            904 903 909 910 -1 
            904 905 978 977 -1 
            905 904 910 911 -1 
            906 912 989 979 -1 
            905 906 979 978 -1 
            906 905 911 912 -1 
            915 907 984 994 -1 
            908 907 915 916 -1 
            909 908 916 917 -1 
            910 909 917 918 -1 
            911 910 918 919 -1 
            912 911 919 920 -1 
            913 921 1000 990 -1 
            912 913 990 989 -1 
            913 912 920 921 -1 
            922 914 993 1004 -1 
            914 915 994 993 -1 
            915 914 922 923 -1 
            916 915 923 924 -1 
            917 916 924 925 -1 
            918 917 925 926 -1 
            919 918 926 927 -1 
            920 919 927 928 -1 
            921 929 1011 1000 -1 
            921 920 928 929 -1 
            931 922 1004 1016 -1 
            932 931 1016 1017 -1 
            923 922 931 932 -1 
            924 923 932 933 -1 
            925 924 933 934 -1 
            926 925 934 935 -1 
            927 926 935 936 -1 
            928 927 936 937 -1 
            929 928 937 938 -1 
            930 939 1024 1012 -1 
            929 930 1012 1011 -1 
            939 938 1023 1024 -1 
            930 929 938 939 -1 
            940 932 1017 1029 -1 
            933 932 940 941 -1 
            934 933 941 942 -1 
            935 934 942 943 -1 
            936 935 943 944 -1 
            937 936 944 945 -1 
            938 946 1035 1023 -1 
            938 937 945 946 -1 
            947 940 1029 1041 -1 
            948 947 1041 1042 -1 
            941 940 947 948 -1 
            942 941 948 949 -1 
            943 942 949 950 -1 
            944 943 950 951 -1 
            945 944 951 952 -1 
            946 953 1047 1035 -1 
            946 945 952 953 -1 
            954 948 1042 1053 -1 
            955 954 1053 1054 -1 
            949 948 954 955 -1 
            950 949 955 956 -1 
            951 950 956 957 -1 
            952 951 957 958 -1 
            953 959 1058 1047 -1 
            959 958 1057 1058 -1 
            953 952 958 959 -1 
            960 955 1054 1064 -1 
            961 960 1064 1065 -1 
            956 955 960 961 -1 
            962 961 1065 1066 -1 
            957 956 961 962 -1 
            958 963 1067 1057 -1 
            963 962 1066 1067 -1 
            958 957 962 963 -1 
            976 968 1088 1094 -1 
            968 969 1089 1088 -1 
            969 968 976 977 -1 
            1088 1089 1095 1094 -1 
            969 970 1090 1089 -1 
            970 969 977 978 -1 
            1089 1090 1096 1095 -1 
            971 979 1097 1091 -1 
            970 971 1091 1090 -1 
            971 970 978 979 -1 
            1090 1091 1097 1096 -1 
            984 974 1092 1101 -1 
            974 975 1093 1092 -1 
            1092 1093 1102 1101 -1 
            975 976 1094 1093 -1 
            1093 1094 1103 1102 -1 
            1094 1095 1104 1103 -1 
            1095 1096 1105 1104 -1 
            1096 1097 1106 1105 -1 
            979 980 1098 1097 -1 
            980 979 989 990 -1 
            1097 1098 1107 1106 -1 
            981 991 1108 1099 -1 
            980 981 1099 1098 -1 
            981 980 990 991 -1 
            1098 1099 1108 1107 -1 
            993 983 1100 1110 -1 
            983 984 1101 1100 -1 
            984 983 993 994 -1 
            1100 1101 1111 1110 -1 
            1101 1102 1112 1111 -1 
            1102 1103 1113 1112 -1 
            1103 1104 1114 1113 -1 
            1106 1107 1117 1116 -1 
            991 1001 1118 1108 -1 
            991 990 1000 1001 -1 
            1107 1108 1118 1117 -1 
            1004 993 1110 1121 -1 
            1110 1111 1122 1121 -1 
            1111 1112 1123 1122 -1 
            1112 1113 1124 1123 -1 
            1113 1114 1125 1124 -1 
            1001 1000 1011 1012 -1 
            1002 1013 1130 1119 -1 
            1001 1002 1119 1118 -1 
            1002 1001 1012 1013 -1 
            1118 1119 1130 1129 -1 
            1015 1003 1120 1132 -1 
            1003 1004 1121 1120 -1 
            1004 1003 1015 1016 -1 
            1120 1121 1133 1132 -1 
            1121 1122 1134 1133 -1 
            1122 1123 1135 1134 -1 
            1013 1012 1024 1025 -1 
            1014 1026 1143 1131 -1 
            1013 1014 1131 1130 -1 
            1014 1013 1025 1026 -1 
            1027 1015 1132 1145 -1 
            1028 1027 1145 1146 -1 
            1016 1015 1027 1028 -1 
            1132 1133 1146 1145 -1 
            1017 1016 1028 1029 -1 
            1133 1134 1147 1146 -1 
            1134 1135 1148 1147 -1 
            1024 1023 1035 1036 -1 
            1025 1024 1036 1037 -1 
            1026 1038 1156 1143 -1 
            1026 1025 1037 1038 -1 
            1040 1028 1146 1159 -1 
            1041 1040 1159 1160 -1 
            1029 1028 1040 1041 -1 
            1146 1147 1160 1159 -1 
            1147 1148 1161 1160 -1 
            1148 1149 1162 1161 -1 
            1036 1035 1047 1048 -1 
            1037 1036 1048 1049 -1 
            1038 1037 1049 1050 -1 
            1039 1051 1170 1157 -1 
            1038 1039 1157 1156 -1 
            1039 1038 1050 1051 -1 
            1052 1041 1160 1172 -1 
            1053 1052 1172 1173 -1 
            1042 1041 1052 1053 -1 
            1160 1161 1173 1172 -1 
            1161 1162 1174 1173 -1 
            1162 1163 1175 1174 -1 
            1048 1047 1058 1059 -1 
            1049 1048 1059 1060 -1 
            1050 1049 1060 1061 -1 
            1051 1062 1182 1170 -1 
            1051 1050 1061 1062 -1 
            1063 1053 1173 1184 -1 
            1064 1063 1184 1185 -1 
            1054 1053 1063 1064 -1 
            1173 1174 1185 1184 -1 
            1174 1175 1186 1185 -1 
            1175 1176 1187 1186 -1 
            1058 1057 1067 1068 -1 
            1059 1058 1068 1069 -1 
            1060 1059 1069 1070 -1 
            1061 1060 1070 1071 -1 
            1062 1072 1193 1182 -1 
            1072 1071 1192 1193 -1 
            1062 1061 1071 1072 -1 
            1074 1064 1185 1194 -1 
            1075 1074 1194 1195 -1 
            1065 1064 1074 1075 -1 
            1185 1186 1195 1194 -1 
            1066 1065 1075 1076 -1 
            1186 1187 1196 1195 -1 
            1067 1066 1076 1077 -1 
            1187 1188 1197 1196 -1 
            1068 1067 1077 1078 -1 
            1069 1068 1078 1079 -1 
            1070 1069 1079 1080 -1 
            1071 1081 1201 1192 -1 
            1081 1080 1200 1201 -1 
            1071 1070 1080 1081 -1 
            1082 1075 1195 1203 -1 
            1083 1082 1203 1204 -1 
            1076 1075 1082 1083 -1 
            1195 1196 1204 1203 -1 
            1084 1083 1204 1205 -1 
            1077 1076 1083 1084 -1 
            1196 1197 1205 1204 -1 
            1085 1084 1205 1206 -1 
            1078 1077 1084 1085 -1 
            1197 1198 1206 1205 -1 
            1086 1085 1206 1207 -1 
            1079 1078 1085 1086 -1 
            1198 1199 1207 1206 -1 
            1080 1087 1208 1200 -1 
            1087 1086 1207 1208 -1 
            1080 1079 1086 1087 -1 
            1199 1200 1208 1207 -1 
            1114 1104 1210 1213 -1 
            1104 1105 1211 1210 -1 
            1210 1211 1214 1213 -1 
            1106 1116 1215 1212 -1 
            1105 1106 1212 1211 -1 
            1211 1212 1215 1214 -1 
            1125 1114 1213 1220 -1 
            1213 1214 1221 1220 -1 
            1214 1215 1222 1221 -1 
            1116 1117 1216 1215 -1 
            1215 1216 1223 1222 -1 
            1118 1129 1224 1217 -1 
            1117 1118 1217 1216 -1 
            1216 1217 1224 1223 -1 
            1135 1123 1218 1227 -1 
            1123 1124 1219 1218 -1 
            1218 1219 1228 1227 -1 
            1124 1125 1220 1219 -1 
            1219 1220 1229 1228 -1 
            1220 1221 1230 1229 -1 
            1221 1222 1231 1230 -1 
            1222 1223 1232 1231 -1 
            1223 1224 1233 1232 -1 
            1129 1130 1225 1224 -1 
            1224 1225 1234 1233 -1 
            1131 1143 1235 1226 -1 
            1130 1131 1226 1225 -1 
            1225 1226 1235 1234 -1 
            1148 1135 1227 1237 -1 
            1149 1148 1237 1238 -1 
            1227 1228 1238 1237 -1 
            1228 1229 1239 1238 -1 
            1229 1230 1240 1239 -1 
            1230 1231 1241 1240 -1 
            1231 1232 1242 1241 -1 
            1232 1233 1243 1242 -1 
            1233 1234 1244 1243 -1 
            1234 1235 1245 1244 -1 
            1144 1157 1246 1236 -1 
            1143 1144 1236 1235 -1 
            1144 1143 1156 1157 -1 
            1235 1236 1246 1245 -1 
            1162 1149 1238 1247 -1 
            1163 1162 1247 1248 -1 
            1238 1239 1248 1247 -1 
            1239 1240 1249 1248 -1 
            1240 1241 1250 1249 -1 
            1241 1242 1251 1250 -1 
            1242 1243 1252 1251 -1 
            1243 1244 1253 1252 -1 
            1244 1245 1254 1253 -1 
            1157 1170 1255 1246 -1 
            1245 1246 1255 1254 -1 
            1175 1163 1248 1257 -1 
            1176 1175 1257 1258 -1 
            1248 1249 1258 1257 -1 
            1249 1250 1259 1258 -1 
            1250 1251 1260 1259 -1 
            1251 1252 1261 1260 -1 
            1252 1253 1262 1261 -1 
            1253 1254 1263 1262 -1 
            1254 1255 1264 1263 -1 
            1171 1183 1265 1256 -1 
            1170 1171 1256 1255 -1 
            1183 1182 1264 1265 -1 
            1171 1170 1182 1183 -1 
            1255 1256 1265 1264 -1 
            1187 1176 1258 1266 -1 
            1188 1187 1266 1267 -1 
            1258 1259 1267 1266 -1 
            1259 1260 1268 1267 -1 
            1260 1261 1269 1268 -1 
            1261 1262 1270 1269 -1 
            1262 1263 1271 1270 -1 
            1182 1193 1272 1264 -1 
            1193 1192 1271 1272 -1 
            1263 1264 1272 1271 -1 
            1197 1188 1267 1273 -1 
            1198 1197 1273 1274 -1 
            1267 1268 1274 1273 -1 
            1199 1198 1274 1275 -1 
            1268 1269 1275 1274 -1 
            1200 1199 1275 1276 -1 
            1269 1270 1276 1275 -1 
            1192 1201 1277 1271 -1 
            1201 1200 1276 1277 -1 
            1270 1271 1277 1276 -1 
            1281 1278 1351 1357 -1 
            1279 1282 1358 1352 -1 
            1278 1279 1352 1351 -1 
            1279 1278 1281 1282 -1 
            1351 1352 1358 1357 -1 
            1286 1280 1356 1363 -1 
            1280 1281 1357 1356 -1 
            1281 1280 1286 1287 -1 
            1356 1357 1364 1363 -1 
            1282 1281 1287 1288 -1 
            1357 1358 1365 1364 -1 
            1282 1283 1359 1358 -1 
            1283 1282 1288 1289 -1 
            1358 1359 1366 1365 -1 
            1284 1290 1367 1360 -1 
            1283 1284 1360 1359 -1 
            1284 1283 1289 1290 -1 
            1359 1360 1367 1366 -1 
            1292 1285 1362 1369 -1 
            1285 1286 1363 1362 -1 
            1286 1285 1292 1293 -1 
            1362 1363 1370 1369 -1 
            1287 1286 1293 1294 -1 
            1363 1364 1371 1370 -1 
            1288 1287 1294 1295 -1 
            1364 1365 1372 1371 -1 
            1289 1288 1295 1296 -1 
            1365 1366 1373 1372 -1 
            1290 1289 1296 1297 -1 
            1366 1367 1374 1373 -1 
            1291 1298 1375 1368 -1 
            1290 1291 1368 1367 -1 
            1291 1290 1297 1298 -1 
            1367 1368 1375 1374 -1 
            1299 1292 1369 1376 -1 
            1300 1299 1376 1377 -1 
            1293 1292 1299 1300 -1 
            1369 1370 1377 1376 -1 
            1294 1293 1300 1301 -1 
            1370 1371 1378 1377 -1 
            1295 1294 1301 1302 -1 
            1371 1372 1379 1378 -1 
            1296 1295 1302 1303 -1 
            1372 1373 1380 1379 -1 
            1297 1296 1303 1304 -1 
            1373 1374 1381 1380 -1 
            1298 1305 1382 1375 -1 
            1305 1304 1381 1382 -1 
            1298 1297 1304 1305 -1 
            1374 1375 1382 1381 -1 
            1306 1300 1377 1383 -1 
            1307 1306 1383 1384 -1 
            1301 1300 1306 1307 -1 
            1377 1378 1384 1383 -1 
            1308 1307 1384 1385 -1 
            1302 1301 1307 1308 -1 
            1378 1379 1385 1384 -1 
            1303 1302 1308 1309 -1 
            1379 1380 1386 1385 -1 
            1304 1310 1387 1381 -1 
            1304 1303 1309 1310 -1 
            1380 1381 1387 1386 -1 
            1311 1308 1385 1390 -1 
            1312 1311 1390 1391 -1 
            1309 1308 1311 1312 -1 
            1385 1386 1391 1390 -1 
            1310 1313 1392 1387 -1 
            1310 1309 1312 1313 -1 
            1386 1387 1392 1391 -1 
            1315 1312 1391 1394 -1 
            1313 1312 1315 1316 -1 
            1391 1392 1395 1394 -1 
            1314 1317 1396 1393 -1 
            1313 1314 1393 1392 -1 
            1314 1313 1316 1317 -1 
            1392 1393 1396 1395 -1 
            1318 1315 1394 1398 -1 
            1316 1315 1318 1319 -1 
            1394 1395 1399 1398 -1 
            1317 1320 1400 1396 -1 
            1317 1316 1319 1320 -1 
            1395 1396 1400 1399 -1 
            1322 1318 1398 1402 -1 
            1319 1318 1322 1323 -1 
            1398 1399 1403 1402 -1 
            1320 1319 1323 1324 -1 
            1399 1400 1404 1403 -1 
            1321 1325 1405 1401 -1 
            1320 1321 1401 1400 -1 
            1321 1320 1324 1325 -1 
            1400 1401 1405 1404 -1 
            1327 1322 1402 1407 -1 
            1323 1322 1327 1328 -1 
            1402 1403 1408 1407 -1 
            1324 1323 1328 1329 -1 
            1403 1404 1409 1408 -1 
            1325 1324 1329 1330 -1 
            1404 1405 1410 1409 -1 
            1326 1331 1411 1406 -1 
            1325 1326 1406 1405 -1 
            1326 1325 1330 1331 -1 
            1405 1406 1411 1410 -1 
            1332 1327 1407 1413 -1 
            1328 1327 1332 1333 -1 
            1407 1408 1414 1413 -1 
            1329 1328 1333 1334 -1 
            1408 1409 1415 1414 -1 
            1330 1329 1334 1335 -1 
            1409 1410 1416 1415 -1 
            1331 1336 1417 1411 -1 
            1331 1330 1335 1336 -1 
            1410 1411 1417 1416 -1 
            1338 1332 1413 1419 -1 
            1339 1338 1419 1420 -1 
            1333 1332 1338 1339 -1 
            1413 1414 1420 1419 -1 
            1334 1333 1339 1340 -1 
            1414 1415 1421 1420 -1 
            1335 1334 1340 1341 -1 
            1415 1416 1422 1421 -1 
            1336 1335 1341 1342 -1 
            1416 1417 1423 1422 -1 
            1337 1343 1424 1418 -1 
            1336 1337 1418 1417 -1 
            1343 1342 1423 1424 -1 
            1337 1336 1342 1343 -1 
            1417 1418 1424 1423 -1 
            1344 1339 1420 1426 -1 
            1345 1344 1426 1427 -1 
            1340 1339 1344 1345 -1 
            1420 1421 1427 1426 -1 
            1341 1340 1345 1346 -1 
            1421 1422 1428 1427 -1 
            1342 1347 1429 1423 -1 
            1347 1346 1428 1429 -1 
            1342 1341 1346 1347 -1 
            1422 1423 1429 1428 -1 
            1348 1345 1427 1432 -1 
            1346 1349 1433 1428 -1 
            1349 1348 1432 1433 -1 
            1346 1345 1348 1349 -1 
            1427 1428 1433 1432 -1 
            "
          >
            <Coordinate
              DEF="VTKcoordinates0021"
              point="
              5.08549987792968750e+02 5.08571441650390625e+02 7.90565185546875000e+01,
              5.09500000000000000e+02 5.08571441650390625e+02 7.90565185546875000e+01,
              5.10450012207031250e+02 5.08571441650390625e+02 7.90565185546875000e+01,
              5.11399993896484375e+02 5.08571441650390625e+02 7.90565185546875000e+01,
              5.08549987792968750e+02 5.09523803710937500e+02 7.90565185546875000e+01,
              5.09500000000000000e+02 5.09523803710937500e+02 7.90565185546875000e+01,
              5.10450012207031250e+02 5.09523803710937500e+02 7.90565185546875000e+01,
              5.11399993896484375e+02 5.09523803710937500e+02 7.90565185546875000e+01,
              5.08549987792968750e+02 5.10476196289062500e+02 7.90565185546875000e+01,
              5.09500000000000000e+02 5.10476196289062500e+02 7.90565185546875000e+01,
              5.10450012207031250e+02 5.10476196289062500e+02 7.90565185546875000e+01,
              5.11399993896484375e+02 5.10476196289062500e+02 7.90565185546875000e+01,
              5.08549987792968750e+02 5.11428558349609375e+02 7.90565185546875000e+01,
              5.09500000000000000e+02 5.11428558349609375e+02 7.90565185546875000e+01,
              5.10450012207031250e+02 5.11428558349609375e+02 7.90565185546875000e+01,
              5.11399993896484375e+02 5.11428558349609375e+02 7.90565185546875000e+01,
              5.09500000000000000e+02 5.08571441650390625e+02 7.95456542968750000e+01,
              5.10450012207031250e+02 5.08571441650390625e+02 7.95456542968750000e+01,
              5.08549987792968750e+02 5.09523803710937500e+02 7.95456542968750000e+01,
              5.09500000000000000e+02 5.09523803710937500e+02 7.95456542968750000e+01,
              5.10450012207031250e+02 5.09523803710937500e+02 7.95456542968750000e+01,
              5.11399993896484375e+02 5.09523803710937500e+02 7.95456542968750000e+01,
              5.08549987792968750e+02 5.10476196289062500e+02 7.95456542968750000e+01,
              5.09500000000000000e+02 5.10476196289062500e+02 7.95456542968750000e+01,
              5.10450012207031250e+02 5.10476196289062500e+02 7.95456542968750000e+01,
              5.11399993896484375e+02 5.10476196289062500e+02 7.95456542968750000e+01,
              5.08549987792968750e+02 5.11428558349609375e+02 7.95456542968750000e+01,
              5.09500000000000000e+02 5.11428558349609375e+02 7.95456542968750000e+01,
              5.10450012207031250e+02 5.11428558349609375e+02 7.95456542968750000e+01,
              5.09500000000000000e+02 5.09523803710937500e+02 8.00347824096679688e+01,
              5.10450012207031250e+02 5.09523803710937500e+02 8.00347824096679688e+01,
              5.09500000000000000e+02 5.10476196289062500e+02 8.00347824096679688e+01,
              5.10450012207031250e+02 5.10476196289062500e+02 8.00347824096679688e+01,
              5.03799987792968750e+02 5.07619049072265625e+02 8.05239105224609375e+01,
              5.03799987792968750e+02 5.08571441650390625e+02 8.05239105224609375e+01,
              5.04750000000000000e+02 5.02857147216796875e+02 8.10130462646484375e+01,
              5.02850006103515625e+02 5.03809509277343750e+02 8.10130462646484375e+01,
              5.03799987792968750e+02 5.03809509277343750e+02 8.10130462646484375e+01,
              5.04750000000000000e+02 5.03809509277343750e+02 8.10130462646484375e+01,
              5.05700012207031250e+02 5.03809509277343750e+02 8.10130462646484375e+01,
              5.06649993896484375e+02 5.03809509277343750e+02 8.10130462646484375e+01,
              5.00950012207031250e+02 5.04761901855468750e+02 8.10130462646484375e+01,
              5.01899993896484375e+02 5.04761901855468750e+02 8.10130462646484375e+01,
              5.02850006103515625e+02 5.04761901855468750e+02 8.10130462646484375e+01,
              5.03799987792968750e+02 5.04761901855468750e+02 8.10130462646484375e+01,
              5.04750000000000000e+02 5.04761901855468750e+02 8.10130462646484375e+01,
              5.05700012207031250e+02 5.04761901855468750e+02 8.10130462646484375e+01,
              5.06649993896484375e+02 5.04761901855468750e+02 8.10130462646484375e+01,
              5.07600006103515625e+02 5.04761901855468750e+02 8.10130462646484375e+01,
              5.00950012207031250e+02 5.05714294433593750e+02 8.10130462646484375e+01,
              5.01899993896484375e+02 5.05714294433593750e+02 8.10130462646484375e+01,
              5.02850006103515625e+02 5.05714294433593750e+02 8.10130462646484375e+01,
              5.03799987792968750e+02 5.05714294433593750e+02 8.10130462646484375e+01,
              5.04750000000000000e+02 5.05714294433593750e+02 8.10130462646484375e+01,
              5.05700012207031250e+02 5.05714294433593750e+02 8.10130462646484375e+01,
              5.06649993896484375e+02 5.05714294433593750e+02 8.10130462646484375e+01,
              5.07600006103515625e+02 5.05714294433593750e+02 8.10130462646484375e+01,
              5.00000000000000000e+02 5.06666656494140625e+02 8.10130462646484375e+01,
              5.00950012207031250e+02 5.06666656494140625e+02 8.10130462646484375e+01,
              5.01899993896484375e+02 5.06666656494140625e+02 8.10130462646484375e+01,
              5.02850006103515625e+02 5.06666656494140625e+02 8.10130462646484375e+01,
              5.03799987792968750e+02 5.06666656494140625e+02 8.10130462646484375e+01,
              5.04750000000000000e+02 5.06666656494140625e+02 8.10130462646484375e+01,
              5.05700012207031250e+02 5.06666656494140625e+02 8.10130462646484375e+01,
              5.06649993896484375e+02 5.06666656494140625e+02 8.10130462646484375e+01,
              5.07600006103515625e+02 5.06666656494140625e+02 8.10130462646484375e+01,
              5.08549987792968750e+02 5.06666656494140625e+02 8.10130462646484375e+01,
              5.00950012207031250e+02 5.07619049072265625e+02 8.10130462646484375e+01,
              5.01899993896484375e+02 5.07619049072265625e+02 8.10130462646484375e+01,
              5.02850006103515625e+02 5.07619049072265625e+02 8.10130462646484375e+01,
              5.03799987792968750e+02 5.07619049072265625e+02 8.10130462646484375e+01,
              5.04750000000000000e+02 5.07619049072265625e+02 8.10130462646484375e+01,
              5.05700012207031250e+02 5.07619049072265625e+02 8.10130462646484375e+01,
              5.06649993896484375e+02 5.07619049072265625e+02 8.10130462646484375e+01,
              5.07600006103515625e+02 5.07619049072265625e+02 8.10130462646484375e+01,
              5.00000000000000000e+02 5.08571441650390625e+02 8.10130462646484375e+01,
              5.00950012207031250e+02 5.08571441650390625e+02 8.10130462646484375e+01,
              5.01899993896484375e+02 5.08571441650390625e+02 8.10130462646484375e+01,
              5.02850006103515625e+02 5.08571441650390625e+02 8.10130462646484375e+01,
              5.03799987792968750e+02 5.08571441650390625e+02 8.10130462646484375e+01,
              5.04750000000000000e+02 5.08571441650390625e+02 8.10130462646484375e+01,
              5.05700012207031250e+02 5.08571441650390625e+02 8.10130462646484375e+01,
              5.06649993896484375e+02 5.08571441650390625e+02 8.10130462646484375e+01,
              5.07600006103515625e+02 5.08571441650390625e+02 8.10130462646484375e+01,
              5.08549987792968750e+02 5.08571441650390625e+02 8.10130462646484375e+01,
              5.00950012207031250e+02 5.09523803710937500e+02 8.10130462646484375e+01,
              5.01899993896484375e+02 5.09523803710937500e+02 8.10130462646484375e+01,
              5.02850006103515625e+02 5.09523803710937500e+02 8.10130462646484375e+01,
              5.03799987792968750e+02 5.09523803710937500e+02 8.10130462646484375e+01,
              5.04750000000000000e+02 5.09523803710937500e+02 8.10130462646484375e+01,
              5.05700012207031250e+02 5.09523803710937500e+02 8.10130462646484375e+01,
              5.06649993896484375e+02 5.09523803710937500e+02 8.10130462646484375e+01,
              5.07600006103515625e+02 5.09523803710937500e+02 8.10130462646484375e+01,
              5.01899993896484375e+02 5.10476196289062500e+02 8.10130462646484375e+01,
              5.02850006103515625e+02 5.10476196289062500e+02 8.10130462646484375e+01,
              5.03799987792968750e+02 5.10476196289062500e+02 8.10130462646484375e+01,
              5.04750000000000000e+02 5.10476196289062500e+02 8.10130462646484375e+01,
              5.05700012207031250e+02 5.10476196289062500e+02 8.10130462646484375e+01,
              5.06649993896484375e+02 5.10476196289062500e+02 8.10130462646484375e+01,
              5.07600006103515625e+02 5.10476196289062500e+02 8.10130462646484375e+01,
              5.02850006103515625e+02 5.11428558349609375e+02 8.10130462646484375e+01,
              5.03799987792968750e+02 5.11428558349609375e+02 8.10130462646484375e+01,
              5.04750000000000000e+02 5.11428558349609375e+02 8.10130462646484375e+01,
              5.02850006103515625e+02 5.01904754638671875e+02 8.15021743774414063e+01,
              5.03799987792968750e+02 5.01904754638671875e+02 8.15021743774414063e+01,
              5.04750000000000000e+02 5.01904754638671875e+02 8.15021743774414063e+01,
              5.05700012207031250e+02 5.01904754638671875e+02 8.15021743774414063e+01,
              5.06649993896484375e+02 5.01904754638671875e+02 8.15021743774414063e+01,
              5.01899993896484375e+02 5.02857147216796875e+02 8.15021743774414063e+01,
              5.02850006103515625e+02 5.02857147216796875e+02 8.15021743774414063e+01,
              5.03799987792968750e+02 5.02857147216796875e+02 8.15021743774414063e+01,
              5.04750000000000000e+02 5.02857147216796875e+02 8.15021743774414063e+01,
              5.05700012207031250e+02 5.02857147216796875e+02 8.15021743774414063e+01,
              5.06649993896484375e+02 5.02857147216796875e+02 8.15021743774414063e+01,
              5.07600006103515625e+02 5.02857147216796875e+02 8.15021743774414063e+01,
              5.00950012207031250e+02 5.03809509277343750e+02 8.15021743774414063e+01,
              5.01899993896484375e+02 5.03809509277343750e+02 8.15021743774414063e+01,
              5.02850006103515625e+02 5.03809509277343750e+02 8.15021743774414063e+01,
              5.03799987792968750e+02 5.03809509277343750e+02 8.15021743774414063e+01,
              5.04750000000000000e+02 5.03809509277343750e+02 8.15021743774414063e+01,
              5.05700012207031250e+02 5.03809509277343750e+02 8.15021743774414063e+01,
              5.06649993896484375e+02 5.03809509277343750e+02 8.15021743774414063e+01,
              5.07600006103515625e+02 5.03809509277343750e+02 8.15021743774414063e+01,
              5.08549987792968750e+02 5.03809509277343750e+02 8.15021743774414063e+01,
              5.00000000000000000e+02 5.04761901855468750e+02 8.15021743774414063e+01,
              5.00950012207031250e+02 5.04761901855468750e+02 8.15021743774414063e+01,
              5.01899993896484375e+02 5.04761901855468750e+02 8.15021743774414063e+01,
              5.02850006103515625e+02 5.04761901855468750e+02 8.15021743774414063e+01,
              5.03799987792968750e+02 5.04761901855468750e+02 8.15021743774414063e+01,
              5.04750000000000000e+02 5.04761901855468750e+02 8.15021743774414063e+01,
              5.05700012207031250e+02 5.04761901855468750e+02 8.15021743774414063e+01,
              5.06649993896484375e+02 5.04761901855468750e+02 8.15021743774414063e+01,
              5.07600006103515625e+02 5.04761901855468750e+02 8.15021743774414063e+01,
              5.08549987792968750e+02 5.04761901855468750e+02 8.15021743774414063e+01,
              5.00000000000000000e+02 5.05714294433593750e+02 8.15021743774414063e+01,
              5.00950012207031250e+02 5.05714294433593750e+02 8.15021743774414063e+01,
              5.01899993896484375e+02 5.05714294433593750e+02 8.15021743774414063e+01,
              5.02850006103515625e+02 5.05714294433593750e+02 8.15021743774414063e+01,
              5.03799987792968750e+02 5.05714294433593750e+02 8.15021743774414063e+01,
              5.04750000000000000e+02 5.05714294433593750e+02 8.15021743774414063e+01,
              5.05700012207031250e+02 5.05714294433593750e+02 8.15021743774414063e+01,
              5.06649993896484375e+02 5.05714294433593750e+02 8.15021743774414063e+01,
              5.07600006103515625e+02 5.05714294433593750e+02 8.15021743774414063e+01,
              5.08549987792968750e+02 5.05714294433593750e+02 8.15021743774414063e+01,
              5.00000000000000000e+02 5.06666656494140625e+02 8.15021743774414063e+01,
              5.00950012207031250e+02 5.06666656494140625e+02 8.15021743774414063e+01,
              5.01899993896484375e+02 5.06666656494140625e+02 8.15021743774414063e+01,
              5.02850006103515625e+02 5.06666656494140625e+02 8.15021743774414063e+01,
              5.03799987792968750e+02 5.06666656494140625e+02 8.15021743774414063e+01,
              5.04750000000000000e+02 5.06666656494140625e+02 8.15021743774414063e+01,
              5.05700012207031250e+02 5.06666656494140625e+02 8.15021743774414063e+01,
              5.06649993896484375e+02 5.06666656494140625e+02 8.15021743774414063e+01,
              5.07600006103515625e+02 5.06666656494140625e+02 8.15021743774414063e+01,
              5.08549987792968750e+02 5.06666656494140625e+02 8.15021743774414063e+01,
              5.00000000000000000e+02 5.07619049072265625e+02 8.15021743774414063e+01,
              5.00950012207031250e+02 5.07619049072265625e+02 8.15021743774414063e+01,
              5.01899993896484375e+02 5.07619049072265625e+02 8.15021743774414063e+01,
              5.02850006103515625e+02 5.07619049072265625e+02 8.15021743774414063e+01,
              5.03799987792968750e+02 5.07619049072265625e+02 8.15021743774414063e+01,
              5.04750000000000000e+02 5.07619049072265625e+02 8.15021743774414063e+01,
              5.05700012207031250e+02 5.07619049072265625e+02 8.15021743774414063e+01,
              5.06649993896484375e+02 5.07619049072265625e+02 8.15021743774414063e+01,
              5.07600006103515625e+02 5.07619049072265625e+02 8.15021743774414063e+01,
              5.08549987792968750e+02 5.07619049072265625e+02 8.15021743774414063e+01,
              5.00000000000000000e+02 5.08571441650390625e+02 8.15021743774414063e+01,
              5.00950012207031250e+02 5.08571441650390625e+02 8.15021743774414063e+01,
              5.01899993896484375e+02 5.08571441650390625e+02 8.15021743774414063e+01,
              5.02850006103515625e+02 5.08571441650390625e+02 8.15021743774414063e+01,
              5.03799987792968750e+02 5.08571441650390625e+02 8.15021743774414063e+01,
              5.04750000000000000e+02 5.08571441650390625e+02 8.15021743774414063e+01,
              5.05700012207031250e+02 5.08571441650390625e+02 8.15021743774414063e+01,
              5.06649993896484375e+02 5.08571441650390625e+02 8.15021743774414063e+01,
              5.07600006103515625e+02 5.08571441650390625e+02 8.15021743774414063e+01,
              5.08549987792968750e+02 5.08571441650390625e+02 8.15021743774414063e+01,
              5.00000000000000000e+02 5.09523803710937500e+02 8.15021743774414063e+01,
              5.00950012207031250e+02 5.09523803710937500e+02 8.15021743774414063e+01,
              5.01899993896484375e+02 5.09523803710937500e+02 8.15021743774414063e+01,
              5.02850006103515625e+02 5.09523803710937500e+02 8.15021743774414063e+01,
              5.03799987792968750e+02 5.09523803710937500e+02 8.15021743774414063e+01,
              5.04750000000000000e+02 5.09523803710937500e+02 8.15021743774414063e+01,
              5.05700012207031250e+02 5.09523803710937500e+02 8.15021743774414063e+01,
              5.06649993896484375e+02 5.09523803710937500e+02 8.15021743774414063e+01,
              5.07600006103515625e+02 5.09523803710937500e+02 8.15021743774414063e+01,
              5.08549987792968750e+02 5.09523803710937500e+02 8.15021743774414063e+01,
              5.01899993896484375e+02 5.10476196289062500e+02 8.15021743774414063e+01,
              5.02850006103515625e+02 5.10476196289062500e+02 8.15021743774414063e+01,
              5.03799987792968750e+02 5.10476196289062500e+02 8.15021743774414063e+01,
              5.04750000000000000e+02 5.10476196289062500e+02 8.15021743774414063e+01,
              5.05700012207031250e+02 5.10476196289062500e+02 8.15021743774414063e+01,
              5.06649993896484375e+02 5.10476196289062500e+02 8.15021743774414063e+01,
              5.07600006103515625e+02 5.10476196289062500e+02 8.15021743774414063e+01,
              5.01899993896484375e+02 5.11428558349609375e+02 8.15021743774414063e+01,
              5.02850006103515625e+02 5.11428558349609375e+02 8.15021743774414063e+01,
              5.03799987792968750e+02 5.11428558349609375e+02 8.15021743774414063e+01,
              5.04750000000000000e+02 5.11428558349609375e+02 8.15021743774414063e+01,
              5.05700012207031250e+02 5.11428558349609375e+02 8.15021743774414063e+01,
              5.06649993896484375e+02 5.11428558349609375e+02 8.15021743774414063e+01,
              5.07600006103515625e+02 5.11428558349609375e+02 8.15021743774414063e+01,
              5.03799987792968750e+02 5.12380981445312500e+02 8.15021743774414063e+01,
              5.04750000000000000e+02 5.12380981445312500e+02 8.15021743774414063e+01,
              5.05700012207031250e+02 5.12380981445312500e+02 8.15021743774414063e+01,
              5.06649993896484375e+02 5.12380981445312500e+02 8.15021743774414063e+01,
              5.02850006103515625e+02 5.01904754638671875e+02 8.19913024902343750e+01,
              5.03799987792968750e+02 5.01904754638671875e+02 8.19913024902343750e+01,
              5.04750000000000000e+02 5.01904754638671875e+02 8.19913024902343750e+01,
              5.05700012207031250e+02 5.01904754638671875e+02 8.19913024902343750e+01,
              5.01899993896484375e+02 5.02857147216796875e+02 8.19913024902343750e+01,
              5.02850006103515625e+02 5.02857147216796875e+02 8.19913024902343750e+01,
              5.03799987792968750e+02 5.02857147216796875e+02 8.19913024902343750e+01,
              5.04750000000000000e+02 5.02857147216796875e+02 8.19913024902343750e+01,
              5.05700012207031250e+02 5.02857147216796875e+02 8.19913024902343750e+01,
              5.06649993896484375e+02 5.02857147216796875e+02 8.19913024902343750e+01,
              5.00000000000000000e+02 5.03809509277343750e+02 8.19913024902343750e+01,
              5.00950012207031250e+02 5.03809509277343750e+02 8.19913024902343750e+01,
              5.01899993896484375e+02 5.03809509277343750e+02 8.19913024902343750e+01,
              5.02850006103515625e+02 5.03809509277343750e+02 8.19913024902343750e+01,
              5.03799987792968750e+02 5.03809509277343750e+02 8.19913024902343750e+01,
              5.04750000000000000e+02 5.03809509277343750e+02 8.19913024902343750e+01,
              5.05700012207031250e+02 5.03809509277343750e+02 8.19913024902343750e+01,
              5.06649993896484375e+02 5.03809509277343750e+02 8.19913024902343750e+01,
              5.07600006103515625e+02 5.03809509277343750e+02 8.19913024902343750e+01,
              5.00000000000000000e+02 5.04761901855468750e+02 8.19913024902343750e+01,
              5.00950012207031250e+02 5.04761901855468750e+02 8.19913024902343750e+01,
              5.01899993896484375e+02 5.04761901855468750e+02 8.19913024902343750e+01,
              5.02850006103515625e+02 5.04761901855468750e+02 8.19913024902343750e+01,
              5.03799987792968750e+02 5.04761901855468750e+02 8.19913024902343750e+01,
              5.04750000000000000e+02 5.04761901855468750e+02 8.19913024902343750e+01,
              5.05700012207031250e+02 5.04761901855468750e+02 8.19913024902343750e+01,
              5.06649993896484375e+02 5.04761901855468750e+02 8.19913024902343750e+01,
              5.07600006103515625e+02 5.04761901855468750e+02 8.19913024902343750e+01,
              5.08549987792968750e+02 5.04761901855468750e+02 8.19913024902343750e+01,
              5.00000000000000000e+02 5.05714294433593750e+02 8.19913024902343750e+01,
              5.00950012207031250e+02 5.05714294433593750e+02 8.19913024902343750e+01,
              5.01899993896484375e+02 5.05714294433593750e+02 8.19913024902343750e+01,
              5.02850006103515625e+02 5.05714294433593750e+02 8.19913024902343750e+01,
              5.03799987792968750e+02 5.05714294433593750e+02 8.19913024902343750e+01,
              5.04750000000000000e+02 5.05714294433593750e+02 8.19913024902343750e+01,
              5.05700012207031250e+02 5.05714294433593750e+02 8.19913024902343750e+01,
              5.06649993896484375e+02 5.05714294433593750e+02 8.19913024902343750e+01,
              5.07600006103515625e+02 5.05714294433593750e+02 8.19913024902343750e+01,
              5.08549987792968750e+02 5.05714294433593750e+02 8.19913024902343750e+01,
              5.00000000000000000e+02 5.06666656494140625e+02 8.19913024902343750e+01,
              5.00950012207031250e+02 5.06666656494140625e+02 8.19913024902343750e+01,
              5.01899993896484375e+02 5.06666656494140625e+02 8.19913024902343750e+01,
              5.02850006103515625e+02 5.06666656494140625e+02 8.19913024902343750e+01,
              5.03799987792968750e+02 5.06666656494140625e+02 8.19913024902343750e+01,
              5.04750000000000000e+02 5.06666656494140625e+02 8.19913024902343750e+01,
              5.05700012207031250e+02 5.06666656494140625e+02 8.19913024902343750e+01,
              5.06649993896484375e+02 5.06666656494140625e+02 8.19913024902343750e+01,
              5.07600006103515625e+02 5.06666656494140625e+02 8.19913024902343750e+01,
              5.08549987792968750e+02 5.06666656494140625e+02 8.19913024902343750e+01,
              5.00000000000000000e+02 5.07619049072265625e+02 8.19913024902343750e+01,
              5.00950012207031250e+02 5.07619049072265625e+02 8.19913024902343750e+01,
              5.01899993896484375e+02 5.07619049072265625e+02 8.19913024902343750e+01,
              5.02850006103515625e+02 5.07619049072265625e+02 8.19913024902343750e+01,
              5.03799987792968750e+02 5.07619049072265625e+02 8.19913024902343750e+01,
              5.04750000000000000e+02 5.07619049072265625e+02 8.19913024902343750e+01,
              5.05700012207031250e+02 5.07619049072265625e+02 8.19913024902343750e+01,
              5.06649993896484375e+02 5.07619049072265625e+02 8.19913024902343750e+01,
              5.07600006103515625e+02 5.07619049072265625e+02 8.19913024902343750e+01,
              5.08549987792968750e+02 5.07619049072265625e+02 8.19913024902343750e+01,
              5.00000000000000000e+02 5.08571441650390625e+02 8.19913024902343750e+01,
              5.00950012207031250e+02 5.08571441650390625e+02 8.19913024902343750e+01,
              5.01899993896484375e+02 5.08571441650390625e+02 8.19913024902343750e+01,
              5.02850006103515625e+02 5.08571441650390625e+02 8.19913024902343750e+01,
              5.03799987792968750e+02 5.08571441650390625e+02 8.19913024902343750e+01,
              5.04750000000000000e+02 5.08571441650390625e+02 8.19913024902343750e+01,
              5.05700012207031250e+02 5.08571441650390625e+02 8.19913024902343750e+01,
              5.06649993896484375e+02 5.08571441650390625e+02 8.19913024902343750e+01,
              5.07600006103515625e+02 5.08571441650390625e+02 8.19913024902343750e+01,
              5.08549987792968750e+02 5.08571441650390625e+02 8.19913024902343750e+01,
              5.00950012207031250e+02 5.09523803710937500e+02 8.19913024902343750e+01,
              5.01899993896484375e+02 5.09523803710937500e+02 8.19913024902343750e+01,
              5.02850006103515625e+02 5.09523803710937500e+02 8.19913024902343750e+01,
              5.03799987792968750e+02 5.09523803710937500e+02 8.19913024902343750e+01,
              5.04750000000000000e+02 5.09523803710937500e+02 8.19913024902343750e+01,
              5.05700012207031250e+02 5.09523803710937500e+02 8.19913024902343750e+01,
              5.06649993896484375e+02 5.09523803710937500e+02 8.19913024902343750e+01,
              5.07600006103515625e+02 5.09523803710937500e+02 8.19913024902343750e+01,
              5.01899993896484375e+02 5.10476196289062500e+02 8.19913024902343750e+01,
              5.02850006103515625e+02 5.10476196289062500e+02 8.19913024902343750e+01,
              5.03799987792968750e+02 5.10476196289062500e+02 8.19913024902343750e+01,
              5.04750000000000000e+02 5.10476196289062500e+02 8.19913024902343750e+01,
              5.05700012207031250e+02 5.10476196289062500e+02 8.19913024902343750e+01,
              5.06649993896484375e+02 5.10476196289062500e+02 8.19913024902343750e+01,
              5.07600006103515625e+02 5.10476196289062500e+02 8.19913024902343750e+01,
              5.02850006103515625e+02 5.11428558349609375e+02 8.19913024902343750e+01,
              5.03799987792968750e+02 5.11428558349609375e+02 8.19913024902343750e+01,
              5.04750000000000000e+02 5.11428558349609375e+02 8.19913024902343750e+01,
              5.05700012207031250e+02 5.11428558349609375e+02 8.19913024902343750e+01,
              5.06649993896484375e+02 5.11428558349609375e+02 8.19913024902343750e+01,
              5.03799987792968750e+02 5.12380981445312500e+02 8.19913024902343750e+01,
              5.04750000000000000e+02 5.12380981445312500e+02 8.19913024902343750e+01,
              5.05700012207031250e+02 5.12380981445312500e+02 8.19913024902343750e+01,
              5.03799987792968750e+02 5.02857147216796875e+02 8.24804382324218750e+01,
              5.01899993896484375e+02 5.03809509277343750e+02 8.24804382324218750e+01,
              5.02850006103515625e+02 5.03809509277343750e+02 8.24804382324218750e+01,
              5.03799987792968750e+02 5.03809509277343750e+02 8.24804382324218750e+01,
              5.04750000000000000e+02 5.03809509277343750e+02 8.24804382324218750e+01,
              5.05700012207031250e+02 5.03809509277343750e+02 8.24804382324218750e+01,
              5.01899993896484375e+02 5.04761901855468750e+02 8.24804382324218750e+01,
              5.02850006103515625e+02 5.04761901855468750e+02 8.24804382324218750e+01,
              5.03799987792968750e+02 5.04761901855468750e+02 8.24804382324218750e+01,
              5.04750000000000000e+02 5.04761901855468750e+02 8.24804382324218750e+01,
              5.05700012207031250e+02 5.04761901855468750e+02 8.24804382324218750e+01,
              5.06649993896484375e+02 5.04761901855468750e+02 8.24804382324218750e+01,
              5.01899993896484375e+02 5.05714294433593750e+02 8.24804382324218750e+01,
              5.02850006103515625e+02 5.05714294433593750e+02 8.24804382324218750e+01,
              5.03799987792968750e+02 5.05714294433593750e+02 8.24804382324218750e+01,
              5.04750000000000000e+02 5.05714294433593750e+02 8.24804382324218750e+01,
              5.05700012207031250e+02 5.05714294433593750e+02 8.24804382324218750e+01,
              5.06649993896484375e+02 5.05714294433593750e+02 8.24804382324218750e+01,
              5.00950012207031250e+02 5.06666656494140625e+02 8.24804382324218750e+01,
              5.01899993896484375e+02 5.06666656494140625e+02 8.24804382324218750e+01,
              5.02850006103515625e+02 5.06666656494140625e+02 8.24804382324218750e+01,
              5.03799987792968750e+02 5.06666656494140625e+02 8.24804382324218750e+01,
              5.04750000000000000e+02 5.06666656494140625e+02 8.24804382324218750e+01,
              5.05700012207031250e+02 5.06666656494140625e+02 8.24804382324218750e+01,
              5.00950012207031250e+02 5.07619049072265625e+02 8.24804382324218750e+01,
              5.01899993896484375e+02 5.07619049072265625e+02 8.24804382324218750e+01,
              5.02850006103515625e+02 5.07619049072265625e+02 8.24804382324218750e+01,
              5.03799987792968750e+02 5.07619049072265625e+02 8.24804382324218750e+01,
              5.04750000000000000e+02 5.07619049072265625e+02 8.24804382324218750e+01,
              5.05700012207031250e+02 5.07619049072265625e+02 8.24804382324218750e+01,
              5.06649993896484375e+02 5.07619049072265625e+02 8.24804382324218750e+01,
              5.01899993896484375e+02 5.08571441650390625e+02 8.24804382324218750e+01,
              5.02850006103515625e+02 5.08571441650390625e+02 8.24804382324218750e+01,
              5.03799987792968750e+02 5.08571441650390625e+02 8.24804382324218750e+01,
              5.04750000000000000e+02 5.08571441650390625e+02 8.24804382324218750e+01,
              5.05700012207031250e+02 5.08571441650390625e+02 8.24804382324218750e+01,
              5.06649993896484375e+02 5.08571441650390625e+02 8.24804382324218750e+01,
              5.04750000000000000e+02 5.09523803710937500e+02 8.24804382324218750e+01,
              5.05700012207031250e+02 5.09523803710937500e+02 8.24804382324218750e+01,
              5.10450012207031250e+02 5.00000000000000000e+02 8.34586944580078125e+01,
              5.11399993896484375e+02 5.00000000000000000e+02 8.34586944580078125e+01,
              5.08549987792968750e+02 5.00952392578125000e+02 8.34586944580078125e+01,
              5.09500000000000000e+02 5.00952392578125000e+02 8.34586944580078125e+01,
              5.10450012207031250e+02 5.00952392578125000e+02 8.34586944580078125e+01,
              5.11399993896484375e+02 5.00952392578125000e+02 8.34586944580078125e+01,
              5.12349975585937500e+02 5.00952392578125000e+02 8.34586944580078125e+01,
              5.07600006103515625e+02 5.01904754638671875e+02 8.34586944580078125e+01,
              5.08549987792968750e+02 5.01904754638671875e+02 8.34586944580078125e+01,
              5.09500000000000000e+02 5.01904754638671875e+02 8.34586944580078125e+01,
              5.10450012207031250e+02 5.01904754638671875e+02 8.34586944580078125e+01,
              5.11399993896484375e+02 5.01904754638671875e+02 8.34586944580078125e+01,
              5.12349975585937500e+02 5.01904754638671875e+02 8.34586944580078125e+01,
              5.08549987792968750e+02 5.02857147216796875e+02 8.34586944580078125e+01,
              5.09500000000000000e+02 5.02857147216796875e+02 8.34586944580078125e+01,
              5.10450012207031250e+02 5.02857147216796875e+02 8.34586944580078125e+01,
              5.11399993896484375e+02 5.02857147216796875e+02 8.34586944580078125e+01,
              5.12349975585937500e+02 5.02857147216796875e+02 8.34586944580078125e+01,
              5.09500000000000000e+02 5.03809509277343750e+02 8.34586944580078125e+01,
              5.10450012207031250e+02 5.03809509277343750e+02 8.34586944580078125e+01,
              5.11399993896484375e+02 5.03809509277343750e+02 8.34586944580078125e+01,
              5.06649993896484375e+02 5.00000000000000000e+02 8.39478225708007813e+01,
              5.07600006103515625e+02 5.00000000000000000e+02 8.39478225708007813e+01,
              5.08549987792968750e+02 5.00000000000000000e+02 8.39478225708007813e+01,
              5.09500000000000000e+02 5.00000000000000000e+02 8.39478225708007813e+01,
              5.10450012207031250e+02 5.00000000000000000e+02 8.39478225708007813e+01,
              5.11399993896484375e+02 5.00000000000000000e+02 8.39478225708007813e+01,
              5.12349975585937500e+02 5.00000000000000000e+02 8.39478225708007813e+01,
              5.13299987792968750e+02 5.00000000000000000e+02 8.39478225708007813e+01,
              5.14250000000000000e+02 5.00000000000000000e+02 8.39478225708007813e+01,
              5.06649993896484375e+02 5.00952392578125000e+02 8.39478225708007813e+01,
              5.07600006103515625e+02 5.00952392578125000e+02 8.39478225708007813e+01,
              5.08549987792968750e+02 5.00952392578125000e+02 8.39478225708007813e+01,
              5.09500000000000000e+02 5.00952392578125000e+02 8.39478225708007813e+01,
              5.10450012207031250e+02 5.00952392578125000e+02 8.39478225708007813e+01,
              5.11399993896484375e+02 5.00952392578125000e+02 8.39478225708007813e+01,
              5.12349975585937500e+02 5.00952392578125000e+02 8.39478225708007813e+01,
              5.13299987792968750e+02 5.00952392578125000e+02 8.39478225708007813e+01,
              5.14250000000000000e+02 5.00952392578125000e+02 8.39478225708007813e+01,
              5.06649993896484375e+02 5.01904754638671875e+02 8.39478225708007813e+01,
              5.07600006103515625e+02 5.01904754638671875e+02 8.39478225708007813e+01,
              5.08549987792968750e+02 5.01904754638671875e+02 8.39478225708007813e+01,
              5.09500000000000000e+02 5.01904754638671875e+02 8.39478225708007813e+01,
              5.10450012207031250e+02 5.01904754638671875e+02 8.39478225708007813e+01,
              5.11399993896484375e+02 5.01904754638671875e+02 8.39478225708007813e+01,
              5.12349975585937500e+02 5.01904754638671875e+02 8.39478225708007813e+01,
              5.13299987792968750e+02 5.01904754638671875e+02 8.39478225708007813e+01,
              5.14250000000000000e+02 5.01904754638671875e+02 8.39478225708007813e+01,
              5.06649993896484375e+02 5.02857147216796875e+02 8.39478225708007813e+01,
              5.07600006103515625e+02 5.02857147216796875e+02 8.39478225708007813e+01,
              5.08549987792968750e+02 5.02857147216796875e+02 8.39478225708007813e+01,
              5.09500000000000000e+02 5.02857147216796875e+02 8.39478225708007813e+01,
              5.10450012207031250e+02 5.02857147216796875e+02 8.39478225708007813e+01,
              5.11399993896484375e+02 5.02857147216796875e+02 8.39478225708007813e+01,
              5.12349975585937500e+02 5.02857147216796875e+02 8.39478225708007813e+01,
              5.13299987792968750e+02 5.02857147216796875e+02 8.39478225708007813e+01,
              5.14250000000000000e+02 5.02857147216796875e+02 8.39478225708007813e+01,
              5.06649993896484375e+02 5.03809509277343750e+02 8.39478225708007813e+01,
              5.07600006103515625e+02 5.03809509277343750e+02 8.39478225708007813e+01,
              5.08549987792968750e+02 5.03809509277343750e+02 8.39478225708007813e+01,
              5.09500000000000000e+02 5.03809509277343750e+02 8.39478225708007813e+01,
              5.10450012207031250e+02 5.03809509277343750e+02 8.39478225708007813e+01,
              5.11399993896484375e+02 5.03809509277343750e+02 8.39478225708007813e+01,
              5.12349975585937500e+02 5.03809509277343750e+02 8.39478225708007813e+01,
              5.13299987792968750e+02 5.03809509277343750e+02 8.39478225708007813e+01,
              5.14250000000000000e+02 5.03809509277343750e+02 8.39478225708007813e+01,
              5.07600006103515625e+02 5.04761901855468750e+02 8.39478225708007813e+01,
              5.08549987792968750e+02 5.04761901855468750e+02 8.39478225708007813e+01,
              5.09500000000000000e+02 5.04761901855468750e+02 8.39478225708007813e+01,
              5.10450012207031250e+02 5.04761901855468750e+02 8.39478225708007813e+01,
              5.11399993896484375e+02 5.04761901855468750e+02 8.39478225708007813e+01,
              5.12349975585937500e+02 5.04761901855468750e+02 8.39478225708007813e+01,
              5.13299987792968750e+02 5.04761901855468750e+02 8.39478225708007813e+01,
              5.14250000000000000e+02 5.04761901855468750e+02 8.39478225708007813e+01,
              5.08549987792968750e+02 5.05714294433593750e+02 8.39478225708007813e+01,
              5.09500000000000000e+02 5.05714294433593750e+02 8.39478225708007813e+01,
              5.10450012207031250e+02 5.05714294433593750e+02 8.39478225708007813e+01,
              5.11399993896484375e+02 5.05714294433593750e+02 8.39478225708007813e+01,
              5.12349975585937500e+02 5.05714294433593750e+02 8.39478225708007813e+01,
              5.13299987792968750e+02 5.05714294433593750e+02 8.39478225708007813e+01,
              5.08549987792968750e+02 5.06666656494140625e+02 8.39478225708007813e+01,
              5.09500000000000000e+02 5.06666656494140625e+02 8.39478225708007813e+01,
              5.10450012207031250e+02 5.06666656494140625e+02 8.39478225708007813e+01,
              5.11399993896484375e+02 5.06666656494140625e+02 8.39478225708007813e+01,
              5.12349975585937500e+02 5.06666656494140625e+02 8.39478225708007813e+01,
              5.06649993896484375e+02 5.00000000000000000e+02 8.44369583129882813e+01,
              5.07600006103515625e+02 5.00000000000000000e+02 8.44369583129882813e+01,
              5.08549987792968750e+02 5.00000000000000000e+02 8.44369583129882813e+01,
              5.09500000000000000e+02 5.00000000000000000e+02 8.44369583129882813e+01,
              5.10450012207031250e+02 5.00000000000000000e+02 8.44369583129882813e+01,
              5.11399993896484375e+02 5.00000000000000000e+02 8.44369583129882813e+01,
              5.12349975585937500e+02 5.00000000000000000e+02 8.44369583129882813e+01,
              5.13299987792968750e+02 5.00000000000000000e+02 8.44369583129882813e+01,
              5.14250000000000000e+02 5.00000000000000000e+02 8.44369583129882813e+01,
              5.06649993896484375e+02 5.00952392578125000e+02 8.44369583129882813e+01,
              5.07600006103515625e+02 5.00952392578125000e+02 8.44369583129882813e+01,
              5.08549987792968750e+02 5.00952392578125000e+02 8.44369583129882813e+01,
              5.09500000000000000e+02 5.00952392578125000e+02 8.44369583129882813e+01,
              5.10450012207031250e+02 5.00952392578125000e+02 8.44369583129882813e+01,
              5.11399993896484375e+02 5.00952392578125000e+02 8.44369583129882813e+01,
              5.12349975585937500e+02 5.00952392578125000e+02 8.44369583129882813e+01,
              5.13299987792968750e+02 5.00952392578125000e+02 8.44369583129882813e+01,
              5.14250000000000000e+02 5.00952392578125000e+02 8.44369583129882813e+01,
              5.06649993896484375e+02 5.01904754638671875e+02 8.44369583129882813e+01,
              5.07600006103515625e+02 5.01904754638671875e+02 8.44369583129882813e+01,
              5.08549987792968750e+02 5.01904754638671875e+02 8.44369583129882813e+01,
              5.09500000000000000e+02 5.01904754638671875e+02 8.44369583129882813e+01,
              5.10450012207031250e+02 5.01904754638671875e+02 8.44369583129882813e+01,
              5.11399993896484375e+02 5.01904754638671875e+02 8.44369583129882813e+01,
              5.12349975585937500e+02 5.01904754638671875e+02 8.44369583129882813e+01,
              5.13299987792968750e+02 5.01904754638671875e+02 8.44369583129882813e+01,
              5.14250000000000000e+02 5.01904754638671875e+02 8.44369583129882813e+01,
              5.06649993896484375e+02 5.02857147216796875e+02 8.44369583129882813e+01,
              5.07600006103515625e+02 5.02857147216796875e+02 8.44369583129882813e+01,
              5.08549987792968750e+02 5.02857147216796875e+02 8.44369583129882813e+01,
              5.09500000000000000e+02 5.02857147216796875e+02 8.44369583129882813e+01,
              5.10450012207031250e+02 5.02857147216796875e+02 8.44369583129882813e+01,
              5.11399993896484375e+02 5.02857147216796875e+02 8.44369583129882813e+01,
              5.12349975585937500e+02 5.02857147216796875e+02 8.44369583129882813e+01,
              5.13299987792968750e+02 5.02857147216796875e+02 8.44369583129882813e+01,
              5.14250000000000000e+02 5.02857147216796875e+02 8.44369583129882813e+01,
              5.07600006103515625e+02 5.03809509277343750e+02 8.44369583129882813e+01,
              5.08549987792968750e+02 5.03809509277343750e+02 8.44369583129882813e+01,
              5.09500000000000000e+02 5.03809509277343750e+02 8.44369583129882813e+01,
              5.10450012207031250e+02 5.03809509277343750e+02 8.44369583129882813e+01,
              5.11399993896484375e+02 5.03809509277343750e+02 8.44369583129882813e+01,
              5.12349975585937500e+02 5.03809509277343750e+02 8.44369583129882813e+01,
              5.13299987792968750e+02 5.03809509277343750e+02 8.44369583129882813e+01,
              5.14250000000000000e+02 5.03809509277343750e+02 8.44369583129882813e+01,
              5.07600006103515625e+02 5.04761901855468750e+02 8.44369583129882813e+01,
              5.08549987792968750e+02 5.04761901855468750e+02 8.44369583129882813e+01,
              5.09500000000000000e+02 5.04761901855468750e+02 8.44369583129882813e+01,
              5.10450012207031250e+02 5.04761901855468750e+02 8.44369583129882813e+01,
              5.11399993896484375e+02 5.04761901855468750e+02 8.44369583129882813e+01,
              5.12349975585937500e+02 5.04761901855468750e+02 8.44369583129882813e+01,
              5.13299987792968750e+02 5.04761901855468750e+02 8.44369583129882813e+01,
              5.14250000000000000e+02 5.04761901855468750e+02 8.44369583129882813e+01,
              5.08549987792968750e+02 5.05714294433593750e+02 8.44369583129882813e+01,
              5.09500000000000000e+02 5.05714294433593750e+02 8.44369583129882813e+01,
              5.10450012207031250e+02 5.05714294433593750e+02 8.44369583129882813e+01,
              5.11399993896484375e+02 5.05714294433593750e+02 8.44369583129882813e+01,
              5.12349975585937500e+02 5.05714294433593750e+02 8.44369583129882813e+01,
              5.13299987792968750e+02 5.05714294433593750e+02 8.44369583129882813e+01,
              5.09500000000000000e+02 5.06666656494140625e+02 8.44369583129882813e+01,
              5.10450012207031250e+02 5.06666656494140625e+02 8.44369583129882813e+01,
              5.11399993896484375e+02 5.06666656494140625e+02 8.44369583129882813e+01,
              5.09500000000000000e+02 5.00952392578125000e+02 8.49260864257812500e+01,
              5.10450012207031250e+02 5.00952392578125000e+02 8.49260864257812500e+01,
              5.09500000000000000e+02 5.01904754638671875e+02 8.49260864257812500e+01,
              5.10450012207031250e+02 5.01904754638671875e+02 8.49260864257812500e+01,
              5.09500000000000000e+02 5.02857147216796875e+02 8.49260864257812500e+01,
              5.15200012207031250e+02 5.11428558349609375e+02 8.59043502807617188e+01,
              5.15200012207031250e+02 5.12380981445312500e+02 8.59043502807617188e+01,
              5.14250000000000000e+02 5.11428558349609375e+02 8.63934783935546875e+01,
              5.15200012207031250e+02 5.11428558349609375e+02 8.63934783935546875e+01,
              5.13299987792968750e+02 5.12380981445312500e+02 8.63934783935546875e+01,
              5.14250000000000000e+02 5.12380981445312500e+02 8.63934783935546875e+01,
              5.15200012207031250e+02 5.12380981445312500e+02 8.63934783935546875e+01,
              5.13299987792968750e+02 5.13333312988281250e+02 8.63934783935546875e+01,
              5.14250000000000000e+02 5.13333312988281250e+02 8.63934783935546875e+01,
              5.15200012207031250e+02 5.13333312988281250e+02 8.63934783935546875e+01,
              5.14250000000000000e+02 5.11428558349609375e+02 8.68826065063476563e+01,
              5.15200012207031250e+02 5.11428558349609375e+02 8.68826065063476563e+01,
              5.13299987792968750e+02 5.12380981445312500e+02 8.68826065063476563e+01,
              5.14250000000000000e+02 5.12380981445312500e+02 8.68826065063476563e+01,
              5.15200012207031250e+02 5.12380981445312500e+02 8.68826065063476563e+01,
              5.14250000000000000e+02 5.13333312988281250e+02 8.68826065063476563e+01,
              5.15200012207031250e+02 5.13333312988281250e+02 8.68826065063476563e+01,
              5.14250000000000000e+02 5.14285705566406250e+02 8.68826065063476563e+01,
              5.15200012207031250e+02 5.14285705566406250e+02 8.68826065063476563e+01,
              5.15200012207031250e+02 5.11428558349609375e+02 8.73717422485351563e+01,
              5.10450012207031250e+02 5.03809509277343750e+02 8.93282623291015625e+01,
              5.11399993896484375e+02 5.03809509277343750e+02 8.93282623291015625e+01,
              5.15200012207031250e+02 5.11428558349609375e+02 8.93282623291015625e+01,
              5.11399993896484375e+02 5.02857147216796875e+02 8.98173904418945313e+01,
              5.12349975585937500e+02 5.02857147216796875e+02 8.98173904418945313e+01,
              5.13299987792968750e+02 5.02857147216796875e+02 8.98173904418945313e+01,
              5.09500000000000000e+02 5.03809509277343750e+02 8.98173904418945313e+01,
              5.10450012207031250e+02 5.03809509277343750e+02 8.98173904418945313e+01,
              5.11399993896484375e+02 5.03809509277343750e+02 8.98173904418945313e+01,
              5.12349975585937500e+02 5.03809509277343750e+02 8.98173904418945313e+01,
              5.13299987792968750e+02 5.03809509277343750e+02 8.98173904418945313e+01,
              5.14250000000000000e+02 5.03809509277343750e+02 8.98173904418945313e+01,
              5.08549987792968750e+02 5.04761901855468750e+02 8.98173904418945313e+01,
              5.09500000000000000e+02 5.04761901855468750e+02 8.98173904418945313e+01,
              5.10450012207031250e+02 5.04761901855468750e+02 8.98173904418945313e+01,
              5.11399993896484375e+02 5.04761901855468750e+02 8.98173904418945313e+01,
              5.12349975585937500e+02 5.04761901855468750e+02 8.98173904418945313e+01,
              5.13299987792968750e+02 5.04761901855468750e+02 8.98173904418945313e+01,
              5.14250000000000000e+02 5.04761901855468750e+02 8.98173904418945313e+01,
              5.09500000000000000e+02 5.05714294433593750e+02 8.98173904418945313e+01,
              5.10450012207031250e+02 5.05714294433593750e+02 8.98173904418945313e+01,
              5.11399993896484375e+02 5.05714294433593750e+02 8.98173904418945313e+01,
              5.12349975585937500e+02 5.05714294433593750e+02 8.98173904418945313e+01,
              5.13299987792968750e+02 5.05714294433593750e+02 8.98173904418945313e+01,
              5.14250000000000000e+02 5.05714294433593750e+02 8.98173904418945313e+01,
              5.10450012207031250e+02 5.06666656494140625e+02 8.98173904418945313e+01,
              5.11399993896484375e+02 5.06666656494140625e+02 8.98173904418945313e+01,
              5.12349975585937500e+02 5.06666656494140625e+02 8.98173904418945313e+01,
              5.13299987792968750e+02 5.06666656494140625e+02 8.98173904418945313e+01,
              5.14250000000000000e+02 5.06666656494140625e+02 8.98173904418945313e+01,
              5.11399993896484375e+02 5.07619049072265625e+02 8.98173904418945313e+01,
              5.12349975585937500e+02 5.07619049072265625e+02 8.98173904418945313e+01,
              5.13299987792968750e+02 5.07619049072265625e+02 8.98173904418945313e+01,
              5.12349975585937500e+02 5.08571441650390625e+02 8.98173904418945313e+01,
              5.13299987792968750e+02 5.08571441650390625e+02 8.98173904418945313e+01,
              5.13299987792968750e+02 5.09523803710937500e+02 8.98173904418945313e+01,
              5.14250000000000000e+02 5.09523803710937500e+02 8.98173904418945313e+01,
              5.12349975585937500e+02 5.10476196289062500e+02 8.98173904418945313e+01,
              5.13299987792968750e+02 5.10476196289062500e+02 8.98173904418945313e+01,
              5.14250000000000000e+02 5.10476196289062500e+02 8.98173904418945313e+01,
              5.15200012207031250e+02 5.10476196289062500e+02 8.98173904418945313e+01,
              5.12349975585937500e+02 5.11428558349609375e+02 8.98173904418945313e+01,
              5.13299987792968750e+02 5.11428558349609375e+02 8.98173904418945313e+01,
              5.14250000000000000e+02 5.11428558349609375e+02 8.98173904418945313e+01,
              5.15200012207031250e+02 5.11428558349609375e+02 8.98173904418945313e+01,
              5.16150024414062500e+02 5.11428558349609375e+02 8.98173904418945313e+01,
              5.12349975585937500e+02 5.12380981445312500e+02 8.98173904418945313e+01,
              5.13299987792968750e+02 5.12380981445312500e+02 8.98173904418945313e+01,
              5.14250000000000000e+02 5.12380981445312500e+02 8.98173904418945313e+01,
              5.15200012207031250e+02 5.12380981445312500e+02 8.98173904418945313e+01,
              5.16150024414062500e+02 5.12380981445312500e+02 8.98173904418945313e+01,
              5.12349975585937500e+02 5.13333312988281250e+02 8.98173904418945313e+01,
              5.13299987792968750e+02 5.13333312988281250e+02 8.98173904418945313e+01,
              5.14250000000000000e+02 5.13333312988281250e+02 8.98173904418945313e+01,
              5.15200012207031250e+02 5.13333312988281250e+02 8.98173904418945313e+01,
              5.16150024414062500e+02 5.13333312988281250e+02 8.98173904418945313e+01,
              5.13299987792968750e+02 5.14285705566406250e+02 8.98173904418945313e+01,
              5.14250000000000000e+02 5.14285705566406250e+02 8.98173904418945313e+01,
              5.15200012207031250e+02 5.14285705566406250e+02 8.98173904418945313e+01,
              5.16150024414062500e+02 5.14285705566406250e+02 8.98173904418945313e+01,
              5.17099975585937500e+02 5.14285705566406250e+02 8.98173904418945313e+01,
              5.13299987792968750e+02 5.15238098144531250e+02 8.98173904418945313e+01,
              5.14250000000000000e+02 5.15238098144531250e+02 8.98173904418945313e+01,
              5.15200012207031250e+02 5.15238098144531250e+02 8.98173904418945313e+01,
              5.16150024414062500e+02 5.15238098144531250e+02 8.98173904418945313e+01,
              5.10450012207031250e+02 5.02857147216796875e+02 9.03065185546875000e+01,
              5.11399993896484375e+02 5.02857147216796875e+02 9.03065185546875000e+01,
              5.12349975585937500e+02 5.02857147216796875e+02 9.03065185546875000e+01,
              5.09500000000000000e+02 5.03809509277343750e+02 9.03065185546875000e+01,
              5.10450012207031250e+02 5.03809509277343750e+02 9.03065185546875000e+01,
              5.11399993896484375e+02 5.03809509277343750e+02 9.03065185546875000e+01,
              5.12349975585937500e+02 5.03809509277343750e+02 9.03065185546875000e+01,
              5.13299987792968750e+02 5.03809509277343750e+02 9.03065185546875000e+01,
              5.09500000000000000e+02 5.04761901855468750e+02 9.03065185546875000e+01,
              5.10450012207031250e+02 5.04761901855468750e+02 9.03065185546875000e+01,
              5.11399993896484375e+02 5.04761901855468750e+02 9.03065185546875000e+01,
              5.12349975585937500e+02 5.04761901855468750e+02 9.03065185546875000e+01,
              5.13299987792968750e+02 5.04761901855468750e+02 9.03065185546875000e+01,
              5.09500000000000000e+02 5.05714294433593750e+02 9.03065185546875000e+01,
              5.10450012207031250e+02 5.05714294433593750e+02 9.03065185546875000e+01,
              5.11399993896484375e+02 5.05714294433593750e+02 9.03065185546875000e+01,
              5.12349975585937500e+02 5.05714294433593750e+02 9.03065185546875000e+01,
              5.13299987792968750e+02 5.05714294433593750e+02 9.03065185546875000e+01,
              5.10450012207031250e+02 5.06666656494140625e+02 9.03065185546875000e+01,
              5.11399993896484375e+02 5.06666656494140625e+02 9.03065185546875000e+01,
              5.12349975585937500e+02 5.06666656494140625e+02 9.03065185546875000e+01,
              5.13299987792968750e+02 5.06666656494140625e+02 9.03065185546875000e+01,
              5.12349975585937500e+02 5.07619049072265625e+02 9.03065185546875000e+01,
              5.13299987792968750e+02 5.10476196289062500e+02 9.03065185546875000e+01,
              5.14250000000000000e+02 5.10476196289062500e+02 9.03065185546875000e+01,
              5.15200012207031250e+02 5.10476196289062500e+02 9.03065185546875000e+01,
              5.13299987792968750e+02 5.11428558349609375e+02 9.03065185546875000e+01,
              5.14250000000000000e+02 5.11428558349609375e+02 9.03065185546875000e+01,
              5.15200012207031250e+02 5.11428558349609375e+02 9.03065185546875000e+01,
              5.12349975585937500e+02 5.12380981445312500e+02 9.03065185546875000e+01,
              5.13299987792968750e+02 5.12380981445312500e+02 9.03065185546875000e+01,
              5.14250000000000000e+02 5.12380981445312500e+02 9.03065185546875000e+01,
              5.15200012207031250e+02 5.12380981445312500e+02 9.03065185546875000e+01,
              5.16150024414062500e+02 5.12380981445312500e+02 9.03065185546875000e+01,
              5.17099975585937500e+02 5.12380981445312500e+02 9.03065185546875000e+01,
              5.13299987792968750e+02 5.13333312988281250e+02 9.03065185546875000e+01,
              5.14250000000000000e+02 5.13333312988281250e+02 9.03065185546875000e+01,
              5.15200012207031250e+02 5.13333312988281250e+02 9.03065185546875000e+01,
              5.16150024414062500e+02 5.13333312988281250e+02 9.03065185546875000e+01,
              5.17099975585937500e+02 5.13333312988281250e+02 9.03065185546875000e+01,
              5.13299987792968750e+02 5.14285705566406250e+02 9.03065185546875000e+01,
              5.14250000000000000e+02 5.14285705566406250e+02 9.03065185546875000e+01,
              5.15200012207031250e+02 5.14285705566406250e+02 9.03065185546875000e+01,
              5.16150024414062500e+02 5.14285705566406250e+02 9.03065185546875000e+01,
              5.11399993896484375e+02 5.03809509277343750e+02 9.07956542968750000e+01,
              5.15200012207031250e+02 5.11428558349609375e+02 9.07956542968750000e+01,
              5.15200012207031250e+02 5.12380981445312500e+02 9.07956542968750000e+01,
              5.09500000000000000e+02 5.08571441650390625e+02 9.17739105224609375e+01,
              5.09500000000000000e+02 5.09523803710937500e+02 9.17739105224609375e+01,
              5.10450012207031250e+02 5.09523803710937500e+02 9.17739105224609375e+01,
              5.09500000000000000e+02 5.10476196289062500e+02 9.17739105224609375e+01,
              5.10450012207031250e+02 5.10476196289062500e+02 9.17739105224609375e+01,
              5.11399993896484375e+02 5.10476196289062500e+02 9.17739105224609375e+01,
              5.09500000000000000e+02 5.11428558349609375e+02 9.17739105224609375e+01,
              5.09500000000000000e+02 5.05714294433593750e+02 9.22630462646484375e+01,
              5.07600006103515625e+02 5.06666656494140625e+02 9.22630462646484375e+01,
              5.08549987792968750e+02 5.06666656494140625e+02 9.22630462646484375e+01,
              5.09500000000000000e+02 5.06666656494140625e+02 9.22630462646484375e+01,
              5.10450012207031250e+02 5.06666656494140625e+02 9.22630462646484375e+01,
              5.11399993896484375e+02 5.06666656494140625e+02 9.22630462646484375e+01,
              5.12349975585937500e+02 5.06666656494140625e+02 9.22630462646484375e+01,
              5.06649993896484375e+02 5.07619049072265625e+02 9.22630462646484375e+01,
              5.07600006103515625e+02 5.07619049072265625e+02 9.22630462646484375e+01,
              5.08549987792968750e+02 5.07619049072265625e+02 9.22630462646484375e+01,
              5.09500000000000000e+02 5.07619049072265625e+02 9.22630462646484375e+01,
              5.10450012207031250e+02 5.07619049072265625e+02 9.22630462646484375e+01,
              5.11399993896484375e+02 5.07619049072265625e+02 9.22630462646484375e+01,
              5.12349975585937500e+02 5.07619049072265625e+02 9.22630462646484375e+01,
              5.13299987792968750e+02 5.07619049072265625e+02 9.22630462646484375e+01,
              5.06649993896484375e+02 5.08571441650390625e+02 9.22630462646484375e+01,
              5.07600006103515625e+02 5.08571441650390625e+02 9.22630462646484375e+01,
              5.08549987792968750e+02 5.08571441650390625e+02 9.22630462646484375e+01,
              5.09500000000000000e+02 5.08571441650390625e+02 9.22630462646484375e+01,
              5.10450012207031250e+02 5.08571441650390625e+02 9.22630462646484375e+01,
              5.11399993896484375e+02 5.08571441650390625e+02 9.22630462646484375e+01,
              5.12349975585937500e+02 5.08571441650390625e+02 9.22630462646484375e+01,
              5.13299987792968750e+02 5.08571441650390625e+02 9.22630462646484375e+01,
              5.05700012207031250e+02 5.09523803710937500e+02 9.22630462646484375e+01,
              5.06649993896484375e+02 5.09523803710937500e+02 9.22630462646484375e+01,
              5.07600006103515625e+02 5.09523803710937500e+02 9.22630462646484375e+01,
              5.08549987792968750e+02 5.09523803710937500e+02 9.22630462646484375e+01,
              5.09500000000000000e+02 5.09523803710937500e+02 9.22630462646484375e+01,
              5.10450012207031250e+02 5.09523803710937500e+02 9.22630462646484375e+01,
              5.11399993896484375e+02 5.09523803710937500e+02 9.22630462646484375e+01,
              5.12349975585937500e+02 5.09523803710937500e+02 9.22630462646484375e+01,
              5.13299987792968750e+02 5.09523803710937500e+02 9.22630462646484375e+01,
              5.05700012207031250e+02 5.10476196289062500e+02 9.22630462646484375e+01,
              5.06649993896484375e+02 5.10476196289062500e+02 9.22630462646484375e+01,
              5.07600006103515625e+02 5.10476196289062500e+02 9.22630462646484375e+01,
              5.08549987792968750e+02 5.10476196289062500e+02 9.22630462646484375e+01,
              5.09500000000000000e+02 5.10476196289062500e+02 9.22630462646484375e+01,
              5.10450012207031250e+02 5.10476196289062500e+02 9.22630462646484375e+01,
              5.11399993896484375e+02 5.10476196289062500e+02 9.22630462646484375e+01,
              5.12349975585937500e+02 5.10476196289062500e+02 9.22630462646484375e+01,
              5.13299987792968750e+02 5.10476196289062500e+02 9.22630462646484375e+01,
              5.05700012207031250e+02 5.11428558349609375e+02 9.22630462646484375e+01,
              5.06649993896484375e+02 5.11428558349609375e+02 9.22630462646484375e+01,
              5.07600006103515625e+02 5.11428558349609375e+02 9.22630462646484375e+01,
              5.08549987792968750e+02 5.11428558349609375e+02 9.22630462646484375e+01,
              5.09500000000000000e+02 5.11428558349609375e+02 9.22630462646484375e+01,
              5.10450012207031250e+02 5.11428558349609375e+02 9.22630462646484375e+01,
              5.11399993896484375e+02 5.11428558349609375e+02 9.22630462646484375e+01,
              5.12349975585937500e+02 5.11428558349609375e+02 9.22630462646484375e+01,
              5.13299987792968750e+02 5.11428558349609375e+02 9.22630462646484375e+01,
              5.05700012207031250e+02 5.12380981445312500e+02 9.22630462646484375e+01,
              5.06649993896484375e+02 5.12380981445312500e+02 9.22630462646484375e+01,
              5.07600006103515625e+02 5.12380981445312500e+02 9.22630462646484375e+01,
              5.08549987792968750e+02 5.12380981445312500e+02 9.22630462646484375e+01,
              5.09500000000000000e+02 5.12380981445312500e+02 9.22630462646484375e+01,
              5.10450012207031250e+02 5.12380981445312500e+02 9.22630462646484375e+01,
              5.11399993896484375e+02 5.12380981445312500e+02 9.22630462646484375e+01,
              5.12349975585937500e+02 5.12380981445312500e+02 9.22630462646484375e+01,
              5.13299987792968750e+02 5.12380981445312500e+02 9.22630462646484375e+01,
              5.06649993896484375e+02 5.13333312988281250e+02 9.22630462646484375e+01,
              5.07600006103515625e+02 5.13333312988281250e+02 9.22630462646484375e+01,
              5.08549987792968750e+02 5.13333312988281250e+02 9.22630462646484375e+01,
              5.09500000000000000e+02 5.13333312988281250e+02 9.22630462646484375e+01,
              5.10450012207031250e+02 5.13333312988281250e+02 9.22630462646484375e+01,
              5.11399993896484375e+02 5.13333312988281250e+02 9.22630462646484375e+01,
              5.12349975585937500e+02 5.13333312988281250e+02 9.22630462646484375e+01,
              5.07600006103515625e+02 5.14285705566406250e+02 9.22630462646484375e+01,
              5.08549987792968750e+02 5.14285705566406250e+02 9.22630462646484375e+01,
              5.09500000000000000e+02 5.14285705566406250e+02 9.22630462646484375e+01,
              5.10450012207031250e+02 5.14285705566406250e+02 9.22630462646484375e+01,
              5.11399993896484375e+02 5.14285705566406250e+02 9.22630462646484375e+01,
              5.08549987792968750e+02 5.05714294433593750e+02 9.27521743774414063e+01,
              5.09500000000000000e+02 5.05714294433593750e+02 9.27521743774414063e+01,
              5.10450012207031250e+02 5.05714294433593750e+02 9.27521743774414063e+01,
              5.07600006103515625e+02 5.06666656494140625e+02 9.27521743774414063e+01,
              5.08549987792968750e+02 5.06666656494140625e+02 9.27521743774414063e+01,
              5.09500000000000000e+02 5.06666656494140625e+02 9.27521743774414063e+01,
              5.10450012207031250e+02 5.06666656494140625e+02 9.27521743774414063e+01,
              5.11399993896484375e+02 5.06666656494140625e+02 9.27521743774414063e+01,
              5.12349975585937500e+02 5.06666656494140625e+02 9.27521743774414063e+01,
              5.06649993896484375e+02 5.07619049072265625e+02 9.27521743774414063e+01,
              5.07600006103515625e+02 5.07619049072265625e+02 9.27521743774414063e+01,
              5.08549987792968750e+02 5.07619049072265625e+02 9.27521743774414063e+01,
              5.09500000000000000e+02 5.07619049072265625e+02 9.27521743774414063e+01,
              5.10450012207031250e+02 5.07619049072265625e+02 9.27521743774414063e+01,
              5.11399993896484375e+02 5.07619049072265625e+02 9.27521743774414063e+01,
              5.12349975585937500e+02 5.07619049072265625e+02 9.27521743774414063e+01,
              5.13299987792968750e+02 5.07619049072265625e+02 9.27521743774414063e+01,
              5.05700012207031250e+02 5.08571441650390625e+02 9.27521743774414063e+01,
              5.06649993896484375e+02 5.08571441650390625e+02 9.27521743774414063e+01,
              5.07600006103515625e+02 5.08571441650390625e+02 9.27521743774414063e+01,
              5.08549987792968750e+02 5.08571441650390625e+02 9.27521743774414063e+01,
              5.09500000000000000e+02 5.08571441650390625e+02 9.27521743774414063e+01,
              5.10450012207031250e+02 5.08571441650390625e+02 9.27521743774414063e+01,
              5.11399993896484375e+02 5.08571441650390625e+02 9.27521743774414063e+01,
              5.12349975585937500e+02 5.08571441650390625e+02 9.27521743774414063e+01,
              5.13299987792968750e+02 5.08571441650390625e+02 9.27521743774414063e+01,
              5.05700012207031250e+02 5.09523803710937500e+02 9.27521743774414063e+01,
              5.06649993896484375e+02 5.09523803710937500e+02 9.27521743774414063e+01,
              5.07600006103515625e+02 5.09523803710937500e+02 9.27521743774414063e+01,
              5.08549987792968750e+02 5.09523803710937500e+02 9.27521743774414063e+01,
              5.09500000000000000e+02 5.09523803710937500e+02 9.27521743774414063e+01,
              5.10450012207031250e+02 5.09523803710937500e+02 9.27521743774414063e+01,
              5.11399993896484375e+02 5.09523803710937500e+02 9.27521743774414063e+01,
              5.12349975585937500e+02 5.09523803710937500e+02 9.27521743774414063e+01,
              5.13299987792968750e+02 5.09523803710937500e+02 9.27521743774414063e+01,
              5.05700012207031250e+02 5.10476196289062500e+02 9.27521743774414063e+01,
              5.06649993896484375e+02 5.10476196289062500e+02 9.27521743774414063e+01,
              5.07600006103515625e+02 5.10476196289062500e+02 9.27521743774414063e+01,
              5.08549987792968750e+02 5.10476196289062500e+02 9.27521743774414063e+01,
              5.09500000000000000e+02 5.10476196289062500e+02 9.27521743774414063e+01,
              5.10450012207031250e+02 5.10476196289062500e+02 9.27521743774414063e+01,
              5.11399993896484375e+02 5.10476196289062500e+02 9.27521743774414063e+01,
              5.12349975585937500e+02 5.10476196289062500e+02 9.27521743774414063e+01,
              5.13299987792968750e+02 5.10476196289062500e+02 9.27521743774414063e+01,
              5.05700012207031250e+02 5.11428558349609375e+02 9.27521743774414063e+01,
              5.06649993896484375e+02 5.11428558349609375e+02 9.27521743774414063e+01,
              5.07600006103515625e+02 5.11428558349609375e+02 9.27521743774414063e+01,
              5.08549987792968750e+02 5.11428558349609375e+02 9.27521743774414063e+01,
              5.09500000000000000e+02 5.11428558349609375e+02 9.27521743774414063e+01,
              5.10450012207031250e+02 5.11428558349609375e+02 9.27521743774414063e+01,
              5.11399993896484375e+02 5.11428558349609375e+02 9.27521743774414063e+01,
              5.12349975585937500e+02 5.11428558349609375e+02 9.27521743774414063e+01,
              5.13299987792968750e+02 5.11428558349609375e+02 9.27521743774414063e+01,
              5.05700012207031250e+02 5.12380981445312500e+02 9.27521743774414063e+01,
              5.06649993896484375e+02 5.12380981445312500e+02 9.27521743774414063e+01,
              5.07600006103515625e+02 5.12380981445312500e+02 9.27521743774414063e+01,
              5.08549987792968750e+02 5.12380981445312500e+02 9.27521743774414063e+01,
              5.09500000000000000e+02 5.12380981445312500e+02 9.27521743774414063e+01,
              5.10450012207031250e+02 5.12380981445312500e+02 9.27521743774414063e+01,
              5.11399993896484375e+02 5.12380981445312500e+02 9.27521743774414063e+01,
              5.12349975585937500e+02 5.12380981445312500e+02 9.27521743774414063e+01,
              5.13299987792968750e+02 5.12380981445312500e+02 9.27521743774414063e+01,
              5.06649993896484375e+02 5.13333312988281250e+02 9.27521743774414063e+01,
              5.07600006103515625e+02 5.13333312988281250e+02 9.27521743774414063e+01,
              5.08549987792968750e+02 5.13333312988281250e+02 9.27521743774414063e+01,
              5.09500000000000000e+02 5.13333312988281250e+02 9.27521743774414063e+01,
              5.10450012207031250e+02 5.13333312988281250e+02 9.27521743774414063e+01,
              5.11399993896484375e+02 5.13333312988281250e+02 9.27521743774414063e+01,
              5.12349975585937500e+02 5.13333312988281250e+02 9.27521743774414063e+01,
              5.07600006103515625e+02 5.14285705566406250e+02 9.27521743774414063e+01,
              5.08549987792968750e+02 5.14285705566406250e+02 9.27521743774414063e+01,
              5.09500000000000000e+02 5.14285705566406250e+02 9.27521743774414063e+01,
              5.10450012207031250e+02 5.14285705566406250e+02 9.27521743774414063e+01,
              5.11399993896484375e+02 5.14285705566406250e+02 9.27521743774414063e+01,
              5.12349975585937500e+02 5.14285705566406250e+02 9.27521743774414063e+01,
              5.09500000000000000e+02 5.15238098144531250e+02 9.27521743774414063e+01,
              5.10450012207031250e+02 5.15238098144531250e+02 9.27521743774414063e+01,
              5.09500000000000000e+02 5.06666656494140625e+02 9.32413024902343750e+01,
              5.07600006103515625e+02 5.07619049072265625e+02 9.32413024902343750e+01,
              5.08549987792968750e+02 5.07619049072265625e+02 9.32413024902343750e+01,
              5.09500000000000000e+02 5.07619049072265625e+02 9.32413024902343750e+01,
              5.10450012207031250e+02 5.07619049072265625e+02 9.32413024902343750e+01,
              5.11399993896484375e+02 5.07619049072265625e+02 9.32413024902343750e+01,
              5.12349975585937500e+02 5.07619049072265625e+02 9.32413024902343750e+01,
              5.07600006103515625e+02 5.08571441650390625e+02 9.32413024902343750e+01,
              5.08549987792968750e+02 5.08571441650390625e+02 9.32413024902343750e+01,
              5.09500000000000000e+02 5.08571441650390625e+02 9.32413024902343750e+01,
              5.10450012207031250e+02 5.08571441650390625e+02 9.32413024902343750e+01,
              5.11399993896484375e+02 5.08571441650390625e+02 9.32413024902343750e+01,
              5.12349975585937500e+02 5.08571441650390625e+02 9.32413024902343750e+01,
              5.06649993896484375e+02 5.09523803710937500e+02 9.32413024902343750e+01,
              5.07600006103515625e+02 5.09523803710937500e+02 9.32413024902343750e+01,
              5.08549987792968750e+02 5.09523803710937500e+02 9.32413024902343750e+01,
              5.09500000000000000e+02 5.09523803710937500e+02 9.32413024902343750e+01,
              5.10450012207031250e+02 5.09523803710937500e+02 9.32413024902343750e+01,
              5.11399993896484375e+02 5.09523803710937500e+02 9.32413024902343750e+01,
              5.12349975585937500e+02 5.09523803710937500e+02 9.32413024902343750e+01,
              5.06649993896484375e+02 5.10476196289062500e+02 9.32413024902343750e+01,
              5.07600006103515625e+02 5.10476196289062500e+02 9.32413024902343750e+01,
              5.08549987792968750e+02 5.10476196289062500e+02 9.32413024902343750e+01,
              5.09500000000000000e+02 5.10476196289062500e+02 9.32413024902343750e+01,
              5.10450012207031250e+02 5.10476196289062500e+02 9.32413024902343750e+01,
              5.11399993896484375e+02 5.10476196289062500e+02 9.32413024902343750e+01,
              5.12349975585937500e+02 5.10476196289062500e+02 9.32413024902343750e+01,
              5.06649993896484375e+02 5.11428558349609375e+02 9.32413024902343750e+01,
              5.07600006103515625e+02 5.11428558349609375e+02 9.32413024902343750e+01,
              5.08549987792968750e+02 5.11428558349609375e+02 9.32413024902343750e+01,
              5.09500000000000000e+02 5.11428558349609375e+02 9.32413024902343750e+01,
              5.10450012207031250e+02 5.11428558349609375e+02 9.32413024902343750e+01,
              5.11399993896484375e+02 5.11428558349609375e+02 9.32413024902343750e+01,
              5.12349975585937500e+02 5.11428558349609375e+02 9.32413024902343750e+01,
              5.06649993896484375e+02 5.12380981445312500e+02 9.32413024902343750e+01,
              5.07600006103515625e+02 5.12380981445312500e+02 9.32413024902343750e+01,
              5.08549987792968750e+02 5.12380981445312500e+02 9.32413024902343750e+01,
              5.09500000000000000e+02 5.12380981445312500e+02 9.32413024902343750e+01,
              5.10450012207031250e+02 5.12380981445312500e+02 9.32413024902343750e+01,
              5.11399993896484375e+02 5.12380981445312500e+02 9.32413024902343750e+01,
              5.12349975585937500e+02 5.12380981445312500e+02 9.32413024902343750e+01,
              5.07600006103515625e+02 5.13333312988281250e+02 9.32413024902343750e+01,
              5.08549987792968750e+02 5.13333312988281250e+02 9.32413024902343750e+01,
              5.09500000000000000e+02 5.13333312988281250e+02 9.32413024902343750e+01,
              5.10450012207031250e+02 5.13333312988281250e+02 9.32413024902343750e+01,
              5.11399993896484375e+02 5.13333312988281250e+02 9.32413024902343750e+01,
              5.12349975585937500e+02 5.13333312988281250e+02 9.32413024902343750e+01,
              5.09500000000000000e+02 5.14285705566406250e+02 9.32413024902343750e+01,
              5.10450012207031250e+02 5.14285705566406250e+02 9.32413024902343750e+01,
              5.12349975585937500e+02 5.11428558349609375e+02 9.37304382324218750e+01,
              5.13299987792968750e+02 5.11428558349609375e+02 9.37304382324218750e+01,
              5.15200012207031250e+02 5.11428558349609375e+02 9.37304382324218750e+01,
              5.11399993896484375e+02 5.12380981445312500e+02 9.37304382324218750e+01,
              5.12349975585937500e+02 5.12380981445312500e+02 9.37304382324218750e+01,
              5.13299987792968750e+02 5.12380981445312500e+02 9.37304382324218750e+01,
              5.14250000000000000e+02 5.12380981445312500e+02 9.37304382324218750e+01,
              5.15200012207031250e+02 5.12380981445312500e+02 9.37304382324218750e+01,
              5.12349975585937500e+02 5.13333312988281250e+02 9.37304382324218750e+01,
              5.13299987792968750e+02 5.13333312988281250e+02 9.37304382324218750e+01,
              5.14250000000000000e+02 5.13333312988281250e+02 9.37304382324218750e+01,
              5.15200012207031250e+02 5.13333312988281250e+02 9.37304382324218750e+01,
              5.16150024414062500e+02 5.13333312988281250e+02 9.37304382324218750e+01,
              5.13299987792968750e+02 5.14285705566406250e+02 9.37304382324218750e+01,
              5.14250000000000000e+02 5.14285705566406250e+02 9.37304382324218750e+01,
              5.15200012207031250e+02 5.14285705566406250e+02 9.37304382324218750e+01,
              5.14250000000000000e+02 5.15238098144531250e+02 9.37304382324218750e+01,
              5.15200012207031250e+02 5.15238098144531250e+02 9.37304382324218750e+01,
              5.14250000000000000e+02 5.09523803710937500e+02 9.42195663452148438e+01,
              5.12349975585937500e+02 5.10476196289062500e+02 9.42195663452148438e+01,
              5.13299987792968750e+02 5.10476196289062500e+02 9.42195663452148438e+01,
              5.14250000000000000e+02 5.10476196289062500e+02 9.42195663452148438e+01,
              5.15200012207031250e+02 5.10476196289062500e+02 9.42195663452148438e+01,
              5.16150024414062500e+02 5.10476196289062500e+02 9.42195663452148438e+01,
              5.12349975585937500e+02 5.11428558349609375e+02 9.42195663452148438e+01,
              5.13299987792968750e+02 5.11428558349609375e+02 9.42195663452148438e+01,
              5.14250000000000000e+02 5.11428558349609375e+02 9.42195663452148438e+01,
              5.15200012207031250e+02 5.11428558349609375e+02 9.42195663452148438e+01,
              5.16150024414062500e+02 5.11428558349609375e+02 9.42195663452148438e+01,
              5.17099975585937500e+02 5.11428558349609375e+02 9.42195663452148438e+01,
              5.12349975585937500e+02 5.12380981445312500e+02 9.42195663452148438e+01,
              5.13299987792968750e+02 5.12380981445312500e+02 9.42195663452148438e+01,
              5.14250000000000000e+02 5.12380981445312500e+02 9.42195663452148438e+01,
              5.15200012207031250e+02 5.12380981445312500e+02 9.42195663452148438e+01,
              5.16150024414062500e+02 5.12380981445312500e+02 9.42195663452148438e+01,
              5.17099975585937500e+02 5.12380981445312500e+02 9.42195663452148438e+01,
              5.12349975585937500e+02 5.13333312988281250e+02 9.42195663452148438e+01,
              5.13299987792968750e+02 5.13333312988281250e+02 9.42195663452148438e+01,
              5.14250000000000000e+02 5.13333312988281250e+02 9.42195663452148438e+01,
              5.15200012207031250e+02 5.13333312988281250e+02 9.42195663452148438e+01,
              5.16150024414062500e+02 5.13333312988281250e+02 9.42195663452148438e+01,
              5.17099975585937500e+02 5.13333312988281250e+02 9.42195663452148438e+01,
              5.12349975585937500e+02 5.14285705566406250e+02 9.42195663452148438e+01,
              5.13299987792968750e+02 5.14285705566406250e+02 9.42195663452148438e+01,
              5.14250000000000000e+02 5.14285705566406250e+02 9.42195663452148438e+01,
              5.15200012207031250e+02 5.14285705566406250e+02 9.42195663452148438e+01,
              5.16150024414062500e+02 5.14285705566406250e+02 9.42195663452148438e+01,
              5.17099975585937500e+02 5.14285705566406250e+02 9.42195663452148438e+01,
              5.13299987792968750e+02 5.15238098144531250e+02 9.42195663452148438e+01,
              5.14250000000000000e+02 5.15238098144531250e+02 9.42195663452148438e+01,
              5.15200012207031250e+02 5.15238098144531250e+02 9.42195663452148438e+01,
              5.16150024414062500e+02 5.15238098144531250e+02 9.42195663452148438e+01,
              5.03799987792968750e+02 5.07619049072265625e+02 9.47086944580078125e+01,
              5.13299987792968750e+02 5.10476196289062500e+02 9.47086944580078125e+01,
              5.14250000000000000e+02 5.10476196289062500e+02 9.47086944580078125e+01,
              5.15200012207031250e+02 5.10476196289062500e+02 9.47086944580078125e+01,
              5.16150024414062500e+02 5.10476196289062500e+02 9.47086944580078125e+01,
              5.12349975585937500e+02 5.11428558349609375e+02 9.47086944580078125e+01,
              5.13299987792968750e+02 5.11428558349609375e+02 9.47086944580078125e+01,
              5.14250000000000000e+02 5.11428558349609375e+02 9.47086944580078125e+01,
              5.15200012207031250e+02 5.11428558349609375e+02 9.47086944580078125e+01,
              5.16150024414062500e+02 5.11428558349609375e+02 9.47086944580078125e+01,
              5.12349975585937500e+02 5.12380981445312500e+02 9.47086944580078125e+01,
              5.13299987792968750e+02 5.12380981445312500e+02 9.47086944580078125e+01,
              5.14250000000000000e+02 5.12380981445312500e+02 9.47086944580078125e+01,
              5.15200012207031250e+02 5.12380981445312500e+02 9.47086944580078125e+01,
              5.16150024414062500e+02 5.12380981445312500e+02 9.47086944580078125e+01,
              5.17099975585937500e+02 5.12380981445312500e+02 9.47086944580078125e+01,
              5.12349975585937500e+02 5.13333312988281250e+02 9.47086944580078125e+01,
              5.13299987792968750e+02 5.13333312988281250e+02 9.47086944580078125e+01,
              5.14250000000000000e+02 5.13333312988281250e+02 9.47086944580078125e+01,
              5.15200012207031250e+02 5.13333312988281250e+02 9.47086944580078125e+01,
              5.16150024414062500e+02 5.13333312988281250e+02 9.47086944580078125e+01,
              5.17099975585937500e+02 5.13333312988281250e+02 9.47086944580078125e+01,
              5.12349975585937500e+02 5.14285705566406250e+02 9.47086944580078125e+01,
              5.13299987792968750e+02 5.14285705566406250e+02 9.47086944580078125e+01,
              5.14250000000000000e+02 5.14285705566406250e+02 9.47086944580078125e+01,
              5.15200012207031250e+02 5.14285705566406250e+02 9.47086944580078125e+01,
              5.16150024414062500e+02 5.14285705566406250e+02 9.47086944580078125e+01,
              5.02850006103515625e+02 5.02857147216796875e+02 9.51978225708007813e+01,
              5.03799987792968750e+02 5.02857147216796875e+02 9.51978225708007813e+01,
              5.01899993896484375e+02 5.03809509277343750e+02 9.51978225708007813e+01,
              5.02850006103515625e+02 5.03809509277343750e+02 9.51978225708007813e+01,
              5.03799987792968750e+02 5.03809509277343750e+02 9.51978225708007813e+01,
              5.04750000000000000e+02 5.03809509277343750e+02 9.51978225708007813e+01,
              5.05700012207031250e+02 5.03809509277343750e+02 9.51978225708007813e+01,
              5.06649993896484375e+02 5.03809509277343750e+02 9.51978225708007813e+01,
              5.01899993896484375e+02 5.04761901855468750e+02 9.51978225708007813e+01,
              5.02850006103515625e+02 5.04761901855468750e+02 9.51978225708007813e+01,
              5.03799987792968750e+02 5.04761901855468750e+02 9.51978225708007813e+01,
              5.04750000000000000e+02 5.04761901855468750e+02 9.51978225708007813e+01,
              5.05700012207031250e+02 5.04761901855468750e+02 9.51978225708007813e+01,
              5.06649993896484375e+02 5.04761901855468750e+02 9.51978225708007813e+01,
              5.07600006103515625e+02 5.04761901855468750e+02 9.51978225708007813e+01,
              5.00950012207031250e+02 5.05714294433593750e+02 9.51978225708007813e+01,
              5.01899993896484375e+02 5.05714294433593750e+02 9.51978225708007813e+01,
              5.02850006103515625e+02 5.05714294433593750e+02 9.51978225708007813e+01,
              5.03799987792968750e+02 5.05714294433593750e+02 9.51978225708007813e+01,
              5.04750000000000000e+02 5.05714294433593750e+02 9.51978225708007813e+01,
              5.05700012207031250e+02 5.05714294433593750e+02 9.51978225708007813e+01,
              5.06649993896484375e+02 5.05714294433593750e+02 9.51978225708007813e+01,
              5.07600006103515625e+02 5.05714294433593750e+02 9.51978225708007813e+01,
              5.00950012207031250e+02 5.06666656494140625e+02 9.51978225708007813e+01,
              5.01899993896484375e+02 5.06666656494140625e+02 9.51978225708007813e+01,
              5.02850006103515625e+02 5.06666656494140625e+02 9.51978225708007813e+01,
              5.03799987792968750e+02 5.06666656494140625e+02 9.51978225708007813e+01,
              5.04750000000000000e+02 5.06666656494140625e+02 9.51978225708007813e+01,
              5.05700012207031250e+02 5.06666656494140625e+02 9.51978225708007813e+01,
              5.06649993896484375e+02 5.06666656494140625e+02 9.51978225708007813e+01,
              5.07600006103515625e+02 5.06666656494140625e+02 9.51978225708007813e+01,
              5.08549987792968750e+02 5.06666656494140625e+02 9.51978225708007813e+01,
              5.00950012207031250e+02 5.07619049072265625e+02 9.51978225708007813e+01,
              5.01899993896484375e+02 5.07619049072265625e+02 9.51978225708007813e+01,
              5.02850006103515625e+02 5.07619049072265625e+02 9.51978225708007813e+01,
              5.03799987792968750e+02 5.07619049072265625e+02 9.51978225708007813e+01,
              5.04750000000000000e+02 5.07619049072265625e+02 9.51978225708007813e+01,
              5.05700012207031250e+02 5.07619049072265625e+02 9.51978225708007813e+01,
              5.06649993896484375e+02 5.07619049072265625e+02 9.51978225708007813e+01,
              5.07600006103515625e+02 5.07619049072265625e+02 9.51978225708007813e+01,
              5.08549987792968750e+02 5.07619049072265625e+02 9.51978225708007813e+01,
              5.01899993896484375e+02 5.08571441650390625e+02 9.51978225708007813e+01,
              5.02850006103515625e+02 5.08571441650390625e+02 9.51978225708007813e+01,
              5.03799987792968750e+02 5.08571441650390625e+02 9.51978225708007813e+01,
              5.04750000000000000e+02 5.08571441650390625e+02 9.51978225708007813e+01,
              5.05700012207031250e+02 5.08571441650390625e+02 9.51978225708007813e+01,
              5.06649993896484375e+02 5.08571441650390625e+02 9.51978225708007813e+01,
              5.07600006103515625e+02 5.08571441650390625e+02 9.51978225708007813e+01,
              5.01899993896484375e+02 5.09523803710937500e+02 9.51978225708007813e+01,
              5.02850006103515625e+02 5.09523803710937500e+02 9.51978225708007813e+01,
              5.03799987792968750e+02 5.09523803710937500e+02 9.51978225708007813e+01,
              5.04750000000000000e+02 5.09523803710937500e+02 9.51978225708007813e+01,
              5.05700012207031250e+02 5.09523803710937500e+02 9.51978225708007813e+01,
              5.06649993896484375e+02 5.09523803710937500e+02 9.51978225708007813e+01,
              5.07600006103515625e+02 5.09523803710937500e+02 9.51978225708007813e+01,
              5.02850006103515625e+02 5.10476196289062500e+02 9.51978225708007813e+01,
              5.03799987792968750e+02 5.10476196289062500e+02 9.51978225708007813e+01,
              5.04750000000000000e+02 5.10476196289062500e+02 9.51978225708007813e+01,
              5.05700012207031250e+02 5.10476196289062500e+02 9.51978225708007813e+01,
              5.06649993896484375e+02 5.10476196289062500e+02 9.51978225708007813e+01,
              5.07600006103515625e+02 5.10476196289062500e+02 9.51978225708007813e+01,
              5.03799987792968750e+02 5.11428558349609375e+02 9.51978225708007813e+01,
              5.04750000000000000e+02 5.11428558349609375e+02 9.51978225708007813e+01,
              5.05700012207031250e+02 5.11428558349609375e+02 9.51978225708007813e+01,
              5.06649993896484375e+02 5.11428558349609375e+02 9.51978225708007813e+01,
              5.15200012207031250e+02 5.11428558349609375e+02 9.51978225708007813e+01,
              5.15200012207031250e+02 5.12380981445312500e+02 9.51978225708007813e+01,
              5.01899993896484375e+02 5.02857147216796875e+02 9.56869583129882813e+01,
              5.02850006103515625e+02 5.02857147216796875e+02 9.56869583129882813e+01,
              5.03799987792968750e+02 5.02857147216796875e+02 9.56869583129882813e+01,
              5.04750000000000000e+02 5.02857147216796875e+02 9.56869583129882813e+01,
              5.05700012207031250e+02 5.02857147216796875e+02 9.56869583129882813e+01,
              5.06649993896484375e+02 5.02857147216796875e+02 9.56869583129882813e+01,
              5.07600006103515625e+02 5.02857147216796875e+02 9.56869583129882813e+01,
              5.00950012207031250e+02 5.03809509277343750e+02 9.56869583129882813e+01,
              5.01899993896484375e+02 5.03809509277343750e+02 9.56869583129882813e+01,
              5.02850006103515625e+02 5.03809509277343750e+02 9.56869583129882813e+01,
              5.03799987792968750e+02 5.03809509277343750e+02 9.56869583129882813e+01,
              5.04750000000000000e+02 5.03809509277343750e+02 9.56869583129882813e+01,
              5.05700012207031250e+02 5.03809509277343750e+02 9.56869583129882813e+01,
              5.06649993896484375e+02 5.03809509277343750e+02 9.56869583129882813e+01,
              5.07600006103515625e+02 5.03809509277343750e+02 9.56869583129882813e+01,
              5.08549987792968750e+02 5.03809509277343750e+02 9.56869583129882813e+01,
              5.00000000000000000e+02 5.04761901855468750e+02 9.56869583129882813e+01,
              5.00950012207031250e+02 5.04761901855468750e+02 9.56869583129882813e+01,
              5.01899993896484375e+02 5.04761901855468750e+02 9.56869583129882813e+01,
              5.02850006103515625e+02 5.04761901855468750e+02 9.56869583129882813e+01,
              5.03799987792968750e+02 5.04761901855468750e+02 9.56869583129882813e+01,
              5.04750000000000000e+02 5.04761901855468750e+02 9.56869583129882813e+01,
              5.05700012207031250e+02 5.04761901855468750e+02 9.56869583129882813e+01,
              5.06649993896484375e+02 5.04761901855468750e+02 9.56869583129882813e+01,
              5.07600006103515625e+02 5.04761901855468750e+02 9.56869583129882813e+01,
              5.08549987792968750e+02 5.04761901855468750e+02 9.56869583129882813e+01,
              5.00000000000000000e+02 5.05714294433593750e+02 9.56869583129882813e+01,
              5.00950012207031250e+02 5.05714294433593750e+02 9.56869583129882813e+01,
              5.01899993896484375e+02 5.05714294433593750e+02 9.56869583129882813e+01,
              5.02850006103515625e+02 5.05714294433593750e+02 9.56869583129882813e+01,
              5.03799987792968750e+02 5.05714294433593750e+02 9.56869583129882813e+01,
              5.04750000000000000e+02 5.05714294433593750e+02 9.56869583129882813e+01,
              5.05700012207031250e+02 5.05714294433593750e+02 9.56869583129882813e+01,
              5.06649993896484375e+02 5.05714294433593750e+02 9.56869583129882813e+01,
              5.07600006103515625e+02 5.05714294433593750e+02 9.56869583129882813e+01,
              5.08549987792968750e+02 5.05714294433593750e+02 9.56869583129882813e+01,
              5.09500000000000000e+02 5.05714294433593750e+02 9.56869583129882813e+01,
              5.00000000000000000e+02 5.06666656494140625e+02 9.56869583129882813e+01,
              5.00950012207031250e+02 5.06666656494140625e+02 9.56869583129882813e+01,
              5.01899993896484375e+02 5.06666656494140625e+02 9.56869583129882813e+01,
              5.02850006103515625e+02 5.06666656494140625e+02 9.56869583129882813e+01,
              5.03799987792968750e+02 5.06666656494140625e+02 9.56869583129882813e+01,
              5.04750000000000000e+02 5.06666656494140625e+02 9.56869583129882813e+01,
              5.05700012207031250e+02 5.06666656494140625e+02 9.56869583129882813e+01,
              5.06649993896484375e+02 5.06666656494140625e+02 9.56869583129882813e+01,
              5.07600006103515625e+02 5.06666656494140625e+02 9.56869583129882813e+01,
              5.08549987792968750e+02 5.06666656494140625e+02 9.56869583129882813e+01,
              5.09500000000000000e+02 5.06666656494140625e+02 9.56869583129882813e+01,
              5.10450012207031250e+02 5.06666656494140625e+02 9.56869583129882813e+01,
              5.00000000000000000e+02 5.07619049072265625e+02 9.56869583129882813e+01,
              5.00950012207031250e+02 5.07619049072265625e+02 9.56869583129882813e+01,
              5.01899993896484375e+02 5.07619049072265625e+02 9.56869583129882813e+01,
              5.02850006103515625e+02 5.07619049072265625e+02 9.56869583129882813e+01,
              5.03799987792968750e+02 5.07619049072265625e+02 9.56869583129882813e+01,
              5.04750000000000000e+02 5.07619049072265625e+02 9.56869583129882813e+01,
              5.05700012207031250e+02 5.07619049072265625e+02 9.56869583129882813e+01,
              5.06649993896484375e+02 5.07619049072265625e+02 9.56869583129882813e+01,
              5.07600006103515625e+02 5.07619049072265625e+02 9.56869583129882813e+01,
              5.08549987792968750e+02 5.07619049072265625e+02 9.56869583129882813e+01,
              5.09500000000000000e+02 5.07619049072265625e+02 9.56869583129882813e+01,
              5.10450012207031250e+02 5.07619049072265625e+02 9.56869583129882813e+01,
              5.00000000000000000e+02 5.08571441650390625e+02 9.56869583129882813e+01,
              5.00950012207031250e+02 5.08571441650390625e+02 9.56869583129882813e+01,
              5.01899993896484375e+02 5.08571441650390625e+02 9.56869583129882813e+01,
              5.02850006103515625e+02 5.08571441650390625e+02 9.56869583129882813e+01,
              5.03799987792968750e+02 5.08571441650390625e+02 9.56869583129882813e+01,
              5.04750000000000000e+02 5.08571441650390625e+02 9.56869583129882813e+01,
              5.05700012207031250e+02 5.08571441650390625e+02 9.56869583129882813e+01,
              5.06649993896484375e+02 5.08571441650390625e+02 9.56869583129882813e+01,
              5.07600006103515625e+02 5.08571441650390625e+02 9.56869583129882813e+01,
              5.08549987792968750e+02 5.08571441650390625e+02 9.56869583129882813e+01,
              5.09500000000000000e+02 5.08571441650390625e+02 9.56869583129882813e+01,
              5.10450012207031250e+02 5.08571441650390625e+02 9.56869583129882813e+01,
              5.11399993896484375e+02 5.08571441650390625e+02 9.56869583129882813e+01,
              5.00950012207031250e+02 5.09523803710937500e+02 9.56869583129882813e+01,
              5.01899993896484375e+02 5.09523803710937500e+02 9.56869583129882813e+01,
              5.02850006103515625e+02 5.09523803710937500e+02 9.56869583129882813e+01,
              5.03799987792968750e+02 5.09523803710937500e+02 9.56869583129882813e+01,
              5.04750000000000000e+02 5.09523803710937500e+02 9.56869583129882813e+01,
              5.05700012207031250e+02 5.09523803710937500e+02 9.56869583129882813e+01,
              5.06649993896484375e+02 5.09523803710937500e+02 9.56869583129882813e+01,
              5.07600006103515625e+02 5.09523803710937500e+02 9.56869583129882813e+01,
              5.08549987792968750e+02 5.09523803710937500e+02 9.56869583129882813e+01,
              5.09500000000000000e+02 5.09523803710937500e+02 9.56869583129882813e+01,
              5.10450012207031250e+02 5.09523803710937500e+02 9.56869583129882813e+01,
              5.11399993896484375e+02 5.09523803710937500e+02 9.56869583129882813e+01,
              5.01899993896484375e+02 5.10476196289062500e+02 9.56869583129882813e+01,
              5.02850006103515625e+02 5.10476196289062500e+02 9.56869583129882813e+01,
              5.03799987792968750e+02 5.10476196289062500e+02 9.56869583129882813e+01,
              5.04750000000000000e+02 5.10476196289062500e+02 9.56869583129882813e+01,
              5.05700012207031250e+02 5.10476196289062500e+02 9.56869583129882813e+01,
              5.06649993896484375e+02 5.10476196289062500e+02 9.56869583129882813e+01,
              5.07600006103515625e+02 5.10476196289062500e+02 9.56869583129882813e+01,
              5.08549987792968750e+02 5.10476196289062500e+02 9.56869583129882813e+01,
              5.09500000000000000e+02 5.10476196289062500e+02 9.56869583129882813e+01,
              5.10450012207031250e+02 5.10476196289062500e+02 9.56869583129882813e+01,
              5.11399993896484375e+02 5.10476196289062500e+02 9.56869583129882813e+01,
              5.02850006103515625e+02 5.11428558349609375e+02 9.56869583129882813e+01,
              5.03799987792968750e+02 5.11428558349609375e+02 9.56869583129882813e+01,
              5.04750000000000000e+02 5.11428558349609375e+02 9.56869583129882813e+01,
              5.05700012207031250e+02 5.11428558349609375e+02 9.56869583129882813e+01,
              5.06649993896484375e+02 5.11428558349609375e+02 9.56869583129882813e+01,
              5.07600006103515625e+02 5.11428558349609375e+02 9.56869583129882813e+01,
              5.08549987792968750e+02 5.11428558349609375e+02 9.56869583129882813e+01,
              5.09500000000000000e+02 5.11428558349609375e+02 9.56869583129882813e+01,
              5.10450012207031250e+02 5.11428558349609375e+02 9.56869583129882813e+01,
              5.11399993896484375e+02 5.11428558349609375e+02 9.56869583129882813e+01,
              5.02850006103515625e+02 5.12380981445312500e+02 9.56869583129882813e+01,
              5.03799987792968750e+02 5.12380981445312500e+02 9.56869583129882813e+01,
              5.04750000000000000e+02 5.12380981445312500e+02 9.56869583129882813e+01,
              5.05700012207031250e+02 5.12380981445312500e+02 9.56869583129882813e+01,
              5.06649993896484375e+02 5.12380981445312500e+02 9.56869583129882813e+01,
              5.07600006103515625e+02 5.12380981445312500e+02 9.56869583129882813e+01,
              5.08549987792968750e+02 5.12380981445312500e+02 9.56869583129882813e+01,
              5.09500000000000000e+02 5.12380981445312500e+02 9.56869583129882813e+01,
              5.10450012207031250e+02 5.12380981445312500e+02 9.56869583129882813e+01,
              5.04750000000000000e+02 5.13333312988281250e+02 9.56869583129882813e+01,
              5.05700012207031250e+02 5.13333312988281250e+02 9.56869583129882813e+01,
              5.06649993896484375e+02 5.13333312988281250e+02 9.56869583129882813e+01,
              5.07600006103515625e+02 5.13333312988281250e+02 9.56869583129882813e+01,
              5.08549987792968750e+02 5.13333312988281250e+02 9.56869583129882813e+01,
              5.09500000000000000e+02 5.13333312988281250e+02 9.56869583129882813e+01,
              5.03799987792968750e+02 5.02857147216796875e+02 9.61760864257812500e+01,
              5.04750000000000000e+02 5.02857147216796875e+02 9.61760864257812500e+01,
              5.05700012207031250e+02 5.02857147216796875e+02 9.61760864257812500e+01,
              5.06649993896484375e+02 5.02857147216796875e+02 9.61760864257812500e+01,
              5.01899993896484375e+02 5.03809509277343750e+02 9.61760864257812500e+01,
              5.02850006103515625e+02 5.03809509277343750e+02 9.61760864257812500e+01,
              5.03799987792968750e+02 5.03809509277343750e+02 9.61760864257812500e+01,
              5.04750000000000000e+02 5.03809509277343750e+02 9.61760864257812500e+01,
              5.05700012207031250e+02 5.03809509277343750e+02 9.61760864257812500e+01,
              5.06649993896484375e+02 5.03809509277343750e+02 9.61760864257812500e+01,
              5.07600006103515625e+02 5.03809509277343750e+02 9.61760864257812500e+01,
              5.08549987792968750e+02 5.03809509277343750e+02 9.61760864257812500e+01,
              5.00950012207031250e+02 5.04761901855468750e+02 9.61760864257812500e+01,
              5.01899993896484375e+02 5.04761901855468750e+02 9.61760864257812500e+01,
              5.02850006103515625e+02 5.04761901855468750e+02 9.61760864257812500e+01,
              5.03799987792968750e+02 5.04761901855468750e+02 9.61760864257812500e+01,
              5.04750000000000000e+02 5.04761901855468750e+02 9.61760864257812500e+01,
              5.05700012207031250e+02 5.04761901855468750e+02 9.61760864257812500e+01,
              5.06649993896484375e+02 5.04761901855468750e+02 9.61760864257812500e+01,
              5.07600006103515625e+02 5.04761901855468750e+02 9.61760864257812500e+01,
              5.08549987792968750e+02 5.04761901855468750e+02 9.61760864257812500e+01,
              5.09500000000000000e+02 5.04761901855468750e+02 9.61760864257812500e+01,
              5.00950012207031250e+02 5.05714294433593750e+02 9.61760864257812500e+01,
              5.01899993896484375e+02 5.05714294433593750e+02 9.61760864257812500e+01,
              5.02850006103515625e+02 5.05714294433593750e+02 9.61760864257812500e+01,
              5.03799987792968750e+02 5.05714294433593750e+02 9.61760864257812500e+01,
              5.04750000000000000e+02 5.05714294433593750e+02 9.61760864257812500e+01,
              5.05700012207031250e+02 5.05714294433593750e+02 9.61760864257812500e+01,
              5.06649993896484375e+02 5.05714294433593750e+02 9.61760864257812500e+01,
              5.07600006103515625e+02 5.05714294433593750e+02 9.61760864257812500e+01,
              5.08549987792968750e+02 5.05714294433593750e+02 9.61760864257812500e+01,
              5.09500000000000000e+02 5.05714294433593750e+02 9.61760864257812500e+01,
              5.00000000000000000e+02 5.06666656494140625e+02 9.61760864257812500e+01,
              5.00950012207031250e+02 5.06666656494140625e+02 9.61760864257812500e+01,
              5.01899993896484375e+02 5.06666656494140625e+02 9.61760864257812500e+01,
              5.02850006103515625e+02 5.06666656494140625e+02 9.61760864257812500e+01,
              5.03799987792968750e+02 5.06666656494140625e+02 9.61760864257812500e+01,
              5.04750000000000000e+02 5.06666656494140625e+02 9.61760864257812500e+01,
              5.05700012207031250e+02 5.06666656494140625e+02 9.61760864257812500e+01,
              5.06649993896484375e+02 5.06666656494140625e+02 9.61760864257812500e+01,
              5.07600006103515625e+02 5.06666656494140625e+02 9.61760864257812500e+01,
              5.08549987792968750e+02 5.06666656494140625e+02 9.61760864257812500e+01,
              5.09500000000000000e+02 5.06666656494140625e+02 9.61760864257812500e+01,
              5.10450012207031250e+02 5.06666656494140625e+02 9.61760864257812500e+01,
              5.00000000000000000e+02 5.07619049072265625e+02 9.61760864257812500e+01,
              5.00950012207031250e+02 5.07619049072265625e+02 9.61760864257812500e+01,
              5.01899993896484375e+02 5.07619049072265625e+02 9.61760864257812500e+01,
              5.02850006103515625e+02 5.07619049072265625e+02 9.61760864257812500e+01,
              5.03799987792968750e+02 5.07619049072265625e+02 9.61760864257812500e+01,
              5.04750000000000000e+02 5.07619049072265625e+02 9.61760864257812500e+01,
              5.05700012207031250e+02 5.07619049072265625e+02 9.61760864257812500e+01,
              5.06649993896484375e+02 5.07619049072265625e+02 9.61760864257812500e+01,
              5.07600006103515625e+02 5.07619049072265625e+02 9.61760864257812500e+01,
              5.08549987792968750e+02 5.07619049072265625e+02 9.61760864257812500e+01,
              5.09500000000000000e+02 5.07619049072265625e+02 9.61760864257812500e+01,
              5.10450012207031250e+02 5.07619049072265625e+02 9.61760864257812500e+01,
              5.11399993896484375e+02 5.07619049072265625e+02 9.61760864257812500e+01,
              5.00000000000000000e+02 5.08571441650390625e+02 9.61760864257812500e+01,
              5.00950012207031250e+02 5.08571441650390625e+02 9.61760864257812500e+01,
              5.01899993896484375e+02 5.08571441650390625e+02 9.61760864257812500e+01,
              5.02850006103515625e+02 5.08571441650390625e+02 9.61760864257812500e+01,
              5.03799987792968750e+02 5.08571441650390625e+02 9.61760864257812500e+01,
              5.04750000000000000e+02 5.08571441650390625e+02 9.61760864257812500e+01,
              5.05700012207031250e+02 5.08571441650390625e+02 9.61760864257812500e+01,
              5.06649993896484375e+02 5.08571441650390625e+02 9.61760864257812500e+01,
              5.07600006103515625e+02 5.08571441650390625e+02 9.61760864257812500e+01,
              5.08549987792968750e+02 5.08571441650390625e+02 9.61760864257812500e+01,
              5.09500000000000000e+02 5.08571441650390625e+02 9.61760864257812500e+01,
              5.10450012207031250e+02 5.08571441650390625e+02 9.61760864257812500e+01,
              5.11399993896484375e+02 5.08571441650390625e+02 9.61760864257812500e+01,
              5.12349975585937500e+02 5.08571441650390625e+02 9.61760864257812500e+01,
              5.00950012207031250e+02 5.09523803710937500e+02 9.61760864257812500e+01,
              5.01899993896484375e+02 5.09523803710937500e+02 9.61760864257812500e+01,
              5.02850006103515625e+02 5.09523803710937500e+02 9.61760864257812500e+01,
              5.03799987792968750e+02 5.09523803710937500e+02 9.61760864257812500e+01,
              5.04750000000000000e+02 5.09523803710937500e+02 9.61760864257812500e+01,
              5.05700012207031250e+02 5.09523803710937500e+02 9.61760864257812500e+01,
              5.06649993896484375e+02 5.09523803710937500e+02 9.61760864257812500e+01,
              5.07600006103515625e+02 5.09523803710937500e+02 9.61760864257812500e+01,
              5.08549987792968750e+02 5.09523803710937500e+02 9.61760864257812500e+01,
              5.09500000000000000e+02 5.09523803710937500e+02 9.61760864257812500e+01,
              5.10450012207031250e+02 5.09523803710937500e+02 9.61760864257812500e+01,
              5.11399993896484375e+02 5.09523803710937500e+02 9.61760864257812500e+01,
              5.12349975585937500e+02 5.09523803710937500e+02 9.61760864257812500e+01,
              5.01899993896484375e+02 5.10476196289062500e+02 9.61760864257812500e+01,
              5.02850006103515625e+02 5.10476196289062500e+02 9.61760864257812500e+01,
              5.03799987792968750e+02 5.10476196289062500e+02 9.61760864257812500e+01,
              5.04750000000000000e+02 5.10476196289062500e+02 9.61760864257812500e+01,
              5.05700012207031250e+02 5.10476196289062500e+02 9.61760864257812500e+01,
              5.06649993896484375e+02 5.10476196289062500e+02 9.61760864257812500e+01,
              5.07600006103515625e+02 5.10476196289062500e+02 9.61760864257812500e+01,
              5.08549987792968750e+02 5.10476196289062500e+02 9.61760864257812500e+01,
              5.09500000000000000e+02 5.10476196289062500e+02 9.61760864257812500e+01,
              5.10450012207031250e+02 5.10476196289062500e+02 9.61760864257812500e+01,
              5.11399993896484375e+02 5.10476196289062500e+02 9.61760864257812500e+01,
              5.12349975585937500e+02 5.10476196289062500e+02 9.61760864257812500e+01,
              5.02850006103515625e+02 5.11428558349609375e+02 9.61760864257812500e+01,
              5.03799987792968750e+02 5.11428558349609375e+02 9.61760864257812500e+01,
              5.04750000000000000e+02 5.11428558349609375e+02 9.61760864257812500e+01,
              5.05700012207031250e+02 5.11428558349609375e+02 9.61760864257812500e+01,
              5.06649993896484375e+02 5.11428558349609375e+02 9.61760864257812500e+01,
              5.07600006103515625e+02 5.11428558349609375e+02 9.61760864257812500e+01,
              5.08549987792968750e+02 5.11428558349609375e+02 9.61760864257812500e+01,
              5.09500000000000000e+02 5.11428558349609375e+02 9.61760864257812500e+01,
              5.10450012207031250e+02 5.11428558349609375e+02 9.61760864257812500e+01,
              5.11399993896484375e+02 5.11428558349609375e+02 9.61760864257812500e+01,
              5.03799987792968750e+02 5.12380981445312500e+02 9.61760864257812500e+01,
              5.04750000000000000e+02 5.12380981445312500e+02 9.61760864257812500e+01,
              5.05700012207031250e+02 5.12380981445312500e+02 9.61760864257812500e+01,
              5.06649993896484375e+02 5.12380981445312500e+02 9.61760864257812500e+01,
              5.07600006103515625e+02 5.12380981445312500e+02 9.61760864257812500e+01,
              5.08549987792968750e+02 5.12380981445312500e+02 9.61760864257812500e+01,
              5.09500000000000000e+02 5.12380981445312500e+02 9.61760864257812500e+01,
              5.10450012207031250e+02 5.12380981445312500e+02 9.61760864257812500e+01,
              5.11399993896484375e+02 5.12380981445312500e+02 9.61760864257812500e+01,
              5.04750000000000000e+02 5.13333312988281250e+02 9.61760864257812500e+01,
              5.05700012207031250e+02 5.13333312988281250e+02 9.61760864257812500e+01,
              5.06649993896484375e+02 5.13333312988281250e+02 9.61760864257812500e+01,
              5.07600006103515625e+02 5.13333312988281250e+02 9.61760864257812500e+01,
              5.08549987792968750e+02 5.13333312988281250e+02 9.61760864257812500e+01,
              5.09500000000000000e+02 5.13333312988281250e+02 9.61760864257812500e+01,
              5.10450012207031250e+02 5.13333312988281250e+02 9.61760864257812500e+01,
              5.04750000000000000e+02 5.04761901855468750e+02 9.66652145385742188e+01,
              5.05700012207031250e+02 5.04761901855468750e+02 9.66652145385742188e+01,
              5.06649993896484375e+02 5.04761901855468750e+02 9.66652145385742188e+01,
              5.04750000000000000e+02 5.05714294433593750e+02 9.66652145385742188e+01,
              5.05700012207031250e+02 5.05714294433593750e+02 9.66652145385742188e+01,
              5.06649993896484375e+02 5.05714294433593750e+02 9.66652145385742188e+01,
              5.07600006103515625e+02 5.05714294433593750e+02 9.66652145385742188e+01,
              5.08549987792968750e+02 5.05714294433593750e+02 9.66652145385742188e+01,
              5.02850006103515625e+02 5.06666656494140625e+02 9.66652145385742188e+01,
              5.03799987792968750e+02 5.06666656494140625e+02 9.66652145385742188e+01,
              5.04750000000000000e+02 5.06666656494140625e+02 9.66652145385742188e+01,
              5.05700012207031250e+02 5.06666656494140625e+02 9.66652145385742188e+01,
              5.06649993896484375e+02 5.06666656494140625e+02 9.66652145385742188e+01,
              5.07600006103515625e+02 5.06666656494140625e+02 9.66652145385742188e+01,
              5.08549987792968750e+02 5.06666656494140625e+02 9.66652145385742188e+01,
              5.09500000000000000e+02 5.06666656494140625e+02 9.66652145385742188e+01,
              5.10450012207031250e+02 5.06666656494140625e+02 9.66652145385742188e+01,
              5.02850006103515625e+02 5.07619049072265625e+02 9.66652145385742188e+01,
              5.03799987792968750e+02 5.07619049072265625e+02 9.66652145385742188e+01,
              5.04750000000000000e+02 5.07619049072265625e+02 9.66652145385742188e+01,
              5.05700012207031250e+02 5.07619049072265625e+02 9.66652145385742188e+01,
              5.06649993896484375e+02 5.07619049072265625e+02 9.66652145385742188e+01,
              5.07600006103515625e+02 5.07619049072265625e+02 9.66652145385742188e+01,
              5.08549987792968750e+02 5.07619049072265625e+02 9.66652145385742188e+01,
              5.09500000000000000e+02 5.07619049072265625e+02 9.66652145385742188e+01,
              5.10450012207031250e+02 5.07619049072265625e+02 9.66652145385742188e+01,
              5.11399993896484375e+02 5.07619049072265625e+02 9.66652145385742188e+01,
              5.02850006103515625e+02 5.08571441650390625e+02 9.66652145385742188e+01,
              5.03799987792968750e+02 5.08571441650390625e+02 9.66652145385742188e+01,
              5.04750000000000000e+02 5.08571441650390625e+02 9.66652145385742188e+01,
              5.05700012207031250e+02 5.08571441650390625e+02 9.66652145385742188e+01,
              5.06649993896484375e+02 5.08571441650390625e+02 9.66652145385742188e+01,
              5.07600006103515625e+02 5.08571441650390625e+02 9.66652145385742188e+01,
              5.08549987792968750e+02 5.08571441650390625e+02 9.66652145385742188e+01,
              5.09500000000000000e+02 5.08571441650390625e+02 9.66652145385742188e+01,
              5.10450012207031250e+02 5.08571441650390625e+02 9.66652145385742188e+01,
              5.11399993896484375e+02 5.08571441650390625e+02 9.66652145385742188e+01,
              5.03799987792968750e+02 5.09523803710937500e+02 9.66652145385742188e+01,
              5.04750000000000000e+02 5.09523803710937500e+02 9.66652145385742188e+01,
              5.05700012207031250e+02 5.09523803710937500e+02 9.66652145385742188e+01,
              5.06649993896484375e+02 5.09523803710937500e+02 9.66652145385742188e+01,
              5.07600006103515625e+02 5.09523803710937500e+02 9.66652145385742188e+01,
              5.08549987792968750e+02 5.09523803710937500e+02 9.66652145385742188e+01,
              5.09500000000000000e+02 5.09523803710937500e+02 9.66652145385742188e+01,
              5.10450012207031250e+02 5.09523803710937500e+02 9.66652145385742188e+01,
              5.11399993896484375e+02 5.09523803710937500e+02 9.66652145385742188e+01,
              5.12349975585937500e+02 5.09523803710937500e+02 9.66652145385742188e+01,
              5.04750000000000000e+02 5.10476196289062500e+02 9.66652145385742188e+01,
              5.05700012207031250e+02 5.10476196289062500e+02 9.66652145385742188e+01,
              5.06649993896484375e+02 5.10476196289062500e+02 9.66652145385742188e+01,
              5.07600006103515625e+02 5.10476196289062500e+02 9.66652145385742188e+01,
              5.08549987792968750e+02 5.10476196289062500e+02 9.66652145385742188e+01,
              5.09500000000000000e+02 5.10476196289062500e+02 9.66652145385742188e+01,
              5.10450012207031250e+02 5.10476196289062500e+02 9.66652145385742188e+01,
              5.11399993896484375e+02 5.10476196289062500e+02 9.66652145385742188e+01,
              5.12349975585937500e+02 5.10476196289062500e+02 9.66652145385742188e+01,
              5.05700012207031250e+02 5.11428558349609375e+02 9.66652145385742188e+01,
              5.06649993896484375e+02 5.11428558349609375e+02 9.66652145385742188e+01,
              5.07600006103515625e+02 5.11428558349609375e+02 9.66652145385742188e+01,
              5.08549987792968750e+02 5.11428558349609375e+02 9.66652145385742188e+01,
              5.09500000000000000e+02 5.11428558349609375e+02 9.66652145385742188e+01,
              5.10450012207031250e+02 5.11428558349609375e+02 9.66652145385742188e+01,
              5.11399993896484375e+02 5.11428558349609375e+02 9.66652145385742188e+01,
              5.06649993896484375e+02 5.12380981445312500e+02 9.66652145385742188e+01,
              5.07600006103515625e+02 5.12380981445312500e+02 9.66652145385742188e+01,
              5.08549987792968750e+02 5.12380981445312500e+02 9.66652145385742188e+01,
              5.09500000000000000e+02 5.12380981445312500e+02 9.66652145385742188e+01,
              5.10450012207031250e+02 5.12380981445312500e+02 9.66652145385742188e+01,
              5.10450012207031250e+02 5.01904754638671875e+02 9.76434783935546875e+01,
              5.11399993896484375e+02 5.01904754638671875e+02 9.76434783935546875e+01,
              5.09500000000000000e+02 5.02857147216796875e+02 9.76434783935546875e+01,
              5.10450012207031250e+02 5.02857147216796875e+02 9.76434783935546875e+01,
              5.11399993896484375e+02 5.02857147216796875e+02 9.76434783935546875e+01,
              5.12349975585937500e+02 5.02857147216796875e+02 9.76434783935546875e+01,
              5.13299987792968750e+02 5.02857147216796875e+02 9.76434783935546875e+01,
              5.08549987792968750e+02 5.03809509277343750e+02 9.76434783935546875e+01,
              5.09500000000000000e+02 5.03809509277343750e+02 9.76434783935546875e+01,
              5.10450012207031250e+02 5.03809509277343750e+02 9.76434783935546875e+01,
              5.11399993896484375e+02 5.03809509277343750e+02 9.76434783935546875e+01,
              5.12349975585937500e+02 5.03809509277343750e+02 9.76434783935546875e+01,
              5.13299987792968750e+02 5.03809509277343750e+02 9.76434783935546875e+01,
              5.14250000000000000e+02 5.03809509277343750e+02 9.76434783935546875e+01,
              5.08549987792968750e+02 5.04761901855468750e+02 9.76434783935546875e+01,
              5.09500000000000000e+02 5.04761901855468750e+02 9.76434783935546875e+01,
              5.10450012207031250e+02 5.04761901855468750e+02 9.76434783935546875e+01,
              5.11399993896484375e+02 5.04761901855468750e+02 9.76434783935546875e+01,
              5.12349975585937500e+02 5.04761901855468750e+02 9.76434783935546875e+01,
              5.13299987792968750e+02 5.04761901855468750e+02 9.76434783935546875e+01,
              5.14250000000000000e+02 5.04761901855468750e+02 9.76434783935546875e+01,
              5.08549987792968750e+02 5.05714294433593750e+02 9.76434783935546875e+01,
              5.09500000000000000e+02 5.05714294433593750e+02 9.76434783935546875e+01,
              5.10450012207031250e+02 5.05714294433593750e+02 9.76434783935546875e+01,
              5.11399993896484375e+02 5.05714294433593750e+02 9.76434783935546875e+01,
              5.12349975585937500e+02 5.05714294433593750e+02 9.76434783935546875e+01,
              5.13299987792968750e+02 5.05714294433593750e+02 9.76434783935546875e+01,
              5.14250000000000000e+02 5.05714294433593750e+02 9.76434783935546875e+01,
              5.09500000000000000e+02 5.06666656494140625e+02 9.76434783935546875e+01,
              5.10450012207031250e+02 5.06666656494140625e+02 9.76434783935546875e+01,
              5.11399993896484375e+02 5.06666656494140625e+02 9.76434783935546875e+01,
              5.12349975585937500e+02 5.06666656494140625e+02 9.76434783935546875e+01,
              5.13299987792968750e+02 5.06666656494140625e+02 9.76434783935546875e+01,
              5.11399993896484375e+02 5.07619049072265625e+02 9.76434783935546875e+01,
              5.12349975585937500e+02 5.07619049072265625e+02 9.76434783935546875e+01,
              5.13299987792968750e+02 5.07619049072265625e+02 9.76434783935546875e+01,
              5.14250000000000000e+02 5.07619049072265625e+02 9.76434783935546875e+01,
              5.12349975585937500e+02 5.08571441650390625e+02 9.76434783935546875e+01,
              5.13299987792968750e+02 5.08571441650390625e+02 9.76434783935546875e+01,
              5.14250000000000000e+02 5.08571441650390625e+02 9.76434783935546875e+01,
              5.12349975585937500e+02 5.09523803710937500e+02 9.76434783935546875e+01,
              5.13299987792968750e+02 5.09523803710937500e+02 9.76434783935546875e+01,
              5.14250000000000000e+02 5.09523803710937500e+02 9.76434783935546875e+01,
              5.15200012207031250e+02 5.09523803710937500e+02 9.76434783935546875e+01,
              5.12349975585937500e+02 5.10476196289062500e+02 9.76434783935546875e+01,
              5.13299987792968750e+02 5.10476196289062500e+02 9.76434783935546875e+01,
              5.14250000000000000e+02 5.10476196289062500e+02 9.76434783935546875e+01,
              5.15200012207031250e+02 5.10476196289062500e+02 9.76434783935546875e+01,
              5.16150024414062500e+02 5.10476196289062500e+02 9.76434783935546875e+01,
              5.12349975585937500e+02 5.11428558349609375e+02 9.76434783935546875e+01,
              5.13299987792968750e+02 5.11428558349609375e+02 9.76434783935546875e+01,
              5.14250000000000000e+02 5.11428558349609375e+02 9.76434783935546875e+01,
              5.15200012207031250e+02 5.11428558349609375e+02 9.76434783935546875e+01,
              5.16150024414062500e+02 5.11428558349609375e+02 9.76434783935546875e+01,
              5.12349975585937500e+02 5.12380981445312500e+02 9.76434783935546875e+01,
              5.13299987792968750e+02 5.12380981445312500e+02 9.76434783935546875e+01,
              5.14250000000000000e+02 5.12380981445312500e+02 9.76434783935546875e+01,
              5.15200012207031250e+02 5.12380981445312500e+02 9.76434783935546875e+01,
              5.16150024414062500e+02 5.12380981445312500e+02 9.76434783935546875e+01,
              5.17099975585937500e+02 5.12380981445312500e+02 9.76434783935546875e+01,
              5.12349975585937500e+02 5.13333312988281250e+02 9.76434783935546875e+01,
              5.13299987792968750e+02 5.13333312988281250e+02 9.76434783935546875e+01,
              5.14250000000000000e+02 5.13333312988281250e+02 9.76434783935546875e+01,
              5.15200012207031250e+02 5.13333312988281250e+02 9.76434783935546875e+01,
              5.16150024414062500e+02 5.13333312988281250e+02 9.76434783935546875e+01,
              5.17099975585937500e+02 5.13333312988281250e+02 9.76434783935546875e+01,
              5.13299987792968750e+02 5.14285705566406250e+02 9.76434783935546875e+01,
              5.14250000000000000e+02 5.14285705566406250e+02 9.76434783935546875e+01,
              5.15200012207031250e+02 5.14285705566406250e+02 9.76434783935546875e+01,
              5.16150024414062500e+02 5.14285705566406250e+02 9.76434783935546875e+01,
              5.14250000000000000e+02 5.15238098144531250e+02 9.76434783935546875e+01,
              5.15200012207031250e+02 5.15238098144531250e+02 9.76434783935546875e+01,
              5.09500000000000000e+02 5.01904754638671875e+02 9.81326065063476563e+01,
              5.10450012207031250e+02 5.01904754638671875e+02 9.81326065063476563e+01,
              5.11399993896484375e+02 5.01904754638671875e+02 9.81326065063476563e+01,
              5.12349975585937500e+02 5.01904754638671875e+02 9.81326065063476563e+01,
              5.13299987792968750e+02 5.01904754638671875e+02 9.81326065063476563e+01,
              5.08549987792968750e+02 5.02857147216796875e+02 9.81326065063476563e+01,
              5.09500000000000000e+02 5.02857147216796875e+02 9.81326065063476563e+01,
              5.10450012207031250e+02 5.02857147216796875e+02 9.81326065063476563e+01,
              5.11399993896484375e+02 5.02857147216796875e+02 9.81326065063476563e+01,
              5.12349975585937500e+02 5.02857147216796875e+02 9.81326065063476563e+01,
              5.13299987792968750e+02 5.02857147216796875e+02 9.81326065063476563e+01,
              5.14250000000000000e+02 5.02857147216796875e+02 9.81326065063476563e+01,
              5.08549987792968750e+02 5.03809509277343750e+02 9.81326065063476563e+01,
              5.09500000000000000e+02 5.03809509277343750e+02 9.81326065063476563e+01,
              5.10450012207031250e+02 5.03809509277343750e+02 9.81326065063476563e+01,
              5.11399993896484375e+02 5.03809509277343750e+02 9.81326065063476563e+01,
              5.12349975585937500e+02 5.03809509277343750e+02 9.81326065063476563e+01,
              5.13299987792968750e+02 5.03809509277343750e+02 9.81326065063476563e+01,
              5.14250000000000000e+02 5.03809509277343750e+02 9.81326065063476563e+01,
              5.08549987792968750e+02 5.04761901855468750e+02 9.81326065063476563e+01,
              5.09500000000000000e+02 5.04761901855468750e+02 9.81326065063476563e+01,
              5.10450012207031250e+02 5.04761901855468750e+02 9.81326065063476563e+01,
              5.11399993896484375e+02 5.04761901855468750e+02 9.81326065063476563e+01,
              5.12349975585937500e+02 5.04761901855468750e+02 9.81326065063476563e+01,
              5.13299987792968750e+02 5.04761901855468750e+02 9.81326065063476563e+01,
              5.14250000000000000e+02 5.04761901855468750e+02 9.81326065063476563e+01,
              5.08549987792968750e+02 5.05714294433593750e+02 9.81326065063476563e+01,
              5.09500000000000000e+02 5.05714294433593750e+02 9.81326065063476563e+01,
              5.10450012207031250e+02 5.05714294433593750e+02 9.81326065063476563e+01,
              5.11399993896484375e+02 5.05714294433593750e+02 9.81326065063476563e+01,
              5.12349975585937500e+02 5.05714294433593750e+02 9.81326065063476563e+01,
              5.13299987792968750e+02 5.05714294433593750e+02 9.81326065063476563e+01,
              5.14250000000000000e+02 5.05714294433593750e+02 9.81326065063476563e+01,
              5.09500000000000000e+02 5.06666656494140625e+02 9.81326065063476563e+01,
              5.10450012207031250e+02 5.06666656494140625e+02 9.81326065063476563e+01,
              5.11399993896484375e+02 5.06666656494140625e+02 9.81326065063476563e+01,
              5.12349975585937500e+02 5.06666656494140625e+02 9.81326065063476563e+01,
              5.13299987792968750e+02 5.06666656494140625e+02 9.81326065063476563e+01,
              5.14250000000000000e+02 5.06666656494140625e+02 9.81326065063476563e+01,
              5.10450012207031250e+02 5.07619049072265625e+02 9.81326065063476563e+01,
              5.11399993896484375e+02 5.07619049072265625e+02 9.81326065063476563e+01,
              5.12349975585937500e+02 5.07619049072265625e+02 9.81326065063476563e+01,
              5.13299987792968750e+02 5.07619049072265625e+02 9.81326065063476563e+01,
              5.14250000000000000e+02 5.07619049072265625e+02 9.81326065063476563e+01,
              5.12349975585937500e+02 5.08571441650390625e+02 9.81326065063476563e+01,
              5.13299987792968750e+02 5.08571441650390625e+02 9.81326065063476563e+01,
              5.14250000000000000e+02 5.08571441650390625e+02 9.81326065063476563e+01,
              5.15200012207031250e+02 5.08571441650390625e+02 9.81326065063476563e+01,
              5.12349975585937500e+02 5.09523803710937500e+02 9.81326065063476563e+01,
              5.13299987792968750e+02 5.09523803710937500e+02 9.81326065063476563e+01,
              5.14250000000000000e+02 5.09523803710937500e+02 9.81326065063476563e+01,
              5.15200012207031250e+02 5.09523803710937500e+02 9.81326065063476563e+01,
              5.12349975585937500e+02 5.10476196289062500e+02 9.81326065063476563e+01,
              5.13299987792968750e+02 5.10476196289062500e+02 9.81326065063476563e+01,
              5.14250000000000000e+02 5.10476196289062500e+02 9.81326065063476563e+01,
              5.15200012207031250e+02 5.10476196289062500e+02 9.81326065063476563e+01,
              5.16150024414062500e+02 5.10476196289062500e+02 9.81326065063476563e+01,
              5.12349975585937500e+02 5.11428558349609375e+02 9.81326065063476563e+01,
              5.13299987792968750e+02 5.11428558349609375e+02 9.81326065063476563e+01,
              5.14250000000000000e+02 5.11428558349609375e+02 9.81326065063476563e+01,
              5.15200012207031250e+02 5.11428558349609375e+02 9.81326065063476563e+01,
              5.16150024414062500e+02 5.11428558349609375e+02 9.81326065063476563e+01,
              5.17099975585937500e+02 5.11428558349609375e+02 9.81326065063476563e+01,
              5.12349975585937500e+02 5.12380981445312500e+02 9.81326065063476563e+01,
              5.13299987792968750e+02 5.12380981445312500e+02 9.81326065063476563e+01,
              5.14250000000000000e+02 5.12380981445312500e+02 9.81326065063476563e+01,
              5.15200012207031250e+02 5.12380981445312500e+02 9.81326065063476563e+01,
              5.16150024414062500e+02 5.12380981445312500e+02 9.81326065063476563e+01,
              5.17099975585937500e+02 5.12380981445312500e+02 9.81326065063476563e+01,
              5.12349975585937500e+02 5.13333312988281250e+02 9.81326065063476563e+01,
              5.13299987792968750e+02 5.13333312988281250e+02 9.81326065063476563e+01,
              5.14250000000000000e+02 5.13333312988281250e+02 9.81326065063476563e+01,
              5.15200012207031250e+02 5.13333312988281250e+02 9.81326065063476563e+01,
              5.16150024414062500e+02 5.13333312988281250e+02 9.81326065063476563e+01,
              5.17099975585937500e+02 5.13333312988281250e+02 9.81326065063476563e+01,
              5.12349975585937500e+02 5.14285705566406250e+02 9.81326065063476563e+01,
              5.13299987792968750e+02 5.14285705566406250e+02 9.81326065063476563e+01,
              5.14250000000000000e+02 5.14285705566406250e+02 9.81326065063476563e+01,
              5.15200012207031250e+02 5.14285705566406250e+02 9.81326065063476563e+01,
              5.16150024414062500e+02 5.14285705566406250e+02 9.81326065063476563e+01,
              5.17099975585937500e+02 5.14285705566406250e+02 9.81326065063476563e+01,
              5.13299987792968750e+02 5.15238098144531250e+02 9.81326065063476563e+01,
              5.14250000000000000e+02 5.15238098144531250e+02 9.81326065063476563e+01,
              5.15200012207031250e+02 5.15238098144531250e+02 9.81326065063476563e+01,
              5.16150024414062500e+02 5.15238098144531250e+02 9.81326065063476563e+01,
              5.10450012207031250e+02 5.03809509277343750e+02 9.86217422485351563e+01,
              5.11399993896484375e+02 5.03809509277343750e+02 9.86217422485351563e+01,
              5.12349975585937500e+02 5.03809509277343750e+02 9.86217422485351563e+01,
              5.12349975585937500e+02 5.04761901855468750e+02 9.86217422485351563e+01,
              5.11399993896484375e+02 5.05714294433593750e+02 9.86217422485351563e+01,
              5.15200012207031250e+02 5.11428558349609375e+02 9.86217422485351563e+01,
              5.14250000000000000e+02 5.12380981445312500e+02 9.86217422485351563e+01,
              5.15200012207031250e+02 5.12380981445312500e+02 9.86217422485351563e+01,
              5.14250000000000000e+02 5.13333312988281250e+02 9.86217422485351563e+01,
              "
            />
          </IndexedFaceSet>
        </Shape>
      </Transform>
      <Transform
        translation="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
        rotation="0.00000000000000000e+00 0.00000000000000000e+00 1.00000000000000000e+00 -0.00000000000000000e+00"
        scale="1.00000000000000000e+00 1.00000000000000000e+00 1.00000000000000000e+00"
      >
        <Shape>
          <Appearance>
            <Material
              ambientIntensity="6.99999988079071045e-01"
              emissiveColor="6.99999999999999956e-01 0.00000000000000000e+00 0.00000000000000000e+00"
              diffuseColor="2.99999999999999989e-01 0.00000000000000000e+00 0.00000000000000000e+00"
              specularColor="0.00000000000000000e+00 0.00000000000000000e+00 0.00000000000000000e+00"
              shininess="7.81250000000000000e-03"
              transparency="0.00000000000000000e+00"
            />
          </Appearance>
          <PointSet>
            <Coordinate
              point="
              5.10000000000000000e+02 5.10000000000000000e+02 9.64000000000000057e+01,
              5.10000000000000000e+02 5.10000000000000000e+02 9.62999999999999972e+01,
              5.10000000000000000e+02 5.10000000000000000e+02 9.62000000000000028e+01,
              5.10000000000000000e+02 5.10000000000000000e+02 9.60999999999999943e+01,
              5.10000000000000000e+02 5.10000000000000000e+02 9.60000000000000000e+01,
              5.10000000000000000e+02 5.10000000000000000e+02 9.30000000000000000e+01,
              5.10000000000000000e+02 5.10000000000000000e+02 9.29000000000000057e+01,
              5.10000000000000000e+02 5.10000000000000000e+02 9.27999999999999972e+01,
              5.10000000000000000e+02 5.10000000000000000e+02 9.27000000000000028e+01,
              5.10000000000000000e+02 5.10000000000000000e+02 9.25999999999999943e+01,
              5.10000000000000000e+02 5.10000000000000000e+02 9.25000000000000000e+01,
              5.10000000000000000e+02 5.10000000000000000e+02 9.24000000000000057e+01,
              5.10000000000000000e+02 5.10000000000000000e+02 9.22999999999999972e+01,
              5.10000000000000000e+02 5.10000000000000000e+02 9.22000000000000028e+01,
              5.10000000000000000e+02 5.10000000000000000e+02 9.20999999999999943e+01,
              5.10000000000000000e+02 5.10000000000000000e+02 7.95999999999999943e+01,
              5.10000000000000000e+02 5.10000000000000000e+02 7.95000000000000000e+01,
              5.10000000000000000e+02 5.10000000000000000e+02 7.94000000000000057e+01,
              5.04000000000000000e+02 5.08000000000000000e+02 9.62000000000000028e+01,
              5.04000000000000000e+02 5.08000000000000000e+02 9.60999999999999943e+01,
              5.04000000000000000e+02 5.08000000000000000e+02 9.60000000000000000e+01,
              5.04000000000000000e+02 5.08000000000000000e+02 9.59000000000000057e+01,
              5.04000000000000000e+02 5.08000000000000000e+02 9.57999999999999972e+01,
              5.04000000000000000e+02 5.08000000000000000e+02 9.57000000000000028e+01,
              5.04000000000000000e+02 5.08000000000000000e+02 9.55999999999999943e+01,
              5.04000000000000000e+02 5.08000000000000000e+02 9.55000000000000000e+01,
              5.04000000000000000e+02 5.08000000000000000e+02 9.54000000000000057e+01,
              5.04000000000000000e+02 5.08000000000000000e+02 9.52999999999999972e+01,
              5.04000000000000000e+02 5.08000000000000000e+02 8.22000000000000028e+01,
              5.04000000000000000e+02 5.08000000000000000e+02 8.20999999999999943e+01,
              5.04000000000000000e+02 5.08000000000000000e+02 8.20000000000000000e+01,
              5.04000000000000000e+02 5.08000000000000000e+02 8.19000000000000057e+01,
              5.04000000000000000e+02 5.08000000000000000e+02 8.17999999999999972e+01,
              5.04000000000000000e+02 5.08000000000000000e+02 8.17000000000000028e+01,
              5.04000000000000000e+02 5.08000000000000000e+02 8.15999999999999943e+01,
              5.04000000000000000e+02 5.08000000000000000e+02 8.15000000000000000e+01,
              5.04000000000000000e+02 5.08000000000000000e+02 8.14000000000000057e+01,
              5.04000000000000000e+02 5.08000000000000000e+02 8.12999999999999972e+01,
              5.04000000000000000e+02 5.08000000000000000e+02 8.12000000000000028e+01,
              5.04000000000000000e+02 5.08000000000000000e+02 8.10999999999999943e+01,
              5.11000000000000000e+02 5.04000000000000000e+02 9.82999999999999972e+01,
              5.11000000000000000e+02 5.04000000000000000e+02 9.82000000000000028e+01,
              5.11000000000000000e+02 5.04000000000000000e+02 9.80999999999999943e+01,
              5.11000000000000000e+02 5.04000000000000000e+02 9.80000000000000000e+01,
              5.11000000000000000e+02 5.04000000000000000e+02 9.75000000000000000e+01,
              5.11000000000000000e+02 5.04000000000000000e+02 9.74000000000000057e+01,
              5.11000000000000000e+02 5.04000000000000000e+02 9.02000000000000028e+01,
              5.11000000000000000e+02 5.04000000000000000e+02 9.00999999999999943e+01,
              5.11000000000000000e+02 5.04000000000000000e+02 9.00000000000000000e+01,
              5.11000000000000000e+02 5.04000000000000000e+02 8.99000000000000057e+01,
              5.11000000000000000e+02 5.04000000000000000e+02 8.97999999999999972e+01,
              5.11000000000000000e+02 5.04000000000000000e+02 8.42999999999999972e+01,
              5.11000000000000000e+02 5.04000000000000000e+02 8.42000000000000028e+01,
              5.11000000000000000e+02 5.04000000000000000e+02 8.40999999999999943e+01,
              5.11000000000000000e+02 5.04000000000000000e+02 8.40000000000000000e+01,
              5.11000000000000000e+02 5.04000000000000000e+02 8.39000000000000057e+01,
              5.15000000000000000e+02 5.12000000000000000e+02 9.82999999999999972e+01,
              5.15000000000000000e+02 5.12000000000000000e+02 9.82000000000000028e+01,
              5.15000000000000000e+02 5.12000000000000000e+02 9.80999999999999943e+01,
              5.15000000000000000e+02 5.12000000000000000e+02 9.80000000000000000e+01,
              5.15000000000000000e+02 5.12000000000000000e+02 9.75000000000000000e+01,
              5.15000000000000000e+02 5.12000000000000000e+02 9.74000000000000057e+01,
              5.15000000000000000e+02 5.12000000000000000e+02 9.45999999999999943e+01,
              5.15000000000000000e+02 5.12000000000000000e+02 9.45000000000000000e+01,
              5.15000000000000000e+02 5.12000000000000000e+02 9.44000000000000057e+01,
              5.15000000000000000e+02 5.12000000000000000e+02 9.42999999999999972e+01,
              5.15000000000000000e+02 5.12000000000000000e+02 9.42000000000000028e+01,
              5.15000000000000000e+02 5.12000000000000000e+02 9.40999999999999943e+01,
              5.15000000000000000e+02 5.12000000000000000e+02 9.02000000000000028e+01,
              5.15000000000000000e+02 5.12000000000000000e+02 9.00999999999999943e+01,
              5.15000000000000000e+02 5.12000000000000000e+02 9.00000000000000000e+01,
              5.15000000000000000e+02 5.12000000000000000e+02 8.99000000000000057e+01,
              5.15000000000000000e+02 5.12000000000000000e+02 8.97999999999999972e+01,
              5.15000000000000000e+02 5.12000000000000000e+02 8.67999999999999972e+01,
              5.15000000000000000e+02 5.12000000000000000e+02 8.67000000000000028e+01,
              5.15000000000000000e+02 5.12000000000000000e+02 8.65999999999999943e+01,
              5.15000000000000000e+02 5.12000000000000000e+02 8.65000000000000000e+01,
              "
            />
          </PointSet>
        </Shape>
      </Transform>
    </Transform>
  </Scene>
</template>

<script>
export default {
  name: "TriangleXdom",
  mounted: function() {
    this.$nextTick(function() {
      this.$emit("loaded", true);
    });
  },
};
</script>
